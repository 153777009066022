@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

@font-face {
    font-family: 'Bessita Handwriting Free';
    src: url('../fonts/BessitaHandwritingFree.woff2') format('woff2'),
        url('../fonts/BessitaHandwritingFree.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {border: 0;font-family: inherit;font-size: 100%;font-style: inherit;font-weight: inherit;margin: 0;outline: 0;padding: 0;vertical-align: baseline;}

html {
    -webkit-text-size-adjust: none;
}

:focus {
    outline: 0;
}

ol, ul {
    margin: 0;
    padding: 0px 0px 10px 40px;
}

table {
    border-collapse: separate;
    border-spacing: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
}

embed, iframe, object {
    max-width: 100%;
    display: none
}

body {
    color: #000;
    word-wrap: break-word;
    font-size: 15px;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden !important;
    overflow-y: auto !important;
    font-family: 'Poppins', sans-serif;
    background: #fff;
    font-weight: 400;
}

cite, em, i {
    font-style: italic;
}

sup{
    top: 0;
}

input, textarea {
    -webkit-border-radius: 0px;
    border-radius: 0;
}

input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"],
input[type='checkbox'],
textarea {
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
textarea,
select {
    padding: 12px 10px;
    border: 1px solid #e4e4e4;
    width: 100%;
    margin: 0 0 10px;
    background-color: #fff;
    border-radius:6px;
    height: 48px;
    font-size: 15px;
    color: #000;
    font-family: 'Poppins', sans-serif;
}

input[type="reset"],
input[type="button"],
input[type="submit"],
.button,
button {
    color: #fff;
    display: inline-block;
    border-radius: 8px;
    margin: 15px 0px 10px 0px;
    padding: 17px 26px 17px;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    cursor: pointer;
    background: #000;
    border: 0px;
    font-size: 15px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    text-transform: uppercase;
    line-height: 1;
    text-align: center;
    overflow: hidden;
    position: relative;
    z-index: 2;
   font-family: 'Poppins', sans-serif;
    letter-spacing: 1px;
    font-weight: 500;
}

input[type="reset"]:hover,
input[type="button"]:hover,
input[type="submit"]:hover,
.btn:hover,
.button:hover,
button:hover {
    color: #fff;
    background: #DB203B;
}

input[type="reset"]:active,
input[type="button"]:active,
input[type="submit"]:active {
    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
}

a {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

a:focus,
a:active,
a:hover {
    text-decoration: none;
}

a:hover {
    color: #DB203B;
}

figure {
    margin: 0;
    text-align: center;
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px 0px 25px 0px;
    padding: 0px;
    line-height: 1.1;
    color: #000;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    font-weight: 600;
}

h1 {
    font-size: 42px;
}

h2 {
    font-size: 35px;
}

h3 {
    font-size: 26px;
}

h4 {
    font-size: 22px;
}

h5 {
    font-size: 17px;
}

h6 {
    font-size: 16px;
}

.show {
    display: block;
}

.hidden {
    display: none;
}

.invisible {
    visibility: hidden;
}

.fl {
    float: left
}

.fr {
    float: right
}

.rel {
    position: relative
}

.textcenter {
    text-align: center
}

.textleft {
    text-align: left
}

.textright {
    text-align: right
}

*,
*:before,
*:after {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.clear,
.clearfix {
    clear: both;
}

.clearfix:after {
    clear: both;
    display: table;
    content: ""
}

strong,
b {
    font-family: 'Poppins', sans-serif;
}

.required_star {
    color: #f00;
}

.container {
    max-width: 1320px;
    margin: 0 auto;
    padding: 0 20px;
    width: 100%;
}

.slick-slider,
.slick-slider * {
    outline: none;
}

p {
    margin: 0 0 15px;
}

.title_sec {
    margin: 0 0 45px;
    color: #040507;
}

.title_sec h2 {
    font-size: 44px;
    line-height: 1;
    margin: 0 0 5px;
}

.title_sec small {
    font-size: 16px;
    display: block;
    font-weight: normal;
    margin: 0 0 13px;
}

.title1 {
    font-size: 32px;
    color: #040507;
    line-height: 1;
    margin: 0 0 24px;
}

.title3 {
    font-size: 20px;
    color: #040507;
    line-height: 1;
}

.title4 {
    font-size: 18px;
    color: #010101;
}

.icon-cap {
    width: 34px;
    height: 31px;
    display: block;
}

table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
}

.table_overflow {
    overflow-x: auto;
    overflow-y: hidden;
    margin-bottom: 20px;
}

.table_overflow .table {
    margin-bottom: 0;
}

.dropdown-menu>li>a {
    padding: 7px 20px;
    border-top: 1px solid #e9ebf5;
}

.dropdown-menu>li:first-child>a {
    border-top: 0;
}

.form_grouptt {
    font-size: 16px;
    margin: 0 0 20px 2px;
    color: #000;
    text-transform: capitalize;
}

.form-group {
    margin: 0 0 8px;
}

label {
    font-size: 14px;
    color: #959595;
}

label span {
    color: #f00;
    margin-left: 3px;
}

.manitatory-select .Select-placeholder:after {
    content: '*';
    color: red;
}

.form-control {
    height: 48px;
    border: 1px solid #e2e2e2;
    box-shadow: none;
    -webkit-box-shadow: none;
    padding: 6px 18px;
    background: #fff;
    font-size: 15px;
    border-radius: 6px;
    color: #615f5f;
    width: 100%;
    display: block
}

textarea.form-control {
    height: 160px;
}

textarea.form-control.sm {
    height: 90px;
}

.form-control.error,
.Select-control.error {
    border-color: #FFBABA;
    color: #686a78;
}

.form-control:focus {
    box-shadow: none;
    -webkit-box-shadow: none;
    border-color: #000000;
}

.focus-out {
    position: relative;
}

.focus-out label {
    position: absolute;
    left: 18px;
    top: 14px;
    pointer-events: none;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    z-index: 3;
    height: 24px;
    overflow: hidden;
    color: #595656;
}

.focused label {
    padding: 0 6px;
    font-size: 11px;
    background: #fff;
    top: -7px;
    height: auto;
}

.clock_icon,
.ileft_ico {
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    height: 100%;
}

.clock_icon img,
.ileft_ico img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: block;
}

.other-add {
    margin: 30px 0 0 0
}

.acc-inform .form-group .row:after,
.filter_tabsec .nav-tabs:after {
    display: table;
    clear: both;
    content: ""
}

.acc-inform .form-group .row .col-md-6 {
    width: 49%;
    float: left;
    margin: 0 2% 0 0;
}

.acc-inform .form-group .row .col-md-6:last-of-type {
    margin-right: 0;
}

.re_select input[type="text"] {
    margin: 0;
    height: 34px;
}

.acc-inform .form-group .row .button {
    width: 100%;
    display: block;
    margin: 0;
    height: 48px;
    color: #fff;
    background: #db203b;
}

.acc-inform .form-group .row .button:hover {
    background: #000;
    color: #fff
}

.re_select .Select-control {
    border: 1px solid #e2e2e2;
    border-radius: 0;
    height: 47px;
    outline: 0;
}

.re_select .Select-placeholder,
.re_select .Select--single > .Select-control .Select-value {
    padding: 0 22px;
    line-height: 45px;
    text-align: left;
    color: #72624f;
}

.re_select .Select-input {
    padding: 0 20px;
    height: 45px;
}

.re_select .Select-input > input {
    line-height: 27px;
    padding: 10px 0;
}

.re_select .Select.is-open > .Select-control {
    border-color: #d5d7e0;
}

.re_select .Select-arrow {
    background: url(../images/arrow-down.png) no-repeat center;
    border: 0;
    width: 18px;
    height: 10px;
}

.re_select .is-open .Select-arrow {
    background-image: url(../images/arrow-up.png);
}

.re_select .Select-menu-outer {
    z-index: 4;
    text-align: left;
}

.re_select .Select-menu-outer {
    background: #ecdfcc;
}

.re_select .Select-arrow-zone {
    padding: 0;
    width: 42px;
}

.re_select .Select--multi .Select-value {
    background-color: #ecdfcc;
    border-color: #dcd8d8;
    color: #181818;
    position: relative;
    padding-right: 19px;
    border-radius: 10px;
    overflow: hidden;
}

.re_select .Select--multi .Select-value-icon {
    border-color: #dcd8d8;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
}

.re_select .Select--multi .Select-value-icon:hover,
.re_select .Select--multi .Select-value-icon:focus {
    background-color: #1c1c1c;
    border-color: #1c1c1c;
    color: #fff;
}

.re_select .Select--multi .Select-value-icon:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 0;
    font-size: 0;
}

.re_select .Select--multi .Select-value-label {
    padding: 2px 5px 2px 9px;
}

.custom_select {
    position: relative;
}

.custom_select:after {
    content: "";
    background: #fff url(../images/arrow-down.png) no-repeat center;
    width: 45px;
    top: 1px;
    right: 1px;
    bottom: 1px;
    position: absolute;
    pointer-events: none;
    height: 46px;
    border-radius: 6px;
}

.react-datepicker {
        font-family: 'Montserrat', sans-serif !important;
}

.react_datepicker .react-datepicker-wrapper,
.react_datepicker .react-datepicker__input-container {
    display: block;
}

.react_datepicker .react-datepicker {
    font-size: 14px;
}

.react_datepicker .react-datepicker__current-month,
.react_datepicker .react-datepicker-time__header {
    font-size: 1.1rem;
    font-weight: bold
}

.react_datepicker .react-datepicker__day-name,
.react_datepicker .react-datepicker__day,
.react_datepicker .react-datepicker__time-name {
    width: 2rem;
}

.react_datepicker .react-datepicker__time-container {
    width: 120px;
}

.react_datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box 
ul.react-datepicker__time-list {
    padding: 0;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list{
    display: block;
}

.react_datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 120px;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected {
    background-color: #000 !important
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover {
    background-color: #000 !important;
}

.react-datepicker__header {
    background-color: #f1f1f1 !important;
}

.react-datepicker__navigation {
    margin: 0;
    box-shadow: none;
    border-radius: 0;
}

.react-datepicker__navigation--previous {
    border-right-color: #000 !important;
}

.react-datepicker__navigation--next {
    border-left-color: #000 !important;
}

.react-datepicker__navigation--previous:hover,
.react-datepicker__navigation--next:hover {
    background: none
}

.react-datepicker__month-dropdown-container--select select,
.react-datepicker__year-dropdown-container--select select {
    height: 36px;
    padding: 5px 10px;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, 
.react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, 
.react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, 
.react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range{ 
    border-radius: 50% !important;     background-color: #000 !important}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box 
ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: #000 !important;
}
/* Custom Radio Check Box*/
@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  input[type=checkbox], input[type=radio] {
    --active: #da203a;
    --active-inner: #fff;
    --focus: 2px rgba(0, 0, 0, .3);
    --border: #757575;
    --border-hover: #000;
    --background: #fff;
    --disabled: #F6F8FF;
    --disabled-inner: #999;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 18px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
  }
  input[type=checkbox]:after,
input[type=radio]:after {
    content: "";
    display: block;
    left: -1px;
    top: -1px;
    position: absolute;
    transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
  }
  input[type=checkbox]:checked,
input[type=radio]:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: .3s;
    --d-t: .6s;
    --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
  }
  input[type=checkbox]:disabled,
input[type=radio]:disabled {
    --b: var(--disabled);
    cursor: not-allowed;
    opacity: 0.9;
  }
  input[type=checkbox]:disabled:checked,
input[type=radio]:disabled:checked {
    --b: var(--disabled-inner);
    --bc: var(--border);
  }
  input[type=checkbox]:disabled + label,
input[type=radio]:disabled + label {
    cursor: not-allowed;
  }
  input[type=checkbox]:hover:not(:checked):not(:disabled),
input[type=radio]:hover:not(:checked):not(:disabled) {
    --bc: var(--border-hover);
  }
  input[type=checkbox]:focus,
input[type=radio]:focus {
    box-shadow: 0 0 0 var(--focus);
  }
  input[type=checkbox]:not(.switch),
input[type=radio]:not(.switch) {
    width: 18px;
  }

  .compo_minmax_maindiv input[type=checkbox]:not(.switch),
  .compo_minmax_maindiv input[type=radio]:not(.switch) {
      width: 24px;
      height: 24px;
    }

    .compo_minmax_maindiv input[type=checkbox]:not(.switch):after {
        height: 13px;
        left: 7px;
    }

  input[type=checkbox]:not(.switch):after,
input[type=radio]:not(.switch):after {
    opacity: var(--o, 0);
  }
  input[type=checkbox]:not(.switch):checked,
input[type=radio]:not(.switch):checked {
    --o: 1;
  }
  input[type=checkbox] + label,
input[type=radio] + label{
    line-height: 21px;
    display: inline-block;
    cursor: pointer;
    margin-left: 5px;
  }

.compo_minmax_maindiv input[type=checkbox] + label, .compo_minmax_maindiv input[type=radio] + label{
    margin-left: 0;
}

  input[type=checkbox]:not(.switch) {
    border-radius: 3px;
  }
  input[type=checkbox]:not(.switch):after {
    width: 5px;
    height: 9px;
    border: 2px solid var(--active-inner);
    border-top: 0;
    border-left: 0;
    left: 6px;
    top: 3px;
    transform: rotate(var(--r, 20deg));
  }
  input[type=checkbox]:not(.switch):checked {
    --r: 43deg;
  }
  input[type=checkbox].switch {
    width: 38px;
    border-radius: 11px;
  }
  input[type=checkbox].switch:after {
    left: 2px;
    top: 2px;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    background: var(--ab, var(--border));
    transform: translateX(var(--x, 0));
  }
  input[type=checkbox].switch:checked {
    --ab: var(--active-inner);
    --x: 17px;
  }
  input[type=checkbox].switch:disabled:not(:checked):after {
    opacity: 0.6;
  }

  input[type=radio] {
    border-radius: 50%;
  }
  input[type=radio]:after {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: var(--active-inner);
    opacity: 0;
    transform: scale(var(--s, 0.7));
  }
  input[type=radio]:checked {
    --s: .5;
  }
}

.error {
    color: #f00;
    font-weight: normal;
}

span.error {
    background: #FFBABA;
    color: #D8000C;
    padding: 5px 10px;
    font-size: 11px;
    position: relative;
    display: inline-block;
    font-weight: normal;
    z-index: 4;
    line-height: 1.1;
}

span.error:before {
    content: "";
    border-bottom: 5px solid #ffbaba;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: -5px;
    width: 0;
}

.alert {
    padding: 10px 15px;
    border-radius: 0;
    -webkit-border-radius: 0;
    text-align: left;
    display: none;
    -webkit-transition: all 400ms;
    transition: all 400ms;
}

.alert_fixed {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 999;
    max-width: 1260px;
    margin: 0 auto;
    padding: 10px;
    line-height: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.alert_success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
}

.alert_danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}

.alert_fixed p {
    margin: 0;
}

.alert_fixed a {
    font-size: 24px;
    right: 20px;
    top: 5px;
    position: absolute
}

.display_inblock {
    display: inline-block;
}

.display_block {
    display: block;
}

.relative {
    position: relative;
}

.vtop {
    vertical-align: top;
}

.vmiddle {
    vertical-align: middle;
}

.vbottom {
    vertical-align: bottom;
}

.vmid_sec:before {
    content: "";
    height: 100%;
    display: inline-block;
    vertical-align: middle;
    font-size: 0;
}

.vmid_in {
    display: inline-block;
    vertical-align: middle;
    width: 98%;
}

.modal-dialog {
    margin: .5rem;
}

.modal-dialog-centered {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - (.5rem * 2));
    min-height: -webkit-calc(100% - (.5rem * 2));
}

.modal-content {
    width: 100%;
    box-shadow: none;
    -webkit-box-shadow: none;
    border-radius: 14px;
    border:1px solid #999;
}

.poup_wrap {
    width: 660px;
}

.popup_in {
    padding: 28px 60px 54px;
}

.close_popup {
    font-size: 38px;
    font-weight: normal;
    line-height: 32px;
    position: absolute;
    right: 10px;
    top: 10px;
    height: 32px;
    width: 32px;
    color: #231f20;
    text-align: center;
}

.chosen-container .chosen-results .highlighted {
    background: #ecdfcc !important;
}

button.mfp-close:hover,
button.mfp-arrow:hover {
    background: none;
}

.tgl {
    display: none;
}

.tgl,
.tgl:after,
.tgl:before,
.tgl *,
.tgl *:after,
.tgl *:before,
.tgl + .tgl-btn {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.tgl::-moz-selection,
.tgl:after::-moz-selection,
.tgl:before::-moz-selection,
.tgl *::-moz-selection,
.tgl *:after::-moz-selection,
.tgl *:before::-moz-selection,
.tgl + .tgl-btn::-moz-selection {
    background: none;
}

.tgl::-moz-selection,
.tgl:after::-moz-selection,
.tgl:before::-moz-selection,
.tgl *::-moz-selection,
.tgl *:after::-moz-selection,
.tgl *:before::-moz-selection,
.tgl + .tgl-btn::-moz-selection {
    background: none;
}

.tgl::selection,
.tgl:after::selection,
.tgl:before::selection,
.tgl *::selection,
.tgl *:after::selection,
.tgl *:before::selection,
.tgl + .tgl-btn::selection {
    background: none;
}

.tgl + .tgl-btn {
    outline: 0;
    display: block;
    width: 45px;
    height: 22px;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.tgl + .tgl-btn:after,
.tgl + .tgl-btn:before {
    position: relative;
    display: block;
    content: "";
    width: 50%;
    height: 100%;
}

.tgl + .tgl-btn:after {
    left: 1px;
}

.tgl + .tgl-btn:before {
    display: none;
}

.tgl:checked + .tgl-btn:after {
    left: 50%;
}

ul.tg-list {
    float: left;
    padding-top: 7px;
    list-style: none;
    padding-left: 0;
}

.tgl-light + .tgl-btn {
    background: #f9f9f9;
    padding: 0;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    border-radius: 2em;
}

.tgl-light + .tgl-btn:after {
    border-radius: 50%;
    background: #b4b4b4;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    width: 22px;
    height: 22px;
}

.tgl-light:checked + .tgl-btn:after {
    background: #4dc93c;
}

.desktop_hmenu_list .submenu-arow {
        padding: 0;
    margin: 0 0 0 4px;
        width: 14px;
}

.mobile_hmenu_list .submenu-arow {
    display: block;
    width: 40px;
    background: #e6e6e6;
    position: absolute;
    right: 0;
    top: 0;
    height: 50px;
}

.submenu-arow:after {
    content: "\f107";
    font-family: FontAwesome;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    top: 50%;
    line-height: 1;
    margin-top: -11px;
    font-size: 22px;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
    display: block !important;
    font-weight: normal;
}

.open-submenu .submenu-arow:after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    display: block !important;
}

.hsearch_sec, .hsearch_sec_mbl {
    width: 380px;
    position: absolute;
    right:0px;
    top: 40px;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    z-index: 3;
    visibility: hidden;
}

.hsearch_sec.open, .hsearch_sec_mbl.open {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
}

.hsearch_bx {
    position: relative;
}

.hsearch_bx .form-control {
    height: 48px;
    border: 0;
}

.hsearch_bx .btn {
    background: #ecdfcc url(../images/search.png) no-repeat center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 65px;
    border-radius: 0;
    margin: 0;
    height: auto;
    padding: 0;
}

.hsearch_bx .btn:hover {
    background-color: #1c1c1c;
}

.hsearch_bx .re_select .Select-control {
    border: 0;
    border-radius: 8px;
    -webkit-box-shadow: 4px 0 6px 0 rgba(24, 24, 24, 0.23);
    box-shadow: 4px 0 6px 0 rgba(24, 24, 24, 0.23);
    overflow: visible;
    height: 54px;
}

.hsearch_bx .re_select .Select-arrow {
    opacity: 0;
}

.hsearch_bx .re_select .Select-placeholder {
    line-height: 52px;
    padding: 0 26px;
    color: #abaaa8;
}

.hsearch_bx .re_select .Select-input {
    height: 54px;
    padding: 0 26px;
    float: left;
}

.hsearch_bx .re_select .Select-input > input {
    line-height: 34px;
}

.hsearch_trigger_img img {
    width: 26px
}

.hsearch_trigger_img {
    margin: 5px 0 0 10px;
}

.hmenu_sec ul {
    padding: 0;
    list-style: none;
    text-align: initial;
}

.hmenu_list {
    margin: 0;
}

.hmenu_list > li {
    display: inline-flex;
    position: relative;
    margin-right: 25px;
}

.hmenu_list > li > a > span {
    position: relative;
}

.hmenu_actions > li {
    list-style: none;
    position: relative;
}

.header-top-cls .hordertype_sec a{
    margin: 0 15px 0 5px;
}

.hordertype_sec a {
    display: block;
    padding: 15px 40px 15px 22px;
    color: #fff;
    background: #DB203B;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    position: relative;
    letter-spacing: 0.5px;
    margin: 0 15px 0 25px;
    line-height: 1;
    font-weight: 600;
    border-radius: 6px;
    white-space: nowrap
}
.hordertype_sec a:after {
    content:"\f107";
    font-family: FontAwesome;
    position: absolute;
    right: 10px;
    top: 13px;
    font-size: 20px;
    line-height: 1;
}

.hordertype_sec a:hover {
    color: #000;
    background: #fff;
}

.barn-family a {
    padding: 10px 40px 10px 40px;
    color: #1f2321;
    background: #dfb87f;
    text-transform: uppercase;
    position: relative;
    letter-spacing: 0.5px;
    margin: 0 0 0 20px;
    line-height: 1;
    border-radius: 20px;
    white-space: nowrap;
    min-width: 172px;
    display: flex;
    justify-content: center;
    font-family: AileronBold!important;
    font-size: 14px;
}

.barn-family a:hover {
    color: #fff;
    background: #1f2321;
}

.hmenu_sec .desktop_hmenu_list ul.submenu_list {
    margin: 0;
    padding: 0px;
    background: #3f3f3f;
    z-index: 99;
    width: 212px;
    position: absolute;
    left: 0px;
    top: 100%;
    display: none;
}

.desktop_hmenu_list > li:hover > ul,
.desktop_hmenu_list > li ul li:hover>ul {
    display: block;
}

.desktop_hmenu_list ul.submenu_list li a {
    font-size: 13px;
    color: #fff;
    text-transform: capitalize;
    padding: 10px;
    display: block;
}

.hmenu_sec .desktop_hmenu_list ul.submenu_list li a:hover {
    color: #DB203B;
    background: #232323;
}

.hmenu_sec .mobile_hmenu_list ul.submenu_list {
    display: none;
    background: #f7f7f7
}

.mobile_hmenu_list ul.submenu_list li a {
    display: block;
    padding: 10px 15px;
    font-size: 14px;
    color: #333;
    border-bottom: 1px solid #e4e4e4;
}

.mobile_hmenu_list ul.submenu_list li a:hover {
    color: #769e90;
}

.hsign_sec > a {
    display: inline-block;
    position: relative;
}

.hsign_sec > a:hover {
    color: #000;
}

.hsearch_sec .search_result, .hsearch_sec_mbl .search_result_mbl, .product_search_result {
    margin: 0;
    padding: 0;
    list-style: none;
    background: #fff;
    max-height: 300px;
    overflow-y: auto;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    display: none;
}
.product_search_result{position: absolute; top: 100%; left: 0;z-index: 5;width: 100%;}

.search_result li, .search_result_mbl li, .product_search_result li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.090);
}

.search_result li a,
.no-pro-found, .search_result_mbl li a, .product_search_result li a {
    color: #333;
    display: block;
    padding: 9px 15px
}

.search_result li a:hover, .search_result_mbl li a:hover, .product_search_result li a:hover {
    background: #000;
    color: #fff;
}

.hsearch_sec.open .input-sec, .hsearch_sec_mbl.open .input-sec {
    position: relative
}

.hsearch_sec.open .input-sec input, .hsearch_sec_mbl.open .input-sec input  {
    padding-right: 10px;
    margin-bottom: 0;
    border-color: rgba(0, 0, 0, 0.3);;
}
.htico_search{position: relative; }
.search_text_clear {
    position: absolute;
    right: 10px;
    top: 11px;
    display: none
}
.hcart_like, .htico_cart {
    position: relative;
    margin-left: 10px;
    padding-left: 5px;
}

.htico_cart > a {
    position: relative;
    display: block;
}
.hcart_like img{ width: 29px; height: 25px;  }
li.hcart_like.active a img:first-child, li.hcart_like a img:last-child {
    display: none;
}
.hcartdd_trigger.active:before {
    opacity: 1;
    visibility: visible;
}

body.cart-items-open .logo-main-section:before {
    position: fixed;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.68);
    z-index: 9;
}

body.cart-items-open{
    overflow: hidden !important;
}

.product_orders_top{position: relative; display: flex; justify-content: space-between; background: #060203;padding: 10px 15px;line-height: 1}
.product_orders_top h4{font-size: 15px;color: #fff; margin: 0; font-weight: normal}
.product_orders_top a{  text-transform: uppercase;border-bottom: 1px solid #fff;color: #fff;font-size: 14px;}

.cart_info {
    display: inline-block;
    vertical-align: top;
    color: #3d3d3d;
}

.cart_info h4 {
    font-size: 15px;
    margin: 0 0 6px;
}

.cart_left .help-block {
    font-style: italic;
    color: #a7a4a4;
    margin: 5px 0 0;
    font-size: 14px;
}

.cart_extrainfo {
    color: #3d3d3d;
}

.cart_extrainfo p b {
    color: #959595;
    font-family: 'Poppins', sans-serif;    
     font-size: 13px;
    font-weight: 600
}

.cart_extrainfo p, .notes-block {
    margin: 0;
    color: #959595;
    font-size: 13px;
    padding-left: 10px
}

.cart_price {
    position: relative;
    margin: 0 0 10px 0;
    padding-right: 22px;
    line-height: 1;
}

.cart_price p {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
}

.cart_remove {
    background: #b0b0b0;
    position: absolute;
    top: 14px;
    right: 0px;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cart_remove:hover {
    background-color: #000;
}
.cart_remove img{
    width: 8px;
}

.qty_bx {
    width: 123px;
    height: 40px;
    position: relative;
    padding: 0 38px;
    display: inline-block;
    border: 0;
    border-radius: 6px;
}

.qty_bx span {
    position: absolute;
    width: 40px;
    height: 100%;
    display: block;
    top: 0%;
    bottom: 0;
    text-indent: 9999px;
    overflow: hidden;
    cursor: pointer;
    margin: auto;
    background: #fff;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
    border: 2px solid #000;
}

.qty_bx span.qty_minus {
    left: 0px;
    border-right: 2px solid #000000;
    border-radius: 6px;
    box-shadow: 1px -1px 8px rgb(0 0 0 / 12%);
}

.qty_bx span.qty_plus {
    right: 0px;
    border-right: 2px solid #000000;
    border-radius: 6px;
    background: #000;
    box-shadow: 1px -1px 8px rgb(0 0 0 / 12%);
}

.qty_bx input {
    height: 100%;
    border: none;
    color: #000;
    font-size: 16px;
    text-align: center;
    margin: 0;
    padding: 2px;
    background: #fff;
    border-radius: 5px;
    font-weight: bold;
}


.qty_bx span.qty_minus:before{
    position: absolute;
    content: "";
    background: #000;
    width: 12px;
    height: 2px;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: auto;
}

.qty_bx span.qty_plus:before, .qty_bx span.qty_plus:after {
    position: absolute;
    content: "";
    background: #fff;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: auto;
}
.qty_bx span.qty_plus:before{
    width: 14px;
    height: 2px;
}
.qty_bx span.qty_plus:after{
    width: 2px;
    height: 14px;    
}

.cart_footer span {
    font-size: 16px;
   
}
.cart_footer .cart_row {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -webkit-box-pack: justify;
    margin-bottom: 5px;
    font-weight: 500;
}
.cart_footer .cart_row p{margin: 0;  }

.discount_info {
    margin-left: 15px;
}

.discount_info p {
    margin: 0;
    font-size: 14px;
    font-weight: normal;
    color: #3d3d3d;
}

.promo_delico_parent {
    position: relative;
}

.promo_delico {
    position: absolute;
    top: 0;
    left: -21px;
    width: 21px;
    height: 21px;
    line-height: 21px;
    text-align: center;
    color: #3d3d3d;
}

.promo_delico:hover {
    background-color: #b69a6b;
    color: #fff;
}

.cart_footer_totrow p {
    font-size: 25px;
}

.cart_footer_totrow p.help-block {
    font-size: 14px;
    font-weight: 300;
}

.cart_footer_totrow span {
    font-size: 25px;
}

.indication_progress {
    height: 15px;
    overflow: hidden;
    border-radius: 10px;
    position: relative
}

.progress_bar {
    content: "";
    height: 15px;
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    background:#c08a20;
    border-radius: 10px;
    -webkit-transition: 0.4s linear all;
    transition: 0.4s linear all;
}

.indication .help-block {
    font-size: 13px;
    margin: 7px 0 0;
}

.delivery_info {
    background: #f5f5f5;
    padding: 24px 35px;
}

.delivery_address {
    margin: 0 0 20px;
}

.delivery_info h4 {
    color: #838181;
    font-weight: 700;
    font-size: 16px;
    margin: 0;
    text-transform: uppercase;
}

.delivery_info p {
    color: #3d3d3d;
    font-weight: 700;
    font-size: 16px;
    margin: 0;
}

.delivery_link {
    color: #3d3d3d;
    line-height: 1;
    border-bottom: 1px solid #b4b4b4;
    margin: 10px 0 0 0;
    display: inline-block;
    vertical-align: top;
}

.delivery_link:hover {
    border-color: #b69a6b;
}

.delivery_time {
    margin: 0 0 16px;
}

.delivery_handle {
    border-top: 1px solid #d3d1d1;
    padding: 20px 0 0 0
}

.cart_action .btn_grey {
    margin: 0;
    border-color: #e6e3e3;
}

.cartaction_price {
    font-size: 24px;
    width: 30%;
    cursor: default;
}

.text-left {
    text-align: left
}

.text-right {
    text-align: right
}

header .cart_action{  padding: 20px 20px 0 20px}

.header_top {
    background: #181818;
    position: relative;
}

.htopinfo {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
    color: #fff;
    padding: 10px 0;
}

.htopinfo p {
    margin: 0;
}

.pop-alert-icon {
    padding: 0 0 15px;
}

.header_bot {
    position: relative;
}

.sticky .alert_fixed {
    top: 95px;
}

.scrollToTop {
    background: rgba(0, 0, 0, 0.7);
    width: 40px;
    height: 40px;
    line-height: 36px;
    position: fixed;
    bottom: 40px;
    right: 20px;
    font-size: 24px;
    border-radius: 2px;
    text-align: center;
    color: #fff;
    z-index: 1;
}

.scrollToTop:hover,
.scrollToTop:focus {
    background: rgba(0, 0, 0, 1);
    color: #fff;
}

.viewmore_poplink {
    position: absolute;
    right: 15px;
    bottom: 15px;
}

.innerproduct-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.addcart-row-child{     
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;  justify-content: center;    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 5px;
}

.modal-lg {
    width: 740px;
}
.inn-product-popup {
    background: #fff;
    width: 100%;
}

.prodet_banleft {
    width: 100%;
}

.prodet_baninfo {
    position: relative;
}

.prodet_baninfo .product-tags-list {
    position: relative;
    margin: 0;
    padding: 15px 0 10px 0;
}

.prodet_baninfo .product-sub-tags{
    padding: 0;
}



.prodet_baninfo .product-tags-list ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; 
    -ms-flex-wrap: wrap; 
    flex-wrap: wrap;
}
.prodet_baninfo .product-tags-list ul li{margin: 0 20px 5px 0;  font-size: 14px; display: flex; align-items: center;font-family: 'Poppins', sans-serif; font-weight: bold;}
.prodet_baninfo .product-sub-tags ul li{ font-size: 18px; margin: 0;}

.prodet_baninfo .inn_product_hea > h3{ font-size: 14px;  font-weight: 600; margin-bottom: 15px;}

.inn-product-popup-bg { position: relative; }

.inn-product-popup-background {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 345px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    -webkit-filter: blur(10px);
    -moz-filter: blur(10px);
    -o-filter: blur(10px);
    -ms-filter: blur(10px);
    filter: blur(10px);
}

.inn-product-popup-inner { position: relative; padding-top: 0px; line-height: 1;}
.inner_product_slide {  outline: 0;}
.inn-product-popup-inner .slick-next, .inn-product-popup-inner .slick-prev { display: none !important }
.inn-product-popup-inner .custom-dots { padding: 0; position: absolute; bottom: 12px; left: 0; right: 0; margin: 0;
    text-align: center;
}
.inn-product-popup-inner .custom-dots li { display: inline-block; width: 20px; height: 20px; position: relative; z-index: 1;}
.inn-product-popup-inner .custom-dots li button { padding: 0; margin: 0}

.inn-product-popup-inner .custom-dots li button:before {
    content: "";
    background: #fff;
    width: 12px;
    height: 12px;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 50%;
    border: 1px solid #c3c3c3;
}

.inn-product-popup-inner .custom-dots li.slick-active button:before {
    background: #769e90;
}

.inn-product-popup-inner .custom-dots li button {
    text-indent: -9999px;
    background: none;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 100%;
    height: 100%;
}

.inner_product_slide {
    position: relative;
}

.pthumb_slide {
    margin: 5px;
    border: 1px solid #a2a6ac;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    width: auto !important;
}

.pthumb_slide:hover,
.slick-current .pthumb_slide {
    -webkit-box-shadow: 0 0 3px 2px rgba(228, 121, 17, .5);
    box-shadow: 0 0 3px 2px rgba(228, 121, 17, .5);
    border-color: #e77600;
}

.popup_social {
    position: absolute;
    bottom: 5px;
    z-index: 1;
    right: 0;
    right: 5px;
}

.popup_social span {
    color: #373535;
    font-size: 12px;
    font-weight: 700;
}

.popup_social_icon {
    list-style: none;
    margin-bottom: 0;
    background: #fff;
    padding: 4px 0px 4px 4px;
}

.popup_social_icon li {
    float: left;
}

.popup_social_icon li a {
    color: #2a2a2a;
    padding: 0 10px;
    display: inline-block;
}

.popup_social_icon li a:hover {
    color: #ccc;
}

.popup_social_inner {
    float: right;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.popup_social_left {
    padding-right: 10px;
}

.copied {
    position: absolute;
    right: 10px;
    top: -21px;
    background: #000;
    padding: 3px 7px;
    color: #fff;
    font-weight: normal;
    font-size: 11px;
    border-radius: 2px;
    pointer-events: none;
}

.copied:before {
    content: "";
    border: 7px solid #000;
    border-color: #000 transparent transparent transparent;
    position: absolute;
    left: 50%;
    bottom: -11px;
    margin-left: -7px;
}

.inn_product_row {
    margin: 0 auto 0 auto;
}

.inn_product_hea p {
    color: #5b5b5b;
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 20px;
}

.inn_product_hea_left h3 {
    font-size: 32px;
    margin: 0 0 20px 0;
    color: #000;
    text-transform: capitalize;
    font-weight: bold;
}

.prd_chosen_row {
    padding: 20px 0px 20px;
}

.product-description-tab ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    border-bottom: 3px solid #eaeaea;
}

.product-description-tab {
    padding: 25px 0 15px 0;
}

.product-description-tab ul li {
    width: 50%;
    position: relative;
}
.product-description-tab ul li:after {
    bottom: -3px;
    content: "";
    display: block;
    height: 3px;
    left: 50%;
    position: absolute;
    background: #000;
    -webkit-transition: width .3s ease 0s,left .3s ease 0s;
    transition: width .3s ease 0s,left .3s ease 0s;
    width: 0;
}
.product-description-tab ul li.active:after, .product-description-tab ul li:hover:after {
    width: 100%;
    left: 0;
}

.product-description-tab ul li a {
    display: block;
    text-align: center;
    font-weight: 700;
    padding: 0px 5px 10px;
}
.product-description-tab ul li a:hover{
    color: #000;
}
.product-addtional-info { padding: 50px 0 0 0;}
.product-addtional-info .accordion .accordion-item .title{
    background-color: #ffffff;
    color: #000;
    padding: 0 30px 10px 0;
    width: 100%;
    border: none;
    border-radius: 0 !important;
    margin: 0;
    font-size: 18px;
    text-transform: inherit;
    letter-spacing: 0;
    overflow: inherit;
    border-bottom: 2px solid #eaeaea;
}
.product-addtional-info .accordion-item .panel{ margin: 0; padding: 0; border: 0; box-shadow: none}
.product-addtional-info .accordion-item.active .title { background-color: #fff !important;}
.product-addtional-info .accordion-item .panel p:last-child{ margin: 0 }
.product-addtional-info .accordion-item .panel p:first-child{ padding: 10px 0 0 0 }
.product-addtional-info .accordion-item{ margin-bottom: 35px}
.product-addtional-info .accordion-item .title::after, .product-addtional-info .accordion-item .title::before {
    background: #000;
    content: "" !important;
    display: block;
    height: 2px;
    margin-top: -2px;
    pointer-events: none;
    position: absolute;
    right: 10px;
    top: 50%;
    transition: -webkit-transform 0.25s ease-in-out 0s;
    transition: transform 0.25s ease-in-out 0s;
    transition: transform 0.25s ease-in-out 0s, -webkit-transform 0.25s ease-in-out 0s;
    -webkit-transition: -webkit-transform 0.25s ease-in-out 0s;
    width: 16px;
    z-index: 2;
}
.product-addtional-info .accordion-item .title::after {  transform: rotate(90deg);    -webkit-transform: rotate(90deg);}
.product-addtional-info .accordion-item .title::before { transform: rotate(0deg); -webkit-transform: rotate(0deg);}
.product-addtional-info .accordion-item.active .title::before {    transform: rotate(180deg); -webkit-transform: rotate(180deg);}
.product-addtional-info .accordion-item.active .title::after { transform: rotate(360deg); -webkit-transform: rotate(360deg);}

.add-extra-paired-products-mobile { width: 100%; display: none;}
.add-extra-paired-products {
    background: #f9f9f9;
    padding: 30px 30px 40px;
    border-radius: 15px;
    text-align: center;
    margin-top: 15px;
}
.add-extra-paired-products .slick-dots {
    display: flex !important;
    margin: 20px 0 0 0;
    padding: 0;
    background: #ffffff;
    height: 3px;
    width: 100%;
    list-style: none;
    position: relative;
    justify-content: stretch;
}
.add-extra-paired-products .slick-dots li {
    width: 50%;
}
.add-extra-paired-products .slick-dots li button {
    padding: 0;
    font-size: 0;
    margin: 0;
    background: #eae8e9;
    height: 3px;
    width: 100%;
    display: block;
}
.add-extra-paired-products .slick-dots li.slick-active button{ background: #686868; }
.add-extra-paired-products h2{ font-size: 20px; font-weight: 600; text-transform: capitalize;font-family: 'Poppins', sans-serif; }
.add-extra-paired-products .slick-slide > div > div { background: #fff; height: 100%; }

.add-extra-paired-products .slick-slide > div { padding: 0 5px;}
.add-extra-paired-products .slick-slide > div button {  padding: 14px 20px 14px;  margin: 0 auto;}
.add-extra-paired-products .slick-slide .paired-products-price-main{ position: absolute; bottom: 15px; left: 0; right: 0;}

.paired-products-image img { max-height: 150px; width: auto !important; margin: 0 auto;}
.paired-products-image { padding: 5px; height: 150px;}
.paired-products-desc { padding: 5px 5px 90px 5px; height: calc( 100% - 150px ); position: relative;}
.paired-products-desc h4{ font-size: 15px; font-weight: 600; text-transform: capitalize;font-family: 'Poppins', sans-serif; margin: 0 0 5px  }
.prodet_baninfo .product-price { font-size: 20px; color: #000; font-weight: 500;}
.add-extra-paired-products .slick-slide .paired-products-price-main .paired-products-price{ margin-bottom: 5px; }

.product-go-back{ margin-bottom: 25px;}
.product-go-back a{ font-weight: 500; text-transform: uppercase; font-size: 15px; position: relative;   padding-left: 34px; letter-spacing: 1px
}
.product-go-back a:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 0;
    background: url(../images/download.png) no-repeat;
    width: 24px;
    height: 11px;
}

.product_chosen_inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.cafe-category-lhs .input-sec {
    position: relative;
    border: 2px solid #f3f3f3;
    box-shadow: 0px 5px 16px 0px rgb(0 0 0 / 7%);
    margin-bottom: 30px
}
.cafe-category-lhs .input-sec .productsearch{margin: 0; border: 0; padding-right: 35px;}
.cafe-category-lhs .search_text_clear { top: 13px;}

.cafe-category-lhs .input-sec:after {
    content: "\f002";
    font-family: FontAwesome;
    position: absolute;
    right: 10px;
    top: 12px;
    font-size: 22px;
    line-height: 1;
    color: #898989;
}

.product_chosen_col { width: 100%;}
.product_chosen_col.product_chosen_col_right { margin-right: 0;}

.product_chosen_col_inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
        margin-bottom: 30px;
}

.product_chosen_col.product_chosen_col_right .product_chosen_col_inner {
    margin-bottom: 0;
}

.form-group-modifier {
    margin-top: 23px;
}

.product_chosen_item_left.product_chosen_item_left_full {
    width: 100%;
    margin-bottom: 10px
}

.product_chosen_item_left:nth-of-type(2n+2) {
    margin-right: 0;
}

.product_chosen_item_left_second {
    float: left;
    width: 47%;
    margin-right: 3.9285714285714285%;
}

.product_chosen_item_left_second:nth-of-type(2n+2) {
    margin-right: 0;
}

.select_full {
    width: 100%;
}

.product_chosen_hea h6 {
    font-size: 15px;
    color: #000000;
    text-transform: uppercase;
    position: relative;
    margin-bottom: 0;
    z-index: 1;
    padding-right: 10px;
}

.product_chosen_hea {
    position: relative;
    padding-bottom: 10px;
}

.product_chosen_item_left .chosen-container-single .chosen-single {
    width: 100%;
    height: 47px;
    line-height: 40px;
    border: 1px solid #e6e5e5;
    font-weight: 700;
}

.product_chosen_item_left .chosen-single div b {
    background: url(../images/spirite_whole.png) no-repeat center;
    background-position: -246px -62px;
    width: 28px;
    height: 28px;
}

.product_chosen_item_left .chosen-container-active.chosen-with-drop .chosen-single div b {
    background: url(../images/spirite_whole.png) no-repeat center;
    width: 28px;
    height: 28px;
    background-position: -287px -62px;
}

.product_chosen_item_left .chosen-container-single .chosen-single div {
    top: 10px;
    right: 0px;
}

.product_chosen_item_left .chosen-container .chosen-results .highlighted {
    background: #ecdfcc;
}

.pmodifier_ddlist ul {
    padding: 0;
    margin: 7px 0;
    list-style: none;
}

.pmodifier_ddlist li {
    background: #ecdfcc;
    padding: 3px 35px 3px 17px;
    border: 1px solid #dcd8d8;
    border-radius: 20px;
    position: relative;
    font-weight: 700;
    color: #181818;
    margin: 0 0 4px;
}

.pmodifier_close {
    background: url(../images/close-modifier.png) no-repeat center;
    width: 48px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 0 20px 20px 0;
    margin: auto 0;
}

.pmodifier_close:hover {
    background-image: url(../images/cross1.png);
    background-color: #1c1c1c;
}

.product_chosen_addons {
    width: 100%;
}

.chosen_adn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 0 10px 0;
}

.chosen_adn_right {
    line-height: 0;
}

.chosen_adn_left span {
    position: relative;
    display: inline-block;
    margin-bottom: 0;
    z-index: 1;
    padding-right: 10px;
}
.chosen_adn .combo-input {
    background: #fff;
    color: #000;
    font-size: 17px;
    line-height: 48px;
    padding: 0;
}

.radio.chosen_adn_right label {
    padding-left: 0;
}

.radio .qty_bx label {
    font-weight: 700;
}

.product_chosen_col_right .custom_checkbox {
    width: 100%;
}

.product_chosen_col_right .custom_checkbox span {
    font-size: 16px;
    font-weight: 700;
    color: #72624f;
    position: relative;
    display: block;
    margin-bottom: 0;
    background: #fbfbfb;
    z-index: 1;
    padding-right: 30px;
    padding-left: 0;
}

.product_chosen_col_right .custom_checkbox span:before {
    right: 0;
    left: auto;
    top: -5px;
}

.product_chosen_col_right .chosen_adn {
    margin-bottom: 16px;
}

.text-box {
    width: 100%;
}

.text-box em {
    text-transform: uppercase;
    font-size: 13px;
    color: #181818;
    text-align: right;
    float: right;
}

.text-box textarea {
    width: 100%;
    height: 120px;
    padding: 15px 15px;
}

textarea {
    resize: none
}

.chosen_upload {
    float: right;
    text-align: right;
    width: 100%;
}

.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    outline: 0;
}

.inputfile + label {
    font-size: 16px;
    font-weight: 700;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 11.5px 15px;
    outline: 0;
    background: #ecdfcc;
    max-width: 160px;
    border: 1px solid #e6e5e5;
    text-overflow: ellipsis;
}

.inputfile + label:hover {
    background: #333;
}

.inputfile + label:hover span {
    color: #fff;
}

.no-js .inputfile + label {
    display: none;
}

.inputfile + label svg {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    margin-top: -0.25em;
    margin-right: 0.25em;
}

.chosen_upload_inner label span {
    color: #72624f;
}

.inputfile-4 + label {
    color: #d3394c;
}

.inputfile-4:focus + label,
.inputfile-4.has-focus + label,
.inputfile-4 + label:hover {
    color: #722040;
}

.inputfile-4 + label figure {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    background-color: #d3394c;
    display: block;
    padding: 20px;
    margin: 0 auto 10px;
}

.inputfile-4:focus + label figure,
.inputfile-4.has-focus + label figure,
.inputfile-4 + label:hover figure {
    background-color: #722040;
}

.inputfile-4 + label svg {
    width: 100%;
    height: 100%;
    fill: #f1e5e6;
}

.inputfile-6:focus + label strong,
.inputfile-6.has-focus + label strong,
.inputfile-6 + label:hover strong {
    background-color: 722040;
}

.prd_chosen_sub_row {
    text-align: center;
    padding: 15px 0 10px;
    border-top: 1px solid #eaeaea;
    margin: 30px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
p.total_price.product_details_price {
    font-size: 34px;
    color: #000;
    margin: 0;
    width: 150px;
    text-align: left;
}
.prd_chosen_sub_col {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    width: calc(100% - 150px);
    justify-content: space-between;
}
.prd_chosen_sub_item_left {
    display: flex;
    /*width: 180px;*/
    width: 124px;
    margin: 0;
    flex-flow: column;
}
.prd_chosen_sub_item_right.cart_update_div {
    /*width: calc(100% - 190px);*/
    width: calc(100% - 134px);
}

span.ab-item-full {
    display: block;
    margin: 0 0 10px 0;
    text-align: left
}
.popup_addcart_cls{max-width: 100%}

.prd_chosen_sub_col .alert {
    margin: 0;
    width: 100%;
}
.d-merge {
    display: flex;
    flex-wrap: wrap;
}
.prd_chosen_sub_item_right button {
    width: 100%;
    margin: 0;
    font-size: 16px;
    padding: 15px 10px;
    min-width: inherit;
    background: #ce0e2d;
    border-color: #ce0e2d;
    color: #fff;
}
.prd_chosen_sub_item_right button:hover{ background: #000; border-color: #000; }
.prd_chosen_sub_item_right.prd_chosen_item_full { width: 100%;}

.prd_chosen_sub_item_right.prd_chosen_item_full button {
    max-width: 100%;
    margin: 0;
    letter-spacing: 0.5px;
    font-size: 18px;
}

.prd_chosen_sub_item_right.prd_chosen_item_full button:hover {
    background: #333;
}

.skip {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    background: #dbd9d9;
    max-width: 89px;
    width: 100%;
    display: block;
    text-align: center;
    padding: 7px 15px;
    color: #181818;
}

.skip:hover {
    color: #fff;
    background: #333;
}

.prd-sub-bg {
    background: #fff;
    max-width: 1000px;
    margin: 0 auto;
    padding: 40px 0;
    border-radius: 10px 0 10px 10px;
    -webkit-border-radius: 10px 0 10px 10px;
    box-shadow: 1px 0px 24px 0px rgba(0, 0, 0, 0.14);
    -webkit-box-shadow: 1px 0px 24px 0px rgba(0, 0, 0, 0.14);
    position: relative;
}

.prd-sub {
    max-width: 862px;
    position: relative;
    margin: 0 auto;
}

.prd-sub h2 {
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
}

.prd-sub .slick-slide {
    padding: 0 5px;
}

.prd-sub .slick-next {
    font-size: 0;
    border: 0;
    width: 62px;
    height: 77px;
    outline: 0;
    position: absolute;
    right: -69px;
    top: 23%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: inherit;
}

.prd-sub .slick-next::after {
    content: "";
    width: 39px;
    height: 39px;
    background: url(../images/spirite_whole.png) no-repeat;
    position: absolute;
    background-position: -27px -123px;
    left: 0px;
    top: 30%;
    margin: auto;
    right: 0;
    text-align: center;
}

.prd-sub .slick-next:hover {
    background: #f5f5f5;
}

.prd-sub .slick-next:hover::after {
    background-position: -154px -123px;
}

.prd-sub .slick-prev {
    font-size: 0;
    border: 0;
    width: 62px;
    height: 77px;
    outline: 0;
    position: absolute;
    left: -69px;
    top: 23%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: inherit;
}

.prd-sub .slick-prev::after {
    content: "";
    width: 39px;
    height: 39px;
    background: url(../images/spirite_whole.png) no-repeat;
    position: absolute;
    background-position: -86px -123px;
    left: 0px;
    top: 30%;
    margin: auto;
    right: 0;
    text-align: center;
}

.prd-sub .slick-prev:hover {
    background: #f5f5f5;
}

.prd-sub .slick-prev:hover::after {
    background-position: -213px -123px;
}

.prd-sub-slide-content {
    text-align: center;
    margin-top: 10px;
}

.prd-sub-slide-content h5 {
    font-size: 14px;
    font-weight: 700;
    color: #363636;
    margin-bottom: 8px;
}

.prd-sub-slide-content h6 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
}

.prd-sub-slide-qty .qty_bx .qty_minus {
    background: #f4b56b;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    width: 40px;
}

.prd-sub-slide-qty .qty_bx .qty_plus {
    background: #e48f2c;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    width: 40px;
}

.prd-sub-slide-qty .qty_bx .qty_minus:hover,
.prd-sub-slide-qty .qty_bx .qty_plus:hover {
    background: #333;
}

.prd-sub-slide-qty .qty_bx input {
    background: #fff;
    width: 62px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
}

.prd-sub-slide-qty {
    background: #ecdfcc;
    padding: 4px 0 0px 0;
    border: 1px solid #f9f9f9;
}

.prd-sub-slide-qty .qty_bx {
    width: 146px;
    height: 40px;
}

.prd-sub-slide-qty .qty_bx span {
    height: 40px;
}

.prd-sub-slide-submit {
    background: #181818;
}

.prd-sub-slide-submit a {
    font-size: 18px;
    padding: 13px 0;
    display: block;
    text-transform: uppercase;
    color: #fff;
    border-top: 1px solid #f9f9f9;
}

.prd-sub-slide-submit a:hover {
    background: #e48f2c;
}

.slick-slide {
    outline: 0;
}

.prd-sub-slide-content .btn {
    width: 100%;
}

.checkout_hea {
    position: relative;
}

.checkout_hea::after,
.check_pg::after {
    display: block;
    content: "";
    clear: both;
}

.checkout_hea h3 {
    font-size: 27px;
    margin-bottom: 5px;
}

.checkout_hea p {
    margin-bottom: 25px;
    color: #5b5b5b;
}

.checkout_hea_inner {
    margin-top: 8px;
}

.check_pg {
    position: relative;
    margin: 0 20px;
}

.check_pg_inner {
    background: #f0f0f0;
    padding: 35px 15px 30px 15px;
    margin-bottom: 14px;
    border-radius: 15px;
}

.check_pg_inner h4{
    color: #000;
}

.check-pg-slider .slick-slider {
    padding: 0 75px;
}

.check_pg_inner .innerproduct-col {
    width: auto;
    margin-right: 0;
}

.check_pg_inner .slick-slide img {
    text-align: center;
    margin: auto;
}

.check_pg_inner .slick-slide {
    padding: 0 7.5px;
}

.check_pg_inner .slick-next {
    font-size: 0;
    border: 0;
    width: 62px;
    height: 77px;
    outline: 0;
    position: absolute;
    right: 0;
    top: 32.5%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: inherit;
    z-index: 1;
}

.check_pg_inner .slick-next::after {
    content: "";
    width: 39px;
    height: 39px;
    background: url(../images/spirite_whole.png) no-repeat;
    position: absolute;
    background-position: -27px -123px;
    left: 0px;
    top: 30%;
    margin: auto;
    right: 0;
    text-align: center;
}

.check_pg_inner .slick-next:hover {
    background: #f5f5f5;
}

.check_pg_inner .slick-next:hover::after {
    background-position: -154px -123px;
}

.check_pg_inner .slick-prev {
    font-size: 0;
    border: 0;
    width: 62px;
    height: 77px;
    outline: 0;
    position: absolute;
    left: 0;
    top: 32.5%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: inherit;
    z-index: 1;
}

.check_pg_inner .slick-prev::after {
    content: "";
    width: 39px;
    height: 39px;
    background: url(../images/spirite_whole.png) no-repeat;
    position: absolute;
    background-position: -86px -123px;
    left: 0px;
    top: 30%;
    margin: auto;
    right: 0;
    text-align: center;
}

.check_pg_inner .slick-prev:hover {
    background: #f5f5f5;
}

.check_pg_inner .slick-prev:hover::after {
    background-position: -213px -123px;
}

.check_pg .product-tag-list{ display: none; }

.check_pg .products-ordernow-action{ bottom: 0; }

.check_out_fourcommon {
    width: 100%;
    margin: 0 auto 50px ;
}

.checkoutpage_form_outer {
    width: 100%;
    margin: 0 auto;
}
.controls-three-small input[type="text"]{ height: 40px; font-weight: 600; text-align: center; margin: 0; padding: 10px 5px; }
.controls-three-small .focus-out{ width: 48.5% }
.controls-three-small { display: flex; justify-content: space-between; align-items: center; margin: 10px 0; }

.cthree-small-inner {
    display: flex;
    width: calc( 100% - 20px );
    justify-content: space-between;
}
.controls-three-small span{width: 20px; text-align: left}

.cdd-details{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;margin-bottom: 20px; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between;}
.cdd-details .ttsplitter{margin-bottom: 0;}
.checkout-total {width: 100%;margin: 25px auto 0px auto}
.checkout-total::after {display: block;content: "";clear: both}
.checkout-total .cdd-details-lhs h4,
.checkout-total .cart-header h4 {padding: 14px 20px;position: relative;background: #87311d;border-bottom: 0px;text-align: center;font-size: 24px;width: 100%;margin-bottom: 0}
.checkout-total .cart-header {padding: 0;background: inherit;border: 0;margin: 0}
.checkout-total .cdd-details-rhs .cart-header h4 {margin: 0;line-height: normal}
.checkout-total .cart-header:after {display: none}
.cdd-details-lhs, .cdd-details-rhs {width:49.6%;position: relative;padding: 0;background: #fff; -webkit-box-shadow:0px 1px 21px 0px rgba(0, 0, 0, 0.18); box-shadow:0px 1px 21px 0px rgba(0, 0, 0, 0.18);}

.checkout-right-body-section{ padding: 0; }
.checkout-body-section{ padding: 20px}

.chk_hea {
    font-size: 15px;
    padding: 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 0 10px 0;
    line-height: 1;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-transform: uppercase;
    color: #000;
    position: relative;
    font-weight: 600;
}

.chk_hea > span {
    color: #000000;
    font-size: 13px;
    font-weight: normal;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    padding-right: 30px;
}

.checkout-total .chosen-container .chosen-results .highlighted { background: #ecdfcc; }

.checkout-control-group-top a {
    float: right;
    font-size: 14px;
    color: #000;
    line-height: 1;
    border-bottom: 1px solid #b4b4b4;
    margin: 5px 0 0 0px;
    display: inline-block;
    vertical-align: top;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
}

.checkout-control-group-top a:hover { color: #DB203B;}

.checkout-control-group-middle{
    margin: 0;
    border: 0;
    padding-top: 25px;
}

.checkout-body-section .checkout-dates-group-top .checkout-control-group-middle {
    padding-top: 5px;
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
}

.checkout-control-group-middle .chk_hea.pickup-datetime {
    background: #fff;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.checkout-total .checkout-control-group-middle .chk_hea {
    padding: 0;
    margin: 0 0 10px;
}

.chk_hea.pickup-datetime .pdate, .chk_hea.pickup-datetime .ptime{
    font-family: 'Poppins', sans-serif;
}

.checkout-pickup-rhs .chk_hea, .checkout-pickup-rhs p{ justify-content: center; text-align: center;}

.chk_hea, .chk_hea>span{
    display: flex;
    align-items: center;
}

.checkout-total  .chk_hea .custom_checkbox, .checkout-total  .send-as-gift .custom_checkbox{
    position: absolute;
    top: 10px;
    right: 0px;
}

.checkout-total  .custom_checkbox .addon_list_single{
    border-radius: 50%;
}

.checkout-control-group-topband { border-bottom: 1px solid #939292; padding: 0 0 35px 0 }
.checkout-control-group-topband span { float: right; font-weight: normal; font-size: 15px}
.checkout-total .checkout-control-group-bottom { padding: 25px 0 0 0;}
.checkout-billing-address { padding: 25px 0 20px 0; width: 100%; border-bottom: 1px solid #eeeded;}
.check-billing{ padding: 20px 0 0 0; }
.checkout-billing-address .chk_hea { padding: 12px 10px; background: #f5f5f5; color: #181818;}

.checkout-total .checkout-control-group-middle .form-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.checkout-total .checkout-control-group-middle .form-group .react_datepicker { width: 48%}
.checkout-total .checkout-control-group-middle .form-group .input_area { width: 49%}

.checkout-total .checkout-control-group-bottom textarea {
    padding: 10px 15px;
    height: 120px;
    font-size: 15px;
    width: 100%;
    outline: 0;
}

.checkout-total textarea::-webkit-input-placeholder, .checkout-total textarea:-moz-placeholder, 
.checkout-total textarea::-moz-placeholder {
    color: #595656;
}

.checkout-total .controls-three {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 0
}

.checkout-total .form-group.controls-three > div { width: 31.3333%;}

.controls-three span { width: 10px; text-align: center; vertical-align: middle; line-height: 48px;}

.checkoutpage_form_outer .controls-three .form-group{ margin-bottom: 0;}

.checkout-total .form-group.controls-three > div input { margin-bottom: 0}

.send-as-gift .controls-two .form-group, .send-as-gift .controls-two .focus-out{ width: 49%; margin-bottom: 0;}
.send-as-gift .controls-two .form-group .focus-out{width: 100%;}
.send-as-gift .form-group .focus-out .form-control, .send-as-gift .focus-out .form-control{ margin-bottom: 0; }

.checkout-total .controls-single textarea {
    padding: 10px 15px;
    height: 120px;
    font-size: 15px;
    width: 100%;
    outline: 0;
}

.controls-single{
    margin-top: 15px;
}

.order-detail-inner {
    padding: 82px 0 0 0
}

.order-detail-inner-icon {
    position: relative;
    max-width: 604px;
}

.order-detail-inner-icon-left {
    position: absolute;
    left: 0;
    top: 2px;
}

.order-detail-scotter {
    position: relative;
    z-index: 2;
}

.order-detail-scotter::after {
    content: "";
    background: #fff url(../images/spirite_whole.png) no-repeat;
    background-position: -283px -126px;
    width: 41px;
    height: 24px;
    left: 0;
    top: -2px;
    position: absolute;
}

.order-detail-scotter::before {
    content: "";
    background: #fff url(../images/spirite_whole.png) no-repeat;
    background-position: -347px -126px;
    width: 22px;
    height: 22px;
    right: 0;
    top: -2px;
    position: absolute;
}

.order-detail-inner-icon-left.order-detail-right {
    position: absolute;
    right: 0;
    top: 3px;
    left: inherit;
}

.order-detail-inner-icon-left.order-detail-inner-center {
    position: static;
    left: inherit;
    top: inherit;
    width: 100%;
    text-align: center;
    display: inline-block;
}

.order-detail-inner-icon-left.order-detail-inner-center::after {
    display: block;
    clear: both;
    content: "";
}

.order-detail-inner-icon-left.order-detail-inner-center::before {
    position: absolute;
    content: "";
    top: 0;
    width: 100%;
    max-width: 497px;
    left: 0;
    right: 0;
    height: 10px;
    margin: auto;
    top: 50%;
}

.order-detail-inner-icon-left.order-detail-inner-center img {
    vertical-align: middle
}

.delivery_total::after {
    display: block;
    content: "";
    clear: both;
}

.delivery_total {
    margin: 10px 0 0px 0;
}

.delivery_total_left {
    float: left;
    text-align: left;
    width: 50%;
}

.delivery_total_left h3 {
    margin-bottom: 0;
    line-height: 22px;
    font-size: 15px
}

.delivery_total_left span {
    display: block;
    color: #000;
    line-height: 17px;
    font-size: 13px
}

.delivery_total_left.delivery_total_right {
    float: right;
    text-align: right
}

.delivery_total_number {
    margin-bottom: 0;
    padding: 10px 0 30px 0;
}

.delivery_total_number .delivery_total_left h2 {
    font-size: 16px;
    margin-bottom: 5px;
    text-transform: uppercase;
}

.delivery_total_number .delivery_total_left h4 {
    color: #060203;
    font-size: 15px;
    margin-bottom: 0;
}

.pickup-order-div:before {
    display: none
}

.pickup-order-div .delivery_total_left {
    width: 100%;
    text-align: center
}

.pickup-order-div .delivery_total_left img {
    width: 25px;
}

.cart-product-desc-inner {
    padding-left: 20px
}

.cart-product-desc-inner span {
    display: block;
    color: #3d3d3d;
    line-height: 19px;
    font-size: 14px
}

.chekout_cart_bar .cart_footer {
    background: #f6f6f6;
    padding: 28px 25px 10px 25px;
    margin: 0
}

.chekout_cart_bar .cart_left {
    padding-left: 0;
    justify-content: space-between;
}
.chekout_cart_bar .cart_left .cart_info{ width: calc( 100% - 115px );}

.chk_hea .custom_checkbox {
  position: absolute;
    top: -2px;
    right: 0;
}
.chk_hea .custom_checkbox span{ vertical-align: top;}

.chk-payment-btn-row {
    margin: 30px auto 0 auto;
    max-width: 470px
}

.chk-payment-btn-row .button {
    width: 100%;
    display: block;
    margin: 0;
    font-size: 17px;
    padding: 15px 10px;
    background: #c81d35;
    border-radius: 0;
    color: #fff;
    letter-spacing: 1px;
}
.chk-payment-btn-row .button:hover{ background: #000; color: #fff; }

.chk-payment-btn-row.add-tocart-cls,
.chk-payment-btn-row.checkout-continue-cls .row-replace,
#PackageEditMdl .chk-payment-btn-row .row-replace {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.chk-payment-btn-row.checkout-continue-cls .row-replace .col-md-cls,
#PackageEditMdl .chk-payment-btn-row .row-replace .col-md-cls {
    width: 49%
}

.dwn_quotion {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
}

.dwn_quotion a {
    background: #e6e3e3;
    font-size: 16px;
    font-weight: 700;
    display: block;
    padding: 10px 0;
    color: #0d0d0d;
    text-transform: uppercase;
    margin-bottom: 0;
}

.dwn_quotion a:hover {
    background: #333;
    color: #fff;
}

.redeem {
    position: relative;
    margin-bottom: 20px;
}

.redeem::after {
    display: block;
    clear: both;
    content: "";
}

.redeem-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.redeem-col {
    width: 49.6%;
    background: #fff;
    padding: 25px 50px 20px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    border: 2px solid #e9e9e9;
}
.redeem-col-full{width: 100%}
.redeem-col-full .redeem-item{   max-width: 660px; margin: 0 auto;}

.redeem-item-hea {
    /* padding-left: 0px;
    position: relative; */

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.redeem-item-hea h4 {
    font-size: 22px;
    text-transform: uppercase;
    margin-bottom: 0;
    font-weight: bold;
}
.redeem-item-hea span {
    font-size: 14px;
}

.redeem-item-hea .points {
    /* position: absolute;
    top: 8px;
    right: 0;
    background: #1c1c1c;
    color: #fff;
    padding: 8px 2px;
    max-width: 260px;
    display: block;
    width: 100%;
    text-align: center;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    font-size: 13px; */

    width: 260px;
    background: #1c1c1c;
    color: #fff;
    padding: 8px 2px;
    max-width: 100%;
    display: block;
    text-align: center;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    font-size: 13px;
    
}

.redeem-item-hea .points b {
    color: #fbd008;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
}

.redeem_apply {
    position: relative;
    margin-top: 5px;
}

.redeem_apply .button {
    position: absolute;
    right: 0;
    top: 0;
    border: 0;
    z-index: 11;
    margin: 0;
    padding: 17px 22px;
    min-width: 150px;
    line-height: 14px;
}

.redeem-item-hea-inner {
    /* width: 41.37770897832817%; */
    
    width: calc(100% - 260px);
}
.redeem_apply .focus-out {
    padding-right: 140px;
}

.chk-payment {
    padding: 40px 30px 30px 30px;
    text-align: center;
    border: 2px solid #e9e9e9;
    border-radius: 10px;
}

.chk-payment-col-radio {
    padding: 0;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    max-width: 1000px;
    margin: 0 auto;
}

.chk-payment-col-radio li {
    padding: 10px 15px 10px 45px;
    border: 1px solid #d0cfcb;
    border-radius: 7px;
    width: 22%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 1% 10px;
}

.chk-payment-col-radio .pay_anyone{ display: none; }
.chk-payment-col-radio .pay_anyone_inner{ display: flex; }

.chk-payment-col-radio .radio_con{  display: flex; font-size: 15px; text-transform: uppercase; font-weight: 500 }
.chk-payment .chk-payment-col-radio li input[type=radio] {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
}


.birthday-info-move {
    padding: 15px 5px 25px;
    text-align: center;
}

.chosen_radio_inner {
    width: 100%;
}

.expand {
    background: #eaeaea;
    padding: 10px 15px;
    margin-bottom: 5px;
    cursor: pointer;
    position: relative;
}

.expand h3 {
    color: #000;
    font-size: 18px;
    margin-bottom: 0;
    font-weight: 600;
    text-transform: uppercase;
    position: relative;
    width: 100%;
}

.expand::after {
    content: "\f107";
    right: 10px;
    display: block;
    top: 3px;
    font-family: "FontAwesome";
    position: absolute;
    color: #000;
    font-size: 26px;
}

.expand.active::after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
}

.expand_sub h1 {
    font-size: 16px;
    font-weight: 700;
    color: #72624f;
    position: relative;
    margin-bottom: 0;
    background: #fbfbfb;
    z-index: 1;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 10px 10px;
}

.expand_sub h1 span {
    width: 80%;
}

.panel-heading.expand_hea {
    cursor: pointer;
}

.expand_sub h4.panel-title {
    font-weight: 700;
    text-transform: uppercase;
    position: relative;
}

.expand_sub_body {
    width: 100%;
}

.expand_sub h4::after {
    content: "\f107";
    right: 0;
    display: block;
    top: -7px;
    font-family: "FontAwesome";
    position: absolute;
    color: #000;
    font-size: 26px;
}

.expand_hea.active h4.panel-title::after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.chk-payment-btn-row .btn {
    width: 100%;
    border: 0;
}

.chk-payment-btn-row .row {
    margin-right: 0;
    margin-left: 0;
}

.chk-payment-btn-row [class*="col-"] {
    padding: 0;
}

.tnk-you {
    position: relative;
    padding: 110px 0;
}

.tnk-you::after {
    display: block;
    clear: both;
    content: "";
}

.tnk-header {
    max-width: 683px;
    margin: 0 auto;
    text-align: center;
}

.tnk-header .tick {
    padding: 0 15px;
}

.tick h2 {
    font-size: 44px;
    margin: 18px 0 0 0;
    text-transform: uppercase;
}

.tick span {
    font-size: 18px;
    color: #0b0405;
}

.tnk-detail {
    margin-top: 20px;
}

.tnk-detail h2 {
    padding: 13px 10px;
    color: #464646;
    text-transform: uppercase;
    background: #fff;
    margin-bottom: 0;
    font-family: 'Poppins', sans-serif;
    font-size: 19px;
}

.tnk-order {
    background: #f5f5f5;
    padding: 30px 15px;
}

.tnk-order h3 {
    margin-bottom: 10px;
    font-size: 36px;
    text-transform: uppercase;
    color: #1a1a1a;
}

.tnk-order p {
    font-size: 16px;
    color: #000;
    margin-bottom: 0;
    font-family: 'Poppins', sans-serif;
}
.Viewreceipt .tnk-order{ background: #f5f5f5;  }
.Viewreceipt .tnk-order h3 {
    color: #292929;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

.Viewreceipt .tnk-detail h2{    
    color: #fff;
    background: #060203;
    font-size: 17px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

.Viewreceipt .tnk-order p {
    color: #292929;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

.tnk-delivery .order-detail-inner {
    padding-top: 0px;
}

.tnk-delivery {
    padding: 30px 15px;
    background: #fff;
}

.cart_row.tnkorder-first {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.tnkorder-first > div {
    width: 49%;
}
.cart_row.tnkorder-first > div h5{
    margin-bottom: 5px;
    font-size: 16px;
}
.cart_row.tnkorder-first > div p{
    margin: 0;
    font-size: 15px;
}

.tnk-delivery .order-detail-inner-icon-left.order-detail-inner-center::before {
    display: none;
}

.tnk-delivery .order-detail-inner-icon {
    margin: 0 auto;
}

.tnk-delivery .delivery_total {
        margin: 6px 0 20px 0 !important;
}
.tnk-chk-order {
    margin: 15px auto;
    max-width: 580px;
}

.tnk-delivery .cart_left .help-block {
    text-align: left;
}

.tnk-delivery .cart_info h4 {
    text-align: left;
}

.tnk-delivery .cart_extrainfo {
    text-align: left;
}

.tnk-delivery .cart_footer.tnk_cart_footer {
    padding: 20px 15px 0px 15px;
    margin: 0;
}

.tnk-delivery .tnk_cart_footer .cart_row {
    text-align: left;
    padding: 0px 0px 5px 0px;
}
.tnk-delivery .tnk_cart_footer .cart_row.grant-total-cls{     margin-bottom: 0;    padding: 10px 0px 10px 0px;    border-bottom: 3px solid #f4f4f5;}
.tnk-chk-order .btn {
    width: 100%;
    border: 0;
}

.delivery_datetime_div {
    padding: 10px 0 10px 0 !important;
    margin: 10px 0 0px 0 !important;
}

.remark_notesec {
    margin: 20px 0 0;
    padding: 0 7px;
}

.remark_notesec h4 {
    margin: 0 0 3px;
    font-weight: 700;
    color: #3d3d3d;
    font-size: 16px;
    text-transform: inherit;
}

.remark_notesec p {
    margin: 0;
}

.mfp-bg {
    z-index: 10042;
}

.mfp-wrap {
    z-index: 10043;
    top: 0px!important;
}

.white-popup {
    max-width: 1026px;
    padding: 0;
    margin: 30px auto;
}
.order_popup{ max-width: 820px; }
.cafe-popup{  max-width: 900px;  }
.pouup_in { padding: 60px 50px 40px;}

.alert_popup,.warining_popup,.warning_popup,.pay-conf-popup { max-width: 450px;}

.warning_popup .warning-popup-img {
    width: 75px;
    margin: 0 0 10px 0
}

button.mfp-close, button.mfp-arrow {
    margin: 0;
    padding: 0
}

.alert_popup .mfp-close {
    display: none;
}

.alert_header {
    background: #DB203B;
    color: #fff;
    font-size: 20px;
    padding: 12px 14px;
    position: relative;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    font-weight: 600
}

.alert_body {
    text-align: center;
    padding: 20px;
}

.alt_btns a {
    margin: 0 5px;
    padding: 14px 30px;
}

.alt_btns a.button-left {
    background: #282828
}

.popup_equalrw {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.popup_ttsec {
    width: 42%;
    min-height: 310px;
    padding: 20px 20px;
    min-height: 310px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.pop_title {
    position: relative;
    color: #fff;
}

.pop_title h2 {
    font-size: 36px;
    margin: 0;
    line-height: 1;
    color: #fff;
    text-transform: inherit;
}

#delevery-postcode-popup .pop_title h2{
    text-transform: uppercase;
}

.pop_title small {
    font-size: 18px;
    font-weight: normal;
}

.popup_right {
    width: 58%;
    padding: 20px 60px 20px 68px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.innervmid_in {
    max-width: 380px;
    margin: 0 auto;
}

.delevery_popup .popup_ttsec {
    background: #769e90;
}

.delevery_popup .popup_ttsec {
    padding-left: 38px;
    padding-right: 28px;
}

.delevery_popup .popup_right .form-group .form-control {
    border: 1px solid #dadada;
}

.two-button-row {
        display: flex;
    justify-content: space-between;
    margin: 0 auto;
}

.two-button-row > div .button {
    display: block;
    margin: 0;
    width: 100%;
    padding: 15px 10px;
    border-radius: 4px;
    box-shadow: 0px 7px 9px 0px rgba(0, 0, 0, 0.21);
    border: 1px solid #000;
}

.two-button-row > div {
    width: 49%
}
.cafe-popup .two-button-row{
    max-width: 380px; margin: 0 auto;
}

.go_second a {
    background: #fff;
    border: 1px solid #241e21;
    color: #000;
}
.cafe-popup .go_second a, .order_popup .go_second a{
    background: #fff;
    border: 1px solid #241e21;
    color: #000;
}

.cafe-popup .go_second a{
    background: #060503;
    border: 1px solid #241e21;
    color: #fff;
}

.cafe-popup .go_second a.button.button-left:hover{
    background: #db203b;
    border: 1px solid #db203b;
    color: #fff
}

.two-button-row .delivery_submit:hover, .go_second a:hover, .cafe-popup .go_second a:hover, .order_popup .go_second a:hover {
    background: #000;
    color: #fff;
}

.error_postal_popup .popup_right .button {
    margin: 0
}

img.pop-scooter-img {
    max-width: 90px;
    margin-bottom: 15px
}

.receipt_popup,.receipt_cateringpopup {  max-width: 620px;}
.receipt_popup .pouup_in,.receipt_cateringpopup .pouup_in { padding: 0;}
.receipt_popup .tnk-you,.receipt_cateringpopup .tnk-you { padding: 0;}
.receipt_popup .tnk-detail,.receipt_cateringpopup .tnk-detail {  margin-top: 0}
.receipt_popup .Viewreceipt,.receipt_cateringpopup .Viewreceipt { margin: 30px 0}

.error_postal_popup .popup_ttsec {
    background: #769e90;
}

.error_postal_popup h4 {
    color: #fff;
    font-size: 16px;
}

.poptt_icontop {
    padding-left: 0;
}

.poptt_icontop i {
    position: static;
    margin: 0 auto 17px;
}

.news_postal_popup .popup_ttsec {
    height: 309px;
}

.news_postal_popup .popup_ttsec {
    background: #b79a6c;
    width: 51.46198830409356%;
}

.news_postal_popup .popup_right {
    background: url(../images/news_popupright.png) no-repeat;
    background-size: cover;
    width: 48.53801169590643%;
}

.news_postal_popup .pop_title p {
    font-size: 16px;
    margin: 6px 0 0 0;
}

.news_subscribe {
    position: relative;
    margin-top: 14px;
}

.news_subscribe .btn {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 11;
    border: 1px solid #f9f9f9;
}

.news_subscribe .btn:hover {
    background: #54362c;
    color: #fff;
}

.news_subscribe .form-control {
    height: 51px;
    border: 1px solid #fff;
}

.news_subscribe p {
    font-size: 16px;
    color: #fff;
    margin-top: 12px;
    margin-bottom: 0;
}

.mfp-close-btn-in .news_postal_popup .mfp-close {
    background: rgba(24, 24, 24, 0.5);
    color: #fff;
    font-size: 0;
    width: 30px;
    height: 32px;
    line-height: 30px;
    opacity: 1;
}

.mfp-close-btn-in .news_postal_popup .mfp-close::after {
    content: "";
    background: url(../images/spirite_whole.png) no-repeat;
    width: 15px;
    height: 15px;
    background-position: -83px -190px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
}

.mfp-close-btn-in .news_postal_popup .mfp-close:hover {
    background: rgba(24, 24, 24, 0.9);
}

.order-body {
    text-align: center;
    padding: 35px 0 30px;
    border: 1px solid #999;
}

.order-body h2 {
    font-size:40px;
    margin-bottom: 0;
    text-transform: capitalize;
}

.order-body p {
    color: #686868;
    font-size: 15px;
    margin: 0 0 20px 0;
}

.order_delivery_item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-evenly;
    margin: 0 auto;
    padding:20px 0 40px 0;
}

.order_delivery_item li {
    width: 48%;
    position: relative;
}
.order-from-tooltip-info {
    position: absolute;
    bottom: 106%;
    left: 0;
    right: 0;
    font-size: 11px;
    line-height: 1.1;
    background: #fff;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #E4E4E4;
    opacity: 0;
    visibility: hidden;
    transform: translateY(20px);
    transition: 0.2s linear all;
}
.order_delivery_item li:hover .order-from-tooltip-info{ opacity: 1; visibility: visible; transform: rotateY(0)}
.order-from-tooltip-info:after, .order-from-tooltip-info:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.order-from-tooltip-info:after {
    border-color: rgba(136, 183, 213, 0);
    border-top-color: #fff;
    border-width: 8px;
    margin-left: -8px;
}
.order-from-tooltip-info:before {
    border-color: rgba(194, 225, 245, 0);
    border-top-color: #e4e4e4;
    border-width: 9px;
    margin-left: -9px;
}
.order-from-tooltip-info p{ margin-bottom: 0}

.two-popup{max-width: 820px;}

.two-popup .full-login-new-body{max-width: 500px; padding: 50px 0;}

.two-popup .order_delivery_item{justify-content: space-between;}

.two-popup .order_delivery_item li{width: 48%}

.cafe-popup .order_delivery_item li {
    width: 18%;
}

.order_delivery_item li a {
    display: block;
    padding: 15px 10px 20px;
    background: #fff;
    position: relative;
    border-radius: 5px;
    transition: 0.3s linear all;
    box-shadow:0px 5px 16px 0px rgba(0, 0, 0, 0.05);
}
.cafe-popup .full-login-new-body{ padding: 50px 50px; background: #f6f6f6; max-width: 100%;}
.cafe-popup .order_delivery_item li a{ padding: 20px 5px 20px;}

.order_delivery_item h3 {
    color: #000;
    margin: 15px 0 0 0;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1px;
}
.cafe-popup .order_delivery_item h3 {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0px;
}

.order_delivery_item li a.active, .order_delivery_item li a:hover{
    background: #241e21;
}
.order_delivery_item li a.active h3, .order_delivery_item li a:hover h3{ color: #fff}
.order_icon {
    display: block;
    text-align: center;
    margin: auto;
}
.order_delivery_col .button{ width: 100%; background: #241e21;    border: 1px solid #cbcbcb;
    box-shadow: 0px 7px 9px 0px rgba(0, 0, 0, 0.21); padding: 15px 10px }
.order_delivery_col .button:hover{ background: #DB203B; color: #fff; }
.alt_btns a:hover {  background: #DB203B; color: #fff;}
.warning_popup .alt_btns a{padding: 15px 20px;}
.header-ordernow-single-img {
    height: 81px;
    display: flex;
    align-items: flex-end;
}

.header-ordernow-single-img img {
    display: none;
    margin: 0 auto;
    max-height: 70px;
    width: 80px;
}
.header-ordernow-single-img img.order_type_img{ display: block }
.order_delivery_item li a.active .order_type_img, .order_delivery_item li a:hover .order_type_img{display: none}
.order_delivery_item li a.active .order_type_imgwt, .order_delivery_item li a:hover .order_type_imgwt{display: block}

.order_delivery_item .tooltip {
    font-size: 16px;
}

.order_delivery_item .tooltip-inner {
    max-width: 260px;
}

.ordelivery_tooltip {
    position: relative;
}

.order_delivery_mobile_tooltip {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    left: -270px;
    top: 23px;
    background: #181818;
    padding: 10px;
    border-radius: 3px;
    width: 260px;
    margin: 0 auto 5px;
    z-index: 1;
    -webkit-transition: all 400ms;
    transition: all 400ms;
}

.order_delivery_item li:hover .order_delivery_mobile_tooltip {
    opacity: 1;
    visibility: visible;
}

.order_delivery_mobile_tooltip:before {
    content: "";
    position: absolute;
    right: -16px;
    top: 50%;
    margin-top: -7px;
    border: 10px solid #181818;
    border-color: transparent transparent transparent #181818;
    border-width: 7px 10px 7px 10px;
}

.order_delivery_mobile_tooltip p {
    margin: 0;
    color: #fff;
    font-size: 16px;
}

.selfcollect_tooltip .order_delivery_mobile_tooltip {
    left: auto;
    right: -270px;
}

.selfcollect_tooltip .order_delivery_mobile_tooltip:before {
    border-color: transparent #181818 transparent transparent;
    left: -16px;
    right: auto;
}

.order_delivery_item li.disable .ordericon_link {
    display: none;
}

.order_delivery_item li.disable:hover {
    background: #f9f9f9;
    border-color: #e1e1e2;
}

.order_delivery_item li.disable .order_delivery_mobile_tooltip {
    display: none;
}

.orderpop_address {
    position: relative;
    height: 100%;
    padding-top: 35px;
}

.orderpop_address p {
    font-size: 12px;
    margin: 0;
}

.orderpop_continue_shop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.orderpop_continue_shop .btn {
    font-size: 11px;
    height: 30px;
    padding: 7px 15px;
    margin: 0;
}

.orderpop_change_address {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
}

.orderpop_change_address:hover {
    color: #000;
}

.mfp-close-btn-in .vouchergift-popup .mfp-close, .mfp-close-btn-in .order_popup .mfp-close, .warning_popup .mfp-close,
.mfp-close-btn-in .awesome_popup .mfp-close, .mfp-close-btn-in .self_popup .mfp-close, .pay-conf-popup .mfp-close,
.mfp-close-btn-in .delevery_popup_datetime .mfp-close, .mfp-close-btn-in .delevery_popup .mfp-close,
.mfp-close-btn-in .error_postal_popup .mfp-close, .mfp-close-btn-in .receipt_popup .mfp-close,
.mfp-close-btn-in .receipt_cateringpopup .mfp-close, .two-popup .mfp-close , .changepw_popup .mfp-close, 
.redeem_popsec .mfp-close, #qr-popup .mfp-close{
    background: #000;
    color: #fff;
    font-size: 0;
    width: 26px;
    height: 26px;
    opacity: 1;
    top: 5px;
    right: 5px;
    padding: 0;
    margin: 0;
    border: 0px;
    border-radius: 5px;
}

.mfp-close-btn-in .order_popup .mfp-close::after, .mfp-close-btn-in .awesome_popup .mfp-close::after,
.mfp-close-btn-in .self_popup .mfp-close::after, .mfp-close-btn-in .delevery_popup .mfp-close::after,
.mfp-close-btn-in .error_postal_popup .mfp-close::after, .two-popup .mfp-close::after, .mfp-close-btn-in .receipt_popup .mfp-close:after,
.mfp-close-btn-in .receipt_cateringpopup .mfp-close:after, .changepw_popup .mfp-close:after,
.redeem_popsec .mfp-close:after, .pay-conf-popup .mfp-close:after, .warning_popup .mfp-close:after, 
.venue_popup .close-icon-but:after, .mfp-close-btn-in .delevery_popup_datetime .mfp-close:after,
.order_review .mfp-close:after, .vouchergift-popup .mfp-close:after, #qr-popup .mfp-close:after{
    content: "";
    background: url(../images/crosslg.png) no-repeat;
    width: 14px;
    height: 14px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
}

.mfp-close-btn-in .order_popup .mfp-close:hover, .mfp-close-btn-in .awesome_popup .mfp-close:hover,
.mfp-close-btn-in .delevery_popup .mfp-close:hover, .two-popup .mfp-close:hover, .mfp-close-btn-in .error_postal_popup .mfp-close:hover,
.changepw_popup .mfp-close:hover, .redeem_popsec .mfp-close:hover, .pay-conf-popup .mfp-close:hover,
.warning_popup .mfp-close:hover, .venue_popup .close-icon-but:hover,.mfp-close-btn-in .delevery_popup_datetime .mfp-close:hover,
.vouchergift-popup .mfp-close:hover, #qr-popup .mfp-close:hover{
    background: #282828;
}

.mfp-close-btn-in .receipt_popup .mfp-close, .mfp-close-btn-in .receipt_cateringpopup .mfp-close {
    background: rgba(1, 1, 1, 0.9);
}

.mfp-close-btn-in .receipt_popup .mfp-close:hover, .mfp-close-btn-in .receipt_cateringpopup .mfp-close:hover {
    background: rgba(0, 0, 0, 0.9);
}

.video_popup .order-body {
    padding: 60px 30px 40px 30px;
}

.awesome_popup .popup_ttsec {
    width: 100%;
    background: #000000;
    padding: 30px 20px;
}

.awesome_popup .btn_sec {
    max-width: 500px;
    margin: 10px auto;
}

.awesome_popup .popup_ttsec .button {
    margin-bottom: 0;
    margin-top: 0;
    background: #fff;
    color: #000
}

.awesome_popup .popup_ttsec .button:hover {
    background: #769e90;
    color: #fff
}

.awesome_del {
    text-align: center;
}

.awesome_del h5 {
    font-size: 16px;
    margin-bottom: 25px;
    text-transform: capitalize;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

.awesome_del h2 {
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    text-transform: inherit;
    line-height: 1.5;
    display: inline-flex;
    font-weight: 600;
    padding-left: 30px;
    position: relative;
}
.awesome_del h2:before{ position: absolute; left: 0; top: -2px; width: 22px; height: 22px; content: ""; background: url(../images/checked.png) no-repeat; }

.awesome_right h4 {
    font-size: 16px;
}

.awesome_popup {
    max-width: 640px;
}

.datetime_selt_lbl{ font-weight:600; padding-bottom: 10px; color: #000; font-size: 14px; text-align: center;text-transform: uppercase}

.delevery_popup, .error_postal_popup{max-width: 730px; background: #f6f6f6;}
.offline_popup{max-width: 520px; background: #f6f6f6;    border-radius: 15px;}
.offline_popup img.offline-img{max-width: 160px;height: 135px;margin-bottom: 20px;}
.offline-head h2{font-weight: bold;color: #060503;text-transform: capitalize;margin: 0px 0px 20px 0px;}
.offline-head p{font-weight: 500;color: #241e21}
.offline_popup .innervmid_in{ max-width: 480px;}
.self_popup, .delevery_popup_datetime{ max-width: 820px; background: #f6f6f6; }
.self_popup .full-login-new-body{ max-width: 100%; padding: 30px 50px }
.full-login-new-body .button{ padding: 15px 10px; margin-top: 0; margin-bottom: 0 }
.self-back {
    position: absolute;
    top: 13px;
    font-size: 18px;
    color: #fff;
    z-index: 1;
    left: 40px;
    text-transform: uppercase;
}

.self-back:hover {
    color: #000;
}

.self_popup .order-body {
    padding: 0 0 0px 0;
}

.self_popup_hea_row {
    padding: 20px 15px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.self_popup_hea_col {
    text-align: left;
}

.order-body .self_popup_hea_row h2 {
    color: #fff;
}

.order-body .self_popup_hea_row p {
    margin-bottom: 0;
    font-size: 20px;
    color: #fff;
}

.self_popup_hea_col_left {
    padding-right: 15px;
}
.self_popup_hea_col_left img{width: 50px }

.self_pop_row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 20px 0px;
}

.self_pop_col.self_pop_col_right {
    width: 100%;
    margin: 0 auto;
}

.self_pop_col {
    width: 50%;
}

.self_pop_item_loc {
    position: relative;
}

.self_pop_item_map {
    box-shadow: 0px 0px 10px 0px rgba(1, 1, 1, 0.17);
    -webkit-box-shadow: 0px 0px 10px 0px rgba(1, 1, 1, 0.17);
    border: 1px solid #bba075;
    height: 100%;
}

.selfpop_mapouter {
    height: 100% !important;
}

.or-seperator-row {
    position: absolute;
    right: -9px;
    z-index: 1;
    top: 6px;
}

.or-seperator-row .or-seperator {
    position: relative;
    padding: 25px 0px 7px 0;
    font-size: 16px;
    font-weight: 300;
}

.or-seperator-row .or-seperator span {
    padding: 7px 0px;
}

.or-seperator-row .or-seperator:after {
    height: 82px;
    width: 1px;
    background: #e5cda6;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.self_pop_item_loc p {
    color: #595656;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
}

.self_pop_item_loc .loc_btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    max-width: 170px;
    margin: 0 auto;
    min-width: 288px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 23px;
    border-radius: 10px;
    height: 62px;
}

.loc_icon {
    width: 33px;
    height: 33px;
    background: url(../images/location_btn.png) no-repeat;
    margin-right: 10px;
}

.self_outlet {
    margin-top: 15px;
    height: calc(100% - 105px);
}

.self_outlet h2 {
    color: #000000;
    font-size: 17px;
    text-align: left;
    margin: 0px 0 10px 5px;
    font-weight: 700
}

.self_outlet_inner {
    padding-left: 0;
    list-style: none;
    text-align: left;
    overflow-y: auto;
    margin: 0 0 14px;
    max-height:500px;
}

.self_outlet_inner li {
    margin-bottom: 12px;
}

.self_outlet_inner li:last-child {
    margin-bottom: 0;
}

.self_outlet .btn.cnt_btn {
    width: 100%;
    background: #72624f;
    margin: 0;
}

.self_outlet .btn.cnt_btn:hover {
    background: #333;
}

.self_pop_locbx .form-group .form-control {
    width: 100%;
    padding-right: 40px
}

.self_outlet .button {
    margin: 0;
    width: 100%;
    display: block;
}

.self_pop_locbx {
    margin: 0 0 20px 0
}
.self_pop_locbx .focus-out:after{
    content:"\f002";
    font-family: FontAwesome;
    position: absolute;
    right: 20px;
    top: 13px;
    font-size: 22px;
    line-height: 1;
    color: #333;
}

.self_outlet .self_outlet_inner > li > span{ display: flex; background: #fff;border-radius: 6px; padding: 10px;box-shadow:0px 5px 13px 0px rgba(0, 0, 0, 0.03); text-align: left; overflow: hidden; color: #000; justify-content: space-between; transition: 0.2s linear all;cursor: pointer}

.outlet-list-lhs strong{ display: block; font-style: italic; color: #000; font-weight: 600; font-size: 17px; }
.outlet-list-name{ font-size: 14px; text-transform: inherit; font-weight: 600; color: #181818;
 font-family: 'Poppins', sans-serif;
    margin: 0 0 5px 0;}
.outlet-list-lhs{ width: 130px }
.outlet-list-rhs {
    color: #181818;
    display: flex;
    flex-flow: column wrap;
    justify-content:center;
    width: calc( 100% - 145px ) 
}
.outlet-list-rhs ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    flex-wrap: wrap;
}
.outlet-list-rhs ul li{ margin: 5px 15px 0px 0; font-size: 13px; }
.outlet-list-rhs ul li:last-child{ margin: 5px 0 0 30px }
.outlet-list-lhs > img{width: 130px; max-height: 85px; object-fit: cover;}
.outlet-list-address{ font-size: 13px;margin-bottom: 5px; }
.otulet-timing{ font-weight: 600; font-style: italic;}
.outlet-list-rhs ul li.otulet-waiting span{ position: relative; padding: 0 0 0 24px;}
.outlet-list-rhs ul li.otulet-waiting span:before{ width: 16px; height: 16px; content: ""; position: absolute; left: 0; top: 0; background: url(../images/waiting-clock.png) no-repeat;}
.outlet-list-rhs ul li.otulet-direction span{ position: relative; padding: 0 0 0 25px;}
.outlet-list-rhs ul li.otulet-direction span:before{ width: 17px; height: 17px; content: ""; position: absolute; left: 0; top: 0; background: url(../images/compass.png) no-repeat; background-size: contain}
.otulet-direction a {
    border-bottom: 1px solid #8c8c8c;
}
.self_outlet .self_outlet_inner > li:hover > span, .self_outlet .self_outlet_inner > li.active > span{ background: #181818; color: #fff; }
.self_outlet .self_outlet_inner > li:hover .outlet-list-name, .self_outlet .self_outlet_inner > li:hover .outlet-list-rhs, .self_outlet .self_outlet_inner > li.active .outlet-list-name, .self_outlet .self_outlet_inner > li.active .outlet-list-rhs{ color: #fff;  }
.self_outlet .self_outlet_inner > li:hover .otulet-direction a, .self_outlet .self_outlet_inner > li.active .otulet-direction a{ color: #fff; border-color: #fff; }
.self_outlet .self_outlet_inner > li:hover .otulet-direction span:before, .self_outlet .self_outlet_inner > li.active .otulet-direction span:before{ background: url(../images/compass-light.png) no-repeat; background-size: contain; }
.self_outlet .self_outlet_inner > li:hover .otulet-waiting span:before, .self_outlet .self_outlet_inner > li.active .otulet-waiting span:before{ background: url(../images/waiting-clock-light.png) no-repeat; background-size: contain; }


.white-popup.login-popup, .white-popup.signup-popup, .white-popup.signup-corparateone-popup,
.white-popup.signup-corparatetwo-popup, .white-popup.guest-popup {
    background: inherit;
    max-width: 820px;
}

.pop-whole-lhs-inner {
    text-align: center;
    max-width: 318px;
}

.pop-whole {
    position: relative
}

.login-popup .mfp-close, .signup-popup .mfp-close, .forgot-password-popup .mfp-close, .signup-corparateone-popup .mfp-close,
.signup-corparatetwo-popup .mfp-close, .guest-popup .mfp-close {
    width: 24px;
    height: 24px;
    right: 40px;
    top: 4px;
    font-size: 0;
    margin: 0;
    opacity: 1;
    background: #000;
    border-radius: 3px;
}

.login-popup .mfp-close:hover, .signup-popup .mfp-close:hover, .forgot-password-popup .mfp-close:hover,
.signup-corparateone-popup .mfp-close:hover, .signup-corparatetwo-popup .mfp-close:hover, .guest-popup .mfp-close:hover {
    background: rgba(24, 24, 24, 0.9);
}

.login-popup .mfp-close:after, .signup-popup .mfp-close:after, .forgot-password-popup .mfp-close:after,
.signup-corparateone-popup .mfp-close:after, .signup-corparatetwo-popup .mfp-close:after, .guest-popup .mfp-close:after {
    content: "";
    background: url(../images/crosslg.png) no-repeat;
    background-size: contain;
    width: 12px;
    height: 12px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
}

.popup-footer .btn {
    display: block;
    max-width: 271px;
    margin: 0 auto;
    background: #2d4486;
    text-transform: inherit;
    border-radius: 7px;
    border: 0;
}

.popup-footer .btn:hover {
    background: #333;
    color: #fff;
}

.popup-footer .btn i {
    padding-right: 15px;
}

.popup-footer span {
    color: #868686;
    font-size: 14px;
    margin: 0 0 3px;
    text-transform: uppercase;
}

.popup-footer .guest_btn {
    background: #70afa7;
    color: #fff;
}

.popup-footer .guest_btn:hover, .popup-footer .guest_btn:focus {
    background: #333333;
    color: #fff;
}

.pop-whole:after {
    display: table;
    clear: both;
    content: ""
}

.loginsep-popup .mfp-close:before, .loginsep-popup .mfp-close:after {
    height: 20px;
    background-color: #000
}

.loginsep-popup .mfp-close {
    right: 20px;
    top: -6px
}

.white-popup.processing {
    max-width: 768px;
}

.process_inner {
    position: relative;
}

.process_col {
    position: relative;
    margin-bottom: 15px;
}

.process_left {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
}

.process_right {
    padding-left: 60px;
}

.process_right h5 {
    color: #b0aeae;
    margin-bottom: 5px;
}

.process_right p {
    margin-bottom: 0;
    font-size: 18px;
}

.redeem_bansec {
    position: relative;
    overflow: hidden;
}

.redeem_bansec .innerproduct-item-image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 300px;
}

.redeem_popsec .pouup_in {
    padding: 40px 50px 40px;
}

.redeem_popsec .title3 {
    font-size: 24px;
}

@-webkit-keyframes bouncein {
    0% {
        opacity: 0;
        -webkit-transform: translate(0px, -25px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translate(0px, 0px);
    }
}

@keyframes bouncein {
    0% {
        opacity: 0;
        -webkit-transform: translate(0px, -25px);
        transform: translate(0px, -25px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translate(0px, 0px);
        transform: translate(0px, 0px);
    }
}

.location-part-outer {
    padding: 0px !important;
    position: relative;
    overflow: hidden;
}

.location-part iframe {
    width: 100%;
    height: 680px;
}

.location-sidemenu {
    position: absolute;
    left: 0;
    top: 0;
    max-width: 320px;
    height: 100%;
    z-index: 8;
    width: 100%;
}

.location-sidemenu .red-bar {
    background: #db4337;
    padding: 7px 40px;
}

.location-sidemenu .three-lines {
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    width: 18px;
    height: 12px;
    background: url(../images/spirite_whole.png) no-repeat scroll -122px -191px transparent;
    display: inline-block;
    cursor: pointer;
    position: absolute;
    left: 15px;
    top: 18px;
}

.location-sidemenu .three-lines:hover {
    background-position: -156px -191px;
}

.location-sidemenu .serch-box form {
    position: relative;
}

.location-sidemenu .serch-box input[type="text"] {
    margin: 0;
    height: 35px;
    border: none;
    padding: 7px 35px 7px 15px;
    background: transparent;
    color: #fff;
    outline: 0;
    font-weight: 700;
}

.location-sidemenu .serch-box input[type="text"]::-webkit-input-placeholder {
    color: #fff;
}

.location-sidemenu .serch-box input[type="text"]::-moz-placeholder {
    color: #fff;
}

.location-sidemenu .serch-box input[type="text"]:-ms-input-placeholder {
    color: #fff;
}

.location-sidemenu .serch-box input[type="text"]:-moz-placeholder {
    color: #fff;
}

.loc-cont-part {
    position: absolute;
    width: 100%;
    left: 0;
    background: #fff;
}

.location-sidemenu .serch-box input[type="submit"] {
    position: absolute;
    font-size: 0;
    right: 0;
    top: 0;
    margin: 0;
    width: 35px;
    height: 35px;
    padding: 5px;
    min-width: inherit;
    border: none;
    border-radius: 0;
    background: url(../images/search-icon.png) no-repeat scroll center center transparent;
}

.location-sidemenu .three-dots {
    width: 19px;
    height: 19px;
    background: url(../images/spirite_whole.png) no-repeat scroll -193px -191px transparent;
    display: inline-block;
    position: absolute;
    right: 13px;
    top: 16px;
    cursor: pointer;
}

.outlet-list {
    max-height: 551px;
    overflow-y: auto;
}

.outlet-list > ul {
    padding: 20px 15px;
}

.outlet-list > ul > li {
    margin-bottom: 20px;
}

.outlet-list li {
    list-style: none;
    padding-left: 38px;
}

.outlet-list li span {
    display: block;
    font-weight: 700;
}

.outlet-list li .submenu {
    padding: 0;
}

.outlet-list li .submenu li {
    margin-top: 10px;
    list-style: none;
    padding: 0;
    line-height: normal;
    padding-left: 3px;
}

.outlet-list li .submenu li a {
    font-weight: 700;
    display: block;
    color: #272626;
    position: relative;
    padding-left: 14px;
    font-size: 11px;
}

.outlet-list li .submenu li a:hover {
    color: #ecdfcc
}

.outlet-list .custom_checkbox {
    position: absolute;
    left: 0;
    font-size: 0;
}

.outlet-list .custom_checkbox span:before {
    width: 16px;
    height: 17px;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    border: 1px solid #e6e6e6;
}


.mapinfo_trigger {
    background: red;
    width: 40px;
    height: 40px;
    position: absolute;
    right: 20px;
    top: 20px;
}

.mapinfo_canvas {
    background: #fff;
    width: 100%;
    max-width: 320px;
    position: absolute;
    left: -320px;
    top: -49px;
    bottom: 0;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    z-index: 9;
}

.mapinfo_canvas.open {
    left: 0;
}

.mapcanvas_ttsec {
    background: #db4337;
    padding: 15.5px 20px 15.5px 49px;
    position: relative;
}

.mapcanvas_close {
    background: url(../images/left-arrow.png) no-repeat center;
    position: absolute;
    width: 49px;
    height: 49px;
    line-height: 49px;
    font-size: 24px;
    text-align: center;
    left: 0;
    top: 0;
}

.outlet-list li .submenu li .mapcanvas_close {
    position: absolute;
    padding: 0;
}

.outlet-list li .submenu li .mapcanvas_close:before {
    display: none;
}

.mapcanvas_ttsec h3 {
    font-size: 14px;
    margin: 0;
    color: #fff;
    font-weight: 700;
}

.mapcanvas_bx {
    padding: 12px 18px;
    color: #000;
}

.mapcanvas_bx h5 {
    color: #888;
    margin: 0;
    font-size: 13px;
}

.mainacc_menusec {
    margin: 0 0 40px;
}

.mainacc_menulist {
    padding: 0;
    list-style: none;
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-bottom:4px solid #e3e3e3;
}

.mainacc_menulist > li {
    width: 25%;
    position: relative;
}
.mainacc_menulist > li:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    bottom: -4px;
    left: 0;
    background: #f5ce69;
    visibility: hidden;
    transform: scaleX(0);
    transition: .25s linear;
}
.mainacc_menulist > li >a {
    background: #fff;
    color: #000;
    font-size: 16px;
    text-transform: uppercase;
    padding: 12px 15px 10px;
    display: block;
    position: relative;
    font-weight: 600;
    letter-spacing: 0.1px;
    text-align: center
}

.mainacc_menulist > li.active:before, .mainacc_menulist > li:hover:before {
    visibility: visible;
    transform: scaleX(1);
}

.mbacc_mslidersec {
    display: none;
}

.mainacc_moblink {
    display: none;
}

.myacc_hea {
    position: relative;
    margin: 0 0 14px;
}

.myacc_hea::after, .myacc_pg::after {
    display: block;
    content: "";
    clear: both;
}

.myacc_hea h3 {
    font-size: 27px;
    margin-bottom: 5px;
}

.myacc_hea p {
    margin-bottom: 25px;
    color: #5b5b5b;
}

.myacc_hea_inner {
    margin-top: 8px;
}

.account_sec {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.accmenu_sec {
    width: 220px;
    display: inline-block;
}

.accprofile {
    padding: 26px 10px 0px;
}

.accprofile_img {
    width: 140px;
    height: 140px;
    border: 2px solid rgba(0, 0, 0, 0.2);
    line-height: 134px;
    border-radius: 50%;
    margin: 0 auto 0;
    overflow: hidden;
}

#otherpayment-popup .mfp-close{
    right: 8px;
    top: 8px;
}

.accprofile_img img {
    vertical-align: middle;
}

.accprofile_info p {
    font-size: 15px;
    color: #000;
    font-weight: normal;
    margin: 0;
}

.accpoints_sec {
    padding: 15px 7px 0px
}

.accpoint_tt span {
    background: #fff;
    padding: 0 4px;
    position: relative;
    display: inline-block;
}

.accearn_points {
    background: rgba(0, 0, 0, 0.5);
    padding: 15px 15px 10px;
}

.accearn_pointtop {
    position: relative;
    padding-left: 52px;
}

.accearn_pointtop i {
    position: absolute;
    left: 0;
    top: 7px;
}

.accearn_pointtop h4 {
    font-size: 15px;
    margin: 0;
    color: #fff
}

.accearn_pointtop span {
    font-size: 45px;
    display: inline-block;
    line-height: 0.8;
    color: #ffc800;
}

.accearn_pointtop sub {
    font-size: 60%;
    bottom: 0;
}

.accearn_points p {
    font-size: 13px;
    margin: 0;
}

.accpoint_ec {
    position: absolute;
    right: 13px;
    bottom: 8px;
}

.accsec_right {
    width: calc(100% - 220px);
    width: -webkit-calc(100% - 220px);
    display: inline-block;
    padding: 40px;
}

.accsec_right h1 {
    font-size: 36px;
    margin-bottom: 10px;
}

.accsec_right p {
    font-size: 17px;
}

.acc-inform .form-group {
    margin-bottom: 11px;
}

.other-add-body {
    height: 175px;
    border: 1px solid #f1efef;
    border-radius: 7px;
    padding: 16px 14px 0px 14px;
    background: #fff;
}

.other-add-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    list-style: none;
    width: 100%;
    padding-left: 0;
    margin-bottom: 0;
}

.other-add-row li {
    width: 32.1875%;
    margin-right: 1.6666666666666666%;
    position: relative;
    margin-bottom: 10px;
}

.other-add-row li:nth-of-type(3n+3) {
    margin-right: 0;
}

.other-add-row li a {
    background: #f9f9f9;
    padding: 15px 15px;
    display: block;
    border: 1px solid #f1efef;
    color: #252525;
    font-size: 13px;
    border-radius: 7px;
    cursor: default;
}

.other-add-row span {
    position: absolute;
    top: 6px;
    right: 8px;
    background: url(../images/spirite_whole.png) no-repeat;
    width: 10px;
    height: 10px;
    background-position: -35px -315px;
    cursor: pointer;
    -webkit-transition: all 400ms;
    transition: all 400ms;
}

.other-add-row span:hover {
    opacity: 0.5;
}


.setting_menu_list > ul {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
}

.setting_menu_list > ul li {
    position: relative;
}

.setting_menu_list > ul > li > a {
    padding: 12px 10px 12px 20px;
    display: block;
    text-transform: uppercase;
    margin-bottom: 0;
    font-weight: 500;
    color: #000;
    background: #f4f3f3;
}

.accmenu_sec .setting_menu_list > ul li a:hover{background: #0d0b06; color: #fff;}

.setting_menu_list > ul li:hover .myacc_dropmenu {
    opacity: 1;
    visibility: visible;
}
.myacc_dropmenu {
    position: absolute;
    left: 105%;
    top: -20px;
    list-style: none;
    padding-left: 0;
    background: rgba(0, 0, 0, 0.77);
    padding: 30px 35px;
    z-index: 1;
    width: 284px;
    border-radius: 7px;
    box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.35);
    -webkit-box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.35);
    opacity: 0;
    visibility: hidden;
}

.myacc_dropmenu::after {
    position: absolute;
    content: "";
    border-right: 14px solid #3b3b3b;
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: -7px;
}

.myacc_dropmenu::before {
    content: "";
    position: absolute;
    left: -13px;
    top: 0;
    background: #ccc;
    width: 20px;
    height: 100%;
    opacity: 0;
}

.myacc_dropmenu li a {
    color: #fff;
}

.myacc_dropmenu .tg-list {
    position: absolute;
    top: 0;
    right: -12px;
    padding-top: 0;
}

.main_tabsec_inner {
    padding: 20px 0px 0;
}

.rewards-newdesign .main_tabsec_inner, .promotion-newdesign .main_tabsec_inner{
    padding: 30px 40px 0;
}

.main_tabsec_inner> .nav-tabs>li {
    width: 33.17%;
    margin-right: .24154589371980675%;
    position: relative;
}

.main_tabsec_inner> .nav-tabs>li:last-child {
    margin-right: 0;
}

.main_tabsec_inner> .nav-tabs {
    text-align: center;
}

.main_tabsec_inner> .nav-tabs>li> a {
    background: #bfbebe;
    margin: 0;
    border-radius: 0;
    border: 0;
    color: #fff;
    font-size: 16px;
    font-weight: normal;
    text-transform: uppercase;
    padding: 17.5px 91px 17.5px 15px;
}

.main_tabsec_inner> .nav-tabs>li.active>a {
    border: 0;
    background: #0b0405;
    color: #fff;
}

.main_tabsec_inner> .nav-tabs>li> a > span > b {
    font-weight: normal;
}

.count_num_t {
    background: #0d0b06;
    height: 20px;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    margin-left: 5px;
    font-size: 11px;
    line-height: 21px;
    vertical-align: top;
    margin-top: 1px;
    padding: 0 4px;
    min-width: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600
}

.setting_menu_list li:hover .count_num_t, .setting_menu_list li.active .count_num_t{background: #fff;color:#000}
.web_order_typecls li:hover .count_num_t, .web_order_typecls li.active .count_num_t{color: #000}

.btn.spirite_image {
    height: 56px;
}

.spirite_image {
    display: inline-block;
    vertical-align: middle;
    position: relative;
}

.spirite_image i {
    margin-right: 10px;
    vertical-align: middle;
    display: inline-block;
}

.filter_tabsec .nav-tabs {
    width: 100%;
    padding: 0;
    border-bottom: 0;
}

.filter_tabsec .nav-tabs>li {
    width: 50%;
    float: left;
    border-bottom: 0;
    position: relative;
}

.filter_tabsec .nav-tabs>li>a span {
    display: inline;
}

.filter_tabsec .nav-tabs>li>a:after {
    height: 5px;
    bottom: -5px;
    background: #DB203B;
    max-width: 253px;
    margin: auto;
    width: 100%;
}

.tab-pane {
    display: none
}

.tab-pane.active {
    display: block
}

.ord-body::after {
    display: block;
    clear: both;
    content: "";
}

.ord-pagination-col {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin: 0 0 10px 0;
}

.ord-pagination-col::after {
    display: block;
    content: "";
    clear: both;
}

.ord-pagination-col span {
    font-size: 12px;
    text-transform: uppercase;
    font-weight:bold;
}

.ord-pagination-col p {
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 0;
    font-weight:bold;
}

.ord-pagination-col .form-group {
    margin-bottom: 0;
    padding-right: 5px;
}

.ord-pagination-col .form-group select {
    background: #d7d4d8;
    border: 0;
    font-weight:bold;
}

.cur-order-body {
    width: 100%;
    clear: both;
}

.cur-order-body::after {
    display: block;
    clear: both;
    content: "";
}

.cur-order {
    padding: 10px 0;
    border-bottom: 1px solid #efefee;
    position: relative;
}

.click_view {
    position: absolute;
    right: 21px;
    color: #474747;
    padding: 0;
    font-weight: 700;
    font-size: 12px;
}

.cur-order-del {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    max-width: 450px;
    list-style: none;
    padding-left: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
}

.cur-order-del li {
    font-weight: 700;
    font-size: 16px;
    position: relative;
    padding-left: 20px;
}

.cur-order-del li a.btn {
    min-width: 132px;
    height: 35px;
    border-radius: 10px;
    font-size: 16px;
    padding: 8px 5px;
    color: #0b0405;
    line-height: normal;
    margin-bottom: 0;
}

.cur-order-del li a.btn:hover {
    color: #fff;
}

.cur-order-del li span {
    padding-left: 5px;
}

.cur-order-del-icon {
    background: url(../images/spirite_whole.png) no-repeat;
    width: 16px;
    height: 16px;
    display: inline-block;
}

.calender-icon {
    background-position: -215px -258px;
}

.time-icon {
    background-position: -249px -257px;
}

.cur-ord-rec {
    list-style: none;
    float: right;
    max-width: 400px;
    padding-right: 19px;
    margin-bottom: 0;
    padding: 8px 19px 0 0;
}

.cur-ord-rec li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.cur-ord-rec li a {
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: underline;
    color: #2e2e2c;
}

.cur-ord-rec li a:hover {
    color: #909090;
}

.cur-ord-rec li span {
    display: inline-block;
    width: 1px;
    height: 100%;
    background: #2e2e2c;
    margin: 0 20px;
    position: relative;
}

.cur-ord-rec li span::after {
    content: "";
    background: #2e2e2c;
    height: 14px;
    width: 1px;
    display: block;
    position: absolute;
    top: 2px;
}

.order-cat-hea {
    background: #fff;
    padding: 8px 35px;
    max-width: 331px;
    margin-bottom: 10px;
}

.order-cat h2 {
    font-size: 20px;
    color: #0b0405;
    margin-bottom: 0;
}

.order-cat-table {
    padding: 0 35px;
}

.order-cat-table h4 {
    color: #474747;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 11px;
}

.order-cat-table table {
    border: 0;
    max-width: 364px;
    margin-bottom: 10px;
}

.order-cat-table table td {
    font-weight: 300;
    font-size: 16px;
    color: #3d3d3d;
    padding: 0 5px;
}

.order-cat-table table td.p_name {
    padding-left: 0;
}

.p_name {
    width: 247px;
}

.p_val {
    width: 80px;
}

.ord-amount {
    padding: 10px 0 0 35px;
    border-top: 1px solid #efefee;
}

.ord-amount .row {
    margin-right: -20px;
}

.ord-amount-item {
    max-width: 364px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.ord-amount h2 {
    font-size: 27px;
    color: #202020;
}

.ord-rec-sec {
    list-style: none;
    padding-left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    text-align: right;
    float: right;
    width: 649px;
}

.ord-rec-sec li {
    color: #aba9a9;
    margin-bottom: 0;
    height: 39px;
    padding: 10px 15px;
    max-width: 184px;
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    width: 25%;
    line-height: 23px;
    overflow: hidden;
}

.ord-rec-sec li.completed {
    background: #ecdfcc;
    color: #3d3d3d;
}

.ord-number {
    width: 22px;
    height: 22px;
    line-height: 22px;
    display: inline-block;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    font-weight: 700;
    margin-right: 6px;
}

.ord-rec-sec li.completed .ord-number {
    color: #fff;
    background: #0b0405;
}

.rewards-tab-section .main_tabsec_inner {
    padding: 6px 72px 50px 72px;
}

.congrats::after {
    display: block;
    content: "";
    clear: both;
}

.congrats-inner {
    margin: 10px auto 0;
    text-align: center;
    padding: 50px 15px;
    background: #000;
}

.congrats-inner h3 {
    font-size: 25px;
    color: #000;
    margin-bottom: 4px;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

.congrats-inner h2 {
    font-size: 40px;
    color: #000;
    margin-bottom: 0;
    font-family: 'Poppins', sans-serif;
}

.rewards-tab-section .ord-amount {
    border-top: inherit;
    padding-top: 0;
}

.rewards-tab-section .order-cat-hea {
    margin-bottom: 7px;
}

.earned_points {
    max-width: 300px;
    padding-left: 0;
    list-style: none;
    float: right;
    padding-right: 24px;
}

.earned_points li span {
    color: #bfbebe;
    text-transform: uppercase;
    font-size: 18px;
    display: inline-block;
    margin-right: 5px;
}

.earned_points li h2 {
    color: #b69a6b;
    font-size: 24px;
    text-transform: uppercase;
    margin-bottom: 8px;
}

.redeem_popsec {
    max-width: 600px;
    border-radius: 10px;
    overflow: hidden;
    -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.34);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.34);
    margin: 0px auto;
}

.redeem_popsec .promo-earned-col-center .innerproduct-item-image {
    width: 25%;
    height: 270px;
}

.redeem_popsec .promo-earned-content {
    padding: 30px 20px 30px;
    text-align: center;
}

.redeem_popsec .promo-earned-content h4 {
    font-size: 24px;
    margin: 0px 0px 10px 0px;
}

.redeem_popsec .promo-earned-content h2 {
    font-size: 42px;
    color: #3c3c3c;
}

.redeem_popsec .promo-earned-content p {
    margin-bottom: 10px;
}

.redeem_popsec .abc_code {
    padding: 20px 0 0px;
}
.redeem_pop_image img {
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.redeem_popsec .pouup_in {
    padding: 30px 55px 30px;
}

.redeem_popsec .pouup_in p {
    font-size: 16px;
    color: #000;
    line-height: 24px;
}

.redeem_popsec .btn {
    background: #DB203B;
    color: #fff;
    display: block;
    border-radius: 6px;
    margin: 0 auto 0px;
    text-align: center;
}

.redeem_popsec .btn:hover {
    background: #200200;
    color: #fff;
}

.redeem_popsec .button{
    display: block;
    padding: 16px 28px;
    margin: 0 auto 20px;
    width: 50%;
    border-radius: 4px;
}

.redeem_popsec .mfp-close {
    background: #d9d7d7;
    border-radius:5px;
    -webkit-transition: 0.5s all ease;
    transition: 0.5s all ease;
    top: 10px !important;
    right: 10px !important;
}

.redeem_popsec .mfp-close:after {
    background: url(../images/cross-close.png) no-repeat;
    background-size: contain;
    background-position: center;
}

.redeem_popsec .mfp-close:hover {
    background: #b5b5b5;
}

.input_area { position: relative;}

.cal_icon {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    height: 100%;
    width: 45px;
    pointer-events: none;
}

.refer-friend-maindiv{
    margin: 25px 40px;
    padding: 28px 15px;
}

.refer-friend-bottom-content{
    text-align: center;
}

.refer-friend-reward-section{
    text-align: center;
    margin: 25px 40px;
    padding: 28px 15px;
}

.refer-friend-reward-section h2{
    color: #060203;
    font-weight: bold;
    margin-bottom: 5px;
    text-transform: uppercase;
}

.refer-friend-reward-section h4{
    color: #060203;
    font-weight: 500;
    margin-bottom: 15px;
    text-transform: capitalize;
}

.refer-friend-reward-section p{
    color: #949292
}

.refer-btn a{
        display: block;
    padding: 15px;
    color: #fff;
    background: #da203a;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    position: relative;
    letter-spacing: 0.5px;
    margin: 0 auto;
    line-height: 1;
    font-weight: 600;
    border-radius: 6px;
    max-width: 160px;
    white-space: nowrap;
}

.refer-btn a:hover{background: #000;color: #fff}

.cal_icon b {
    display: block;
    width: 100%;
    height: 100%;
    background: url(../images/arrow-down.png) no-repeat center;
}

.pop-whole .kep-login-facebook.metro, #login-content .kep-login-facebook.metro{
    margin: 0 auto;
    background: #3d5a98;
    text-transform: inherit;
    border-radius: 5px;
    width: 100%;
    padding: 8px 15px;
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    border: 1px solid #cbcbcb;
    box-shadow:0px 3px 9px 0px rgba(0, 0, 0, 0.21);
    display: flex;
    justify-content: center;
    align-items: center;
}
.pop-whole .kep-login-facebook.metro i, #login-content .kep-login-facebook.metro i{ font-size: 25px; }

.pop-whole .kep-login-facebook.metro:hover, #login-content .kep-login-facebook.metro:hover {
    background: #333;
    color: #fff;
}

.combo-input-label {
    height: 100%;
    border: none;
    color: #fff;
    font-size: 15px;
    text-align: center;
    margin: 0;
    padding: 2px;
    background: #000;
    border-radius: 5px;
    line-height: 34px;
}

.compo_acc_innerdiv {
    padding: 10px 10px 0px 20px;
}

.gloading_img {
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 9;
}

.gloading_img:after {
    content: "";
    width: 35px;
    height: 35px;
    border: 4px solid rgba(30, 29, 29, 0.49);
    border-top-color: #1e1d1d;
    border-radius: 50%;
    position: relative;
    -webkit-animation: loader-rotate 1s linear infinite;
    animation: loader-rotate 1s linear infinite;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
}

@-webkit-keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@-webkit-keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.load_warpper {
    position: relative;
}

.load_warpper #dvLoading {
    position: absolute;
}

#dvLoading {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
    margin: 0;
    background: #fff url(../images/loading.gif) no-repeat center;
    background-size: 60px;
    -webkit-background-size: 60px;
    bottom: 0;
    right: 0;
}

#inner_loading {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
    margin: 0;
    background: rgba(255, 255, 255, 0.9) url(../images/loading_inner.gif) no-repeat center;
    bottom: 0;
    right: 0;
}

.popup_close {
    position: absolute;
    z-index: 1;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background: #fff;
    opacity: 1;
    padding: 10px;
    border-radius: 5px;
    width: 40px;
    z-index: 1
}

.back_arrow {
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    width: 43px;
    height: 49px;
    text-align: center;
    line-height: 49px;
    cursor: pointer;
    z-index: 4;
}

.back_arrow:after {
    content: "";
    background: url(../images/spirite_whole.png) no-repeat;
    width: 26px;
    height: 26px;
    background-position: -160px -16px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
}

.react-datepicker-popper {
    z-index: 11 !important;
}

.scrollup {
    width: 40px;
    height: 40px;
    position: fixed;
    bottom: 50px;
    right: 15px;
    background: #523429;
    color: #fff;
    display: inline-block;
    text-align: center;
    line-height: 40px;
    font-size: 12px;
    transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
    cursor: pointer;
    z-index: 111;
}

.scrollup:hover {
    background: #000;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.scrollup:hover .fa {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.scrollup .fa {
    transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
}

.cms-page {
    margin: 0px 0 30px;
}

.cms-page .container {
    background: #fff;
    padding: 40px 20px 30px;
}

.our-story-main-div .cms-page .container {
    padding: 0px;
    background: none;
}

.our-story-page .container{
    padding: 0px;
    background: none;
}

.cms-page h3 {
    font-size: 27px;
    color: #040507;
}

.contact_left p a {
    color: inherit;
}

.contact_left p a:hover {
    color: #b69a6b;
}

.contact_left p.open-hours {
    border-top: 1px solid #eaeaea;
    padding-top: 25px;
    margin-top: 26px;
}

.contactus_pg .contact_form h3 {
    margin: 0 0 5px;
}

.contact_form .help-block {
    margin: 0 0 24px;
}

.product_tag {
    list-style: none;
    padding-left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 5px 0 10px;
}

.product_tag li {
    padding-right: 10px;
}

.product_tag li b {
    margin-right: 10px;
}

.product_tag li a {
    display: inline-block;
    color: #5b5b5b;
    font-weight: 700;
}

.catogry_row_fixed .bakery_slider {
    position: fixed;
    top: 96px;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 9;
    margin: auto;
    text-align: center;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    max-width: 1260px;
    padding-top: 0 !important;
}

.tbars .bakery_row_fixed {
    top: 171px;
}

.bakery_row.bakery_row_fixed .slick-next {
    right: 0;
}

.chosen_file {
    position: relative;
    display: inline-block;
}

.chosen_file input[type="file"] {
    position: absolute;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.chosen_file label {
    font-size: 16px;
    font-weight: 700;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 11.5px 15px;
    outline: 0;
    background: #ecdfcc;
    max-width: 160px;
    border: 1px solid #e6e5e5;
    text-overflow: ellipsis;
    margin: 0;
    pointer-events: none;
}

.catering .ttsplitter span {
    background: #fff;
}

.catering .ttsplitter {
    margin: 0 auto 20px;
}

.container_720 {
    margin: 0 auto;
    max-width: 720px;
}

.date_list {
    margin-bottom: 30px;
    position: relative;
    padding: 40px 120px 30px;
    border: 2px solid #dddddd;
    background: #fff;
    border-radius: 6px;
}

.date_list:after {
    content: "";
    display: table;
    clear: both;
}

.datelist_bracktime h3, h3.choose-eventtype {
    text-align: center;
    font-size: 26px;
    margin: 0 0 20px 0;
}

.ordering-more {
    text-align: center
}

.ordering-more .button.no {
    background: #000;
}

.ordering-more .button.yes {
    background: #769e90;
}
.more-time-slots{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 476px;
    margin: 0 auto;
}
.more-time-slots a{
    width: 49%;
    margin: 0;
    padding: 17px 5px 16px;
    border-radius: 5px;
    font-size: 19px;
}

#nobtn_id {
    margin-right: 5px
}

.ordering-more .button.yes:hover,
.ordering-more .button.no:hover {
    background: #333;
}

.ordering-more h3 {
    color: #3d3d3d;
    font-size: 16px;
    font-weight:bold;
    margin: 0 0 15px 0;
}

.date_list h4 {
    color: #3d3d3d;
    font-size: 16px;
    font-weight:bold;
    margin: 0 0 15px 0;
     
}

.venue-details-inner h3 {
    display: block;
    font-size: 14px;
    color: #3c3a3a;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 5px;
    padding-left: 5px;
}

.show {
    display: block;
}

.no-padding {
    padding: 0px;
}
.errorInfo-div {
    display: none;
    background: #ef1616;
    padding: 5px;
    color: #fff;
}

.making-catering {
    width: 36%;
    float: left;
    padding: 5px 20px;
    border-right: 1px solid rgba(0, 0, 0, 0.17)
}

.making-catering p {
    margin: 0;
}

.making-place {
    font-weight:bold;
    font-size: 16px;
}

.making-place.making-place-padding {
    display: block;
}

.change-location a {
    text-decoration: underline;
    font-size: 17px;
    color: #000;
}

.change-location a:hover {
    color: #769e90;
}

.event-time {
    width: 33%;
    padding: 5px 0px 5px 20px;
}

.event-time .title4 {
    margin-bottom: 3px;
}

.event-time .row {
    margin: 0 -4px;
}

.event-time [class*="col-"] {
    padding: 0 4px;
}

.event-time .form-group {
    margin: 0;
}

.event-time .form-control {
    height: 57px;
}

.order-select-brktime ul {
    margin: 0;
    padding: 0;
    list-style: none
}

.order-select-brktime ul .time-cls {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 0 10px 0;
}

.order-select-brktime ul .time-cls .form-group {
    margin: 0;
    position: relative;
    border: 1px solid #e4e4e4;
}

.order-select-brktime ul .time-cls label {
    width: calc( 100% - 200px);
    font-size: 18px;
    font-weight:bold;
    color: #020202;
    text-transform: uppercase
}

.viewmenu-top-div {
    margin: 10px 0 0 0;
}

.viewmenu-top-div .back_bx {
    padding: 36px 10px 12px;
}

.viewmenu-top-div .back_bx:after {
    font-size: 30px;
    top: 5px;
}
ul li .cat-product-info{
    background: rgba(0, 0, 0, 0.60);
    padding: 45px 15px 45px;
    color: #fff;
    line-height: 1.4;
    font-size: 14px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: all 0.35s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
}
ul li .cat-product-info h4{color: #ffffff;margin: 0 0 5px 0; }
.cat-product-button{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
.cat-product-button a{
    margin: 0;
    display: block;
    height: 45px;
    padding: 15px 10px;
}
.btn_download {
    font-size: 18px;
    color: #fff;
    padding: 8px 20px 7px;
    height: 38px;
    border: 0;
    border-radius: 30px;
    background: #769e90;
    -webkit-box-shadow: 0 2px 0px 0px #0b0405;
    box-shadow: 0 2px 0px 0px #0b0405;
    margin: 6px 0px 12px;
}

.btn_download:hover {
    background: #ecdfcc;
    color: #0b0405;
    -webkit-box-shadow: 0 2px 0px 0px #b1b1b1;
    box-shadow: 0 2px 0px 0px #b1b1b1;
}

.product_modifiers_listinner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.cross_multi {
    font-size: 15px;
    color: #5d5d5d;
    margin: 0 0px 5px 0;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding: 6px 12px;
    z-index: 1;
    background: #ffffff;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1px;
    border: 1px solid #d6d6d6;
    line-height: 1;
    border-radius: 4px;
    text-align: center;
}

.checkout_time {
    padding: 0px 0px 20px;
}

.checkout_time label {
    color: #000000;
    line-height: 40px;
    padding: 4px 0px;
    font-size: 17px;
    text-transform: uppercase;
    font-weight:bold;
}

.total_amt {
    background: #1c1c1c;
    padding: 35px 70px 30px 50px;
}

.total_amt .subtotal {
    font-size: 16px;
    color: #fff;
    font-weight:bold;
}

.total_amt .total {
    font-size: 25px;
    color: #fff;
    font-weight:bold;
    text-transform: uppercase;
}

.total_amt .rt {
    text-align: right;
}

.brline {
    background: #f5f5f5;
    height: 2px;
}

.download-quote {
    padding: 20px 0px 0px;
    text-align: center;
}

.download-quote .btn {
    font-size: 18px;
    color: #fff;
    padding: 7px 20px;
    border: 0;
    background: #769e90;
}

.catering_thak_you .tnk-header {
    text-align: unset;
}

.catering_thak_you .tnk-detail {
    text-align: center;
}

.catering_thak_you .tnk-header .tick {
    text-align: center;
}

.catering_thak_you .order_details .qtybxs {
    font-weight: 700;
    color: #000000;
    font-size: 16px;
    line-height: 38px;
    text-align: right;
}

.catering_thak_you .package .package-list {
    padding: 0px 0px 15px 0px;
}

.catering_thak_you .package .title {
    border-bottom: 1px solid #000;
}

.catering_thak_you .brline {
    margin-left: 0px;
}

.promotion_popup {
    font-size: 16px;
    max-width: 650px;
}

.promo_poptt {
    margin: 0 0 30px;
}

.promo_poptt .title1 {
    margin-bottom: 8px;
}

.popoff_bar {
    padding: 10px 0 15px;
    border: 1px solid #ddd;
    border-right: 0;
    border-left: 0;
    margin: 0 0 35px;
}

.popoff_bar span {
    display: block;
    font-size: 36px;
    font-weight: 700;
    color: #000;
}

.popoff_bar b {
    display: block;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 400;
}

.popwel_formsec h4 {
    margin: 0 0 10px;
}

.popwel_inputbx {
    position: relative
}

.popwel_inputbx .form-control {
    height: 51px;
    font-size: 16px;
}

.popwel_inputbx .btn {
    position: absolute;
    right: 0;
    top: 0;
    border-color: #ecdfcc;
}

.rating-option {
    position: relative;
    display: inline-block;
}

.rating-option input {
    opacity: 0;
    filter: alpha(opacity=0);
    height: 100%;
    left: 0;
    top: 0;
    margin: 0;
    width: 100%;
    position: absolute;
    cursor: pointer;
}

.rating-option span {
    padding: 5px 0 0 40px;
}

.rating-option span:before {
    content: "";
    background: #fff;
    position: absolute;
    width: 30px;
    height: 30px;
    border: 2px solid #aba8a3;
    left: 0;
    top: 3px;
    pointer-events: none;
    line-height: 21px;
    border-radius: 50%;
}

.rating-option input[type=radio]:checked + span:before {
    content: "\f00c";
    font-family: FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    line-height: 26px;
    color: #fff;
    text-align: center;
    text-align: center;
    background: #72624f;
    border-color: #ecdfcc;
}

.contact_left p {
    margin: 0 0 10px;
}

.react-datepicker__time-list-item--disabled {
    display: none;
}

#pay-conf-popup .process_inner {
    text-align: center;
}

#pay-conf-popup .process_inner .btn-block {
    display: inherit;
    width: auto;
}

.loader-main-cls {
    position: relative;
    pointer-events: none;
}

.loader-sub-div {
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 98;
}

.dark-theme .loader-sub-div{
    background: rgb(145 145 145 / 80%);
}

.loader-sub-div:after {
    width: 35px;
    height: 35px;
    border: 4px solid rgba(30, 29, 29, 0.49);
    border-top-color: #1e1d1d;
    border-radius: 50%;
    position: relative;
    -webkit-animation: loader-rotate 0.3s linear infinite;
    animation: loader-rotate 0.3s linear infinite;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto
}

@-webkit-keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.header_middle .container {
    position: relative;
}

.htico_search > a, .htico_search_mbl > a {
    position: relative;
    display: block;
}

.header_bot {
    display: inline-block;
}

.home-banner-full {
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
}
.home-banner .slick-slide img {
    width: 100%;
}

.header-top {
    background: #000;
    text-align: center;
    clear: both;
    padding-top: 10px;
    padding-bottom: 10px;
}

.header-top p {
    color: #fff;
    font-size: 15px;
    margin-bottom: 0px;
}
.header-top-cls {
    padding: 10px 0;
    position: relative;
}

.logo img {
    transition: 0.3s linear all;
}

.logo-main-section {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    z-index: 9;
    align-items: center;
}
.menu_icon.trigger_menunav_act {
    display: none;
}
.mbl_search_liblk{display: none}

.slick-slide {
    position: relative;
}

.wppc-checked {
    color: red;
}

.wppc-rating-full {
    background-image: url("../images/star-bg.png");
    background-repeat: no-repeat;
    padding: 5px 10px;
    margin-top: 0px;
    margin-left: 44px;
}

.subtle {
    position: absolute;
    left: 0;
    right: 0;
    background: rgba(41,41,41, 0.50);
    padding: 15px 5px 10px;
    top: 50%;
    transform: translateY(-50%);
}
footer { position: relative; background: #000000; padding: 45px 0 0 0; color: #fff;}
.footer-top { padding: 0 0 30px 0; display: flex; justify-content: space-between;}
.footer-newletter { width: 32.03125%;}
.footer-logo { width: 28.90625%;text-align: center}
.footer-nav { width: 29.6875%; display: flex; flex-wrap: wrap;justify-content: space-between;}
.footer-nav-inner { width: 49%;}
.footer-newletter span{color: #fff; font-size: 12px;display: block;margin: 0 0 5px 0; font-weight: 600}
.footer-newletter h4{ color: #fff; font-size: 20px; text-transform: inherit;line-height: 1.4;}
.f-newletter-form{position: relative}
.f-newletter-form input[type="text"] {
    background: none;
    border: 0;
    border-bottom: 2px solid #a9a9a9;
    padding-left: 2px;
    padding-right: 34px;
    color: #fff;
    border-radius: 0;
}
.f-newletter-form .button {
    position: absolute;
    right: 5px;
    top: 20px;
    margin: 0;
    background: none;
    padding: 0;
    border-radius: 0;
    width: 21px;
    height: 14px;
    font-size: 0;
}
.f-newletter-form .button:after{ 
    background: url(../images/gold-right-arrowcopy.svg) no-repeat; width: 20px; height: 12px; content: "";    
    display: block; position: absolute; top: 0; left: 0; }
.f-newletter-form input[type="text"]::placeholder {color: #fff;}
.f-newletter-bottom label { font-size: 11px; color: #d6d6d6;line-height: 16px !important;}
.f-newletter-bottom { position: relative;padding-left: 24px;}
.f-newletter-bottom input[type="checkbox"] { position: absolute; left: 0; top: 4px;background: #000;border-color: #ee2442}
.f-newletter-bottom input[type=checkbox]:not(.switch):checked{ background: #DB203B; }
.footer-nav h5{ color: #fff;     font-family: 'Poppins', sans-serif; font-size: 14px; font-weight: 700; letter-spacing: 5px;margin-bottom: 20px }
.footer-nav ul{padding: 0; margin: 0; list-style: none;}
.footer-nav ul li a{ color: #fff;display: inline-flex; margin-bottom: 5px;}
.footer-nav ul li a:hover{ color: #DB203B; }
.social-media a img { height: 33px;width: 33px; }
/*.social-media a:first-child img{ max-width: 14px }
.social-media a:nth-child(2) img{ max-width: 27px }
.social-media a:last-child img{ max-width: 40px }*/
.social-media a{display: block; margin-right: 20px}
.social-media {margin: 30px 0 0 0; display: flex; justify-content: center;}
.social-media a:last-child{margin-right: 0}
.social-media img:hover{-webkit-transform: scale(1.1);transform: scale(1.1);transition: all .2s cubic-bezier(.63,.05,.43,1.7);}

.copyright-section p {
    font-size: 14px;
    color: #838383;
    text-align: right;
    margin-bottom: 0px;
}

.mobile-footer-nav ul li a:hover{ color: #c81d35; }

.home-banner {
    position: relative;
    z-index: 2;
}
.homebanner-content-inner {
    max-width: 670px;
    background: rgba(255,255,255,0.86);
    padding: 50px 60px;
}

.homebanner-content {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    max-width: 1280px;
    margin: 0 auto;
    width: 100%;
    right: 0;
        height: auto !important;
    line-height: normal;
}
.homebanner-content-inner h4{ letter-spacing: 5px; font-size: 14px; font-family: 'Poppins', sans-serif;font-weight: 700; margin-bottom: 10px;}
.homebanner-content-inner h2{font-size: 38px;font-weight: bold; text-transform: inherit; line-height: 1.2}
.homebanner-content-inner a{font-size: 14px; letter-spacing: 4px;font-weight: 700; position: relative;text-transform: uppercase; display: inline-flex; padding-right: 40px; margin-bottom: 0}
.homebanner-content-inner a:after{position: absolute; right: 0; top: 3px; background: url(../images/black-right-arrow.svg) no-repeat; content: ""; width: 29px; height: 14px;}
.container-full{ 
    max-width: 1340px;
    margin: 0 auto;
    padding: 0 30px;
    width: 100%;
}

.slick-track {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.slick-track:before,
.slick-track:after {
    display: none !important
}

.slick-track .slick-slide {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    height: inherit;
}

.slick-track .slick-slide .featured-products-single {
    height: 100%;
    background-color: #fff;
}
.prd_chosen_sub_item_left p {
    margin: 0 0 5px;
}

.addcart_row .btn {
    margin: 0 0 0 2%;
    float: left;
    font-size: 14px;
    color: #fff;
    height: 44px;
    border: none;
    cursor: pointer;
    padding: 5px 15px
}

.addcart_row .btn:hover,.addcart_row .btn:focus {  background: #000;}

.innerproduct-item::after {
    content: "";
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    position: absolute;
    z-index: 1;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    opacity: 0;
    -webkit-transition: 0.5s all ease-in-out;
    transition: 0.5s all ease-in-out;
}

.innerproduct-col:hover::after,.innerproduct-col.open::after { opacity: 1;}

.innerproduct-item-text h4 {
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    color: #0a0b10;
    padding: 10px 0 8px 0;
    margin-bottom: 0;
}

.bakery-review .fa-star {  color: #000;}
.bakery-review .wppc-checked { color: #ed8a19;}

.innerproduct-item-text p {
    line-height: 20px;
    font-size: 14px;
    color: #949292;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px dotted #ccc;
    border-bottom: 1px dotted #ccc;
    margin-top: 15px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.innerproduct-item-text h5 {
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    color: #000;
    margin-bottom: 0;
}

.innerproduct-item-text {
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    background: #fff;
    padding-top: 10px;
    padding-bottom: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
}

.menu-section_full {
    overflow: hidden;
}

header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 99;
    margin: auto;
    background: #000000;
}

.logo a {
    position: relative;

}

.header-bottom .header-bottom-innersec {
    position: relative;
}

ul.hmenu_list > li > a {
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    color: #fff;
    text-transform: uppercase;
    padding: 20px 1px 20px;
    display: block;
    transition: 0.1s linear all;
    position: relative;
    margin: 0 5px;
    line-height: 1;
    font-weight:500;
}

.hsign_sec > span {
    font-size: 17px;
    vertical-align: text-bottom;
    position: relative;
    top:2px;
    color: #fff;
    margin: 0 5px;
}

.hsign_sec a {
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
    padding: 10px;
}

.hsign_sec a:hover, ul.hmenu_list > li > a:hover, ul.hmenu_list > li:hover > a {
    color: #DB203B;
}

.haction_sec {
    text-align: center;
    position: relative;
}

.hmenu_actions {
    position: absolute;
    top:10px;
    right: 0;
    padding: 0;
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.htico_search> a > img{
    width: 25px;
    height: 25px;
    display: block;
}

.htico_cart> a img {
    width: 26px;
    height: 28px;
    display: block; 
}

.header_in {
    padding-top: 45px;
    position: relative;
}

.hcart_round {
    background: #de5036;
    min-width: 14px;
    min-height: 14px;
    font-size: 7px;
    border-radius: 100%;
    position: absolute;
    right: 3px;
    top: -7px;
    text-align: center;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1px solid #fff;
    line-height: 12px;
}

.home-banner .slick-track .slick-slide > div {
    line-height: 0;
    width: 100%;
}

.featured-products-full .slick-dots {
    display: none !important
}
.home-content{ padding: 60px 0 0px 0;background: #fff;}
.home-content-inner h3 { text-transform: inherit; font-size: 20px; line-height: 1.7; margin: 0;}
.home-content-inner p{ font-size: 19px; line-height: 2; font-weight: 500; }
.home-content-inner ul{padding:20px 0 0 0; margin: 0; list-style: none; display: flex; justify-content: center; flex-wrap: wrap}
.home-content-inner ul li{margin:0 2% 20px 0;position: relative; width: 32%; }
.home-content-inner ul li:nth-child(3n){ margin-right: 0 }
.home-content-inner ul li:nth-child(6n) a img{ width: 68px; height: 46px;}
.home-content-inner ul li a{ text-transform: uppercase; padding: 10px 10px; font-weight: 500; font-size: 22px;border: 1px solid #fff;transition: 0.2s linear all; text-align: center; box-shadow: 0 0px 13px 0 rgba(0,0,0, 0.11); display: flex; justify-content: center; align-items: center;height: 100%;min-height: 104px;     line-height: 1; background: #fff;}
.home-content-inner ul li a strong { display: block; font-size: 12px; line-height: 1; color: #919191;}
.home-content-inner ul li a:hover, .home-content-inner ul li.active a{ background: #000; color: #fff }
.home-content-inner ul li a img{margin-right: 12px;}
.home-content-inner ul li a .hci-hover{display: none;}
.home-content-inner ul li a:hover .hci-normal, .home-content-inner ul li.active a .hci-normal{display: none;}
.home-content-inner ul li a:hover .hci-hover, .home-content-inner ul li.active a .hci-hover{display: block;}

.hci-one img{ width: 49px; height: 47px;}
.hci-two img{ width: 47px; height: 52px;}
.hci-three img{ width: 52px; height: 52px;}
.hci-four img{ width: 42px; height: 46px;}
.hci-five img{ width: 49px; height: 33px;}
.hci-six img{ width: 29px; height: 39px;}

.featured-products-section .container > p{ max-width: 980px; margin: 0 auto;text-align: center}
.featured-products-main{padding: 50px 0 0 0}
.featured-products-section h2.home-title{ padding-bottom: 0}
.home-title strong{font-weight: 700}
.home-bottom-link { display: flex; justify-content: flex-end; padding: 40px 0 0 0;}

.home-bottom-link a{ font-size: 16px;
    letter-spacing: 4px;
    position: relative;
    text-transform: uppercase;
    display: inline-flex;
    padding-right: 40px;
    font-family: 'Poppins', sans-serif;
}
.home-bottom-link a:after{position: absolute; right: 0; top: 4px; background: url(../images/black-right-arrow.svg) no-repeat; content: ""; width: 29px; height: 14px;}
.home-bottom-link a strong{ font-family: 'Poppins', sans-serif;font-weight: 700;margin-right: 6px;}

.home-pantry { border-bottom: 2px solid #f3f3f3; padding: 80px 0;}
.home-promotion{ padding: 80px 0; }
.home-blog{ padding: 100px 0 100px 0;border-top: 2px solid #f3f3f3; border-bottom: 2px solid #f3f3f3}
.home-title{text-align: center;letter-spacing:0; padding-bottom: 20px;font-size: 33px;position: relative; font-weight: 400}
.home-title strong{ font-weight: 600 }
.home-blog-slider { margin: 40px 0 0 0;}
.home-blog-top{background: #e5e5e5; padding: 50px 30px; position: relative;text-align: center}
.home-blog-newsletter h3 { text-transform: inherit; font-weight: 600; font-size: 25px; margin-bottom: 15px;}
.home-blog-newsletter p {max-width: 780px;margin: 0 auto;color: #535353;}
.blog-form {
    margin: 20px auto 0;
    max-width: 920px;
    position: relative;
    padding-right: 30px;
}
.blog-form input[type="text"] {
    height: 58px;
    border-color: #c4c4c4;
    padding-left: 20px;
    padding-right: 170px;
}
.blog-form .button{
    position: absolute;
    right: 0;
    top: 0;
    margin: 0;
    height: 58px;
    letter-spacing: 4px;
}
.home-blog-slider .owl-dots {
    text-align: left;
    display: flex;
    margin: 20px 0 0 0;
    padding-left: 75px;
}
.home-blog-slider .owl-dots .owl-dot {
    margin: 0 5px;
}
.home-blog-slider .owl-dots .owl-dot span {
    display: block;
    width: 11px;
    height: 11px;
    background: #fff;
    border-radius: 50%;
    border: 1px solid #000000;
}

.home-blog-slider .owl-dots .owl-dot.active span {
    background: #000;
}
.home-blog-content {
    padding: 30px 0 0 0;
}

.home-blog-content h5 {
    margin-bottom: 15px;
    font-family: 'Poppins', sans-serif;
    text-transform: inherit;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.2;
}
.home-blog-slider .owl-item{
    opacity: 0.3;
}
.home-blog-slider .owl-item.active{
    opacity: 1;
}

.home-testimonial .home-title {
    padding-bottom: 30px;
    margin-bottom: 30px;
}
.home-testimonial{padding: 10px 0 80px;}
.inner-testimonials {
    position: relative;
    font-size: 15px;
    opacity: 0.26;
    height: 100%;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    padding: 20px 25px 0 25px;
}
.testi-content{ text-align: center }
.home-testimonial .owl-item.active.center .inner-testimonials{ opacity: 1}

.testi-author{ padding: 60px 0 0; display: flex;justify-content: space-between}
.testi-author-img {
    width: 58px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.testi-author-name {
    width: calc( 100% - 80px );
        display: flex;
    flex-flow: column wrap;
    justify-content: center;
}

.testi-author-img img {
    height: 58px;
    width: 58px;
    border-radius: 50%;
    overflow: hidden;
}
.testi-author-name h4{ margin: 0 0 3px 0;text-transform: inherit; font-size: 17px;  font-family: 'Poppins', sans-serif; }
.testi-author-name span{color: #8d8d8d;font-size: 14px;}
.home-testimonial .owl-prev, .home-testimonial .owl-next {
    width: 26px;
    height: 14px;
    overflow: hidden;
    text-indent: -9999px;
    border-radius: 0;
    padding: 0;
    position: absolute;
    bottom: 25px;
    margin: 0;
    background: none;
    box-shadow: none;
}
.home-testimonial .owl-prev{left: -5px;}
.home-testimonial .owl-next{right: -5px;}
.home-testimonial .owl-prev:before{ background: url(../images/download.png) no-repeat; width: 24px; height: 11px;content: "";position: absolute; left: 0; top: 0; }
.home-testimonial .owl-next:before{  background: url(../images/download.png) no-repeat; width: 24px; height: 11px;content: "";position: absolute; left: 0; top: 0;transform: rotate(180deg); }
.testimonials-title-text { text-align: center; padding-bottom: 30px;}
.home-order-now{ padding: 0 0 80px; }
.home-order-now ul{display: flex; padding: 0; margin: 0; list-style: none; flex-wrap: wrap;justify-content: space-between}
.home-order-now ul li{width: 32%;}

.home-order-now .hon-icon {
    width: 162px;
    text-align: center;
    border: 4px solid #fff;
    height: 162px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    z-index: 2;
    justify-content: center;
    margin: 0 auto;
}
.hon-bg{width: 100%;}
.hon-icon-bg{
    left: 0;
    position: absolute;
    right: 0;
    margin: auto;
    bottom: 60px;
    text-align: center;
    -webkit-transform: translate3d(0,0px,0);
    transform: translate3d(0,0px,0);
    transition: transform 0.35s;
    line-height: 1;
}

.home-order-now ul li a:hover .hon-icon-bg{ 
    -webkit-transform: translate3d(0,-50px,0);
    transform: translate3d(0,-50px,0);
}

.hon-icon-bg span{display: block; color:#fff; text-transform: uppercase; margin: 20px 0 0 0;font-size: 17px;font-weight: 600}
.home-our-journey{ padding: 80px 0; background: url(../images/abtus-bg.jpg) no-repeat; background-size: cover; color: #fff;  }
.hoj-inner{display: flex; justify-content: space-between; flex-wrap: wrap}
.hoj-lhs { width: 47%; display: flex; flex-flow: column wrap; justify-content: center; align-items: flex-start;}
.hoj-rhs{width: 47%;position: relative;}
.hoj-lhs .button { background: #111; border: 1px solid #fff; min-width: 170px; margin-bottom: 0;}
.hoj-lhs .button:hover { background: #db203b; border-color:#db203b; }
.hoj-rhs .owl-dots { position: absolute; left: 0; right: 0; bottom: 30px; display: flex; justify-content: center;}
.hoj-rhs .owl-dot { width: 12px; height: 12px; border: 2px solid #fff; border-radius: 50%; margin: 0 3px;}
.hoj-rhs .owl-dot.active{ background: #fff; }
.home-our-journey-grammed{background: #f5f5f5; padding: 80px 0;}
.hoj-lhs h5 { color: #fff; font-size: 14px; letter-spacing: 4.6px; margin-bottom: 15px;}
.hoj-lhs h2 { color: #fff; font-size: 32px; letter-spacing: 6.25px;}
.hoj-grammed { display: flex; flex-wrap: wrap; justify-content: space-between;}

.grammed-lhs{width: 32.03125%;text-align: center;    display: flex; flex-flow: column wrap; justify-content: center;}
.grammed-rhs{width: 64.0625%;    display: flex; flex-wrap: wrap;  justify-content: space-between;}
.grammed-rhs ul{padding: 0; margin: 0; list-style: none; display: flex; flex-wrap: wrap;  justify-content: space-between;}
.grammed-rhs ul li{width: 48%; margin: 0 0 15px 0;}
.grammed-rhs ul li:nth-child(3){margin-bottom: 0}
.grammed-rhs ul li:nth-child(4){margin-bottom: 0}
.grammed-rhs-left{width: 49%}
.grammed-rhs-right{width: 49%}
.grammed-rhs-left img{width: 100%;}
.grammed-rhs-right ul li img{width: 100%}
.grammed-lhs h3{ letter-spacing: 7px; font-size: 32px; margin-bottom: 10px}
.grammed-lhs a {font-weight: 600;letter-spacing: 3px;color: #000;text-transform: uppercase;margin-top: 15px;}
.grammed-rhs a{display: block;overflow:hidden;background: #000; position: relative}
.grammed-rhs a img { -webkit-transition: opacity 0.35s, -webkit-transform 0.35s; transition: opacity 0.35s, transform 0.35s; -webkit-transform: scale(1.10);  transform: scale(1.10);}
.grammed-rhs a:before {position: absolute;top: 20px;right: 20px;bottom: 20px;left: 20px;border: 1px solid #fff;content: '';-webkit-transform: scale(1.1);transform: scale(1.1);opacity: 0;-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;transition: opacity 0.35s, transform 0.35s;z-index: 2;}
.grammed-rhs a:hover:before { opacity: 1; -webkit-transform: scale(1); transform: scale(1);}
.grammed-rhs a:hover img {opacity: 0.7;-webkit-transform: scale(1);transform: scale(1);}

.checkout-terms-and-condition {
    height: 136px;
    overflow-y: auto;
    margin: 0 0 15px 0;
    font-size: 16px;
    border: 1px solid #d0cfcb;
    padding: 15px 10px
}

a.change-edt {
    color: #666;
    border-bottom: 1px solid #999;
    margin: 5px 0 0 0;
    display: inline-block;
    font-size: 16px;
}

a.change-edt:hover {
    color: #769e90;
    border-color: #769e90;
}

.page404-main-div {
    text-align: center;
      height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
   -webkit-box-orient: vertical;
   -webkit-box-direction: normal;
   -ms-flex-flow: column wrap;
   flex-flow: column wrap;
}

.page404-main-div .gohome {
    display: inline-block;
    line-height: 1;
    font-weight: bold;
    background: #000;
    padding: 15px 50px;
    border-radius: 4px;
    text-align: center;
    background: #000;
    margin: 10px;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
    color: #FFF;
}
.page404-main-div h1{     
    margin-bottom: 10px;
    margin-top: 40px;}
.copyright-section {
    background: #191919;
    padding: 20px 10px
}

.innerproduct-button .disabled {
    background: #d5d1d2 !important;
    color: #769e90;
}

.innerproducts-list-maindiv {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.innerproduct-button .btn_minwid {
    background: #98684a;
}

.prodetailinner-main-div {
    min-height: 105px;
    padding: 60px 0 0 0;
}

.product-detail-empty {
    padding: 52px 0px 35px 0px;
    text-align: center;
    color: #cb4d36;
    font-weight: bold;
}

.text-center {
    text-align: center;
}

.custom-select-bxcls {
    position: relative
}

.custom-select-bxcls:after {
    content: "";
    background: #fff url(../images/arrow-down.png) no-repeat center;
    width: 45px;
    top: 1px;
    right: 1px;
    bottom: 1px;
    position: absolute;
    height: 48px;
    pointer-events: none;
        border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.custom-select-bxcls:after {
    height: 48px;
}

.custom-select-bxcls select {
    margin: 0 !important;
    width: 100%;
    height: 50px;
    padding-left: 10px;
    -moz-appearance: none; 
    -webkit-appearance: none; 
    appearance: none;
}

.disable-addtocart {
    pointer-events: none;
    cursor: default;
}

.disable-addtocart:after {
    background: hsla(0, 0%, 100%, 0.8) none repeat scroll 0 0;
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 99;
    content: '';
}

.modfr-alert-danger {
    display: none;
    background: #ffdfdf;
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    text-align: center;
    padding: 10px;
    font-size: 13px;
    color: #c10606;
}

.mdf_common_rows {
    position: relative;
}

.compo_pro_acc {
    background: #f6f6f6;
}

.prd_chosen_row.compo_top_div {
    background: #ffffff;
}

.compoMdfr_item_left {
    width: 100%;
    padding: 0;
    margin-bottom: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.compoMdfr_item_left .product_chosen_addons {
    width: 30%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.compoMdfr_item_left .product_chosen_hea {
    margin: 0px;
}

.compoMdfr_item_left .form-group {
    width: 70%;
    float: left;
    margin: 0;
}

.compoMdfr_item_left .product_chosen_hea h6 {
    font-size: 14px;
}

.compopro_acc_head {
    padding: 12px 20px 12px 20px;
    cursor: pointer;
}

.compopro_acc_head::before,
.compopro_acc_head::after {
    background: #5e5e5e;
    content: "";
    display: block;
    height: 2px;
    margin-top: -2px;
    pointer-events: none;
    position: absolute;
    right: 20px;
    top: 50%;
    transition: -webkit-transform 0.25s ease-in-out 0s;
    transition: transform 0.25s ease-in-out 0s;
    transition: transform 0.25s ease-in-out 0s, -webkit-transform 0.25s ease-in-out 0s;
    -webkit-transition: -webkit-transform 0.25s ease-in-out 0s;
    width: 14px;
    z-index: 2;
}

.compopro_acc_head::before {
    content: "";
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
}

.compo_acc_active .compopro_acc_head::before {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
}

.compopro_acc_head::after {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
}

.compo_acc_active .compopro_acc_head::after {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
}


.product-rhs .cart_footer {
   padding: 20px 20px;
    box-shadow: 0px 1px 16px 0px rgba(1, 1, 1, 0.14);
    margin-top: 15px;
}
.product-rhs .product_orders_top{display: none}
.cart_row.grant-total-cls p,
.cart_row.grant-total-cls span {
    font-size: 23px;
    line-height: 1.2;
    font-weight: 600;
}

.cart_footer .cart_row .row-replace {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.delivery-cart-div h4 {
    font-weight:600;
    color: #0d0b06;
    font-size: 15px;
    text-transform: uppercase;
    margin: 0 0 5px 0;
    font-family: 'Poppins', sans-serif;
}

.delivery-cart-div p {
    color: #000000;
    margin: 0;
    font-size: 14px;
}

.cart-header-second h5 {
    font-weight:600;
    color: #060203;
    font-size: 17px;
    text-transform: uppercase;
    margin: 0 0 5px 0
}

.cart-header-second h3{ font-family: 'Poppins', sans-serif; font-size: 16px;}


.cart-header-second p {
    font-weight:bold;
    color: #1c1b1b;
    font-size: 28px;
}

.cart_footer .cart_remove {
    top: 3px;
    right: 0px;
}

.product-details {
    padding: 15px 0px;
    position: relative;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #e6e6e6;
    
}
.order-details-with-clear, .order_card_head{display: flex; justify-content: space-between; padding: 10px 15px;background: #060203; align-items: center; line-height: 1;}
.order-details-with-clear h5, .order_card_head h5{ color: #fff; margin: 0; font-size: 15px; letter-spacing: 0.3px; }
.cart_body { padding: 20px;}

.cartaction_bottom .btn {
    margin: auto;
    width: 100%;
    text-align: center;
    display: block;
    background: #DB203B;
    padding: 10px 10px;
    border-radius: 5px;
    height: auto;
    font-size: 16px;
    letter-spacing: 0.5px;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    transition: 0.3s linear all;
}

.cartaction_bottom .btn:hover {
    background: #000;
}
.cart_row.grant-total-cls {
    padding-top: 10px;
    margin-bottom: 20px;
}
.Viewreceipt .cart_row.grant-total-cls{ border-top: 0; margin-bottom: 0; padding-top: 0 }
.Viewreceipt .tnk-delivery .cart_footer.tnk_cart_footer {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
}
.Viewreceipt .delivery_total::before {
    position: absolute;
    left: 50px;
    right: 30px;
    background: #fff;
    top: 10px;
    content: "";
    margin: auto;
    border: 2px dashed #dfdad7;
}
.Viewreceipt .tnk-delivery .delivery_total_number:before{
    display: none;
}

#cart-close-span{    
    display: block;
    position: relative;
    order: 2;
    width: 24px;
    height: 24px;
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
    background: #2f2f2e;
}

#cart-close-span:before, #cart-close-span:after {
    position: absolute;
    left: 12px;
    content: ' ';
    height: 16px;
    width: 2px;
    background-color: #fff;
}
#cart-close-span:before {
    transform: rotate(45deg);
}
#cart-close-span:after {
    transform: rotate(-45deg);
}
.delivery-cart-div, .cart-header-second{display: flex; justify-content: space-between; flex-wrap: wrap;}
.delivery-cart-div .col-sm-cls, .cart-header-second .col-sm-cls {
    width: 48%;
}
.cart_left {
    width: calc( 100% - 95px );
}
.cart_right {
    width: 95px;
}
.gst-row {
    margin-top: 15px;
}
.productlist-main-div {
    padding: 0px 0 30px;
    width: 100%;
}

.form-group.half-fg {
    display: flex;
    justify-content: space-between;
}
.form-group.half-fg .input_field{ width: 49%; }
.cart_body .hclear_cart:hover {
    color: #DB203B;
}

.compo_minmax_maindiv .combo-input-label {
    display: block;
}

.compo_minmax_maindiv .max-min-bar {
    padding-bottom: 10px;
}

.compo_minmax_maindiv .error_combo_div {
    color: red;
    padding-bottom: 10px;
    display: none;
}

.compo_minmax_maindiv .combo_pro_price {
    color: #DB203B;
}

.alert-success {
    background-color: #dff0d8;
    color: #3c763d;
    border-color: #d6e9c6;
}

.custom_close span {
    color: red;
}

.alert-success:before,
.single-danger:before {
    top: 10px;
    left: 8px
}

.jquery-success-msg {
    margin-left: 20px;
}

.alert-danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}

.btn-medium {
    min-width: 190px;
}

.cart-header-second {
    padding: 15px 0 0 0;
    width: 100%;
}

.hcart_dropdown .pickup-cart-div h4{
    margin-bottom: 5px;
    font-size: 17px;
    font-weight: 600;
    color: #060203;
}

.hcart_dropdown .cart-header-first .cart_left p{
    margin-bottom: 5px;
    line-height: 1.1;
}

.cart-direction {
    width: 100%;
    margin: 0 0 15px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative
}
.cart-direction::before {
    position: absolute;
    top: 15px;
    left: 50px;
    right: 30px;
    background: #fff;
    content: "";
    margin: auto;
    border: 1px dashed #dfdad7;
}

.innersection_wrap {
    padding: 20px 0 0 0;
}

.tnk-chk-order .button {
    display: block;
    margin: 0;
    width: 100%;
    padding: 18px 20px 18px;
    font-size: 17px;
    border-radius: 0;
}

.tnk-chk-order .button:hover {
    background: #000000
}

.thank-order-detaildiv {
    max-width: 580px;
    margin: 0 auto;
    -webkit-box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.1);
}

.mainacc_toptext {
    text-align: center;
    padding: 0 0 20px 0;
}

.mainacc_toptext h2 {
    margin: 20px 0 5px 0
}

.mainacc_toptext p {
    font-size: 17px;
    margin: 0;
    color: #000;
}
.myacc_order_details {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.myacc_order_details .current_order {
    width: 48.5%;
    margin-bottom: 20px;
    background: #f9f9f9;
    padding: 0 0 150px 0;
    position: relative
}

.myacc_head_sec {
    background: #f1f1f1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
    padding: 15px;
    overflow: hidden
}

.head_right .head-group {
    z-index: 2;
    position: relative
}

.head_left h4 {
    color: #000000;
    margin: 0;
    font-size:14px
}

.head_right h4 {
    color: #fff;
    margin: 0;
    text-transform: capitalize;
    font-size: 14px
}

.head_right::before {
    content: "";
    display: block;
    background: #000;
    width: 40%;
    position: absolute;
    right: -30px;
    height: 100%;
    top: 0;
    -webkit-transform: skew(-39deg);
    transform: skew(-39deg);
    z-index: 1;
}

.order_details_footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.order_btns,
.order_amt {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 16px 8px 8px;
}


.order_amt h3 {
    margin: 0;
    color: #000;
    font-size: 22px
}

.order_details_body {
    padding: 15px
}

.order_no_deatails {
    margin: 0 0 20px 0;
    text-align: center
}

.order_no_deatails span {
    display: block;
}

.delivery_total_left img {
    margin: 0 0 10px 0;
    max-height: 33px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.delivery_total_left.delivery_total_right img {
    margin: 0 0 20px 0;
}

.delivery_total {
    position: relative;
}

.delivery_total_text::before {
    position: absolute;
    left: 50px;
    right: 30px;
    background: #fff;
    top: 10px;
    content: "";
    margin: auto;
    border: 2px dashed #dfdad7;
}

.web_order_typecls {
    list-style: none;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
     -webkit-box-pack: center;
     -ms-flex-pack: center;
     justify-content: center;
    margin: 0 0 50px 0
}

.web_order_typecls > li {
    width: 50%;
    border-left: 2px solid rgba(255, 255, 255, 0);
}

.web_order_typecls li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    padding: 14px 10px;
    background: #e4e4e4;
    color: #000
}

.web_order_typecls li.active a, .web_order_typecls li a:hover { background: #769e90 }
.web_order_typecls li:hover .count_num_t, .web_order_typecls li.active .count_num_t { background: #fff; }
.web_order_typecls li a h5 { margin: 0; color: #000; text-transform: uppercase}
.web_order_typecls li.active a h5, .web_order_typecls li a:hover h5{ color: #fff; }
.sprite_lst_icon { margin: 0 10px 0 0}
.sprite_lst_icon.order_t {  max-width: 33px;}

.sprite_lst_icon.catering_icon_t { width: 21px;}
.sprite_lst_icon.reserve_icon_t { width: 43px;}
.reserve_icon_t img, .order_t img {
    display: block;
    margin: 0 auto;
    max-height: 24px;
}
.reserve_icon_t img.order_type_img, .order_t img.order_type_img{ display: block;}

.web_account_menu_cls li a.active .order_type_img, .web_account_menu_cls li a:hover .order_type_img{display: none}
.web_account_menu_cls li a.active .order_type_imgwt, .web_account_menu_cls li a:hover .order_type_imgwt{display: block}

.myacc_order_details .reserv-orderlist {
    padding: 0;
}

.reserv-orderlist .order_no_deatails { margin: 15px 0px 0; border-top: 1px solid #e4e4e4; padding: 20px 0 0 0;}
.reserv-orderlist .order_no_deatails h3 { margin: 0; color: #000; font-size: 19px; text-transform: uppercase;}
.reserv-orderlist .order_no_deatails p { margin: 0; color: #6c6866; font-size: 21px; font-weight:bold;}
.reserv-loader-div { display: inline-block}
.reservation-full{padding: 25px 0px;}
.res-main-title .col-sm-6 .form-group:nth-child(4n){ margin-bottom: 15px;}
.reservation_outlet, .reservation_user, .reservation_Date, .reservation_Time { display: flex; justify-content: space-between;}
.reservation_outlet h4, .reservation_user h4, .reservation_Date h4, .reservation_Time h4{ width: 200px; text-align: left;}
.reservation_outlet p, .reservation_user p, .reservation_Date p, .reservation_Time p{ width: calc(100% - 200px); text-align: right;}
.reserv-orderlist .delivery_total.delivery_total_number { margin: 0; padding: 0}

.ord-pagination-col select {
    width: auto;
    height: auto;
    padding: 1px 0 1px 1px;
    border: 0;
    margin: 0 5px;
    background: none;
}

.delivery_submit_div .button {
    width: 100%;
}

.mainacc_menulist #masterCount {
    background: #fedf88;
    height: 20px;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    margin-left: 5px;
    font-size: 11px;
    line-height: 19px;
    vertical-align: top;
    margin-top: 1px;
    padding: 0 4px;
    min-width: 20px;
}

.reward-list-body .delivery_total_number {
    padding: 0;
    margin: 0
}

.reward-list-body .delivery_total_left {
    display: inline-block;
    width: auto;
    float: none;
    margin: 0;
}

.reward-list-body .expiry_on_rhs {
    width: 100%;
    justify-content: flex-start;
    margin: 10px 0 0;
    display: flex;
    flex-wrap: wrap;
}

.reward-list-body .delivery_total_left h4 {
    font-size: 17px
}

.reward-list-body .order_amt_div h3 {
    margin: 0;
    padding-top: 10px;
    font-size: 19px;
}

.reward-list-body .order_amt_div{
    display: flex;
    justify-content: space-between;
}

.reward-list-body .order_amt-right h3 {
    color: #DB203B;
}

.reward-list-body .checkoutDate, .reward-list-body .checkoutTime {
    position: relative;
    padding: 0 0 0 22px;
}

.reward-list-body .checkoutDate:before {
    background: url(../images/calenderorder.svg) no-repeat;
}

.reward-list-body .checkoutTime:before {
    background: url(../images/clock-round.svg) no-repeat;
}

.reward-list-body .checkoutDate:before, .reward-list-body .checkoutTime:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    content: "";
}

.expiry_on_lhs {
    display: flex;
    justify-content: space-between;
}

.promo-form {
    text-align: center;
    padding: 40px 15px;
    margin: 0;
    background: #000;
}

.promo_wrap .both-order-tab {
    padding: 10px 40px 0 40px;
}

.promo-form .myuacc-promo {
    position: relative;
    max-width: 640px;
    margin: 0 auto;
}

.promo-form input[type="text"] {
    margin: 0;
    height: 47px;
    padding: 5px 150px 5px 20px;
    background: #fff;
    font-size: 14px;
    color: #615f5f;
}

.promo-form .applyBtn {
    margin: 0;
    border: none;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 16px;
    background: #000;
    padding: 13px 20px;
    height: 47px;
    min-width: 146px;
    color: #fff;
    border-radius: 6px;
}

.promo-form h3 {
    color: #000;
    font-size: 34px;
    margin: 0 0 15px;
    text-transform: uppercase;
    line-height: 1.3;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
}

.promo-form h3 span {
    font-size: 32px;
    display: block;
    text-transform: uppercase;
    line-height: 1;
    font-weight: 400;
}

.promo-earned-col{width: 49%; background: #fff;margin: 0 0 20px 0;}

.promo-earned-col-image, .promo-earned-col-image .innerproduct-item-image{     position: relative;}
.promo-earned-col-image img{ width: 100%; height: 100%; -o-object-fit: cover; object-fit: cover; object-position: center;    max-height: 206px; } 
.promo-earned-col-item { display: -webkit-box; display: -ms-flexbox; display: flex; background: #fff;
    -webkit-box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.14); box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.14); 
}

.promo-earned-info{
    display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap;  flex-wrap: wrap;
    flex-direction: column;  flex: 1 1 auto; -webkit-box-align: center;  -ms-flex-align: center;   align-items: center;    width: 50%;
    -webkit-box-pack: center;  -ms-flex-pack: center; justify-content: center; text-align: center;
    padding: 30px 10px;  position: relative;  z-index: 1;
}

.promo-earned-info:before{  content: ""; z-index: -1; position: absolute; top: 0px; left: -40px; bottom: 0px;
    background: #ffffff; width: 80px; -webkit-transform: skewX(-20deg); transform: skewX(-20deg);   
}

.promo-earned-top {  width: 100%;}
.promo-earned-code{  font-size: 14px;margin: 0 0 5px; }
.promo-earned-code span{display: block; font-size: 22px}
span.promo-valid { color: #a6a6a6;  font-size: 17px; padding: 0 0 15px 0;display: block;}
.promo-earned-info p{ font-size: 20px;  color: #4a4646; }
.promation_btn {width: 100%; line-height: 0; display: flex; justify-content: space-between;}
.promation_btn .button:hover{ background: #000; color: #fff; border-color: #000; }
.promation_btn .button{ margin: 0;-webkit-box-shadow: none; box-shadow: none;  border: 1px solid #181818; border-radius: 4px;
    width: 49%; display: block; padding: 14px 4px; color: #fff; background: #da203a; border: 0px; font-size: 14px; 
    transition: all .3s ease; text-transform: uppercase; text-align: center; overflow: hidden;
 }
 .promation_btn .promation_btn-one{ background: #fff; color: #da203a; border: 2px solid #da203a;margin-right: 4px; }

  .promation_btn .promation_btn-one:hover{ background: #da203a; color: #fff; border: 2px solid #da203a; }

.promo-bot-left span {
    display: block;
}

.promo-bot-left p {
    margin: 0;
    color: #333;
    text-transform: uppercase;
    font-size: 18px;
}

.promo-bot-left p span {
    display: block;
    font-size: 14px;
}

.promo-earned-col .tag {
    font-size: 16px;
    text-transform: uppercase;
    color: #fff;
    background: #000000;
    margin: 0;
    padding: 10px 12px 5px;
    position: absolute;
    left: -43px;
    top: 30px;
    transform: rotate(-40deg);
    -webkit-transform: rotate(-40deg);
    width: 220px;
    line-height: normal;
    text-align: center;
}

.promo-earned-col .tag span {
    display: inline-block;
    width: 130px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.mypromo-main-div .myacc_order_details {
    justify-content: space-between;
    padding: 0;
    margin: 0;
    list-style: none;
}

.pickup-cart-div .cart-direction:after {
    display: none
}

/*.pickup-cart-div .cart-direction img {
    width: 50px;
    margin: 0 auto
}*/

.cart-direction img{
    max-height: 33px;
}

.pickup-cart-div .cart-header-first .row {
    display: block;
}

.pickup-cart-div .cart-header-first .cart_left {
    padding: 0 0 15px 0;
    width: 100%;
    display: block;
}

span.myaccount_separate {
    width: 2px;
    height: 16px;
    display: inline-block;
    background: #fff;
    margin: 0 7px 0 5px;
}

.load_more_div {
    display: block;
    clear: both;
    width: 100%;
    text-align: center;
    padding: 30px 0 0 0
}

.load_more_div .load_more_data {
    margin: 0 auto;
    min-width: 160px
}

.make-pay-div h4 {
    font-size: 20px;
}

.pay-cart-header {
    padding: 18px 0px 5px 0px !important;
}

.inner_banner {
    height: 400px;
}

.inner_banner img {
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.clear:after {
    content: "";
    display: block;
    clear: both;
}

.err_alt {
    border: 1px solid #ff1313 !important;
}

.err_tarms_chk span {
    color: #ff1313;
}

.mfp-bg {
    background: rgba(0, 0, 0, 0.8);
}

.ribbon {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
    top: -10px;
    left: -10px;
    border-top-color: transparent;
    border-left-color: transparent;
}
.ribbon::after {
    position: absolute;
    z-index: -1;
    display: block;
    width: 74px;
    height: 74px;
    background-repeat: no-repeat;
    background: url(../images/ribbon-after.png)no-repeat;
}

.ribbon::before {
    position: absolute;
    z-index: -1;
    display: block;
    width: 74px;
    height: 74px;
    background-repeat: no-repeat;
      background: url(../images/ribbon-before.png)no-repeat;
}
.ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 8px 0;
    background-color: #e91954;
    color: #fff;
    text-transform: capitalize;
    text-align: center;
    right: -25px;
    top: 30px;
    transform: rotate(-45deg);
    z-index: 2;
    font-weight: bold;
}

 .sale-flag-sash {
    background: #e91954;
    color: #fff;
    font-size: 15px;
    height: 35px;
    left: -40px;
    line-height: 1.06667rem;
    padding-top: 5px;
    padding-right: 27px;
    position: absolute;
    text-align: center;
    top: 3px;
    transform: rotate(-45deg);
    transition: .8s ease;
    width: 10.93333rem;
    z-index: 20;
}

.sale-text{
    padding-left: 0px;
    line-height: 20px;
    font-weight: bold
}

.products-single-li.offer_tag_show:after {
    background: url(../images/product-off-1.png) no-repeat;
    visibility: visible;
    -webkit-backface-visibility: visible;
    position: absolute;
    content: "";
    bottom: 0;
    width: 44px;
    left: 55px;
    z-index: -1;
    top: -24px;
    height: 44px;
}

.products-single-li.offer_tag_show:before {
    position: absolute;
    content: "";
    bottom: 0;
     background: url(../images/product-off-2.png) no-repeat;
    width: 44px;
    left: -23px;
    z-index: -1;
    top: 54px;
    height: 44px;
}

.box-outer{
    overflow: hidden;
    position: relative;
    height: 100%
}

.box-outer img{
    height: 100%
}

.ribbon::before {
  top: 0;
  right: 10px;
}
.ribbon::after {
  bottom: 10px;
  left: 0;
}
.cafe-category-rhs .productlist-main-div .products-list-ulmain > li .ribbon {
    transform: none;
    width: auto;
    height: auto;
    top: 0;
    left: 0;
    border: 0;
    overflow: inherit;
}
.cafe-category-rhs .productlist-main-div .products-list-ulmain > li .ribbon:before, .cafe-category-rhs .productlist-main-div .products-list-ulmain > li .ribbon:after { display: none;}
.cafe-category-rhs .productlist-main-div .products-list-ulmain > li .ribbon span {
    width: auto;
    transform: none;
    right: inherit;
    top: 0;
    left: 0;
    padding: 5px 15px;
    font-size: 20px;
    white-space: nowrap;
    display: block;
}

#scrollbutton {
    position: fixed;
    bottom: 80px;
    right: 30px;
    width: auto;
    height: auto;
    z-index: 999;
    opacity: 0;
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

#scrollbutton.show {
    display: inline-block;
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

#scrollbutton a {
    background: #252525;
    display: flex;
    position: relative;
    color: #DB203B;
    overflow: hidden;
    border-radius:6px;
    font-size: 18px;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
}

#scrollbutton a i.ars {
    -webkit-transition: all 0.2s linear 0.2s;
    transition: all 0.2s linear 0.2s;
}

#scrollbutton a:hover i.ars {
    -webkit-transform: translateY(-25px) rotateY(360deg);
    transform: translateY(-25px) rotateY(360deg);
    opacity: 0;
    -webkit-transition: all 0.3s linear 0.2s;
    transition: all 0.3s linear 0.2s;
}

#scrollbutton a i.ars1, #scrollbutton a i.ars2 {
    position: absolute;
    bottom: 0%;
    left: 50%;
    width: auto;
    height: auto;
    -webkit-transform: translate(-50%, -50%) rotateZ(360deg);
    transform: translate(-50%, -50%) rotateZ(360deg);
    opacity: 0;
    -webkit-transition: all 0.2s linear 0.2s;
    transition: all 0.2s linear 0.2s;
}

#scrollbutton a i.ars2 {
    -webkit-transform: translate(-50%, -50%) rotateZ(0deg);
    transform: translate(-50%, -50%) rotateZ(0deg);
    -webkit-transition: all 0.2s linear 0s;
    transition: all 0.2s linear 0s;
}

#scrollbutton a:hover i.ars1, #scrollbutton a:hover i.ars2 {
    bottom: 10%;
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) rotateZ(0deg);
    transform: translate(-50%, -50%) rotateZ(0deg);
    -webkit-transition: all 0.2s linear 0.2s;
    transition: all 0.2s linear 0.2s;
}

#scrollbutton a:hover i.ars2 {
    bottom: 0%;
    -webkit-transform: translate(-50%, -50%) rotateZ(0deg);
    transform: translate(-50%, -50%) rotateZ(0deg);
    -webkit-transition: all 0.2s linear 0.4s;
    transition: all 0.2s linear 0.4s;
}
.oreder-row-inv {
    margin: 0;
}

.oreder-row-inv .cart_img {
    padding: 0;
}

.oreder_itm_row {
    padding: 18px 5px 5px 5px !important;
}

.order-items-maindiv {
    background: #f5f5f5;
}
.Viewreceipt .order-items-maindiv{
    background: #fff;
}
.Viewreceipt .oreder_itm_row{
    padding: 10px 0 10px !important;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
}
.Viewreceipt .cart_price, .thank-order-detaildiv .cart_price{ padding-right: 0 }
.Viewreceipt .tnk-delivery .delivery_total_number{
    border-bottom: 1px solid #e4e4e4;
    padding-bottom: 20px;
    margin-bottom: 10px;
}
.oreder-row-inv .cart_left {
    text-align: left;
}

.month-select-div, .choose-date-div {
    margin: 0 auto 40px auto;
}

.tarms-error {
    display: none;
    color: #f03011;
}

.catering-product-rowlist span.min_pax_spn {
    display: block;
    font-size: 17px;
    text-transform: uppercase;
    margin: 0 0 20px 0;
    font-family: 'Poppins', sans-serif;
}

.catering-product-rowlist .price_nor {
    font-size: 22px;
    display: block;
    padding: 10px 0 0 0;  
    font-weight: bold;
}

.dotted-line-top {
    border-top: 1px dotted #ccc;
    padding: 20px 0 0 0;
    margin: 10px 0 0 0;
}

.error_div_cls {
    color: red;
    display: none
}

.single_outlet_info {
    padding-top: 15px;
    text-align: center;
}

.single_outlet_info p {
    margin: 0;
    font-size: 16px;
}

.single_outlet_info .mainacc_toptext p {
    font-size: 20px;
    font-style: normal;
}

.isDisabled {
    cursor: default;
    text-decoration: none;
}

.disableDivCls {
    display: none;
}

.event-type-single {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.rmv-border-bottom {
    border-bottom: 0px;
}

.no-stock-product .innerproduct-item::after {
    opacity: 1;
}

.no-stock-product .innerproduct-button {
    opacity: 1;
    visibility: visible;
    top: 50%;
}

.no-stock-product .innerproduct-button .btn {
    cursor: not-allowed;
    pointer-events: all;
}

.guest_btn {
    text-align: center;
}

.controls.single-link {
    text-align: center;
}

.controls.single-link a {
    font-size: 15px;
    display: inline-block;
    color: #272727;
     font-weight: 600;
    text-transform: uppercase;
}

.back-to-listbtn a {
    width: 100% ;
}

.apply-red {
    color: red;
}

.pdpa_trmcont_link {
    font-size: 14px;
}
.pdpa_trmcont_link span{
    display: inline-block
}

.delivery_outletpoup .self_popup_hea_row { background: #769e90; }
.delivery_outletpoup .outlet-scooter-img { width: 86px; }

.outleterror { color: #769e90; }
.delete-act-icon {
    position: absolute;
    right: 2px;
    top: 0px;
}
.act-remove-topdiv {
    position: relative;
    padding-right: 20px;
}

.venue_popup .close-icon-but {background: #98684a;
    color: #fff;
    font-size: 0;
    width: 30px;
    height: 30px;
    line-height: 30px;
    opacity: 1;
    top: -15px;
    right: -15px;
    padding: 0;
    margin: 0;}
.hide_section_cls, .back_click_mbl{display: none}
.back_click_mbl a:before {
    content: "\f104";
    font-family: FontAwesome;
    margin-right: 5px;
    font-size: 18px;
    font-weight: bold;
}
.custom_center_alertcls {
    position: fixed;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    right: 0;
    max-width: 250px;
    margin: auto !important;
    padding: 20px 30px;
    font-size: 16px;
    border-radius: 5px;
    display: inline-block;
    text-align: center;
}
.custom_center_alertcls.alert-danger{     
    color: #ca322f;
    background-color: #fdd1d1;}
.mfp-custom-close {
    position: absolute;
}
img.view-img-span {
    width: 20px;
    position: relative;
    top: 3px;
    margin-left: 5px;
}
img.close-img-span {
    width: 19px;
    position: relative;
    top: 1px;
    margin-left: 5px;
    display: none;
}
.chk-payment .form-group.custom-tarms-div {
    padding: 0 20px 20px;
    text-align: left;
}
.text-lbl {
    display: block;
    font-weight:bold;
    text-transform: uppercase;
    margin: 0 0 5px 0;
}
.mk-catering-payment .chk-payment-col-radio li{ margin-right: 0 }
.price_nor dl {
    display: inline-block;
    font-size: 16px;
    vertical-align: text-top;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
}
.ordrdatetime_error .error { width: 100%; }
.syd_merge {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.syd_date {  width: 48%;}
.syd_time {  width: 48%;}
.syd_merge .form-group {margin: 0}

.checkout-control-group-middle .react-datepicker-wrapper {
    display: block;
}
.checkout-control-group-middle .react-datepicker__input-container {
    position: relative;
    display: block;
}
.datetime_selt_sec .react-datepicker-wrapper, .datetime_selt_sec .react-datepicker__input-container {
    display: block;
}
.syd_date .react-datepicker__input-container:after, .syd_time .react-datepicker__input-container:after{
    content: "\f107";
    font-family: FontAwesome;
    position: absolute;
    right: 10px;
    top: 16px;
    font-size: 18px;
    line-height: 1;
}
.syd_date .react-datepicker__input-container .form-control, .syd_time .react-datepicker__input-container .form-control{
    padding-right: 20px
}

.membership_status {
margin-top: -33px;
position: relative;
}

.membership_status span {
    position: absolute;
    left: 0;
    right: 0;
    top: 10px;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
}

/*myaccount - 13 jan 2020*/
.membership-desc { background-color: #769e90; border: 2px solid #fff; padding: 12px 5px 10px; font-size: 20px; text-transform: uppercase; font-family: 'Poppins', sans-serif; margin: 15px auto; max-width: 250px; }
.membership-desc img{ width: 20px; margin-right: 8px; }
.user-id{ margin: 10px 0; }
.user-id span {text-transform: uppercase; font-weight:bold; }
.membership-progress {background: #4f7873; margin: 15px -10px 0; padding: 20px 0 15px; }
.membership-progress p {font-weight:bold; margin: 9px auto 0; max-width: 170px; line-height: 1.2; }
.pg-bar{ display: block; position: relative; width: 100%; height: 15px; border-radius: 25px; background-color: #2a2521; max-width: 175px; margin: 0 auto;}
.pg-bar span{ position: absolute; height: 100%; width: 0%; background-color: #fff; top: 0; left: 0; border-radius: 25px; -webkit-box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.3); box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.3);}
.member-discount-desc { display: inline-block;background: #769e90;border-radius: 10px;padding: 3px 10px;font-size: 12px;color: #fff;margin: 5px 0;line-height: 1;text-align: center; }
.member-discount-total {color: #98684a; font-weight:bold; font-size: 14px; }


.product-tag-list ul {
    list-style: none;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.product-tag-list ul li {
    margin: 0 5px;
}

.product-tag-list{ margin-bottom: 10px; }
.product-tag-list ul li img{max-width: 16px; max-height: 16px; vertical-align: middle; }

.products-image-div, .det_inner_image{height: 230px;   }
.products-image-div img, .det_inner_image img{height: 100%;width: 100%; object-fit: cover}
.product-info-div, .det_inner_content{
    padding:10px 20px 110px 20px ;      
    display: flex;
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    height: calc(100% - 230px);
    background: #fff;    
    transition: 0.3s linear all;    
}
.product-info-div.multibuttons{
    padding:10px 20px 160px 20px ; 
}
.det_inner_content h5{text-transform: inherit}
.product-title-maindiv {
    position: relative;
    margin: 0 0 10px 0;
    padding: 0 0 0px 0;
}
.product-title h5 {
    margin-bottom: 10px;
}
.product-tags-list{
  padding: 20px 0 0 0;
}
.product-short-description{ 
    color: #949292;
    font-size: 14px;
    margin: 0;
    line-height: 1.3;
    flex: 1 1 auto;
}
.product-price h3{margin: 0; font-size: 18px;color: #000; font-family: 'Poppins', sans-serif;font-weight: 600}

.original-price {
    margin-bottom: 0px;
    font-size: 14px;
    color: #606060;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    text-decoration: line-through;
    margin-right: 10px;
}
.innerproduct {
    padding-bottom: 25px;
}
.innerproduct > h3{ font-family: 'Poppins', sans-serif;font-weight: 600; text-transform: capitalize;font-size: 18px; text-align: center }
.product-load-more {
    text-align: center;
    padding: 10px 0 20px 0;
}
.product-load-more a{
    display: inline-block;
    letter-spacing: 2px;
}
.product-load-more a span{    font-weight: 700;}

.products-ordernow-action .button{margin-bottom: 0; margin-top: 0; }
.owl-carousel .products-ordernow-action .button{min-width: inherit}

.products-ordernow-action{  position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;margin: 0 auto}
.products-ordernow-action .product-price{margin-bottom: 19px; display: flex; justify-content: center; align-items: center; flex-flow: column;}
.product-short-description p { margin: 0;}
.products-single-li{position: relative; z-index: 2; text-align: center; border: 2px solid #f3f3f3;}
.products-single-li > a{ display: block; /*height: 100%;*/}
.products-ordernow-action .button.disabled, .products-ordernow-action .button.disabled:hover{ opacity: .4; cursor: not-allowed; -webkit-box-shadow: none; pointer-events: none; }
.no-stock-product .products-image-div img{ filter: grayscale(1); }

.price_disc{
    text-decoration: line-through;
    color: #949292;
    font-size: 17px;
    display: block;
    line-height: 1;
}

.hmenu-login-section ul {
    padding: 0;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.doorstep-block-section{position: relative}

.guest-checkout-popupdiv {
    background: #ededed;
    margin: 0 -55px;
    padding: 25px 55px;
}
.guest-checkout-popupdiv .button {
    margin: 0;
    background: #464646;
    display: block;
    width: 100%;
    padding: 17px 5px;
    font-family: 'Poppins', sans-serif;
}
.signup-gender-selct {
    margin-bottom: 0;
}
span.birthday_info_sp {
    font-size: 12px;
    color: #737373;
}

span.birthday_info_sp b {
    font-weight: 500;
    font-style: italic;
}
.product-menu-listing{padding:40px 0 10px 0;}
.product-menu-listing .container { display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between;     align-items: flex-start}
.product-menu-listing .container:before, .product-menu-listing .container:after{ display: none;}

.productlist-main-div .products-list-ulmain, .product_det_cards > ul{ display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap;padding: 0;list-style: none}
.productlist-main-div .products-list-ulmain > li, .product_det_cards > ul li.det_card{ text-align: center;width: 23.828125%;
    margin: 0 1.5625% 20px 0; background: #fff; transition: 0.4s linear all; border: 2px solid #f3f3f3; }
.products-single-li:hover .products-ordernow-action .button{ background:#db203b; color: #fff;  }
.productlist-main-div .products-list-ulmain > li:nth-child(4n), .product_det_cards > ul li.det_card:nth-child(4n){margin-right: 0}

section.menu-nav-section { padding: 20px 0 0 0; position: sticky; top: 57px; z-index: 9; background: #fff;}
.menu-section-left { display: flex; flex-wrap: wrap;  position: relative; z-index: 3; }
.menu-section-left-inner .menu-list-intvl{/* width: 20%;*/text-align: center; }
.menu-section-left-inner .menu-list-intvl a{
 padding: 10px 2px; position: relative;cursor: pointer; color: #0a0b10; font-family: 'Poppins', sans-serif;text-transform: uppercase; font-size:15px;line-height: normal; display: block;     height: 100%;}

.menu-section-left-inner .menu-list-intvl a:before, .more-menu-parent:before{
    content: "";
    position: absolute;
    left: 51%;
    right: 51%;
    bottom:0px;
    background: #fedf88;
    height: 2px;
    -webkit-transition-property: left, right;
    transition-property: left, right;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}
.menu-section-left-inner .menu-list-intvl.active a:before, .menu-section-left-inner .menu-list-intvl a:hover:before, .more-menu-parent:hover:before, .more-menu-parent.active:before{ left: 0; right: 0; }
.menu-section-left-inner { width: calc( 100% - 20% );  display: flex; }

.more-menu { width: 20%;}
ul.more_categor_info {
    padding: 0;
    list-style: none;
    min-width: 200px;
    max-width: 280px;
    background: #fff;
    position: absolute;
    right: 0;
    max-height: 60vh;
    overflow-y: auto;
    border-radius: 3px;
    top: calc(100% + 5px);
    border: 1px solid rgba(0,0,0,0.1);
}
ul.more_categor_info li a{ display: block;
    padding: 12px 20px;
    border-bottom: 1px solid #f3f3f3;
    color: #0a0b10; font-family: 'Poppins', sans-serif;
    text-transform: capitalize;
    font-size: 15px;
    line-height: 1;
    color: #000;}
ul.more_categor_info li:last-child a{  border-bottom: 0; }
ul.more_categor_info li a:hover, ul.more_categor_info li.active a{ color: #f6aa32 }
.more-menu-parent {
    cursor: pointer;
    padding: 10px 27px 10px 0px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #606060;
    font-family: 'Poppins', sans-serif;
    text-transform: capitalize;
    font-size: 15px;
    font-weight: 600;
    line-height: normal;
    height: 100%;
}
label.open_more_category {
    width: 25px;
    text-align: right;
    cursor: pointer;
    font-size: 25px;
    color: #606060;
    position: absolute;
    right: 10px;
}
.progress_bar_div{ margin-top: 5px;padding: 5px 20px 10px; }
.progress_bar_one{ padding-bottom: 0; margin-top: 10px; padding: 10px 20px 0; }
.common-inner-banner{ position: relative; padding: 20px 10px 10px; 
    color: #000; display: -webkit-box; display: -ms-flexbox; display: flex; 
     -webkit-box-align: center; -ms-flex-align: center; align-items: center; 
     -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-flow: column wrap;
      flex-flow: column wrap;
      background-size: cover; min-height: 130px; text-align: center; } 
.menu-section-inner{max-height: 500px; overflow: auto;}
.fxd_lftmenu_div{position: fixed; top: 160px; width: 300px;}
.bottom_abs {  position: absolute; top: auto; bottom: 0; width: 300px;}

.prodet_top{text-align: center}
.prodet_top img{margin: 0 auto}
.prodetailinner-main-div .modal-body{ padding: 0; }

.detail-pg-slider .product-title h3{margin: 0;}
.addonpro-slider-top{ margin: 70px 0 0px 0}
.chk-payment-title h3, .chk-addonpro-title h3{font-size: 18px;text-transform: inherit; text-align: center; font-family: 'Poppins', sans-serif; font-weight: 600; margin-bottom: 20px}

.addcart_row.addcart_done_maindiv{ display: none}
.prd_chosen_sub_item_left.addcart_done_maindiv{ display: flex;} 

.d-merge .addcart_row.addcart_done_maindiv{ display: block; }
.products-mayalso{ text-align: center;
    width: 100%;
    background: #fff;
    transition: all .4s linear;
    display: flex;
    flex-direction: column;
    border: 2px solid #f3f3f3;position: relative;height: 100%;}
.owl-carousel .products-mayalso .products-image-div img{ width: auto; margin: 0 auto;}
.detail-pg-slider{ margin: 0 0 50px 0; }
.detail-pg-slider .owl-carousel .owl-stage, .home-insta-feed .owl-carousel .owl-stage{display: flex}
.detail-pg-slider .owl-carousel .owl-item > div, .home-insta-feed .owl-carousel .owl-item > div{ height: 100%; }

.chk-addonpro-title h3{margin-bottom: 40px; font-size: 32px; letter-spacing: 4px; }
.addcart_row:after { clear: both; content: ""; display: table;overflow: hidden;}
.birthday-info-div{padding: 25px 5px 0px 5px; text-align: center;}
.birthday-info-top h3{ font-size: 43px;text-transform: uppercase;margin-bottom: 0; padding-top: 10px; line-height: 1; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; -webkit-box-align: center; -ms-flex-align: center; align-items: center; }
.birthday-info-top h3 img {margin: 0px 0 0 15px; position: relative; top: 5px;}
.birthday-info-middle{  padding: 10px 0 0 0; font-size:18px; color: #000; font-weight: 600 }
.left-input-div, .right-input-div {max-width: 280px;width: 100%;}
.birthday-inpt-act {display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-pack: justify;-ms-flex-pack: justify;
justify-content: space-between;-ms-flex-wrap: wrap;flex-wrap: wrap;max-width: 570px;margin: 0 auto;}
.birthday-inpt-act input[type="text"]{border-radius: 0}
.narml_select { position: relative;}
.narml_select:after {  width: 30px; height: 46px; content: ""; position: absolute; right: 1px; background: #fff;    top: 1px;}

.normal-popup { max-width: 680px; text-align: center;}
.normal_popup_cont ul{padding: 0; list-style: none}
.normal-popup button.mfp-close{background:#000;
    opacity: 1;
    color:#fff;
    border-radius: 2px;
    width: 30px;
    height: 30px;
    line-height: 1;
    right: 5px;
    top: 5px;
    border-radius: 5px;
}

.normal_popup_cont{ padding: 50px 50px; }

.normal_popup_cont p{ font-size: 17px; font-weight: 600; }

.promopopup-maindiv {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
#promo-check-popup {
  max-width: 810px;
  background: none;
}
.promopopup-rhs {
  width: 53.08641975308642%;
  border: 1px solid #DB203B;    
}
.promopopup-lhs {
    width: 46.91358024691358%;
    border-top: 30px solid rgba(0,0,0,0);
    border-bottom: 30px solid rgba(0,0,0,0);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.frm-action-div{   background: #DB203B; color: #fff;  padding:40px 30px; }
.promopopup-rhs img{ width: 100%; height: 100%; -o-object-fit: cover; object-fit: cover; }
.promopopup-lhs .frm-top-title h3{ color: #fff; margin-bottom:0px; }
.promopopup-lhs .frm-inputbtn-div { position: relative;}
.promopopup-lhs .frm-inputbtn-div .button {
    position: absolute;
    right: 0;
    top: 0;
    margin: 0;
    height: 48px;
    background: #000;
    font-size: 15px;
    padding: 10px 14px 10px;
    font-weight: bold;
    border: 1px solid #fff;    
}
.promopopup-lhs .form-group input{border-color: #fff;padding-right: 110px;}
.promopopup-lhs .frm-bottom-text{ text-transform: uppercase; font-size: 14px; font-weight: bold;margin-bottom: 0}
.promopopup-lhs .frm-bottom-text p{margin: 0}
.promopopup-lhs .form-group{margin-bottom: 5px;}
#promomail-error { 
    color: #f00;
    line-height: 1;
    font-size: 11px;
    background: #febaba;
    padding: 5px;
    text-align: center;
    border-radius: 5px;
    position: relative;
}

.promo-check-popup button.mfp-close{background:#DB203B;
    opacity: 1;
    color:#fff;
    border-radius: 6px;
    width: 30px;
    height: 30px;
    line-height: 1;
    top: -15px;
    right: -15px;
}

.prodetailinner-main-div button.close {
    width: 24px;
    height: 24px;
    background: #000;
    color: #fff;
    box-shadow: none;
    top: 5px;
    right: 5px;
    text-indent: 9999px;
    position: absolute;
    border-radius: 4px;
}
.prodetailinner-main-div button.close:after{ 
    background: url(../images/plus-white.svg) no-repeat;
    width: 16px;
    height: 16px;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    -webkit-transform: rotate(45deg);   
    transform: rotate(45deg);    
}
#ProductDetailMdl .modal-content{ border-radius: 0 }

.mbl-hiden{overflow-y: hidden}

.mobile-login{display: none}
.mobile-login ul {
    padding: 0;
    list-style: none;
    position: absolute;
    top: 110%;
    left: 0;
    background: #f66750;
    border-radius: 6px;
    min-width: 150px;
    visibility: hidden;
    opacity: 0;
    transform: translateY(2em);
    transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
    z-index: 5;
    
}
.mobile-login ul.active{
    visibility: visible;
    opacity: 1;
    transform: translateY(0%);
    transition-delay: 0s, 0s, 0.3s;
}

.mobile-login {
    position: relative;
}

.mobile-login ul li a {
    display: block;
    line-height: 1;
    padding: 10px 20px;
    color: #fff;
    border-bottom: 1px solid rgba(255,255,255,0.22);
}
a.controller-nav img {
    width: 32px;
}

.mobile-menu {
    position: fixed;
    padding: 0;
    overflow: hidden;
    background-color: #000;
    height: 100vh;
    left: 0;
    top: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transition: opacity .2s linear, -webkit-transform .3s ease-in-out;
    transition: opacity .2s linear, -webkit-transform .3s ease-in-out;
    transition: opacity .2s linear, transform .3s ease-in-out;
    transition: opacity .2s linear, transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
    width: 100vw;
    z-index: 9999;
    max-width: 520px
}

.mobile-menu .menu {
    list-style: none;
    padding: 0;
    margin: 0 0 30px
}

.mobile-menu .menu li {
    list-style: none;
    position: relative
}

.mobile-menu .mobile-menu-body {
    overflow: hidden;
    overflow-y: auto;
    padding: 50px 40px 40px;
    height: 100%
}

.mobile-menu.active {
    -webkit-transform: translateX(0);
    transform: translateX(0)
}

.mobile-menu.active .menu a {
    -webkit-animation: a .4s .2s backwards;
    animation: a .4s .2s backwards
}

.mobile-menu.active .menu li:nth-of-type(2) a {
    -webkit-animation-delay: .3s;
    animation-delay: .3s
}

.mobile-menu.active .menu li:nth-of-type(3) a {
    -webkit-animation-delay: .4s;
    animation-delay: .4s
}

.mobile-menu.active .menu li:nth-of-type(4) a {
    -webkit-animation-delay: .5s;
    animation-delay: .5s
}

.mobile-menu.active .menu li:nth-of-type(5) a {
    -webkit-animation-delay: .6s;
    animation-delay: .6s
}
.mobile-menu.active .menu li:nth-of-type(6) a {
    -webkit-animation-delay: .7s;
    animation-delay: .7s
}
.mobile-menu.active .menu li:nth-of-type(7) a {
    -webkit-animation-delay: .8s;
    animation-delay: .8s
}

.mobile-menu .toggle-submenu-button {
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    display: block;
    height: 60px;
    cursor: pointer
}

.mobile-menu .toggle-submenu-button:before {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg)
}

.mobile-menu .toggle-submenu-button:after,
.mobile-menu .toggle-submenu-button:before {
    background: #fff;
    display: block;
    height: 2px;
    margin-top: -2px;
    pointer-events: none;
    position: absolute;
    right: 10px;
    top: 50%;
    transition: -webkit-transform .25s ease-in-out 0s;
    transition: transform .25s ease-in-out 0s;
    transition: transform .25s ease-in-out 0s, -webkit-transform .25s ease-in-out 0s;
    -webkit-transition: -webkit-transform .25s ease-in-out 0s;
    width: 14px;
    z-index: 1;
    content: ""
}

.mobile-menu .toggle-submenu-button:after {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg)
}

.mobile-menu .toggle-submenu-button.open-submenu:before {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg)
}

.mobile-menu .toggle-submenu-button.open-submenu:after {
    transform: rotate(1turn);
    -webkit-transform: rotate(1turn)
}


.mobile-menu .mobile-menu-close {

}

.mobile-menu .mobile-menu-close span {
    cursor: pointer;
    display: block;
    width: 22px;
    height: 22px;
    position: relative
}

.mobile-menu .mobile-menu-close span:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
}

.mobile-menu .mobile-menu-close span:after,
.mobile-menu .mobile-menu-close span:before {
    background: #fff;
    display: block;
    height: 2px;
    margin-top: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 50%;
    transition: -webkit-transform .25s ease-in-out 0s;
    transition: transform .25s ease-in-out 0s;
    transition: transform .25s ease-in-out 0s, -webkit-transform .25s ease-in-out 0s;
    -webkit-transition: -webkit-transform .25s ease-in-out 0s;
    width: 24px;
    z-index: 2;
    content: ""
}

.mobile-menu .mobile-menu-close span:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg)
}

.mobile-menu .mobile-menu-header {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    padding: 15px 0;
    position: fixed;
    top: 0;
    right: 40px;
    left: 40px;
    height: 50px;
    background: #000;
    z-index: 2;
    text-align: right;
}

.mobile-menu .menu a {
    display: block;
    font-size: 17px;
    color: #fff ;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    padding: 1.3rem 0;
    line-height: 1;
    text-transform: uppercase;
        letter-spacing: 1px;
    font-weight: 700;
}

.mobile-menu .menu a:hover {
    color: #DB203B ;
}

.menu li.active>a{
    color: #DB203B;
}

.menu li.active .submenu_list>li.active>a{
    color: #DB203B;
}

.mobile-menu .menu a.submenu-arow {
    display: inline-flex;
    padding: 10px;
    position: absolute;
    right: 10px;
    top: 20px;
}
.mobile-menu .menu .submenu_list li {
    padding-right: 0;
    border-bottom: 0
}

.mobile-menu .menu .submenu_list a {
    font-size: 15px;
    padding: 10px 0;
    font-weight: 500;
}
.submenu_list{display: none; padding:0px 0 10px 0}
.mobile-menu .menu li {
    border-bottom: .0625rem solid rgba(255,255,255,0.22);
    padding-right: 40px
}

@-webkit-keyframes a {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-80px);
        transform: translateX(-80px)
    }

    to {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@keyframes a {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-80px);
        transform: translateX(-80px)
    }

    to {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}
.pkselect_tabsec .tab-content {
    background: #f9f7f8;
    padding: 0px 25px 20px;
}
.rest-list-cls { 
    margin-top: 20px;
}
.li-full-width {
    width: 100% !important;;
}
.btn-act-clss { 
    color: #fff;
    background: #000;
    border: 1px solid #769e90;
}
.takelist-btn-part, .delevery_popup_datetime .btn_sec {
    max-width: 330px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}
.delevery_popup_datetime .btn_sec{margin: 10px auto 0; max-width: 100%}
.delevery_popup_datetime .full-login-new-body{ max-width: 440px; }

.delevery_popup_datetime .full-login-new-body > h2{ font-family: 'Poppins', sans-serif; font-weight: 600; margin-bottom: 5px; }

.takelist-btn-part .button, .delevery_popup_datetime .button {
    width: 48%;
    margin: 0;
    display: block;
    box-shadow: 0px 7px 9px 0px rgba(0, 0, 0, 0.21);
    padding: 15px 10px; border-radius: 5px;
}
.takelist-btn-part .button:first-child, .delevery_popup_datetime .button:first-child{ background: #fff; color: #000; border:1px solid #000; }
.takelist-btn-part .button:first-child:hover, .delevery_popup_datetime .button:first-child:hover{ background:#DB203B; color: #fff;  border: 1px solid #DB203B}
#viewmenu-popup .takelist-btn-part .button{width: 100%}
.mobile-pantry-search{display: none}

/* omise */
.omise-form-error input {
    -webkit-box-shadow: inset 0 1px 2px transparent, 0 1px 0 hsla(0,0%,100%,0), 0 0 2px 0 rgba(255,0,0,.5);
    box-shadow: inset 0 1px 2px transparent, 0 1px 0 hsla(0,0%,100%,0), 0 0 2px 0 rgba(255,0,0,.5);
    border-color: #ff7076;
    border-top-color: #ff5c61;
}

.pay-omiseconf-popup { max-width: 500px; border-radius: 10px;background: #f5f5f7}
span.omise-pay-img {
    display: block;
    width: 78px;
    height: 78px;
    border-radius: 50%;
    background: #fff;
    overflow: hidden;
    margin: 0 auto;
    border: 4px solid #fff;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.45);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.45);
    position: absolute;
    top: -39px;
    left: 0;
    right: 0;
}

img.makisan-logo-img {
    width: 100%;
    height: 100%;
    -o-object-fit: none;
    object-fit: none;
}

.omise_pay_header {
    position: relative;
    padding: 50px 30px 25px;
    text-align: center;
    border-bottom: 1px solid #d2d2d3;
}

.omise-pay-title{
    color: #000;
    font-weight: 600;
    font-size: 26px;
}

.omisepop_in{
    text-align: center;
}

#paynow-qr-image{
    background: #fff;
    border: 2px solid #cbcbcb;
    padding: 25px 50px;
    border-radius: 8px;
}

#paynow-qr-image:hover{
    border: 2px solid #DB203B;
}

.omise-pay-now-notes{
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
}

.omise-paynow-amount-to-paid span{
    display: block;
    font-weight: 600;
    font-size: 18px;
    margin: 15px 0;
}

.paynow-notes ul{
    list-style: none;
    padding: 0;
}

.paynow-notes ul li img{
    height: 13px;
    vertical-align: middle;
    padding-left: 10px;
    margin-top: 5px;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.paynow-notes ul li{
    text-align: left;
    padding-bottom: 15px;
    display: flex;
}

.pay-omisepaynowconf-popup .mfp-close{
    top: 5px;
    right: 5px;
    border-radius: 5px;
    background: #000;
    color: #fff;
    width: 26px;
    height: 26px;
    opacity: 1;
    line-height: 28px;
}

.pay-omisepaynowconf-popup .mfp-close:hover{
    background: #000;
    color: #fff;
}
.omise_pay_body {
    padding: 30px;
    position: relative;
        border-top: 2px solid #fff;
}
.omisepop_in .form-control{ padding: 10px; height: 40px; font-size: 14px;border-radius: 4px; padding-left: 36px}
.expire_row_inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.expire_row_inner > div {
    width: 32%;
    position: relative;
}
.omisepop_in .alt_btns .button {margin-bottom: 0;
    width: 100%;
    padding: 13px 10px 11px;
    border-radius: 4px;}
.omisepop_in .form_sec i{ position: absolute; left: 10px; color: #888;top:12px;  }
.omisepop_in .form_sec > div{ position: relative }
.omisepop_in .form_sec .uname-box  i{ left: 13px;}
.omisepop_in .form_sec .card-numbr-div i, .omisepop_in .form_sec .expire_left i {font-size: 16px;top:13px;}
.omisepop_in .form_sec .expire_right  i{ font-size: 19px; top: 12px;}
.expire_mdl .form-control{ padding-left: 15px }
.card {
    position: absolute;
    display: block;
    right: 10px;
    width: 28px;
    height: 19px;
    background: url(../images/cards/spritecard.png) no-repeat 0;
    top: 10px;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
    background-position: 0 0;
    overflow: hidden;
}
.visa + .card {
    background-position: 0 -19px;
}
.master+ .card {
    background-position: 0 -38px;
}
.discover + .card {
    background-position: 0 -57px;
}
.american + .card {
    background-position: 0 -76px;
}
.pay-omiseconf-popup button.mfp-close {
    padding: 0;
    width: 21px;
    height: 21px;
    line-height: 20px;
    display: block;
    color: #fff !important;
    border-radius: 50%;
    background: #000;
    border: 1px solid #000;
    top: 10px;
    right: 10px;
    font-size: 20px;
    text-align: center;
    text-indent: 2px;
}


.pay-omiseconf-popup button.mfp-close:hover{ background: #000; border-color: #000; }
.omisepay-mode-option {
    position: fixed;
    top: 20px;
    right: 20px;
    background: #ffd025;
    line-height: 1;
    padding:9px 11px 8px;
    font-size: 12px;
    border-radius: 4px;
    font-weight: 600;
    text-transform: uppercase;
}
.omise-error-info-div {
    color: #ffffff;
    background-color: #b12f2f;
    border-color: #ebccd1;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    max-width: 100%;
    margin: 0 auto;
    padding: 10px;
    line-height: 1;
}
.omise-error-icon { font-size: 14px; }
.omise_alert_close {
    position: absolute;
    right: 0px;
    color: #FFF;
    top: 0;
}
.omise-error-msg {
    margin: 0 0 0 11px;
    font-size: 14px;
}
.omise-error-info-div .container {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    position: relative;
}
.displayDivCls { display: none; }
.would-like-bottom label.chk_hea {
    margin: 0;
    padding: 0 0 0 35px;
    font-size: 15px;
    text-transform: uppercase;
}

.would-like-bottom .custom_checkbox {
    left: 0;
    right: inherit;
    top: -4px;
}

.would-like-bottom {
    margin: 15px 0 10px;
}
.cart_row.promo-cart-row {
    position: relative;
    padding-right: 22px;
}
.contact_form {
    padding-top: 30px;
    padding-bottom: 30px;
}

.contact_form .form-control.input-focus {
    border-radius: 0;
}

.product-cms-content { padding: 50px 0 30px;}
.product-cms-content-top{text-align: center; margin: 0 0 50px 0;position: relative}

.product-cms-content-top h3{ font-weight: 700; letter-spacing: 6px; font-size: 33px; }
.product-cms-content-top .container{max-width: 920px; margin: 0 auto;}
.product-cms-content-middle .normal-menu-nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.product-cconmid-rhs {width: 100%;  }
.product-cconmid-lhs h3 {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 4px;
}
.product-cconmid-rhs .owl-carousel .owl-item img{ width: 100%;}
.product-cconmid-rhs .owl-dots {
    display: flex;
    padding: 20px 0 0 0;
    justify-content: center;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
}

.product-cconmid-rhs .owl-dots span {
    width: 10px;
    height: 10px;
    background: #fff;
    display: block;
    border-radius: 50%;
    border: 1px solid #000;
}

.product-cms-content-middle .product-cconmid-rhs .owl-dots{
    position: inherit;
    left: unset;
    right: unset;
    flex-wrap: wrap;
}

.product-cms-content-middle .product-cconmid-rhs .owl-dots .owl-dot {  margin: 0 10px 10px 0; }


.product-cconmid-rhs .owl-dot.active span{ background: #db203b; border-color: #db203b }
.product-cconmid-rhs .owl-dots .owl-dot {  margin: 0 10px 0 0; }

.product-cconmid-rhs .menu-list-intvl div{border-radius: 20px; margin: 0 auto 10px; border:2px solid #fff; overflow: hidden  }
.product-cconmid-rhs .menu-list-intvl{text-align: center; line-height: 1.1 }
.product-cconmid-rhs .owl-carousel .owl-item .menu-list-hover{display: none}
.product-cconmid-rhs a.menu-title-link{ color: #717171;text-transform: capitalize; }
.menu-title-link div:hover, .owl-carousel .owl-item .menu-list-intvl.active div{ border-color: #DB203B }
.product-cconmid-rhs a.menu-title-link:hover{ color: #000; }
.product-cconmid-rhs .menu-list-intvl.active .menu-title-link{ color: #000; font-weight: bold} 

.product-cms-content-bottom{ position: relative; padding: 50px 0; min-height: 380px;display: flex; align-items: center;}
.product-cms-content-bottom img{ width: 100%; height: 100%; object-fit: cover; position: absolute; left: 0; right: 0; top: 0; }
 .sticky-menu-nav{
    position: fixed;
    top: 106px;
    left: 0;
    right: 0;
    z-index: 91;
 -webkit-transform: translate3d(0,-150px,0);
    transform: translate3d(0,-150px,0);
    background: #fff;
    box-shadow: rgb(0 0 0 / 10%) 0 2px 4px;
    -webkit-transition: -webkit-transform 420ms cubic-bezier(.165,.84,.44,1);
    transition: -webkit-transform 420ms cubic-bezier(.165,.84,.44,1);
    transition: transform 420ms cubic-bezier(.165,.84,.44,1);
    transition: transform 420ms cubic-bezier(.165,.84,.44,1),-webkit-transform 420ms cubic-bezier(.165,.84,.44,1);
}
.sticky-menu-product  .sticky-menu-nav{ 
-webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}
.sticky-menu-nav .owl-carousel{ max-width: 1280px; margin: 0 auto;padding: 0 25px}
.sticky-menu-nav .owl-carousel .owl-nav div{ position: absolute; top:0; width: 25px; height: 100%; font-size: 0; transition: 0.3s linear all; }
.sticky-menu-nav .owl-carousel .owl-nav div:hover{ opacity: 0.6 }
.sticky-menu-nav .owl-carousel .owl-prev{ left: 0;}
.sticky-menu-nav .owl-carousel .owl-next{ right: 0; }
.sticky-menu-nav .owl-carousel .owl-prev:after, .sticky-menu-nav .owl-carousel .owl-next:after{  background: url(../images/aroow_myaccountb.png) no-repeat; background-size: contain;  
content: ""; position: absolute;
    width: 20px;
    height: 14px;
    top: 50%;  margin-top: -7px;
}
.sticky-menu-nav .owl-carousel .owl-prev:after{ transform: rotate(90deg);left: 0;}
.sticky-menu-nav .owl-carousel .owl-next:after{ transform: rotate(270deg); right: 0;}
.sticky-menu-nav .owl-stage{ display: flex}
.sticky-menu-nav .owl-stage .menu-list-intvl{height: 100%}
.sticky-menu-nav .menu-title-link {
    display: flex;
    text-align: center;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 15px;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 10px 5px;
    line-height: 1.1;
    position: relative;
}
.sticky-menu-nav .menu-list-intvl.active a {
    background: #DB203B;
    color: #000;
}
.sticky-menu-nav a.menu-title-link:hover{
    color: #000
}

.category-desc-section {
    max-width: 670px;
    background: rgba(255,255,255,0.86);
    padding: 50px 60px;
    z-index: 1;
    position: relative;
}
.category-desc-section h3{ text-transform: inherit;font-weight: bold; font-size: 20px; margin-bottom: 15px  }
.product-filters {
border-bottom: 2px solid #f3f3f3;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 0 30px 0;
    margin: 0 0 50px 0;
}
.filters-lhs, .filters-rhs, .cafe-lhs-filter{ position: relative}
.filters-lhs ul, .filters-rhs ul, .cafe-lhs-filter ul{ display: none; padding: 0; margin: 0; background: #fff; border: 1px solid #e4e4e4; position: absolute; top: 100%;left: 0;list-style: none; width: 160px;z-index: 9}
.filters-lhs ul.active, .filters-rhs ul.active, .cafe-lhs-filter ul.active{display: block; background: #fff}
.filters-rhs ul{left: inherit; right: 0}
.filters-lhs span, .filters-rhs span, .cafe-lhs-filter span{display: block;color: #606060;}
.filters-lhs .filter-act, .filters-rhs .filter-act{text-transform: uppercase;}
.filter-act{ position: relative;padding-right: 40px; font-weight: bold; text-transform: capitalize; letter-spacing:0.5px;margin-top: 10px;cursor: pointer}
.filters-rhs span{text-align: right}
.filter-act:after {
    content: "\f107";
    font-family: FontAwesome;
    position: absolute;
    right: 0;
    top: -3px;
    font-size: 28px;
    line-height: 1;
    font-weight: normal;
    transform: rotate(0deg);
}

.cafe-lhs-filter.filter_by_brand_tag.active .filter-act:after  {
    content: "\f107";
    transform: rotate(180deg);
}

.filters-lhs ul li a, .filters-rhs ul li a, .cafe-lhs-filter ul li a {
display: block;
    padding: 8px 10px;
    line-height: 1.2;
    border-bottom: 1px solid #f4f4f4;
    font-size: 13px;
    font-weight: 500;
}
 .filters-lhs ul li.active a, .filters-rhs ul li.active a, .cafe-lhs-filter ul li.active a{
    background: #000;
    color: #DB203B;
}

.product_sidebar_top{display: none}
.product-rhs .datetime-wrap .react-datepicker-wrapper{display: block}
.product-lhs-inner{ box-shadow: 0px 1px 16px 0px rgba(1, 1, 1, 0.14); padding: 10px 0 0 0; }
.catering-product-list .product-lhs{ box-shadow: none;  padding: 0;}
.products_sidebar_inner ul{margin: 0; padding: 0; list-style: none; display: flex; flex-wrap: wrap}
.products_sidebar_inner ul li{ width: 24.6%;min-height: 56px;margin: 0 0.5% 5px 0;}
.products_sidebar_inner ul li a, .slick-slider .products_sidebar_content a{ color: #9d9d9d;border: 2px solid #999999; padding: 5px; text-align: center; display: flex; justify-content: center; align-items: center; color: #9d9d9d;  text-transform: uppercase;font-family: 'Poppins', sans-serif;transition: 0.3s linear all; height: 100%;border-radius: 5px; }
.products_sidebar_inner ul li:nth-child(4n){ margin-right: 0 }
.products_sidebar_inner ul li:hover a, .products_sidebar_inner ul li.active a, .slick-slider .products_sidebar_content a:hover, .slick-slider .products_sidebar_content.active a{ border: 2px solid #DB203B; color: #fff; background: #DB203B }
.product-lhs-inner .products_list{ padding: 22px 18px 20px 18px;  }
.products_sidebar_inner { margin-bottom: 15px;}
.products_list_content { display: flex; flex-wrap: wrap; justify-content: space-between;}
.products_list_inner{width: 49%;}
.products_list_desc{width: 49%;}
body .accordion{border: 0; border-radius: 0 }
.product_acc_grp .accordion .accordion_title{ background: #282828; color: #fff; font-family: 'Poppins', sans-serif;
padding: 12px 35px 10px 12px;  line-height: 1; text-transform: capitalize;cursor: pointer;position: relative;    
display: flex;  justify-content: space-between; align-items: center;}
.product_acc_grp .accordion{margin: 0 0 10px 0}
.product_acc_grp .accordion .accordion_title span{ 
    color: #DB203B;
    font-family: 'Poppins', sans-serif;
    font-size: 90%;
}
.product_acc_grp .accordion .accordion_title::before, .product_acc_grp .accordion .accordion_title::after {
    background: #fff;
    content: "";
    display: block;
    height: 2px;
    margin-top: -2px;
    pointer-events: none;
    position: absolute;
    right:10px;
    top: 50%;
    transition: transform 0.25s ease-in-out 0s;
    -webkit-transition: transform 0.25s ease-in-out 0s;
    width: 14px;
    z-index: 2;
}
.product_acc_grp .accordion .accordion_title::before {
    content: "";
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
}
.product_acc_grp .accordion .accordion_title::after {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
}

.product_acc_grp .accordion .accordion_title.active::before{
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
}
.product_acc_grp .accordion .accordion_title.active::after{
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
}
.review-full ul {
    list-style: none;
    padding: 30px 0 0 0;
}

.review-full ul li {
    margin-bottom: 30px;
}
.review-full ul li:last-child{margin-bottom: 0}

.review-author {
    position: relative;
    display: flex;
    justify-content: space-between;
}

.review-author-img {
    width: 58px;
    height: 58px;
    border-radius: 50%;
    overflow: hidden;
}

.review-author-content {
    width: calc( 100% - 74px );
}

.review-author-content h4 {
    text-transform: inherit;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
}

a.button.add_to_cart {
    width: 100%;
}
.mobile-footer-nav{display: none}
.mobile-footer-nav .accordion {
    border: 0;
    border-radius: 0;
}
.mobile-footer-nav .accordion button.title {
    margin: 0;
    border: 0;
    box-shadow: none;
    background: #1d1d1d;
    border-radius: 0 !important;
        font-size: 15px;
    color:#ed3650;
}
.mobile-footer-nav .accordion .accordion-item .title:after {
    content: "\f107";
    font-size: 25px;
    color: #ed3650;
    margin-left: 0px;
    font-family: 'FontAwesome';
    position: absolute;
    right: 20px;
    top: 12px;
    transition: 0.3s linear all;
    transform: rotate(0deg);
}
.mobile-footer-nav .accordion .accordion-item.active .title:after{ content: "\f107";transform: rotate(180deg);  }
.accordion .accordion-item.active .title{background-color: #111111;}
body .accordion .accordion-item.active .panel{ max-height: 2000px }
.mobile-footer-nav .accordion .accordion-item .panel {
    background-color: #222;
    border:0;
    border-radius: 0px;
    box-shadow:none;
}
.mobile-footer-nav .accordion .accordion-item.active .panel {
    max-height:800px;
    transition: max-height 0.5s linear;
}
.mobile-footer-nav .accordion .accordion-item .panel ul{ padding:0; list-style: none;  padding: 20px;}
.mobile-footer-nav ul li a {
    color: #fff;
    font-size: 14px;
    display: block;
    margin-bottom: 5px;
}
.panel { margin-bottom: 10px;}
.menu-section-mobile-inner .slick-track, .mobile-menu-section-left .slick-track{
    border-bottom: 4px solid #e7e6e6;
}
.menu-section-mobile-inner .slick-track .slick-slide > div, .mobile-menu-section-left .slick-track .slick-slide > div{display: block; width: 100%;}

.menu-section-mobile-inner .slick-track .slick-slide > div .menu-title-link, .mobile-menu-section-left .slick-track .slick-slide > div .menu-title-link{
    padding: 10px 2px;
    position: relative;
    cursor: pointer;
    color: #c3c1c1;
    font-family: 'Poppins', sans-serif;
    text-transform: capitalize;
    font-size: 17px;
    line-height: normal;
    display: block;
    height: 100%;text-align: center}

.menu-section-mobile-inner .slick-track .slick-slide > div .menu-title-link:before, .mobile-menu-section-left .slick-track .slick-slide > div .menu-title-link:before {
    content: "";
    position: absolute;
    left: 51%;
    right: 51%;
    bottom: -4px;
    background: #f6aa32;
    height: 4px;
    transition-property: left, right;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
}
.menu-section-mobile-inner .slick-track .slick-slide > div .active .menu-title-link:before, .mobile-menu-section-left .slick-track .slick-slide > div .active .menu-title-link:before{    left: 0; right: 0;}
.menu-section-mobile-inner .slick-track .slick-slide > div .active .menu-title-link, .mobile-menu-section-left .slick-track .slick-slide > div .active .menu-title-link{color: #181818}
.menu-section-mobile-inner, .mobile-menu-section-left{display: none}
header.small_sticky {
    box-shadow: 0 2px 16px 0 rgba(0,0,0,.08);
}
.slick-slide .menu-list-intvl, .products_sidebar_inner .slick-slide {
    height: 100%;
}
.products_sidebar_inner .slick-track .slick-slide > div {
    display: block;
    width: 100%;
}
.menu-section-mobile-inner button.slick-arrow, .products_sidebar_inner button.slick-arrow, .mobile-menu-section-left button.slick-arrow{
    position: absolute;
    overflow: hidden;
    text-indent: -9999px;
    padding: 0;
    width: 26px;
    height: 26px;
    border-radius: 3px;
    margin: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.3s linear all;
}
.menu-section-mobile-inner .slick-prev, .products_sidebar_inner .slick-prev, .mobile-menu-section-left .slick-prev{left: 0}
.menu-section-mobile-inner .slick-next, .products_sidebar_inner .slick-next, .mobile-menu-section-left .slick-next{right: 0}
.menu-section-mobile-inner button.slick-arrow:before, .products_sidebar_inner button.slick-arrow:before, .mobile-menu-section-left button.slick-arrow:before{ position: absolute;
    left: 0;
    right: 0;
    width: 14px;
    height: 14px;
    top: 50%;
    margin-top: -8px; background: url(../images/left-arrow.png) no-repeat; content: "";
    margin-left: 6px;}
.menu-section-mobile-inner button.slick-arrow.slick-next:before, .products_sidebar_inner button.slick-arrow.slick-next:before, .mobile-menu-section-left button.slick-arrow.slick-next:before{
    transform: rotate(180deg);
    margin-top: -6px;
}

.hcart_dropdown {
    background: #fff;
    right: 0;
    width: 720px;
    position: fixed;
    height: 100vh;
    right: 0;
    top: 0;
    transform: translateX(100%);
    transition: transform .3s ease-in-out;
    z-index: 11;
    text-align: left;
}

.hcart_dropdown.open {
    transform: translateX(0);
}

body.cart-items-open #scrollbutton.show{ opacity: 0; }
body.cart-items-open {
    overflow: hidden;
}

.cart_left { width: calc( 100% - 180px );    display: flex;align-items: flex-start}
.cart_right{ width: 175px;}


.catering_receipt_popup .cart_left { width: calc( 100% - 150px );    display: flex;align-items: flex-start}
.catering_receipt_popup .cart_right{ width: 150px;}

.cart_img {
    width: 90px;
    margin: 0 15px 5px 0;
}
.hcart_scrollarea .cart_img img{width: 98px;height: 77px;}
.hcart_tt {
    width: 100%;
    background: #2f2f2e;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:7px 12px;
}

.hcart_tt h3 {
    color: #fff;
    margin: 0 auto;
    font-size: 18px;
        font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

span.close_product_orders {
    line-height: 1;
}
.hcart_dropdown .hcart_scrollarea {
    overflow-y: auto;
    height: calc(100% - 94px);
}
.hcart_dropdown .cartaction_bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
}
header .hcart_dropdown .cartaction_bottom .btn{border-radius: 0;}
header .hcart_dropdown .cart_footer { padding:20px 20px; background: #f7f7f7; margin: 0px 20px;}
.product_card_desc {
    text-align: center;
    padding: 10px;
    font-style: italic;
}
.product_card_desc p{margin-bottom: 5px;}
.package_checkitem{text-align: left;font-size: 14px;}
.package_checkitem .custom_checkbox{margin: 0 0 10px 0;display: block}
.package_checkitem .custom_checkbox span{ padding: 0 50px 0 0;  line-height: 1.2; font-size: 14px; display: block }
/* 
.package_checkitem .custom_checkbox span:before{right: 0; left: inherit;    border-radius: 5px;}
.package_checkitem .custom_checkbox input[type=checkbox]:checked + span:before{ background: url(../images/tick20-square.png) no-repeat; }
*/
.package_checkitem .custom_checkbox span p{margin: 5px 0 0 0;}
.package_checkitem .custom_checkbox span img { position: absolute; right: 30px;}
.input-file-sec{ position: relative;margin: 10px 0 0 0}
.input-file-sec #upload-file {position: absolute; opacity: 0; width: 100%; height: 100%; right: 0; cursor: pointer;}
.file-name {display: inline-block; vertical-align: middle; box-shadow: none; border-radius: 4px; margin: 0; margin-right: 10px; }
.input-file-sec .btn {pointer-events: none;padding: 9px 20px; font-size: 14px; }
.product_det_cards .det_inner_content i {font-family: 'avantgarde_bk_btdemi_oblique'; font-style: normal;}
.show_details > label.combo-input{ display: none;}
.det_card .pkgerror_txt {
    color: #f90000;
    font-size: 13px;
    margin: 0 0 5px 0;
}
.pkgerror_txt {
    color: #f90000;
    font-size: 13px;
    margin: 0 0 5px 0;
}

.package_details {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 22px;
}

.package_details_left {
    width: 48%;
}

.package_details_right {
    width: 50%;
}
.package_details_left .slick-dots {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex !important;
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    justify-content: center;
}
.package_details_left .slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 11px;
    height: 11px;
    color: transparent;
    border: 0;
    outline: none;
    padding: 0;
    margin: 0 3px;
    border: 2px solid #fff;
    border-radius: 50%;
    background: #302d2a;
}
.package_details_left .slick-dots li.slick-active button{ background: #fff }
.package_details_left .slick-slide img{ max-height: 240px; width: 100%; object-fit: cover; }
.pdr_right {
    text-align: center;
    width: 150px;
    
}
.pdr_right-inner{ display: flex; flex-flow: column wrap; align-items: center; justify-content: center; }

.package_details_right_inner {
    display: flex;
    justify-content: space-between;
}

.pdr_left {
    width: calc(100% - 160px);
    font-size: 14px;
}
.pdr_left h4{    margin-bottom: 15px;
    font-size: 17px;
    text-transform: capitalize;}

.pdr_right .qty_bx{width: 120px; height: 38px;    padding: 0 30px;}
.pdr_right .product-min-pax{font-size: 13px; margin: 8px 0 12px}
.pdr_right h5{
    font-size: 15px;
    margin-bottom: 10px;
}
.pdr_right .proqty_input{
    height: 100%;
    line-height: 36px;
    font-size: 17px;
    border-right: 2px solid #e0e0e0;
    width: 100%;
    display: block;
    border-left: 2px solid #e0e0e0;
}
.pdr_right .qty_bx span{width: 30px}
.pdr_right h4{margin-bottom: 15px;}
.fix-only-lhs{ display: none}
.fix-only-lhs h3{
    margin-bottom: 15px;
    font-size: 17px;
    text-transform: capitalize;
}
.pdr_right.price-fixed{ position: fixed;/* right: 0;*/background: #fff; padding: 10px; z-index: 9; top: 80px;    box-shadow: 0px 2px 13px 0px rgba(1, 1, 1, 0.07); width: auto;display: flex; justify-content: space-between;     border: 3px solid #fedf88; border-top: 0;margin: 0} 
.pdr_right.price-fixed .fix-only-lhs{display: block; padding-right: 30px}
.pdr_right.price-fixed .flavours, .pdr_right.price-fixed h5{ display: none}
.pdr_right.price-fixed .product-min-pax {font-size: 12px; margin: 2px 0 0px;}
.pdr_right.price-fixed h4 {margin-bottom: 5px;font-size: 20px}
.pdr_right.price-fixed .qty_bx { width: 110px; height: 30px;}
.pdr_right.price-fixed .proqty_input { line-height: 26px;font-size: 15px;}

.additionals {
    padding-top: 10px;
}
.instruction h5 {
    margin-bottom: 10px;
    text-transform: inherit;
}
.product_details_popup a.button.add_to_cart {
    font-size: 20px;
    margin: 10px 0 0 0;
    padding: 15px 24px 15px;
    letter-spacing: 0.5px;
}

.det_inner_content .show_details{ background: #282828; margin: 0 auto;min-width: 128px;    padding: 12px 6px 12px;}
.det_inner_content .prd_close{     color: #fedf88;font-family: 'Poppins', sans-serif; text-transform: uppercase;}
.det_inner_content .prd_close  span{text-decoration: underline;}
.det_inner_content .product_desc{
    color: #353535;
    font-size: 14px;
    margin: 0 0 20px 0;
    line-height: 1.3;
    flex: 1 1 auto;
}
.det_inner{height: 100%;}
.modifier-btn-grp {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.modifier-btn-grp .button {
    width: 49%;
    padding: 12px 5px;
    font-size: 14px;
    margin: 0 0 5px 0;
}
.modifier-btn-grp .mdf-done.button {background: #769e90}
.custom_logo_banner textarea.form-control {
    height: 80px;
    border-radius: 5px;
    margin-bottom: 0px;
    padding: 10px;
    font-size: 13px;
}
.custom_logo_banner .input_desc{font-size: 12px}
.event_timing .tooltip_link {
    width: 21px;
    height: 19px;
}
.order_card{ border: 1px solid #e4e4e4; border-radius: 5px; overflow: hidden; margin-bottom: 10px; }
.event_timing {
    border-bottom: 1px solid #e4e4e4;
    padding: 15px 15px 10px 15px;
}
.event_timing .form-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.event_timing .form-group .input_field {
    width: calc( 100% - 32px );
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.event_timing .form-control{ margin-bottom: 0;  padding: 6px 12px;}
.event_timing .form-control.required{ border-color:  #e4000d }
.event_timing .custom_select:after{    width: 30px; border-top-right-radius: 5px; border-bottom-right-radius: 5px}
.event_timing .form-group .input_field .custom_select {
    width: calc( 100% - 110px );
}
.event_timing label{ color: #353535; }
.det_inner_image img {
    width: 100%;
    object-fit: cover;
    height: 100%;
}
.package_pdfico a {
    color: #fedf88;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
}

.package_pdfico a i {
    margin: 0 5px 0 0;
    font-size: 16px;
}
.file_details {
    font-size: 13px;
    margin: 5px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.file_details a {
    width: 10px;
    height: 10px;
    display: inline-flex;
    margin-left: 5px;
}
.file_details a img{width: 10px}
.products_cart {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    padding: 30px 10px;
}
.products_cart img{ margin-bottom: 10px }
.order_card.light{ background: #f9f9f9;  }
.order_card.light .order_card_head{ background: #dcdcdc;  }
.order_card.light .order_card_head h5 { color: #464646;}
.order_tooltip {
    padding: 20px 12px 20px;
    width: 230px;
    letter-spacing: 0.3px;
}
.order_tooltip p:first-of-type{ font-family: 'Poppins', sans-serif; text-transform: uppercase; }
.det_inner_content{ border: 2px solid #fff; border-top: 0; }
.selected .det_inner_content{ border-color: #DB203B }
.downalod-quote {
    text-align: center;
    padding: 15px 0 0 0;
}
.downalod-quote a{ font-family: 'Poppins', sans-serif; text-decoration: underline; color: #464646;  text-transform: uppercase; }
.products_info_item .cart_left h4 {
    font-size: 14px;
    margin: 0 0 2px;
    color: #161616;
    position: relative;
    padding-left: 20px;
}
.receipt_cateringpopup .pouup_in .products_info_item .cart_left h4{ padding-left: 0 }
.receipt_cateringpopup .pouup_in .cart_indvl_price{ display: block; clear: both }
.receipt_cateringpopup .pouup_in .pkg-list-lhs > div{ padding-right: 0 }

.products_info_item .cart_left h4 a{
    position: absolute;
    left: 0;
    top: 0px;
    color: #161616;
    font-size: 15px;
}
.products_info_options p{margin: 0 0 2px 0;
    color: #959595;
    font-size: 13px;
    padding-left: 10px; font-style: italic}
.products_info_options span b {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    display: block;
    padding: 10px 0 0 0;
    font-size: 13px;
}
.product-details.products_info_item{ padding-top: 15px; }
.cart_indvl_price{ margin: 0; color: #161616; font-size: 16px; font-family: 'Poppins', sans-serif;}
.cart_right .cart-counter .product_quantity {
    height: 100%;
    line-height: 27px;
    border-right: 2px solid #e0e0e0;
    width: 100%;
    display: block;
    border-left: 2px solid #e0e0e0;
        font-size: 13px;  font-weight: normal;text-align: center;
    background: #fff;
}
.cartaction_checkout{ display: block; margin: 0; border-radius: 0; font-size: 16px; background: #c81d35;}
.cartaction_checkout:hover{ background: #000;}

.add-breaktime {
    font-family: 'Poppins', sans-serif;
    text-decoration: underline;
    color: #464646;
    text-transform: capitalize;
    font-size: 14px;
    display: block;
    margin: 0 auto;
    text-align: center
}
.product_container{ font-family: 'Open Sans', sans-serif;}
.additionals ul{
    list-style: none;
    padding: 0;
    margin: 0;
}
.cart-settup-list, .products_info_item{ padding: 5px 15px }

.catering_receipt_popup .cart-settup-list, .products_info_item{
    padding: 5px 4px 5px 15px;   
}

.cart-settup-list ul{list-style: none; padding: 0;display: block;}
.cart-settup-list ul li {
    padding: 5px 0px 10px 0;
}
.cart-settup-list ul li:last-child{padding-bottom: 10px}
.pkgslist_addition{ font-size: 13px; color: #959595;}
.pkgslist_addition p{ font-size: 13px; color: #959595; margin: 0 0 1px 0; }
.pkgslist_addition p:last-of-type{margin-bottom: 0}

.pkg-list-lhs {
    display: flex;
    justify-content: space-between;
}
.pkg-list-lhs label {
    color: #161616;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    margin-bottom: 5px;
    line-height: 1;
}
.pkg-list-lhs > div{ position: relative;padding-right: 20px; line-height: 1;}
.cart-package-info:nth-child(even){ background: #f8f8f8; border-top: 1px solid #e4e4e4; }
.pkg-list-lhs > div a.remove{ top: 1px; right: 0;}
.pkg-list-lhs > div .price{ color: #161616; font-family: 'Poppins', sans-serif;}
.instruction .spl_instruction{ height: 100px; border-radius: 5px; }
.additionals h5{ text-transform: capitalize; }
.product_acc_grp {
    margin-bottom: 30px;
}
ul.additionals-deliverylist {
    padding: 15px 0 10px 30px;
}
.additionals_setuplist > li{ padding-bottom: 15px;    }
.additionals_setuplist > li .custom_radio span{     font-weight: 600; } 
ul.additionals-deliverylist li{ margin-bottom: 15px; }
.addon_checklist li{ margin-bottom: 10px; }
ul.additionals-deliverylist li:last-child, .addon_checklist li:last-child{margin-bottom: 0}
ul.additionals-deliverylist.equipment_listing_div{ padding: 0px 0 10px 0px;}
ul.additionals-deliverylist li .custom_checkbox span{ line-height: 1.1; }
ul.additionals-deliverylist li .custom_checkbox span:before, .addon_checklist li .custom_checkbox span:before{ border-radius: 3px; width: 16px; height: 16px; top: 4px; }
/*
ul.additionals-deliverylist li .custom_checkbox input[type=checkbox]:checked + span:before, 
.addon_checklist li .custom_checkbox input[type=checkbox]:checked + 
span:before{ background: url(../images/tick-16.png) no-repeat;}*/
.addon_popup-catering{ max-width: 780px; }
.addon_popup-catering .addon_linfo h4 {
    text-transform: capitalize;
    font-size: 17px;
    margin-bottom: 10px;
}
.addon_poptop_header h3{ font-size:18px;font-weight: 700; }
.additionals_equipmentlist .qty_val, .addon-lright-parent .addon_qty_lable, .combo_qtycount .qty_val {
    height: 100%;
    line-height: 28px;
    font-size: 14px;
    border-right: 1px solid #e0e0e0;
    width: 100%;
    display: block;
    border-left: 1px solid #e0e0e0;text-align: center
}
.additionals_equipmentlist .counter{margin-bottom: 5px}
ul.additionals_equipmentlist li{ margin-bottom: 20px}
.addon_poptop_header h3 small {
    font-size: 80%;
    text-transform: capitalize;
    margin-left: 10px;
    
    color: #4e4e4e;
}
.addon_poptop_header h3 small {
    font-size: 80%;
    text-transform: capitalize;
    margin-left: 10px;
    
    color: #4e4e4e;
}

.addon_listrw {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px
}

.addon_linfo {
    width: calc( 100% - 160px );
}

.addon-lright-parent {
    width: 150px;
}
.addon_price{  font-family: 'Poppins', sans-serif; font-size: 16px;}
.addon-lright-parent .btn {    padding: 10px 12px; line-height: 1; font-size: 14px;margin: 5px 0 0 0; background: #282828}
.addon-lright-parent .btn:hover{ color: #fff; background: #f6aa32 }
.addon_lright { display: flex; flex-flow: column wrap; align-items: flex-end;}
.addon_popbot {
    text-align: right;
}

.addon_popbot .button {
    margin: 10px 0 0 10px;
}
.act-remove-topdiv .delete-act-icon{ color: #000; }
.address_linfo {
    margin-bottom: 10px;
}
.catering-checkout-total .order_det{ padding: 0 15px; }
.catering-checkout-total .cart_footer{  padding: 10px 15px 0 15px; }
.btn_big {
    display: block;
    padding: 12px 10px;
    font-size: 16px;
}
.cdd-details-rhs .event_timing .form-group .input_field .custom_select{ width: calc( 100% - 220px );}
.cdd-details-rhs .products_info_item .cart_left h4{padding-left: 0}
.cdd-details-rhs .event_timing{padding-bottom: 5px; border-bottom: 0}
.catering-checkout-total .cdd-details-rhs{font-family: 'Open Sans', sans-serif;}
.no-recrds-found.loader-main-cls{ min-height: 250px; }
.modal.fade{opacity: 1;}

.hcart_dropdown .pkgslist_additionrow {
    margin-bottom: 15px;
}
.hcart_dropdown .pkgslist_additionrow:last-child{margin-bottom: 0}
.hcart_dropdown .cart-settup-list ul li:last-child{padding-bottom: 5px}
.hcart_dropdown .cart-settup-list ul li label{     color: #161616;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    margin-bottom: 5px;
    line-height: 1;}

.popup_product_details{ width: 100%; max-width: 1080px; }
.modal-backdrop.show {
    opacity: .5;
}
.modal.show .modal-dialog {
    -webkit-transform: none;
    transform: none;
}
select option:disabled{ color: rgba(0,0,0,0.4) }
.cdd-details-rhs .pkgslist_additionrow{padding-bottom: 10px} 
.cdd-details-rhs .pkgslist_additionrow:last-child{padding-bottom: 0}
.cart-settup-list ul li > label{ padding-bottom: 5px; color: #161616; font-family: 'Poppins', sans-serif;
text-transform: uppercase; }
.slick-slider .products_sidebar_content a{     padding: 10px 15px; }
.products_sidebar_inner .slick-slider{ display: none}
.additionals .instruction {
    padding-top: 20px;
}
.product-edit-half{display: flex; flex-wrap: wrap; justify-content: space-between}


.pop-whole-lhs-inner {
    text-align: center;
    max-width: 318px;
}

.pop-whole {
    position: relative
}

.login-popup, .signup-popup, .forgot-password-popup{
    max-width: 980px;
    margin: 20px auto;
    width: 100%;
    background: #fff;
    position: relative;
}
.full-login-new{
    background: #f6f6f6;
}
.full-login-new-header{ background: #DB203B; padding: 20px 30px; text-align: center;box-shadow:0px 4px 18px 0px rgba(2, 2, 2, 0.04); color: #fff;}
.full-login-new-header h3 {
    font-size: 31px;
    margin-bottom: 0px;
    line-height: 1;
    color: #fff;
}

.full-login-new-header p {
    margin-bottom: 0;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: normal;
    padding-top: 5px
}
.full-login-new-body {
    max-width: 500px;
    margin: 0 auto;
    width: 100%;
    padding: 50px 0;
    text-align: center;
}

.login_pop_sub button{width: 100%; background: #241e21;    border: 1px solid #cbcbcb;
    box-shadow: 0px 7px 9px 0px rgba(0, 0, 0, 0.21); }
.login_pop_sub button:hover{ background: #DB203B; }

.checkout-delivery-details{display: flex; justify-content: space-between;flex-wrap: wrap;}
.checkout-delivery-lhs {
    width: 49%;
}
.checkout-delivery-rhs {
    width: 49%;
    text-align: right;
}
.checkout-delivery-rhs .chk_hea, .checkout-delivery-lhs .chk_hea{ display: block}
.checkout-delivery-details p{ margin-bottom:0px; }

.checkout-heading {
    background: #2f2f2e;
    color: #fff;
    font-size: 18px;
    padding:10px 10px;
    position: relative;
    font-weight: 600;
}
.controls.two-links a{   color: #1d1d1d; text-transform: uppercase;font-size: 12px; display: block;font-family: 'Poppins', sans-serif;  font-weight: 600;}
.controls.two-links { display: flex; justify-content: center; align-items: center }

.controls.two-links a:hover{ color: #DB203B; }
span.spilter {
    width: 2px;
    height: 24px;
    display: block;
    margin: 0 12px;
    background: #d3d4d4;
}
.full-login-new-body .sign_reg{margin-top: 20px}
.full-login-new-body .sign_reg p{margin-bottom: 0}
.full-login-new-body .sign_reg a {color: #000;text-decoration: underline;}

.forgot-password-popup .mfp-close, .login-popup .mfp-close, .signup-popup .mfp-close {
    background: #000;
    right: 5px;
    top: 5px;
}
.login-popup .resend_link {
    padding: 10px 0px;
    text-align: right;
}

.login-popup .resend_link a {
    color: #0dad85;
    font-weight: 600;
}

.popup-footer .btn {
    display: block;
    max-width: 271px;
    margin: 0 auto;
    background: #2d4486;
    text-transform: inherit;
    border-radius: 7px;
    border: 0;
}

.popup-footer .btn:hover {
    background: #333;
    color: #fff;
}

.popup-footer .btn i {
    padding-right: 15px;
}

.popup-footer .guest_btn {
    background: #70afa7;
    color: #fff;
}

.popup-footer .guest_btn:hover,
.popup-footer .guest_btn:focus {
    background: #333333;
    color: #fff;
}

.pop-whole:after {
    display: table;
    clear: both;
    content: ""
}

.inside-popup-rhs {
    float: right;
    max-width: 390px;
    background: #fff;
    padding: 45px 35px 0px 35px;
    position: relative;
    z-index: 9;
    margin: 0 35px 0 0;
    width: 100%;
    -webkit-transition: 0.4s linear all;
    transition: 0.4s linear all;
}

.signup-popup .inside-popup-rhs,
.signup-corparateone-popup .inside-popup-rhs,
.signup-corparatetwo-popup .inside-popup-rhs, .forgot-password-popup .inside-popup-rhs{
    padding: 45px 30px 30px 30px;
}

.signup-corparatetwo-popup .inside-popup-rhs,
.guest-popup .inside-popup-rhs {
    padding: 60px 35px 40px 35spx;
}

.pop-whole-lhs {
    position: absolute;
    background: #769e90;
    padding: 30px 35px 30px 35px;
    left: 0;
    right: 0;
    z-index: 2;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.forgot-password-popup .pop-whole-lhs {
    padding: 33px 50px 33px 35px;
}

.forgot-password-popup .pop-whole-lhs-inner {
    text-align: center;
    max-width: 328px;
}
.signup-popup .pop-whole-lhs, .guest-popup .pop-whole-lhs{  padding: 60px 30px 60px 20px;}
.signup-popup .pop-whole-lhs-inner, .guest-popup .pop-whole-lhs-inner {  max-width: 385px;}

.pop-whole-lhs h3 {
    font-size: 30px;
    margin: 0 0 5px 0;
    line-height: 1;
    color: #fff;
    text-transform: uppercase;
}

#signup-popup .pop-whole-lhs h3{
    margin:5px 0;
}

.pop-whole-lhs p {
    font-size: 14px;
    color: #fff;
    margin: 0;
}

.loginsep-popup .mfp-close:before,
.loginsep-popup .mfp-close:after {
    height: 20px;
    background-color: #000
}

.loginsep-popup .mfp-close {
    right: 20px;
    top: -6px
}
.login_submit, .signup_submit, .forgotpassword-cls{ background: #222222; display: block; width: 100% }

.popup-header {
    padding: 15px 0 24px 0;
}

.popup-header h4 {
    font-size: 19px;
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #000;
    text-transform: uppercase;
}

.popup-header h4 img {
    width: 240px;
    margin: 0 auto;
}

.popup-header p {
    font-size: 13px;
    margin: 0px;
}

.popup-body input {
    margin: 0 0 8px;
}
.popup-body input[type=checkbox]{margin: 3px 5px 0 0; display: inline-block;}

.two-links {
    padding: 10px 0 5px 0;
   text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
}

.two-links a {
    font-size: 14px;
    display: block;
    position: relative;
    color: #1d1d1d;
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    text-align: center;padding-left: 3px;
}

.two-links a:hover {
    color:#ec6851
}

.spilter {
    width: 2px;
    height: 20px;
    background: #d4d4d4;
    display: inline-block;
    content: "";
    vertical-align: top;
    margin: 1px 2px 0;
    position: relative;
    left: 0;
}

.or-seperator {
    position: relative;
    padding: 25px 0 20px 0;
    line-height: 1;
    margin: 0 auto;
    text-align: center;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 300;
}

.or-seperator span {
    padding: 0 10px;
    background: #f6f6f6;
    display: inline-block;
    z-index: 1;
    position: relative;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

.or-seperator:after {
    height: 2px;
    width: 100%;
    content: "";
    display: block;
    background: #e1e1e1;
    position: absolute;
    top: 55%;
    left: 0
}

.login_pop_sub .btn {
    width: 100%;
}

.guest_chk_out {
    margin-left: -55px;
    margin-right: -55px;
}

.guest_chk_out .btn {
    width: 100%;
    border: 0;
    margin-bottom: 0;
    color: #f1e0c6;
}

.guest_chk_out .btn b {
    color: #fff;
}

.guest_chk_out .btn:hover {
    color: #fff;
}

.popup-body h4 {
    text-transform: uppercase;
    margin: 0 0 12px 0px;
    font-weight: 700;
    font-size: 16px;
    color: #0e0e0e;
    font-family: 'Poppins', sans-serif;
}

.popup-body h4.your_lgn_spacing {
    margin-top: 19px;
}

.popup-body .sign_reg {
    margin: 0;
    display: inline-block;
}

.popup-body .sign_reg a {
    width: 100%;
    color: #595656;
}

.popup-body .sign_reg a:hover {
    /*background: #333;*/
    /*color: #fff;*/
    background: inherit;
    color: #DB203B;
}

.popup-body .custom_checkbox span:before {
    width: 16px;
    height: 16px;
    border: 2px solid #e4e4e4;
    top: 2px;
    border-radius: 0px;
}

.popup-body .sign_reg p {
    margin-bottom: 9px;
    color: #0e0e0e;
    line-height: 18px;
    font-weight: 300;
    font-size: 13px;
}



.popup-body .sign_reg a.sign_reg_uline {
    text-decoration: underline;
    color: #0e0e0e;
}

.popup-body .sign_reg a.sign_reg_uline:hover {
    color: #DB203B;
    background: inherit;
}

.row.pop-two-form {
    margin-left: -5px;
    margin-right: -5px;
    margin-bottom: 20px;
}

.row.pop-two-form [class*="col-"] {
    padding-left: 5px;
    padding-right: 5px;
}

.back_click {
    position: absolute;
    left: 19px;
    top: 13px;
}

.back_click a {
    color: #c3c3c3;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    position: relative;
    display: inline-block;
    padding-left: 24px;
}

.back_click a:hover {
    color: #333;
}

.back_click a::after {
    content: "";
    position: absolute;
    left: 0;
    width: 11px;
    height: 19px;
    background: url(../images/spirite_whole.png) no-repeat;
    background-position: -344px -67px;
    top: 2px;
}

.back_click a:hover::after {
    background-position: -375px -67px;
}

.changepw_popup {
    max-width: 768px;
}

.home-banner .slick-dots{     
    padding: 0;
    margin: 0;
    list-style: none;
    position: absolute;
    text-align: center;
}
 .home-banner .slick-dots li button{
    padding: 0;
    margin: 0 5px;
    border-radius: 50%;
    overflow: hidden;
    text-indent: -9999px;
    background: #ec6851;
}

.home-banner .slick-dots{
    left: 50%;
    transform: translateX(-50%);
    bottom: 40px;
}

.home-banner .slick-dots li button{
    height: 11px;
    width: 11px;
    border: 1px solid #fff;
    background: transparent;
}

.home-banner .slick-dots li.slick-active button{  background: #fff;  }

.home-banner .slick-dots li{display: inline-block}

.bann-container{z-index: 3;position: relative;}
.white-home .slick-slider, .white-home .slick-list, .white-home .slick-track, .white-home .slick-track .slick-slide div{height: 100%;}
.white-home .slick-arrow{display: none !important;}

.featuredpro-section {
    position: relative;
    z-index: 2;
}

 .featured-products-section .products-list-ulmain{
    display: flex;
    flex-wrap: wrap;
    padding: 0;
}
.featured-products-section .products-list-ulmain li{
    text-align: center;
    background: #fff;
    -webkit-transition: all .4s linear;
    transition: all .4s linear;
    display: flex;
    flex-direction: column;
    border: 2px solid #f3f3f3;
    height: 100%;
}

.featured-products-section .owl-carousel .owl-stage{
    display: flex;
}

.add-cart {
    padding-bottom: 50px;
}

.product-title h3 {
    font-size: 18px;
    margin-bottom: 15px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    text-transform: inherit;
}

.myacc_filter .nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover,
.myacc_filter .nav-tabs > li > a:hover {
    background-color: #fff;
    border: 0;
    border-bottom-color: transparent;
    text-transform: uppercase;
    color: #000;
    font-weight: 600;
}

.myacc_filter .nav.nav-tabs.text-center{
    border-bottom: 5px solid #e3e3e3;
}

.faq-page .myacc_filter .nav.nav-tabs.text-center{
    border-bottom: 0px;
}

.myacc_filter .nav.nav-tabs.text-center > li.active:before, .nav.nav-tabs.text-center > li:hover:before {
    visibility: visible;
    transform: scaleX(1);
}

.myacc_filter .nav.nav-tabs.text-center > li:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    bottom: -4px;
    left: 0;
    background: #000;
    visibility: hidden;
    transform: scaleX(0);
    transition: .25s linear;
}

.myacc_filter .tab-content {
    padding-top: 25px;
}

.myacc_filter .nav-tabs > li > a{
    text-transform: uppercase;
    color: #000;
    background-color: #fff;
    border: 0;
    border-bottom-color: transparent;
    font-weight: 600;
}

.modal .inn-product-popup .inn-product-img-bg img{
    width: 100%;
}

.progress_bar {
    background: #f0ce46;
}

.progress_bar_low{
    background: #29d492;
}

.indication_progress{
    background: #1c1c1c;
}

.progress_bar_div .help-block{
    color: #000;
    text-transform: uppercase;
    padding-top: 0px;
    font-weight: 500;
    text-align: center;
}

.cart_footer .progress_bar_div .indication{
    width: 100%;
}

.page404-main-div img{
    height: 300px;
}

.outlet-lst-page{ padding: 20px 0 40px 0; text-align: center }
.outlet-alt-class .order_delivery_item li{ width: 16%; background: #fff; padding: 30px 20px 70px; position: relative; overflow: hidden; z-index:1;}
.outlet-alt-class .order_delivery_col .button {
    margin-bottom: 0;
    max-width: 170px;
    margin: 0 auto;
    position: absolute;
    left: 10px;
    right: 10px;
    bottom: 20px;
}
.outlet-alt-class .order_delivery_item h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
}

.outlet-lst-page h2 {
    font-size: 33px;
    letter-spacing: 5px;
}

.outlet-lst-page h2 span {
    font-weight: bold;
}

.outlet-alt-class .order_delivery_item li:after {
    border-radius: 50%;
    background: rgba(247,213,126,1);
    height: 100%;
    position: absolute;
    width: 100%;
    left: -50%;
    top: -50%;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    content: "";
    z-index: -1;
}
.outlet-alt-class .order_delivery_item li:hover:after {
    -webkit-transform: scale(3);
    -moz-transform: scale(3);
    -ms-transform: scale(3);
    -o-transform: scale(3);
    transform: scale(3);
}

.img-list li:before {
    transition: all .5s ease;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #333;
    transform: scale(0);
}
.img-list li:after {
    transition: all .6s ease .2s;
    content: "";
    position: absolute;
    top: 8px;
    left: 8px;
    right: 8px;
    bottom: 8px;
    border: 1px solid #aaa;
    background: #000;
    opacity: 0;
    transform: scale(0);
}
.img-list li:hover:before {
    opacity: .5;
    transform: scale(1);
}
.slick-slide img{width: 100%;}
.slick-slide > div{width: 100%;}

.outletList-main-div .slick-slider .slick-prev {
    left: 0;
}
.outletList-main-div .slick-slider .slick-next {
    right: 0;
}
.outletList-main-div .slick-slider{ line-height: 0}
.outletList-main-div .slick-arrow {
    position: absolute;
    top: 50%;
    margin-top: -23px;
    outline: 0;
    width: 48px;
    height: 48px;
    text-indent: -9999px;
    z-index: 9;
    border: none;
    padding: 0;
    margin-bottom: 0;
    background: #060801;
    border-radius: 0;
}
.outletList-main-div .slick-slider .slick-next:after, .outletList-main-div .slick-slider .slick-prev:before {
    background:url(../images/banner-arrow.png) no-repeat ;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    width: 24px;
    height: 11px;
    content: "";
    margin: -5px auto auto;
}
.outletList-main-div .slick-slider .slick-next:after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}
#ordernowpopup .header-ordernow-single-img{ width: 96px; height: 96px; overflow: hidden; border-radius: 50%; border: 2px solid #000; align-items:center; justify-content: center;margin: 0 auto}
#ordernowpopup .header-ordernow-single-img img{max-height: 54px;}
#ordernowpopup .order_delivery_item li a{ padding: 30px 10px 30px; }
#ordernowpopup, #order-popup{background: #f6f6f6}
#ordernowpopup ul li:last-child .header-ordernow-single-img img{max-height: 44px;}
#ordernowpopup .order_delivery_item li a:hover .header-ordernow-single-img, #ordernowpopup .order_delivery_item li a.active .header-ordernow-single-img{border-color: #fff;}

.exist-cart {
    padding: 12px 12px 12px;
}
.exist-cart:hover{background: #525252;}
img.aftercart {
    width: 32px;
}
.cafe-category-rhs .productlist-main-div .products-list-ulmain {
    flex-flow: column;
}
.cafe-category-rhs {
    width: calc( 100% - 330px );
}

.cafe-category-lhs {
    width: 280px;
}

.cafe-category-rhs .productlist-main-div .products-list-ulmain > li{ width: 100%; margin: 0 0 20px 0;}
.cafe-category-rhs .productlist-main-div .products-list-ulmain > li > div{display: flex; flex-wrap: wrap;}
.cafe-category-rhs .products-image-div{height: auto; width: 50%; display: block  }
.cafe-category-rhs .products-image-div .slick-slider{height: 250px;}
.cafe-category-rhs .products-image-div .slick-list{ top: 50%; transform: translateY(-50%);}
.cafe-category-rhs .product-info-div{height: auto; width: 50%; text-align: left; padding: 20px 20px 20px 20px;}
.cafe-category-rhs  .products-ordernow-action .product-price{ display: block; margin: 0 0 20px 0; }
.cafe-category-rhs  .products-ordernow-action{position: inherit; margin: 20px 0 0px 0; }
.category-nav-list, .cafe-lhs-filter{ border: 2px solid #f3f3f3; box-shadow:0px 5px 16px 0px rgba(0, 0, 0, 0.07);}
.category-nav-list a{ font-size: 16px; font-weight: 600; display: block; padding: 15px 30px 15px 20px; text-transform: capitalize; position: relative}
.category-nav-list a:after{    content: "\f105";
    font-family: FontAwesome;
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 22px;
    line-height: 1;}
.category-nav-list .active a, .category-nav-list a:hover{ background: #DB203B; color: #fff }
.cafe-category-rhs .productlist-main-div .products-list-ulmain > li .product-title h3{     font-size: 19px; font-family: 'Poppins', sans-serif; font-weight: bold; margin-bottom: 20px;}
.cafe-category-rhs .productlist-main-div .products-list-ulmain > li .product-price h3{ font-size: 20px; font-weight: 600; color: #000;}
.pantry-category-parent{width: 100%;}
.cafe-category-rhs .product-tags-list ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0 20px 0;
    margin: 0;
    list-style: none;
}

.cafe-category-rhs .product-tags-list ul li {
    display: flex;
    align-items: center;
    margin: 0 20px 10px 0;
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
}

.cafe-category-rhs .product-tags-list ul li img { margin-right: 5px;}

.cafe-product-description p{margin: 0; height: 65px; min-height: 65px;overflow: hidden;}
.cafe-lhs-filter ul{width: 100%; position: relative}
.cafe-lhs-filter ul li a{font-size: 14px;}
.cafe-lhs-filter{ margin: 20px 0;padding: 15px 20px 30px 20px}
.cafe-lhs-filter .filter-act{ letter-spacing: 0; text-transform: capitalize; border-bottom: 1px solid #e3e3e4; font-size: 16px; padding-bottom: 10px; font-weight: 600; margin-top: 15px; }
.filter-label{color: #606060}
.product-details-lhs {
    width: 50%;
    padding-right: 15px;
    position: relative;
}

.product-details-rhs {
    width: 50%;
    padding-left: 15px;
}
.product-details-top-info .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.product-details-img .slick-arrow {
    display: none;
}

.product-details-img .slick-dots, .cafe-category-rhs .products-image-div .slick-dots{
    padding: 0;
    margin: 0;
    list-style: none;
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
}

.product-details-img .slick-dots li button, .cafe-category-rhs .products-image-div .slick-dots li button{
    padding: 0;
    width: 11px;
    height: 11px;
    font-size: 0;
    overflow: hidden;
    margin: 0;
    background: #fff;
    border: 1px solid #000;
}

.product-details-img .slick-dots li, .cafe-category-rhs .products-image-div .slick-dots li{
    display: inline-block;
    margin: 0 5px;
}

.product-details-img .slick-dots li.slick-active button, .cafe-category-rhs .products-image-div .slick-dots li.slick-active button{
    background: #000;
}

.postal_error {
    position: relative;
    top: -8px;
}

.postal_error span.error {
    font-size: 12px;
}
.no-product {
    text-align: center;
}

.no-product .container {
       justify-content: center;
    flex-flow: column wrap;
    align-items: center;
}

.no-product .container h2{
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    margin-bottom: 10px;
}
.mobile-order-now {
   display: none;
    text-align: center;
}
.mobile-order-lhs{ width: 50%; /*background: #e5e5e5;*/background: #fff; display: flex; align-items: center; padding: 10px; position: relative; justify-content: center}
.mobile-order-lhs a{ color: #000; font-weight: bold;padding: 0 30px 0 0; position: relative;}
.mobile-order-lhs a:hover{ color: #c81d35; }
.mobile-order-rhs{width: 50%; height: 100%;}
.mobile-order-lhs a:last-child:after {
    height: 15px;
    content: "";
    width: 2px;
    display: inline-flex;
    background: #000;
    position: absolute;
    left: -15px;
    top: 4px;
}
.mobile-order-lhs a:last-child{ padding-right: 0 }
.mobile-order-rhs a{   
    padding: 10px;
    display: block;
    font-size: 16px;
    text-transform: capitalize;
    position: relative;
    text-align: center;
    background: #c81d35;
    color: #fff;
    font-weight: 600;}

.mobile-pantry-search{display: none}
.pantry-work-in-progress .product-cconmid-rhs {
    width: 100%;
    padding: 0;
}

.pantry-work-in-progress .product-cconmid-rhs {
    text-align: center;
    font-size: 23px;
    font-weight: 700;
}

.pantry-work-in-progress .product-cconmid-rhs .owl-item {
    width: 100%;
    margin: 0;
}

.pantry-work-in-progress .product-cconmid-rhs .owl-stage {
    width: 100%;
}
.pantry-comming-soon {
    padding: 100px 0;
}
/***************************** STARTED CSS **********************************/

.birthday-info-div{  height: 100px;}
.order-details-with-clear .hclear_cart{
    color: #fff;
    margin: 0;
    font-size: 13px;
    letter-spacing: .3px;
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: underline;
}

.cart_body .hclear_cart:hover {
    color: #DB203B;
}

.checkout-common-div{
    border-top: 2px solid #f3f3f3;
}

.heading-welcome{
    font-size: 16px;
    font-weight: 550;
    margin-bottom: 5px;
}

.birthday-info-div{
    border-top: 2px solid #f4f4f4;
}

.birthday-info-top .birthday-info-inner-heading img  {
    margin-left: 10px;
}

.checkout-main-div .birthday-info-div .birthday-info-top h3>div span {
    color: #282828;
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    font-weight: 550;
    font-size: 16px;
    text-transform: capitalize;
}

.checkout-main-div .birthday-info-div .birthday-info-top h3{
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 0 20px;
    font-size: 26px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

.checkout-main-div .birthday-info-div .birthday-info-top h3>div {
    text-align: center;
}

.custom_checkbox input {
    margin: 0;
    position: absolute;
    cursor: pointer;
}

.checkout-main-div .custom_center_alertcls.alert_fixed a {
    right: 10px;
    top: 16px;
    position: absolute
}
/******************************************* FAQ STARTING *************************************/

.faq-page h3{ text-align: center; padding: 38px 0; margin: 0; letter-spacing: 5.6px; }

.container.cms-content{border-top: 1px solid #eaeaea;}

.faq-page .accordion{ padding: 25px 0 68px;}

.faq-page .accordion .accordion-item .title{
    background: #fff;
    margin: 0;
    padding: 18px 0 8px;
    border-bottom: 1px solid #eaeaea;
    color: #000000;
    border-radius: 0;
    font-family: 'Poppins', sans-serif;
    /*text-transform: capitalize;*/
    letter-spacing: normal;
    font-size: 17px;
}

.faq-page .accordion .accordion-item .title{
    border-radius: 0;
    display: flex;
    justify-content: space-between;
    line-height: 26px;
    padding-right: 42px;
}

.faq-page .accordion .accordion-item.active .title{
    background: transparent;
    border-radius: 0;
}

.faq-page .accordion .accordion-item .panel{
    border: 0;
    border-bottom: 0;
    margin: 0
}

.faq-page .accordion .accordion-item .panel p{
    padding: 15px 0;
    margin: 0;
    color: #8e8e8e;
}

.faq-page .accordion .accordion-item.active .panel p{
    border: 0;
}

.faq-page .accordion .accordion-item .panel p a{
    color: #8e8e8e;
}

.faq-page .accordion .accordion-item .panel p a:hover{
    color: #DB203B;
}

.faq-page .cms-content .accordion .accordion-item .title::before,
.faq-page .cms-content .accordion .accordion-item .title::after {
    background: #5e5e5e;
    content: "";
    display: block;
    height: 2px;
    margin-top: -2px;
    pointer-events: none;
    position: absolute;
    right: 20px;
    top: 50%;
    transition: transform 0.25s ease-in-out 0s;
    -webkit-transition: -webkit-transform 0.25s ease-in-out 0s;
    width: 14px;
    z-index: 2;
}

.faq-page .cms-content .accordion .accordion-item .title::before {
    content: "";
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
}

.faq-page .cms-content .accordion .accordion-item.active .title::before {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
}

.faq-page .cms-content .accordion .accordion-item.active .title::after {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
}

.faq-page .cms-content .accordion .accordion-item .title::after {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
}
/*********************************** EVENTS ***************************************************/

.container.cms-content.event-content {
    border-top: 0;
}

.event-page{
    padding: 60px 0;
}

.event-page h3{
    text-align: center;
    letter-spacing: 5.8px;
    font-weight: 700;
    font-size: 32px;
}

.event-container{
    box-shadow: 2px 0px 4px 0px rgb(0 0 0 / 4%);
    -webkit-transition: all .4s linear;
    transition: all .4s linear;
    padding: 65px 72px 65px;
    display: flex;
    justify-content: space-between;
}

.event-container .event-menu-left{
    width: 47.3333%;
}

.event-container .event-menu-left .event-top-heading h3, .event-container .event-menu-right .event-top-heading h3{
    text-transform: uppercase;
    letter-spacing: normal;
     font-family: 'Poppins', sans-serif;
     font-size: 22px;
}

.event-menu-left .event-single-menu .product-title-maindiv .product-title h5{
    font-family: 'Poppins', sans-serif;
    text-transform: capitalize;
}

.event-container .event-menu-right form select{
    margin: 0!important;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #cbcbcb;
    color: #939393;
}
.event-container .event-menu-left .event-single-menu{
    text-align: center;
    background: #fff;
    box-shadow: 0 3px 28px 0 rgb(1 1 1 / 8%);
    -webkit-transition: all .4s linear;
    transition: all .4s linear;
    position: relative;
    z-index: 2;
    overflow: hidden;
    margin-top: 12px;
}

.event-container .event-menu-left .event-single-menu .event-product-item .event-products-image-div{
    /*height: 330px;*/
    width: 100%;
    display: flex;
    --webkit-box-pack: center;
    justify-content: center;
    align-items: flex-end;
}

.event-container .event-menu-left .event-single-menu .event-product-item .event-products-image-div img{
    width: 100%;
    height: 100%;
}

.event-container .event-menu-left .event-single-menu .event-product-item .product-info-div {
    padding: 30px 80px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    height: calc(100% - 330px);
    background: #fff;
    -webkit-transition: all .3s linear;
    transition: all .3s linear;
}

.event-menu-left form select{
    margin: 0!important;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #cbcbcb;
    color: #939393;
}

.event-menu-right{
    width: 47.3333%;
}

.event-container .event-menu-right  .form-group {
    margin: 12px 0 10px;
}
    
.event-menu-right  form .text-box {
    width: 100%;
    margin-top: 10px;
}

.event-menu-right  form .text-box textarea {
    width: 100%;
    height: 230px;
    padding: 15px;
}

.event-menu-right  form .text-box textarea {
    resize: none;
    border: 1px solid #cbcbcb;
    margin: 0 0 0px;
    background-color: #fff;
    border-radius: 5px;
    font-size: 15px;
    color: #939393;;
}

.event-menu-right  .event-form-right .form-terms .custom_checkbox.custom_checkbox_content{
    position: relative;
    display: inline-block;
}

.event-menu-right  .event-form-right .form-terms .custom_checkbox.custom_checkbox_content span {
    width: 16px;
    height: 16px;
    vertical-align: middle;
    margin-right: 5px;
}

.event-menu-right  .event-form-right .form-terms .custom_checkbox.custom_checkbox_content span {
    padding: 0 0 0 20px;
    pointer-events: none;
    color: #3d3d3d;
}

.event-menu-right  form .form-group .focus-out label{
    top: 10px;
    color: #939393;
}

.event-menu-right  form .form-group .focused label{
    top: -7px;
}

.event-menu-right  form .form-group .custom_checkbox .sign_reg {
    display: inline-block;
    vertical-align: middle;
}

.event-menu-right  form .form-group .custom_checkbox .sign_reg p {
    margin-bottom: 0;
    font-size: 14px;
}

.event-menu-right  form .form-group .custom_checkbox .sign_reg a {
    color: #000;
    text-decoration: underline;
}

.event-menu-right  form .form-group .custom_checkbox .sign_reg p strong{
    font-weight: 600;
    text-transform: capitalize;
    font-family: 'Poppins', sans-serif;
}

.event-menu-right input[type=checkbox]:not(.switch):checked {
    background: #DB203B;
}

.event-menu-right input[type="checkbox"] {
    position: absolute;
    left: 0;
    top: 3px;
    background: #000;
    border-color: #f5ce6d;
}

.event-menu-right .custom_checkbox.custom_checkbox_content input[type="checkbox"]{
    position: relative;
    left: 20px;
}

.event-menu-right  form .form-terms{
    text-align: center;
    margin: 10px 0 0;
}

.event-menu-right  form .form-group .login_pop_sub .signup_submit {
    background: #181818;
}

.event-menu-right  form .form-group .login_pop_sub .btn_minwid {
    min-width: 170px;
    padding-left: 25px;
    padding-right: 25px;
}

.event-menu-right  form .form-group .btn {
    border-radius: 6px;
    padding: 15px 15px 14px;
    height: auto;
    font-size: 16px;
    -webkit-transition: all .4s;
    transition: all .4s;
    text-transform: uppercase;
    margin-bottom: 4px;
    position: relative;
    color: #fff;
    font-weight: 400;
    display: inline-block;
    margin: 0px 0 10px;
    appearance: none!important;
    cursor: pointer;
    line-height: 1;
    text-align: center;
    overflow: hidden;
    z-index: 2;
    font-weight: 600;
}

.event-menu-right form .form-group .login_pop_sub .signup_submit:hover {
    color: #000;
    background: #DB203B;
}

.event-single-menu .products-ordernow-action{
    position: inherit;
}

.event-product-item  .products-ordernow-action .button{
    padding: 15px 15px 14px;
}

.event-menu-right  form .form-group.form-terms{
    margin: 15px 0 15px;
}

.event-form-box{
    position: relative;
}

.event-menu-right  form .form-group .focus-out .form-control{
    padding: 8px 10px;
    height: 40px;
    border-radius: 5px;
    color: #939393;
}

.event-container .event-menu-right form select{
    padding: 8px 10px;
    height: 40px;
    border-radius: 5px;
}

.event-menu-left form select{
    padding: 8px 10px;
    height: 40px;
}

.event-form-right{
    position: relative;
}

.event-menu-right form .form-group .custom_checkbox .sign_reg a:hover{
    color: #DB203B;
}

.event-menu-right  .event-form-right .form-terms .custom_checkbox.custom_checkbox_content input[type=checkbox]:not(.switch):checked {
    background: #DB203B;
}

.event-menu-right  .event-form-right .form-terms .custom_checkbox.custom_checkbox_content input[type="checkbox"] {
    position: absolute;
    left: 0;
    top: 3px;
    background: #fff;
    border-color: #f5ce6d;
}
/********************** owl slider *******************/
.detail-pg-slider .owl-dots {
    text-align: center;
    display: none;
    justify-content: center;
    padding: 40px 0 0 0;
}
.detail-pg-slider .owl-dots .owl-dot{margin: 0 5px 0}

.event-single-menu .event-products-image-div .owl-dots .owl-dot span, .detail-pg-slider .owl-dots .owl-dot span {
    display: block;
    width: 11px;
    height: 11px;
    background: transparent;
    border-radius: 50%;
    border: 1px solid #000;
}
.detail-pg-slider .owl-dot.active span{ background: #000; }

.event-single-menu .event-products-image-div .owl-dots {
    text-align: center;
    display: flex;
    justify-content: center;
    margin: 0;
    position: absolute;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
}
.event-single-menu .event-products-image-div .owl-dots .owl-dot {
    margin: 0 3px;
}

.event-single-menu .event-products-image-div .owl-dots .owl-dot.active span {
    background: #000;
}

/******************** MYACCOUNT START *****************************/

.filter_tabsec .main_tabin .account_sec .accmenu_sec{
    position: -webkit-sticky;
    position: sticky;
    top: 95px;
    background: transparent;
}

.filter_tabsec .main_tabin .account_sec .accmenu_sec .setting_menu_list {
    padding: 0;
    list-style: none;
    margin: 0;
    box-shadow: none;
    position: inherit;
}

.main_tabsec_inner .filter_tabsec .tab_mobtrigger{
    text-align: center;
    font-size: 32px;
    text-transform: uppercase;
    margin-bottom: 0;
}

.main_tabsec_inner .filter_tabsec  .tab_mobtrigger_para{
    text-align: center;
    text-transform: capitalize;
    color: #000;
    font-weight: 550;
}

.main_tabsec_inner .filter_tabsec .tab_mobrow.main_tabin{
    padding-top: 20px;
}

.filter_tabsec .main_tabin .account_sec .accmenu_sec .setting_menu_list > ul > li > a{
    background: #f4f3f3;
    border-bottom: 0;
    text-transform: capitalize;
    color: #000;
}

.filter_tabsec .main_tabin .account_sec .accmenu_sec .setting_menu_list > ul li a:hover {
    background: #0d0b06;
    color: #fff;
}

.filter_tabsec .main_tabin .account_sec .accmenu_sec .setting_menu_list > ul li.active a{
    color: #fff;
    background: #0d0b06;
}


.myacc-main-div .innersection_wrap.myadmin_wrap{
        padding: 0px 0 0 0
}

.cart_header_title {
    position: relative;
    text-align: left;
    height: 45px;
}

.cart_header_title .cart_innr_header {
    position: relative;
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;
}

.cart_header_title p{
    margin-bottom: 0;
    max-width: 110px;
    text-align: center;
    font-weight: 550;
}

.cart_header_title .cart_innr_header h4 {
    position: relative;
    font-size: 30px;
    font-weight: 700;
    right: -10px;
    padding-right: 10px;
    margin: inherit;
    flex-flow: column;
}

.cart_header_title .cart_innr_header img {
    position: relative;
    left: 20px;
    top: -15px;
    width: 40px;
    height: 40px;
}

.box_in.accsec_right .acc-inform .form-group .row .col-md-6.acc-birthday{
    margin-right: 0;
    float: right;
}

.box_in.accsec_right .acc-inform .form-group .row .col-md-6.acc-gender{
    margin-top: 11px;
}

.box_in.accsec_right .acc-inform .form-group .row .col-md-6.acc-gender .css-10nd86i .css-vj8t7z .css-1hwfws3 .css-xp4uvy{
    top: 45%;
}

.box_in.accsec_right .acc-inform .form-group .row .col-md-6.acc-gender .css-10nd86i .css-vj8t7z .css-1wy0on6 .css-1ep9fjw{
    padding: 4px 8px 8px;
}

.box_in.accsec_right .acc-inform .form-group .row .col-md-6.acc-gender .css-10nd86i .css-vj8t7z .css-1wy0on6 .css-d8oujb{
    margin-top: 0;
}

.box_in.accsec_right .acc-inform .form-group .row .col-md-6{
    padding: 0;
}

.box_in.accsec_right .acc-inform .form-group .row .acc-unit-row{
    margin: 0;
    display: flex;
}

.box_in.accsec_right .acc-inform .form-group .row .acc-unit-row h3{
    margin-bottom: 0;
    font-weight: 600;
    font-size: 18px;
    margin-right: 15px;
    line-height: 40px;
}

.box_in.accsec_right .acc-inform .form-group .row .acc-unit-row .acc-unit{
    width: 25%;
}

.box_in.accsec_right .acc-inform .form-group .row .acc-unit-row .acc-unit .focus-out label{
    left: 8px;
}
.css-15k3avv{ z-index: 5 }

.profile-info-div .form-group .row{
    margin-left: 0;
    margin-right: 0;
}

.profile-info-div .form-group input, .address-info-div input, .other-add .other-add-body{border-radius: 0px }

.address-info-div .form-group .row{
    margin-left: 0;
    margin-right: 0;
}

.changepw_popup .pouup_in .title1.text-center{
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

.common-inner-banner p{
    margin: 0;
    font-size: 32px; 
    text-transform: uppercase;line-height: 1; 
    position: absolute; 
    top: 30%; 
    color: #000;
    letter-spacing: 1px;
    width: 100%; 
    font-weight: bold;
}

.common-inner-banner .chkout-para{
    font-size: 20px; 
    text-transform: inherit;  
    top: 60%;
    color: #000;
    font-weight: 500;
}

.common-inner-banner span{  font-family: 'Poppins', sans-serif;
font-size: 16px; position: absolute; top: 65%; font-weight: 550; line-height: 1;}

.pagesList-main-div .cms-page .cms-content h1,
.pagesList-main-div .cms-page .cms-content h4,
.pagesList-main-div .cms-page .cms-content h3{margin-bottom: 20px; font-size: 22px; text-transform: capitalize;}

.pagesList-main-div .cms-page .cms-content h3,
.pagesList-main-div .cms-page .cms-content h1 span strong,
.pagesList-main-div .cms-page .cms-content h4 span strong{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

.pagesList-main-div .cms-page .cms-content p,
.pagesList-main-div .cms-page .cms-content p strong{
    font-family: 'Poppins', sans-serif;
    margin-bottom: 8px;
}

.pagesList-main-div .cms-page .cms-content p{color: #535353}

.pagesList-main-div .cms-page .cms-content h3{text-transform: capitalize;}

.pagesList-main-div .cms-page .cms-content ul li{
    list-style: none;
    margin-bottom: 10px;
}

/******************** MYACCOUNT ORDERS START *****************************/

.myadmin_wrap .main_tabsec_inner .filter_tabsec .tab_mobtrigger.inner_tab_border{
    margin-bottom: 20px;
    margin-top: 15px;
}


.common-inner-banner .acc-para{
    position: inherit;
    font-size: 14px;
    line-height: 1.2;
    top: 0;
    font-weight: 550;
    margin-bottom: 20px;
    letter-spacing: normal;
    font-family: 'Poppins', sans-serif;
}

.common-inner-banner h3{
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 32px;
    margin-top: 35px;
    margin-bottom: 10px;
}

.main_tabsec_inner .myacc_filter .tab-content {
    padding-top: 0;
}

.main_tabsec_inner .filter_tabsec .tab_mobrow.main_tabin {
    padding-top: 0px;
}

/************* MY ORDER PROMOTION **************/

.myadmin_wrap .mainacc_menusec .mainacc_menuout{  margin-bottom: 10px;}
.myadmin_wrap .promo-form .applyBtn:hover{  background: #db203b; color: #fff;}
.mfp-inline-holder .redeem_popsec .redeem_bansec .promo-popup-image{  height: 400px;}
.mfp-inline-holder .redeem_popsec .redeem_bansec .promo-popup-image img{  width: 100%; height: 100%;}

.full-login-new-body .address-list-main .address_linfo .custom_radio input.address_chk { margin-right: 8px;}

/**************************** THANKYOU PAGE ************************/

.thankyou-main-div .innersection_wrap.tnk-you .mainacc_toptext h2{
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 32px;
}

/*.thankyou-main-div .innersection_wrap.tnk-you .mainacc_toptext p{
    font-weight: 550;
}*/

/*.thankyou-main-div .innersection_wrap.tnk-you .thank-order-detaildiv .tnk-detail h2{
    background: #DB203B;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}*/

.thankyou-main-div .innersection_wrap.tnk-you .thank-order-detaildiv .tnk-detail .tnk-order{
    background: #000;
}

.thankyou-main-div .innersection_wrap.tnk-you .thank-order-detaildiv .tnk-detail .tnk-order h3{
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

.thankyou-main-div .innersection_wrap.tnk-you .thank-order-detaildiv .tnk-detail .tnk-order p{
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-weight: 550;
}

.thankyou-main-div .tnk-you .thank-order-detaildiv .tnk-delivery{
    padding: 0;
}

.thankyou-main-div .tnk-you .thank-order-detaildiv .tnk-delivery .delivery-cart-div{
    padding: 30px 15px 0;
}

.thankyou-main-div .tnk-you .thank-order-detaildiv .tnk-delivery .delivery_total.delivery_datetime_div{
    padding: 30px 15px !important;
    margin: 0 !important;
}

.pickup-cart-div {
    padding: 30px 15px 0 !important;
    margin: 0 !important;
}

.pickup-thankfull{
    margin-bottom: 10px;
}

.pickup-thankfull p{
    margin-bottom: 5px;
}

.thankyou-main-div .tnk-you .thank-order-detaildiv .tnk-delivery .orderitem_body_div{
    padding: 0 15px;
}

.thankyou-main-div .tnk-you .thank-order-detaildiv .tnk-delivery .cart_footer.tnk_cart_footer{
    background: #f8f8f8;
    margin-top: 15px;
}

.thankyou-main-div .tnk-you .thank-order-detaildiv .tnk-delivery .tnk-chk-order{
    margin: 0;
}

/*.thankyou-main-div .tnk-you .thank-order-detaildiv .tnk-delivery .tnk-chk-order .button{
    border-radius: 0;
}*/

.innersection_wrap.tnk-you{
    margin: 35px 0 45px;
}

.thankyou-main-div .tnk-you .thank-order-detaildiv .tnk-delivery .tnk-chk-order .button:hover{
    background: #DB203B;
}

/**************************** PRODUCT DETAIL PAGE ************************/

.product-detail-page .prodetailinner-main-div .addonpro-slider-top .chk-addonpro-title h3{
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

.checkout-container{
    border-top: 2px solid #f4f4f4;
}

.thankyou-main-div.thankyou-main-recept .common-inner-blckdiv{
    display: none;
}

.inn-product-img-bg .slick-dots li, .cafe-category-rhs .inn-product-img-bg .slick-dots li {
    display: inline-block;
    margin: 0 5px;
}

.cafe-category-rhs .inn-product-img-bg .slick-dots li button {
    padding: 0;
    width: 11px;
    height: 11px;
    font-size: 0;
    overflow: hidden;
    margin: 0;
    background: #fff;
    border: 1px solid #000;
}

.cafe-category-rhs .inn-product-img-bg .slick-dots li.slick-active button {
    background: #000;
}

.cafe-category-rhs .inn-product-img-bg .slick-slide img {
    width: auto;
    margin: 0 auto;
}

.cafe-category-rhs .inn-product-img-bg .slick-slider {
    height: 400px;
}

.cafe-category-rhs .inn-product-img-bg .slick-dots {
    padding: 0;
    margin: 0;
    list-style: none;
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
}

.cafe-category-rhs .inn-product-img-bg .slick-list {
    top: 50%;
    transform: translateY(-50%);
}

.inn-product-popup#product_66605 .product-go-back a:before{
    display: none;
}

.product-details-lhs .inn-product-img-bg .product-details-thumbimg .slick-slide{border: 2px solid #eaeaea; margin-right: 15px; cursor: pointer;}

.product-details-lhs .inn-product-img-bg .product-details-thumbimg .slick-slide img{
    width: 100%;
    height: 100%;
}

.product-details-lhs .inn-product-img-bg .product-details-thumbimg .slick-slider .slick-list .slick-track{
    width: 100% !important;
}
.product-details-top-info{ padding: 0 0 40px 0 }

.product-details-top-info .product-details-lhs .prodet_top .product-details-img .slick-dots li button{
    display: none;
}

.cafe-category-rhs .products-list-ulmain .products-single-li .product-detail-page .prodetailinner-main-div {
    padding: 30px 20px 30px 20px;
    position: relative;
}

.cafe-category-rhs .products-list-ulmain .products-single-li .product-detail-page{
    width: 100%;
}

.cafe-category-rhs .products-list-ulmain .products-single-li .product-detail-page .inn-product-popup
.product-go-back a {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 2px;
    position: absolute;
    right: 10px;
    top: 10px;
    background: #000;
    border-radius: 6px;
    width: 24px;
    height: 24px;
    color: #fff;
    line-height: 25px;
    text-indent: 3px;
}

.cafe-category-rhs .products-list-ulmain .products-single-li .product-detail-page .inn-product-popup
.product-go-back a:hover {
    background: #DB203B;
    color: #000;
}

.cafe-category-rhs .products-list-ulmain .products-single-li .product-detail-page
 .inn-product-popup .product-go-back a:before{
    display: none;
 }

.inn-product-popup .addonpro-slider-top .detail-pg-slider .products-mayalso:hover,
.inn-product-popup .addonpro-slider-top .detail-pg-slider .products-mayalso.active{
    box-shadow: 0 1px 21px 0 rgb(0 0 0 / 8%);
 }

 .home-popup-head img{
    width:100px;
    margin-top: 25px;
}

/***************** PRIVACY POLICY ***********************************/

.pagesList-main-div .common-inner-blckdiv .privacy-heading{
    min-height: 130px;
    padding: 30px 10px 30px;
}

.pagesList-main-div .common-inner-blckdiv .privacy-heading p{
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    letter-spacing: 5.6px;
 }

 .privacy-inner-heading h3{
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: 550;
    margin-bottom: 25px;
    text-transform: initial;
 }

 .privacy-inner-heading p{
    margin-bottom: 30px;
 }

 .pagesList-main-div .privacy-inner-heading ul li{
    list-style: none;
    padding-bottom: 10px
 }
 .pagesList-main-div .privacy-inner-heading ul li:last-child, .pagesList-main-div .privacy-inner-heading ul{
    padding-bottom: 0;
 }

.pagesList-main-div .privacy-inner-heading p span {
    display: block;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 22px;
}

/******************* COMMON ****************************/

.popup_sec.delevery_popup .full-login-new-body .innervmid_in .address-list-cls.address-list-main {
    text-align: left;
}

.full-login-new-body .address-list-main .address_linfo .custom_radio input.address_chk{
    position: relative;
}

.full-login-new-body .address-list-main .address_linfo .custom_radio span{
    position: absolute;
}

.cart_left .notes-block {
    font-style: italic;
}

.checkout-total .cart_body { padding: 20px 20px; box-shadow: none;}
.checkout-right-body-section .product-details-parent .cart_row.product-details:last-child{ border-bottom: 0;}

.cart_row.promo-cart-row {  position: relative; padding-right: 15px;}
.cart_row.promo-cart-row { position: relative; padding-right: 22px;}

/*************************** MY ORDER SIDEBAR **************************/

.accmenu_sec .setting_menu_list ul li.active a {
    color: #fff;
    background: #0d0b06
}

.box_in.accsec_right .accsec_right{
    width: 100%;
}

.box_in.accsec_right {
    box-shadow: 0 3px 28px 0 rgb(1 1 1 / 14%);
    padding: 0;
    background: #fff;
}

/***************** CHECKOUT START *************************/

.checkout-container .check_out_fourcommon .chk-payment{
    box-shadow: 0 3px 28px 0 rgb(1 1 1 / 6%);
}

.cdd-details-rhs.fl .checkout-right-body-section .cart_footer .cart_row.promo-cart-row{
    padding-right: 0;
}

.cdd-details-rhs.fl .checkout-right-body-section .cart_footer .promo-cart-row .cart_remove {
    right: -20px;
    top: 3px;
}

.cart_remove:after, .cart_remove:before {
    position: absolute;
    top: 3px;
    right: 7px;
    content: "";
    height: 10px;
    width: 1.9px;
    background-color: #fff;
}

.cart_remove:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.cart_remove:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

/************************ MYACCOUNT DETAILS ******************************/

.tab_mobrow.main_tabin .accsec_right .account_sec .profile-info-div .form-group 
.re_select .css-10nd86i .css-vj8t7z{
    height: 40px;
}

.full-login-new-body .form-group .custom_checkbox_content span {
    width: 16px;
    height: 16px;
    vertical-align: middle;
    margin-right: 5px;
}

.full-login-new-body .form-group .custom_checkbox_content span {
    position: relative;
    display: inline-block;
    padding: 0 0 0 20px;
    pointer-events: none;
    color: #3d3d3d;
}

.catering_receipt_popup .Viewreceipt .tnk-delivery .order-items-maindiv .cart_row.oreder_itm_row .col-sm-cls.cart_left{
    display: block;
}

.pay-omiseconf-popup .custom_alert .mfp-close:after, .pay-omiseconf-popup .custom_alert .mfp-close:before {
    position: absolute;
    top: 3px;
    right: 7px;
    content: "";
    height: 10px;
    width: 1.9px;
    background-color: #fff;
}

.pay-omiseconf-popup .custom_alert .mfp-close:before {
    transform: rotate(45deg);
}

.pay-omiseconf-popup .custom_alert .mfp-close:after {
    transform: rotate(-45deg);
}

.pay-omiseconf-popup .custom_alert button.mfp-close{
    font-size: 0;
}

.receipt_popup .Viewreceipt .tnk-delivery .order-items-maindiv .oreder_itm_row .cart_left {
    display: block;
}

.common-inner-blckdiv .common-inner-banner p.acc-para{
    text-transform: capitalize;
}

.cdd-details-lhs.fl .checkout-body-section .checkout-control-group-top .col-xs-cls p b{
    font-family: 'Poppins', sans-serif;
}

.birthday-info-inner-heading span{
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    font-family: 'Poppins', sans-serif;
}

.catering_receipt_popup .tnk-header .tnk-delivery .order-items-maindiv .cart_row.oreder_itm_row .row.oreder-row-inv{
    width: 100%;
}

.catering_receipt_popup .tnk-header .tnk-delivery .order-items-maindiv .cart_row.oreder_itm_row .row.oreder-row-inv
 .col-xs-7.cart_left{
    padding: 0;
}

.catering_receipt_popup .tnk-header .tnk-delivery .order-items-maindiv .cart_row.oreder_itm_row .row.oreder-row-inv
.col-xs-5.cart_right.text-right{
    padding: 0;
}

.box_in.accsec_right .acc-inform .profile-info-div .form-group .col-md-6 .re_select .css-10nd86i .css-vj8t7z .css-1wy0on6 .css-d8oujb{
    top: 0;
}

.accsec_right .welcome-title h1 {
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px 0 20px;
    font-size: 26px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    
}

.accsec_right .welcome-title h1>div {
    text-align: center;
}

.accsec_right .welcome-title h1>div span {
    color: #282828;
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    font-weight: 550;
    font-size: 16px;
    text-transform: capitalize;
}

.accsec_right .welcome-title h1> img{
    margin-left: 10px;
}

.hcart_dropdown.open .hcart_scrollarea .cart_table .cart_body .cart_row.product-details:last-child{
    border-bottom: 0;
}

.product-details-rhs .prd_chosen_sub_row .prd_chosen_sub_col .d-merge .prd_chosen_sub_item_right.pantry_dtl button{
    margin-top: 0;
}

.hoj-inner .hoj-rhs .owl-nav div:hover{
    background: #DB203B;
}

.home-our-journey-grammed .hoj-grammed .grammed-lhs a:hover{
    color: #DB203B;
}

#scrollbutton a:hover{
    background: #575757;
    color: #fff;
}
.cart-items-open .scrolltop {
    display: none;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 100px !important; 
}

.react-datepicker__time-container {
    width: 100px !important;
}
.react-datepicker--time-only .react-datepicker__time-box{border-radius: 0 !important}
.gift-main-div {
    margin: 20px 0 0 0;
}
.gift-main-div .custom_checkbox span{ margin-left: 25px; }

.order_amt {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background: #f6f6f6;
    padding: 16px 15px;
}

.order_btns .button { color: #000; display: block; padding: 15px 6px 12px; box-shadow: none; border-radius: 10px;}
.order_btns .print_invoice{ margin:8px; background: #fff; border: 2px solid #000;}
.order_btns .view_recipt{  background: #181818; color: #fff;}
.order_btns .view_recipt.button_full_view{width: 100%}

.order_btns .button:hover {  background: #000; color: #fff;}

.review_get_points .button:hover { color: #fff; background: #f0f0f0;}
.review_get_points .button:hover { color: #000; background: #f0f0f0;}

.order_btns .button_full_view{ margin: 0; width: 100%; background: #DB203B; color: #fff;}
.order_btns .view_recipt:hover{ background: #000; color: #fff;}
.order_btns .print_invoice , .order_btns .view_recipt{ margin: 0; width: 49%; }

.review_button .view_recipt{ margin: 0; width: 100%;}

.congrats-inner, .promo-form {
    margin: 25px 40px;
    text-align: center;
    padding: 28px 15px;
    background: #f6f6f6;
}

.promotion-newdesign .accsec_right, .rewards-newdesign .accsec_right, .vouchers-newdesign .accsec_right{
    padding: 0;
}

.hcart_scrollarea .cart_row.product-details .cart_left .cart_info {
    max-width: calc(100% - 105px);
}

.carousel-root {
    outline: none;
}
.carousel {
    position: relative;
    width: 100%;
}
.carousel .carousel {
    position: relative;
}
.carousel .control-arrow {
    display: none
}
.carousel .thumbs-wrapper {
    margin: 20px 0 0 0;
    overflow: hidden;
    text-align: left;
}
 .carousel .thumbs {
    position: relative;
    list-style: none;
    white-space: nowrap;
    padding: 0;
}
 .carousel .thumb {
     display: inline-block;
     margin-right: 15px;
     white-space: nowrap;
     overflow: hidden;
     border: 2px solid #eaeaea;
     padding: 2px;
}
 .carousel .thumb:focus {
     border: 2px solid #ccc;
     outline: none;
}
 .carousel .thumb.selected, .carousel .thumb:hover {
     border: 2px solid #333;
}
 .carousel .thumb img {
     vertical-align: top;
}
 .carousel.carousel-slider {
     position: relative;
     margin: 0;
     overflow: hidden;
         border: 2px solid #eaeaea;
}
 .carousel.carousel-slider .control-arrow {
     top: 0;
     color: #fff;
     font-size: 26px;
     bottom: 0;
     margin-top: 0;
     padding: 5px;
}
 .carousel.carousel-slider .control-arrow:hover {
     background: rgba(0, 0, 0, 0.2);
}
 .carousel .slider-wrapper {
     overflow: hidden;
     margin: auto;
     width: 100%;
}
 .carousel .slider-wrapper.axis-horizontal .slider {
     -ms-box-orient: horizontal;
     display: -webkit-box;
     display: -moz-box;
     display: -ms-flexbox;
     display: -moz-flex;
     display: -webkit-flex;
     display: flex;
}
 .carousel .slider-wrapper.axis-horizontal .slider .slide {
     flex-direction: column;
     flex-flow: column;
}
 .carousel .slider-wrapper.axis-vertical {
     -ms-box-orient: horizontal;
     display: -webkit-box;
     display: -moz-box;
     display: -ms-flexbox;
     display: -moz-flex;
     display: -webkit-flex;
     display: flex;
}
 .carousel .slider-wrapper.axis-vertical .slider {
     -webkit-flex-direction: column;
     flex-direction: column;
}
 .carousel .slider {
     margin: 0;
     padding: 0;
     position: relative;
     list-style: none;
     width: 100%;
}
 .carousel .slide {
     min-width: 100%;
     margin: 0;
     position: relative;
     text-align: center;
}
 .carousel .slide img {
     width: 100%;
     vertical-align: top;
     border: 0;
}
 .carousel .slide iframe {
     display: inline-block;
     width: calc(100% - 80px);
     margin: 0 40px 40px;
     border: 0;
}
 .carousel .slide .legend {
     position: absolute;
     bottom: 40px;
     left: 50%;
     margin-left: -45%;
     width: 90%;
     border-radius: 10px;
     background: #000;
     color: #fff;
     padding: 10px;
     font-size: 12px;
     text-align: center;
     opacity: 0.25;
}
 .carousel .control-dots {
     position: absolute;
     bottom: 0;
     margin: 10px 0;
     padding: 0;
     text-align: center;
     width: 100%;
     z-index: 1;
}
 .carousel .control-dots .dot {
     box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
     background: #fff;
     border-radius: 50%;
     width: 8px;
     height: 8px;
     cursor: pointer;
     display: inline-block;
     margin: 0 8px;
}
 .carousel .carousel-status {
     position: absolute;
     top: 0;
     right: 0;
     padding: 5px;
     font-size: 10px;
     text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
     color: #fff;
}
 .carousel:hover .slide .legend {
     opacity: 1;
}
 
.owlmobile{display: none;}
.owlmobile .owl-stage{
    border-bottom: 5px solid #ededed;
    display: flex;
}
.owlmobile .menu-list-intvl a{ font-family: 'Poppins', sans-serif; font-weight: 600; text-align: center; display: block; font-size: 17px;text-transform: capitalize; padding-bottom: 5px;line-height: 1.1; }
.owlmobile .menu-list-intvl a:after {
    bottom: -5px;
    content: "";
    display: block;
    height: 5px;
    left: 50%;
    position: absolute;
    background: #f8d57c;
    transition: width .3s ease 0s,left .3s ease 0s;
    width: 0;
}
.owlmobile .menu-list-intvl.active a:after, .owlmobile .menu-list-intvl a:hover:after {
    width: 100%;
    left: 0;
}
.owlmobile .menu-list-intvl a:hover{color: #000;}

.common-inner-banner span { font-size: 14px;line-height: 1.4; top: 60px; margin: 0 12px;}       

.my_acc p{
    top: 15%;
    font-family: 'Poppins', sans-serif;
    letter-spacing: normal;
    font-weight: 550;
}

.product_list_cart{
    margin: 0;
    padding: 7px 14px 7px;
}

ul.hmenu_list > li.active a{
     color: #DB203B;
}

ul.hmenu_list > li.active ul li a{
    color: #DB203B;
}

/**************** OUTLET ***************************************************************/

.outletul{ padding: 0; list-style: none;}
.outletul li{ display: flex; flex-wrap: wrap; max-width: 1080px; margin: 0 auto;}

.ourrest_row{ width: 100%; display: flex; flex-wrap: wrap; box-shadow: 0px 2px 12px 0px rgb(0 0 0 / 17%); margin-bottom: 20px;}

.ourrest_img { width: 50%;}
.ourrest_img img{ width: 100%; height: 100%; object-fit: cover;}

.ourrest_info { width: 50%; background: #fff; padding:40px 30px 30px; text-align: left; position: relative;}
.ourrest_info p{ color: #000; margin-bottom: 20px;}
.ourrest_info p strong{ font-family: 'Poppins', sans-serif; font-weight: 600;display: block; margin-bottom: 20px; color: #000;}

.ourrest_info h4{ color: #000; line-height: normal; margin: 0 0 6px; padding: 0;}
.ourrest_info_address{ margin: 0 0 20px;}
.ourrest_info_ohours{ font-size: 20px; margin: 0 0 20px; font-weight: 600;}
.ourrest_info_ohours span{ font-size: 15px; font-weight: normal;}
.ourrest_infolinks{ padding-left: 0;}
.ourrest_infolinks .order_now{ font-weight: 600; color: #000; font-size: 22px;}
.order_now_img_box{ padding: 0; display: flex; flex-wrap: wrap;}
.order_now_img_box li{ padding-left: 15px; margin-bottom: 0;}
.order_now_img_box li:first-child{ padding-left: 0;}
.ourrest_info_ohours .phone-show-mbl a{ color: #000;}
.ourrest_info_ohours .phone-show-mbl a:hover{ color: #000;}

.ourrest_info a.faddress{
    font-family: 'Poppins', sans-serif;
    font-size: 21px;
    display: inline-block;
    position: relative;
    color: #000;
    font-weight: 600;
    margin-bottom: 10px;
}

.ourrest_info a.faddress:before{
    position: absolute;
    height: 2px;
    background: #000;
    width: 45%;
    bottom: -2px;
    -webkit-transition: .4s linear all;
    transition: .4s linear all;
    content: '';
    left: 0;
}

.ourrest_info .order_now_img_box li{
    margin: 0;
}

.outlet_unit_phone {
    margin-bottom: 20px;
}

.outlet_unit_mail{
    font-size: 15px;
}
/*.outlet_unit_mail a{ color: # }*/
.ourrest_info p strong i{
    padding-right: 5px;
}

.ourrest_info .outlet_partners{
    padding: 0;
}

.ourrest_info .outlet_partners li{
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
}

.li-full-width a{
    position: relative;
    display: block;
    margin: 0;
    padding: 15px 32px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1;
    letter-spacing: 0.5px;
    border-radius: 6px;
    white-space: nowrap;
    background: #DB203B;
    color: #fff;
    border: 2px solid #DB203B;
}

.li-full-width a:hover{
    border: 2px solid #000;
    background: #000;
}

.popupwindow{
    background:none repeat scroll 0 0 rgba(0,137,220,0.9);
}

.popupwindow {
    color: #000;
    padding: 40px 45px;
    position: absolute;
    top: 0;
    left: -100%;
    height: 100%;
    width: 100%;
    background: rgb(247 213 126 / 86%);
    z-index: 9;
    transition: .4s linear all;
    -webkit-transition: .4s linear all;
    transition: .4s linear all;
}

.popupwindow {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.popupmodel:hover .popupwindow {
    display: block;
    left: 0;
    cursor: default;
}

.popupmodel:hover {
    cursor: pointer;
}

.popupmodel {
    display: inline-block;
    transition: .4s linear all;
    -webkit-transition: .4s linear all;
}

.popupwindow h2{
    letter-spacing: normal;
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

.popupmodel:hover a{
    cursor: pointer;
    color: #000;
}

.popupmodel{
    display: none;
}

.send-as-gift .check-billing .controls-two, .check-billing .controls-two {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.checkout-total .checkout-body-section .send-as-gift .custom_checkbox input[type=radio]:focus{
    box-shadow: none; box-shadow: 0;
}

.components_selctbox_cls:after, .event-form-right .select-group-box:after {
    content: "";
    background: #fff url(../images/dropdown-grey.png) no-repeat 50%;
    width: 45px;
    top: 1px;
    right: 1px;
    bottom: 1px;
    position: absolute;
    pointer-events: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 38px;
}

.event-menu-left form, .event-form-right .select-group-box{
    position: relative;
}

.product-menu-listing .owlmobile .owl-drag .owl-nav .owl-prev {
    left: 0;
}

.product-menu-listing .owlmobile .owl-drag .owl-nav .owl-next {
    right: 0;
}

.product-menu-listing .owlmobile .owl-drag .owl-nav .owl-prev:before,
.product-menu-listing .owlmobile .owl-drag .owl-nav .owl-next:before  {
    position: absolute;
    left: 0;
    right: 0;
    width: 14px;
    height: 14px;
    top: 50%;
    margin-top: -8px;
    background: url(../images/left-arrow.png) no-repeat;
    content: "";
    margin-left: 6px;
}

.product-menu-listing .owlmobile .owl-drag .owl-nav .owl-next:before {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    margin-top: -6px;
}

.product-menu-listing .owlmobile .owl-drag .owl-nav .owl-prev,
.product-menu-listing .owlmobile .owl-drag .owl-nav .owl-next{
    color: #fff;
    display: inline-block;
    border-radius: 5px;
    margin: 15px 0 10px;
    padding: 14px 24px;
    -moz-appearance: none!important;
    -webkit-appearance: none!important;
    appearance: none!important;
    cursor: pointer;
    background: #DB203B;
    border: 0;
    font-size: 15px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    text-transform: uppercase;
    line-height: 1;
    text-align: center;
    overflow: hidden;
    position: absolute;
    z-index: 2;
    box-shadow: 0 4px 9px 0 rgb(2 2 2 / 8%);



    overflow: hidden;
    text-indent: -9999px;
    padding: 0;
    width: 26px;
    height: 26px;
    border-radius: 3px;
    margin: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all .3s linear;
    transition: all .3s linear;
}

.owlmobile .owl-drag .owl-stage-outer .owl-stage {
    padding: 10px;
}

 .product-menu-listing .owlmobile .owl-drag .menu-list-intvl a:after{
    bottom: -16px;
    transition: width .3s ease 0s,left .3s ease 0s;
}

.product-menu-listing .owlmobile .owl-drag .menu-list-intvl a{    padding-bottom: 0;}

.alert_body p{font-weight: 450;}

.warning_popup .full-login-new-body p{font-weight: 450;}

.delevery_popup_datetime .delivery_submit_cls .custom_select .form-control{
    padding-left: 10px;
    padding-right: 24px;
    border-radius: 6px; 
}

.delevery_popup_datetime .syd_date .react-datepicker__input-container:after{
    content: "";
    background: #fff url(../images/arrow-down.png) no-repeat center;
    width: 40px;
    top: 3px;
    right: 1px;
    bottom: 1px;
    position: absolute;
    pointer-events: none;
    height: 42px;
    border-radius: 5px;
}

.syd_date .react-datepicker__input-container:after, .syd_time .react-datepicker__input-container:after {
    content: "";
    background: #fff url(../images/arrow-down.png) no-repeat center;
    width: 40px;
    top: 4px;
    right: 1px;
    bottom: 1px;
    position: absolute;
    pointer-events: none;
    height: 42px;
    border-radius: 5px;
}


.delevery_popup_datetime .delivery_submit_cls .custom_select:after{
    width: 30px;
    height: 42px;
    top: 2px;
}

.signup-popup .full-login-new .popup-body .form-group-11 .single-link a:hover, .controls.single-link a:hover,
.forgot-password-popup .full-login-new .popup-body .form-group .single-link a:hover{
    color: #DB203B;
}

.productpage-main-div .product-cms-content-middle .sticky-menu-nav .owl-carousel .owl-nav .owl-next.disabled{
    opacity: 0.3;
    cursor: none !important;
}

.sticky-menu-product .productpage-main-div .product-cms-content-middle.product-sticky{
    position: -webkit-sticky;
    position: sticky;

}

.home-main-div .home-order-now ul li{
    position: relative;
    line-height: 0
}

.home-order-now-li-item{
    position: relative;
    display: block;
    overflow: hidden;
}

.home-order-now-li-item:before{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(72,76,97,0) 0%, rgba(0,0,0,0.8) 85%);
    content: '';
    opacity: 0;
    transform: translate3d(0,50%,0);
    transition: opacity 0.35s, transform 0.35s;
}

.home-order-now ul li .home-order-now-li-item:hover:before {
    opacity: 1;
    transform: translate3d(0,0,0);
}

.home-order-now ul li .home-order-now-li-item:hover .hon-icon-bg {
    transform: translate3d(0,-50px,0);
}

.pagesList-main-div .cms-page .cms-content h2 {
    text-transform: capitalize;
    font-family: 'Poppins', sans-serif;
    font-weight: 550;
    font-size: 22px;
    margin-bottom: 20px;
    line-height: 1.5;
}

.outletList-main-div .outletul .ourrest_info p .outlet_unit_phone a:hover,
.outletList-main-div .outletul .ourrest_info p .outlet_unit_fax a:hover{color: #ecc664;}

.outlet_unit_mail span a:hover{color: #ecc664;}
.css-10nd86i{z-index: 4;}

.about-us-our-client {
    max-width: 1040px;
    margin: 20px auto;
}

.about-us-our-client .owl-dots {
    padding: 0;
    margin: 0;
    list-style: none;
    position: absolute;
    text-align: center;
     left: 50%;
    transform: translateX(-50%);
    margin: 20px 0;
}

.about-us-our-client .owl-dot {
    display: inline-block;
    height: 11px;
    width: 11px;
    line-height: 11px;
    border-radius: 50%;
    border: 1px solid #000;
    background: transparent;
    margin-right: 10px;
}

.about-us-our-client .owl-dot.active{
    background: #000;
}

.about-us-our-client .about-us-our-client-image {
    height: 150px;
    width: 150px;
    margin: 0 auto;
}

.outletList-main-div .about-banner-img img{width: 100%;}

.tab_mobrow.main_tabin .accsec_right .account_sec .profile-info-div .form-group .re_select .css-10nd86i .css-vj8t7z {
    height: 46px;
}
.icon-date-cs .lFVgC{padding: 0; font-family: 'Poppins', sans-serif;}
.icon-date-cs .lFVgC input[type="text"] {
    padding: 6px 18px;
}
.delivery_datepiker{ font-family: 'Poppins', sans-serif; }
body ._2vLUd {
    background-color: #DB203B;
    color: #000; position: relative !important
}
body ._3K2Ws, body .wgwdj button{ font-family: 'Poppins', sans-serif; font-weight: 700}
._1X9ls .Nv9Bc, ._1X9ls ._3iPkS{ margin: 0 }
._1X9ls .Nv9Bc  span, ._1X9ls ._3iPkS span{ display: none !important}
body ._2qF_L._1pjXb>span {
    background: #DB203B;
    color: #000;
}
button#left:after {
    font-family: FontAwesome;
    font-size: 24px;
    content: "\f104";
}
button#right:after {
    font-family: FontAwesome;
    font-size: 24px;
   content:"\f105";
}

#login-content .kep-login-facebook.metro{text-transform: uppercase; font-size: 14px;padding: 8px 15px;}
.rev-btnn{display: block; margin-top: 0; border-radius: 5px;
    width: 100%;
   padding: 14px 15px;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    border: 1px solid #cbcbcb;
    box-shadow: 0px 3px 9px 0px rgb(0 0 0 / 21%);}

#reservation .event-menu-left .event-top-heading h3, #reservation .event-menu-right .event-top-heading h3{ text-transform: capitalize; font-weight: 600; font-size: 20px;margin-bottom: 15px; }
#reservation .guest_login{ padding: 30px 0 0 0; }
#reservation .custom_checkbox.tarms_chkbox_div {
    position: relative;
    display: flex;
    justify-content: center; text-align: left;
}
#reservation .event-menu-right input[type="checkbox"]{ position: relative; margin-right: 10px;}
#reservation .event-menu-right span a{ text-decoration: underline; }
#reservation .event-menu-right span strong{ font-family: 'Poppins', sans-serif; font-weight: bold}
.final-submit{    width: 100%;
    display: block;
    padding: 15px 26px;
    margin: 20px 0 0 0;
    border-radius: 5px;box-shadow: rgb(0 0 0 / 21%) 0px 3px 9px 0px;}
.reserve-content .row {
    margin-bottom: 5px;
}

.reserve-content textarea.form-control {
    height: 240px;
}
.wgwdj button{margin-top: 0; margin-bottom: 0}
._2ESpD{margin-bottom: 15px;}
._2qF_L>span{font-weight: 500;}
._1X9ls{height: auto !important;}
.self_pop_item.dinein_popup_item {
    max-width: 312px;
    margin: 0 auto; text-align: center;}
.self_pop_item.dinein_popup_item {
    max-width: 312px;
    margin: 0 auto;
}
.scan-qr{background: #fff; border: 2px solid #cbcbcb;padding: 40px 10px 30px;width: 100%;}
.scan-qr:hover{  border: 2px solid #DB203B;background: #fff;}
.scan-qr span{display: block; margin: 30px 0 0 0; text-transform: capitalize; letter-spacing: 0; color: #000; font-size: 15px;}
.dinein_popup_item .takelist-btn-part .button {
    width: 184px;
    background: #000;
    color: #fff;
    margin: 0 auto;
}
.dinein_popup_item .form-group input[type="text"]{ border: 1px solid #cbcbcb; height: 40px; text-align: center; font-weight: 600;color: #000}
#dinein-popup .self_pop_row{ padding-top: 0}

.instapro-section-inner h2.home-title {
    font-weight: 700;
    letter-spacing: 2px;
    padding-bottom: 5px;
    text-transform: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
}
.instapro-section-inner h2.home-title img{margin-right: 10px}
.instapro-section-inner h2.home-title span{display: block; line-height: 1}

.home-insta-feed {
    background: #f5f5f5;
    padding-top: 50px;
    padding-bottom: 50px;
}

.dymic-insta-feed{
    padding-top: 0;
    padding-bottom: 80px;
}

.home-instafeed-image img {
    height: 100%;
    object-fit: cover;
    /*object-position: right;*/
}
.instapro-section-inner h2.home-title img {
    width: 32px;
}

.home-newletter {
    text-align: center;
    max-width: 720px;
    margin: 0 auto;
    padding: 40px 0;
}
.home-newletter span{
    color: #000;
    font-size: 20px;
    font-weight: bold;
    text-transform: capitalize;
}
.home-newletter h4{
    font-size: 19px;
    text-transform: inherit;
    font-weight: 500;
    padding: 10px 0 0 0;
}
.home-newletter .button{ width: 320px; margin: 0 auto; padding: 16px 10px 16px;}

.home-newletter-bottom {
    text-align: left;
    padding-bottom: 30px;
    display: block;
    justify-content: space-between;
}
.home-newletter-bottom input[type="checkbox"] {
    width: 18px;
    top: 3px;
}
.home-newletter-bottom label {
    width: calc( 100% - 30px );
    font-size: 13px;
    color: #757575;
    line-height: 19px !important;
}
.home-newletter-bottom label a{ text-decoration: underline; font-weight: 500 }


.modify_item{
    margin-right: 20px;
}

.mobile-cart{
    display: none;
}

.mobile-cart h4 {
    font-size: 17px;
    color: #fff;
    margin: 0;
}

.mobile-cart img {
    vertical-align: top;
    margin-top: -3px;
}

.mobile-cart a {
    text-transform: uppercase;
    /*border-bottom: 1px solid #fff;*/
    color: #fff;
    font-size: 14px;
    font-weight: 600;
}

.checkout-right-body-section .cart_body .order-details-with-clear{
    display: block;
    padding: 0;
}

.checkout-right-body-section .hclear_cart{
    text-decoration: none;
}

.home-our-newletter .home-newletter-form span.error, .home-our-newletter .home-newletter-bottom span {
    background: #FFBABA;
    color: #D8000C;
    padding: 5px 10px;
    font-size: 11px;
    position: relative;
    display: inline-block;
    font-weight: normal;
    z-index: 4;
    line-height: 1.1;
    text-transform: none;
    float: left;
}

.home-our-newletter .home-newletter-form .form-group .row .col-md-6,
.home-our-newletter .home-newletter-form .form-group .row .col-md-12 {
    padding: 0;
}

.home-our-newletter .home-newletter-form .form-group .row .col-md-6 {
    width: 49%;
    float: left;
    margin: 0 2% 0 0;
}

.home-our-newletter .home-newletter-form .form-group .row .col-md-6:last-of-type {
    margin-right: 0;
}

.home-newletter{
    padding: 40px 15px;
}

.ferror{
    margin-bottom: 8px;
}

.dinein-scantable-inner section{
    text-align:center;
    justify-content: center;
    margin: 0 auto;
}

/********************************* 29-07-21 *********************************/

.dinein-scantable p {
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    display: flex;
    margin-bottom: 0;
    justify-content: center;
    align-items: center;
}

.dinein-scantable a {
    padding: 12px 25px;
    margin-bottom: 0;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background: #000;
    position: absolute;
    left: 50px;
    border-radius: 6px;
    letter-spacing: 0.5px;
    line-height: 1;
    white-space: nowrap;
}

.dinein-scantable a:hover{
    background: #DB203B;
    color: #fff;
}

.dinein-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 20px 0;
}

.dinein-heading h5{
    margin-bottom: 0;
}

.reservation-main-div .slick-slider .slick-prev {
    left: 0;
}
.reservation-main-div .slick-slider .slick-next {
    right: 0;
}
.reservation-main-div .slick-slider{ line-height: 0}
.reservation-main-div .slick-arrow {
    position: absolute;
    top: 50%;
    margin-top: -23px;
    outline: 0;
    width: 48px;
    height: 48px;
    text-indent: -9999px;
    z-index: 9;
    border: none;
    padding: 0;
    margin-bottom: 0;
    background: #060801;
    border-radius: 0;
}
.reservation-main-div .slick-slider .slick-next:after, .reservation-main-div .slick-slider .slick-prev:before {
    background:url(../images/banner-arrow.png) no-repeat ;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    width: 24px;
    height: 11px;
    content: "";
    margin: -5px auto auto;
}
.reservation-main-div .slick-slider .slick-next:after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.reservation-main-div .reservation-full .res-main-title h3{
    text-align: center;
}

.reservation-main-div .reservation-full .res-main-title #reservation h4{
    text-align: center;
}

.reservation-main-div .nayana-res-ppl {
    display: flex;
    align-items: center;
    padding: 0;
}

.reservation-main-div .res-pm p{
    margin-bottom: 0
}

.reservation-full .res-main-title .special-instructions .reservation_notes {
    height: 150px;
}

.row .continue {
    text-align: center;
}

.reservation-full .res-main-title .choose-datetime-full .react-datepicker-wrapper {
    width: 100%;
}

.timeslot_info, .error_div_cls, .error_div_cls_limit{
    background: #ffbaba;
    color: #d8000c;
    padding: 5px 10px;
    font-size: 11px;
    position: relative;
    display: inline-block;
    font-weight: 400;
    z-index: 4;
    line-height: 1.1;
    bottom: 0px;
}

.error_div_cls, .error_div_cls_limit{
    bottom: 0;
    top: 4px;
}

.timeslot_info:before, .error_div_cls:before, .error_div_cls_limit:before {
    content: "";
    border-bottom: 5px solid #ffbaba;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: -5px;
    width: 0;
}

.reservation-summary-row{
    margin: 0;
}
.reservation-full .reservation-summary-all{
    padding: 0;
}

.reservation_sum_text, .reservation_text{
    text-align: center;
}

.reservation-summary ul{
    margin: 0 auto;
    max-width: 400px;
}

.reservation-summary ul li{
    list-style-type: disc;
}

.reservation-summary-button{
     max-width: 400px;
     margin: 0 auto;
}

.reservation-summary-button .go-back {
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
}

.reservation_step_one{
    padding: 15px 0 10px;
}

.reservation_order_details h4{ font-weight: 600; }

.reservation_notes{
    width: 100%;
}

.reservation_notes p{
    padding: 5px 15px 0;
}

.catering-thankyou-page .tnk-chk-order .button:hover{
    background: #DB203B;
    color: #000;
}

.catering-thankyou-page .tnk-chk-order a.button{
    color: #fff;
}

.home-newletter .home-newletter-bottom label{
    cursor: inherit;
}

.home-newletter .home-newletter-bottom .home-newletter-para-link:hover{
    color: #DB203B; 
}

.home-newletter .home-newletter-bottom .home-newletter-para-link{
    text-decoration: none;
}

.catering-thankyou-page .tnk-you .thank-order-detaildiv .delivery-cart-div{
    display: block;
}

.inside-dine-popup-rhs {
    /*float: right;*/
    background: #FAE2C7;
    padding: 5px 45px 0px 45px;
    position: relative;
    z-index: 9;
    border: 1px solid rgb(232 90 0 / 90%);
    margin: 0 auto;
    /*width: 80%;*/
    -webkit-transition: 0.4s linear all;
    transition: 0.4s linear all;
}

span.error.event-error {
    left: 50%;
    transform: translateX(-50%);
}

.event-page .event-form-right .form-group .react-datepicker-wrapper {
    width: 49%;
}

.event-page .event-form-right .form-group .focus-event-date {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.event-page .event-form-right .form-group .react-datepicker-wrapper .react-datepicker__input-container {
    display: block;
}

.whats-app-img {
    width: 17px;
    margin-left: 3px;
}

.event-menu-left .event-product-item .product-info-div .events-contact{
    margin-top: 10px;
    font-weight: 500;
}

.order_popup .common-button-row, .self_outlet .takelist-btn-part{ justify-content: center;}

.review_0rder_btn{
    padding: 0px 15px;
    display: flex;
    letter-spacing: 3px;
    color: #000;
    font-weight: 600;
    justify-content: flex-end;
    text-decoration: underline;
    transition: all .3s ease;
}

.review_0rder_btn:hover{
    color: #DB203B;
}

.order_review{
    max-width: 626px;
    margin:  0 auto;
}

.order_review .pouup_in{
    padding: 0px 50px 40px;
}

.order_review .mfp-close {
    background: #000;
    color: #fff;
    font-size: 0;
    width: 26px;
    height: 26px;
    opacity: 1;
    top: 5px;
    right: 5px;
    padding: 0;
    margin: 0;
    border: 0px;
    border-radius: 5px;
}

.order_review .mfp-close:hover{
     background: rgba(1, 1, 1, 0.9);
}

.order_review .mfp-close {
    background: rgba(1, 1, 1, 0.9);
}


.order_review .tnk-order {
    background: #fff;
    padding: 30px 15px;
    text-align: center;
}

.order_review .tnk-order p {
    color: #292929;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
}

.order_review .tnk-order h3{
    font-size: 28px;
    margin-bottom: 5px;
    color: #292929;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
}

.order_review .order_section_review .chk_hea {
    font-size: 15px;
    padding: 0;
    line-height: 1;
    width: 300px;
    text-transform: uppercase;
    color: #000;
    position: relative;
    font-weight: 600;
    margin: 0;
}

.order_review .order_section_review .order-start{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 10px 0;
}

.order_review .order_section_review .checkout-control-group-bottom{
    padding-bottom: 20px;
    border-bottom: 1px solid #e4e4e4;
}

.order_review .order_section_review .checkout-control-group-bottom textarea {
    padding: 10px 15px;
    height: 90px;
    font-size: 15px;
    width: 100%;
    outline: 0;
    margin-bottom: 0;
    font-family: sans-serif;
}

.order_review .order_product_info .checkout-control-group-bottom{
    border-bottom: 0;
}

.order_review .order_product_info{
    padding-top: 30px;
}

.order_review .order_product_info.no-pd-top{
    padding-top: 0;
}

.order_review .home-newletter-bottom {
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.order_review .home-newletter-bottom label {
    width: auto;
    cursor: auto;
    color: #000;
}

.order_review .home-newletter-bottom input[type="checkbox"] {
    top: 0px;
}

.order_review .pouup_in .subscribe_submit{
    text-align: center;
    width: 205px;
    margin: 0 auto;
    display: block;
    background: #000;
    color: #fff;
}

.order_review .pouup_in .subscribe_submit:hover{     
    background: #DB203B;
    color: #000;
}

.order_section_review_star:not(:checked) > input {
    position:absolute;
    top:-9999px;
}
.order_section_review_star:not(:checked) > label {
    float:right;
    width:1em;
    overflow:hidden;
    white-space:nowrap;
    cursor:pointer;
    font-size:30px;
    color:#ccc;
}
.order_section_review_star:not(:checked) > label:before {
    content: '★ ';
}
.order_section_review_star > input:checked ~ label {
    color: #ffc700;    
}
.order_section_review_star:not(:checked) > label:hover,
.order_section_review_star:not(:checked) > label:hover ~ label {
    color: #ffc700;  
}
.order_section_review_star > input:checked + label:hover,
.order_section_review_star > input:checked + label:hover ~ label,
.order_section_review_star > input:checked ~ label:hover,
.order_section_review_star > input:checked ~ label:hover ~ label,
.order_section_review_star > label:hover ~ input:checked ~ label {
    color: #ffc700;
}

.product_section_review_star:not(:checked) > input {
    position:absolute;
    top:-9999px;
}
.product_section_review_star:not(:checked) > label {
    float:right;
    width:1em;
    overflow:hidden;
    white-space:nowrap;
    cursor:pointer;
    font-size:30px;
    color:#ccc;
}
.product_section_review_star:not(:checked) > label:before {
    content: '★ ';
}
.product_section_review_star > input:checked ~ label {
    color: #ffc700;    
}
.product_section_review_star:not(:checked) > label:hover,
.product_section_review_star:not(:checked) > label:hover ~ label {
    color: #ffc700;  
}
.product_section_review_star > input:checked + label:hover,
.product_section_review_star > input:checked + label:hover ~ label,
.product_section_review_star > input:checked ~ label:hover,
.product_section_review_star > input:checked ~ label:hover ~ label,
.product_section_review_star > label:hover ~ input:checked ~ label {
    color: #ffc700;
}
.product_section_review_star{
    margin-top: 0px;
    display: block!important;
    justify-content: center;
    width: 35%;
    margin-bottom: 35px;
}

.product_section_review_star_pantry:not(:checked) > input {
    position:absolute;
    top:-9999px;
}
.product_section_review_star_pantry:not(:checked) > label {
    float:right;
    width:1em;
    overflow:hidden;
    white-space:nowrap;
    cursor:pointer;
    font-size:30px;
    color:#ccc;
}
.product_section_review_star_pantry:not(:checked) > label:before {
    content: '★ ';
}
.product_section_review_star_pantry > input:checked ~ label {
    color: #ffc700;    
}
.product_section_review_star_pantry:not(:checked) > label:hover,
.product_section_review_star_pantry:not(:checked) > label:hover ~ label {
    color: #deb217;  
}
.product_section_review_star_pantry > input:checked + label:hover,
.product_section_review_star_pantry > input:checked + label:hover ~ label,
.product_section_review_star_pantry > input:checked ~ label:hover,
.product_section_review_star_pantry > input:checked ~ label:hover ~ label,
.product_section_review_star_pantry > label:hover ~ input:checked ~ label {
    color: #c59b08;
}
.product_section_review_star_pantry{
    margin-top: -15px;
    display:  inline-block;
    justify-content: center;
    margin-bottom: 10px;
}

.product_list_sec .rating, .product-details-lhs .rating { 
    border: none;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 0px;
    display: flex;
    justify-content: center;
    flex-flow: row-reverse;
}

.product_list_sec .rating > input, .product-details-lhs .rating > input { display: none; } 
.product_list_sec .rating > label:before, .product-details-lhs .rating > label:before { 
  margin: 5px;
  font-size: 1.25em;
  font-family: FontAwesome;
  display: inline-block;
  content: "\f005";
}

.product_list_sec .rating > .half:before, .product-details-lhs .rating > .half:before { 
  content: "\f089";
  position: absolute;
}

.product_list_sec .rating > label, .product-details-lhs .rating > label { 
    color: #959595; 
    float: right; 
    margin-left: 0px !important;
    font-size: 18px;
    cursor: auto;
}

.product_list_sec .rating .custom_checked, .product-details-lhs .rating .custom_checked{
    color: #FFD700;
}

.product_list_sec .pantry_rating { 
    border: none;
    display: flex;
    flex-flow: row-reverse;
    justify-content: center;
    margin-top: 5px;
}

.product_list_sec .pantry_rating > input { display: none; } 
.product_list_sec .pantry_rating > label:before { 
    margin: 5px;
    font-size: 1.25em;
    font-family: FontAwesome;
    display: inline-block;
    content: "\f005";
}

.product_list_sec .pantry_rating > .half:before { 
    content: "\f089";
    position: absolute;
}

.product_list_sec .pantry_rating > label { 
    color: #959595; 
    float: right; 
    margin-left: 0px !important;
    font-size: 18px;
    cursor: auto;
}

.product_list_sec .pantry_rating .custom_checked{
    color: #FFD700;
}
.sec-checkbox{
    display: flex;
}
.check-left{
    text-align:left
}

.memb-foot{
    box-shadow: 0px 1px 21px 0px rgb(0 0 0 / 18%);
    text-align: center;
    padding: 30px 15px;
    margin-bottom: 30px;
    background: url(../images/membership-bg.jpg) no-repeat 50%;
    background-size: cover;
}
.memb-payment {
    padding: 0px 0px 0px 0px;
    text-align: center;
    border-radius: 10px;
}
.memb-payment-col-radio {
    padding: 0;
    list-style: none;
    min-width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.membership-banner {
    width: 100%;
    max-width: 100%;
}

.memb-payment-col-radio li {
    max-width: 295px;
    margin-right: 10px;
    padding: 15px 20px 15px 20px;
    border: 1px solid #d0cfcb;
    border-radius: 7px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.memb-bord {
    padding: 45px 20px 30px 0px;
}

.memb-payment-col-radio .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
    position: relative;
    min-height: 1px;
}

.memb-bord-inner{
    padding: 15px;
    display: block;
    border: 1px solid #f1efef;
    color: #252525;
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    border-radius: 7px;
    cursor: default;
}

.memb-bord-inner h3{
    font-size: 25px;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    font-weight: bold;
}

.memb-bord-inner p{
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

.memb-first img{
    max-width: 75%;
}

.memb-first{
    text-align: right;
}

.memb-first p{
    font-weight: bold;
    color: #fff;
    background: #000;
    width: 200px;
    text-align: center;
    margin: 0 auto;
    border-radius: 19px;
    font-size: 18px;
    padding: 12px;
    text-transform: uppercase;
}

.membership-banner::after {
    display: block;
    content: "";
    clear: both;
}

.membership-banner-inner {
    margin: 0px auto 0;
    text-align: center;
    padding: 0px 0px;
}

.membership-banner-inner h3 {
    font-size: 40px;
    color: #c81d35;
    max-width: 750px;
    text-align: right;
    margin-bottom: 20px;
    text-transform: lowercase;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}
.membership-banner-inner h3 span{
     color: #fff;
}

.membership-banner-inner h4 {
    font-size: 28px;
    color: #fff;
    margin-bottom: 0;
    font-family: 'Poppins', sans-serif;
    margin-left: 30px;
    text-align: left;
    font-weight: 400;
}

.membership-banner-inner h2 {
    font-size: 28px;
    color: #fecb81;
    margin-top: 5px;
    margin-bottom: 0px;
    font-family: 'Poppins', sans-serif;
    margin-left: 30px;
    text-align: left;
    font-weight: 500;
}

.member-progress-2 {
    margin:15px auto 8px;
    padding:0;
    width:100%;
    height:6px;
    overflow:hidden;
    background:#292927;
    border-radius:15px;
    /*margin-right: -10px;*/
  }

  .member-bar-2 {
    position:relative;
    float:left;
    min-width:1%;
    height:100%;
    background:#fbd008;
    border-radius:15px;
  }
  
  .member-percent-2 {
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    margin:0;
  }
.member-bar-width-2{
    width:55%
}  
 .member-prog-bar p.member-help-block{
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    color: #000;
    line-height: 1;
 }

 .member-prog-bar p strong{
    font-weight: 600;
 } 
.member-prog-bar{
    /*margin-bottom: 0px; 
    margin-left: 30px;*/
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 100%;
}
.memb-payment-col-radio .custom_checkbox p{
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0px;
    text-transform: uppercase;
}
.member-progress-img{
    display: flex;
}
/*.member-help-block{
    text-align: left;
    margin-left: 30px;
    margin-top: 0px;
    color: #fff;
    text-transform: capitalize;
    font-size: 16px;
}*/

.membership-hd{
    padding: 0px 0px!important;
}
/*Membership Infographic Section*/

.membership-infographic-products-main{
    padding: 50px 0 0 0;
}

.membership-infographic-products-main-benefits{
    padding: 10px 0 0 0;
}


.membership-infographic-products-main .products-list-ulmain{
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    justify-content: center;
}

.membership-infographic-products-main .products-list-ulmain li{
    text-align: center;
    width: 31%;
    margin: 0 1.5625% 20px 0;
    background: #fff;
    transition: all .4s linear;
    display: flex;
    flex-direction: column;
}

.join-membership-infographic-products-main{
    padding: 20px 0 0 0;
}

.join-membership-infographic-products-main-benefits{
    padding: 10px 0 0 0;
}

.join-membership-infographic-products-main .products-list-ulmain{
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    justify-content: center;
}

.join-membership-infographic-products-main .products-list-ulmain li{
    text-align: center;
    width: 31%;
    margin: 0 1.5625% 20px 0;
    background: #fff;
    transition: all .4s linear;
    display: flex;
    flex-direction: column;
}

.join-membership-infographic-products-main .benefits-head h3{
    text-transform: capitalize;
}

.join-membership-infographic-products-main .benefits-head p{
    background: #dfb87f;
    border-radius: 20px;
    width: auto;
    margin: 20px 40px 0px 40px;
    padding: 3px;
}

.join-membership-infographic-products-main .benefits-body{
    text-align: left;
    margin-top: 25px;
}

.join-membership-infographic-products-main .benefits-body p{
    margin-top: 20px;
    
    color: #1f2321;
    font-size: 20px;
    margin-left: 10px;

}

.join-membership-infographic-products-main .benefits-body p > span img{
    max-width: 13%;
}

.benefits-inner {
    display: flex;
    height: 100%;
    align-items: center;
    width: 100%;
    /*justify-content: space-between;*/
}

.join-membership-infographic-products-main .benefits-inner span img{
    max-width: 10%;
}

.membership-infographic-products-main-mobile .products-list-ulmain li{
    text-align: center;
    width: 100%;
    margin: 0 1.5625% 20px 0;
    background: #fff;
    transition: all .4s linear;
    display: flex;
    flex-direction: column;
}

.membership-infographic-products-main .products-single-li {
    position: relative;
    z-index: 2;
}

.membership-infographic-products-main .products-image-div, .det_inner_image{
    height: 150px;
    --webkit-box-pack: center;
    justify-content: center;
    align-items: flex-end;
    padding: 10px 10px 10px;
}
.membership-infographic-products-main .products-image-div img, .det_inner_image img{
    height: 150px;
    width: 100%;
    object-fit: contain;
}
.membership-infographic-products-main .featuredpro-section .featured-products-main .products-list-ulmain .products-single-li .product-info-div{
        padding: 5px 20px 80px 20px;
    height: auto;
}

.membership-infographic-products-main .product-title h3{
    font-size: 25px;
    margin-bottom: 0;
    font-family: 'AileronHeavy'!important;
    color: #1f2321;
    text-transform: uppercase;
    line-height: 1;
}

.join-membership-infographic-products-main .products-single-li {
    position: relative;
    z-index: 2;
}

.join-membership-infographic-products-main .products-image-div, .det_inner_image{
    height: 220px;
    --webkit-box-pack: center;
    justify-content: center;
    align-items: flex-end;
    padding: 10px 10px 10px;
}
.det_inner_image img{
    height: 125px;
    width: 100%;
    object-fit: contain;
}
.join-membership-infographic-products-main .featuredpro-section .featured-products-main .products-list-ulmain .products-single-li .product-info-div{
        padding: 5px 20px 80px 20px;
    height: auto;
}
.join-membership-infographic-products-main .product-info-div, .det_inner_content{
    padding: 25px 20px 25px 20px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    height: calc(100% - 220px);
    background: #fff;
    transition: 0.3s linear all;
}
.join-membership-infographic-products-main .product-title-maindiv{
    position: relative;
    margin: 0 0 10px 0;
    padding: 0 0 0px 0;
}
.join-membership-infographic-products-main .product-title h3{
    font-size: 30px;
    margin-bottom: 0;
    
    color: #1f2321;
    font-weight: 600!important;
    text-transform: capitalize;
    line-height: 1;
}

.membership-infographic-products-main .benefits-head h3{
    text-transform: capitalize;
}

.membership-infographic-products-main .benefits-head p{
    background: #dfb87f;
    border-radius: 20px;
    width: auto;
    margin: 10px 40px 0px 40px;
    padding: 3px;
}

.membership-infographic-products-main .benefits-body{
    text-align: left;
}

.membership-infographic-products-main .benefits-body p{
    margin-top: 25px;
    color: #1f2321;
    font-size: 16px;
    margin-left: 10px;
}

.membership-infographic-products-main .benefits-body p > span img{
    max-width: 13%;
}

.benefits-inner {
    display: flex;
    height: 100%;
    align-items: center;
    width: 100%;
    /*justify-content: space-between;*/
}

.membership-infographic-products-main .benefits-inner span img{
    max-width: 10%;
}

.membership-infographic-products-main .product-title p{
    font-size: 21px;
    margin-bottom: 0;
    
    color: #1f2321;
    font-weight: 500;
}
.membership-infographic-products-main .products-ordernow-action{
    position: absolute;
    bottom: 25px;
    left: 0;
    right: 0;
    margin: 0 auto;
}
.membership-infographic-products-main .products-ordernow-action .product-price{
        margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.membership-infographic-products-main .product-price h3{
    margin: 0;
    font-size: 18px;
    color: #606060;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

.join-membership-infographic-products-main .product-title p{
    font-size: 21px;
    margin-bottom: 0;
    color: #1f2321;
    font-weight: 500;
    margin: 0 auto;
}
.join-membership-infographic-products-main .products-ordernow-action{
    position: absolute;
    bottom: 25px;
    left: 0;
    right: 0;
    margin: 0 auto;
}
.join-membership-infographic-products-main .products-ordernow-action .product-price{
        margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.join-membership-infographic-products-main .product-price h3{
    margin: 0;
    font-size: 18px;
    color: #606060;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

.membership-info-page .container > p{
    max-width: 850px;
    margin: 0 auto;
    font-size: 21px;
    text-align: center;
    color: #1f2321;
    font-family: AileronRegular!important;
}
.membership-info-page .home-title {
    text-align: center;
    letter-spacing: 0px;
    font-family: AileronBold!important;
    padding-bottom: 10px;
    font-size: 55px;
    color: #1f2321;
    position: relative;
    text-transform: none;
}

.membership-info-page h4{
    text-align: center;
    letter-spacing: 0px;
    font-family: AileronBold!important;
    padding-bottom: 10px;
    font-size: 37px;
    color: #1f2321;
    position: relative;
    text-transform: inherit;
}

.membership-info-page .home-title span{
    font-family: AileronBold!important;
    color: #dd841e;
}
.membership-info-page .home-our-journey {
    padding: 20px 0;
    /*background: url(../images/icon-cap.png) no-repeat;*/
}

.border-right{
    border-right: 1px solid #ebebeb;
}
.sec-seperator{
    position: relative;
    padding: 10px 0 35px;
    line-height: 1;
    margin: 0 auto;
    text-align: center;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 300;
}
.sec-seperator:before{
        height: 100%;
    background: url(../images/sec-seperator-left.png) no-repeat;
    content: "";
    width: 47%;
    position: absolute;
    justify-content: center;
    left: 0;
    top: 30%;
    /*z-index: -1;*/
}

.sec-seperator:after{
    height: 100%;
    width: 100%;
    content: "";
    background: url(../images/sec-seperator-right.png) no-repeat;
    position: absolute;
    justify-content: center;
    top: 30%;
}

.sec-seperator span{
    padding: 10px;
    /*background: #fff;*/
    color: #000;
    font-size: 18px;
    z-index: 1;
    position: relative;
}

.sec-seperator span img{
    max-width: 25%;
}
.membership-infographic-welcome-section .products-list-ulmain li{
    display: contents;
}

.membership-infographic-welcome-section .products-list-ulmain{
    align-items: center;
}

.membership-infographic-welcome-section .product-title-maindiv{
    text-align: left;
}

.barn-family-button{
    display: flex;
    justify-content: center;
    align-items: center;
}

.barn-family-button p{
        font-size: 18px;
    margin-bottom: 0;
    
    color: #1f2321;
    font-weight: 500;
}
.earn-points-section h3{
    font-size: 43px;
    margin-bottom: 0;
    font-family: AileronBold!important;
    color: #1f2321;
    text-align: center;
    text-transform: capitalize;
    margin: 40px;
}

.membership-infographic-products-main .product-title p strong{
    font-family: 'AileronBold'!important;
    font-weight: 500;
}

.join-membership-infographic-products-main .product-title p strong{
    font-family: 'AileronBold'!important;
    font-weight: bold;
}

.member-bar ul{
    padding: 0;
    display: flex;
    justify-content: space-between;
}

.member-bar ul li{
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
}

.member-bar{
    padding: 20px 0;
}

/*************************** 28-09-21 *********************************/

    .destop-barn-family{
        display: block;
    }

    .mobile-barn-family{
        display: none;
    }

        .our-story-slider{
        display: block;
    }

    .destop-benefits{
        display: block;
    }

    .mobile-benefits{
        display: none;
    }

    .carousel-control.left{
        background: url(../images/back.png) no-repeat;
        opacity: 5;
    }
    
    .mobile-benefits .products-single-li {
        text-align: center;
        width: 100%;
        margin: 0 1.5625% 20px 0;
        background: #fff;
        transition: all .4s linear;
        display: flex;
        flex-direction: column;
    }

    .mobile-benefits .benefits-head p {
        background: #dfb87f;
        border-radius: 20px;
        width: auto;
        margin: 10px 40px 0px 40px;
        padding: 3px;
    }

    .mobile-benefits .product-title h3 {
        font-size: 25px;
        margin-bottom: 0;
        font-family: "AileronRegular" !important;
        color: #1f2321;
        text-transform: capitalize;
        line-height: 1;
        font-weight: 600 !important;
    }

    .mobile-benefits .benefits-body p {
        margin-top: 25px;
        
        color: #1f2321;
        font-size: 16px;
        margin-left: 10px;
    }

    .mobile-benefits .benefits-body {
        text-align: left;
    }

    /****************************** 29-09-21 *********************************/

    .membership-infographic-products-main .products-image-div{
        padding: 0;
    }

    .membership-infographic-products-main .product-title-maindiv{
        position: relative;
        margin: 0;
        padding: 0;
    }

    .membership-infographic-products-main .product-info-div, .det_inner_content{
        padding: 30px 0px 25px 5px;
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        height: calc(100% - 250px);
        background: #fff;
        transition: 0.3s linear all;
    }

    .membership-infographic-products-main .delivery-info-div,
    .membership-infographic-products-main .dine-info-div{
        padding-left: 20px;
    }

    .membership-info-page .welcombarn{
        padding: 0px 0 20px;
    }

    .membership-info-page .welcombarn .products-list-ulmain{
        padding-bottom: 0px;
        padding-left: 80px;
    }

    .join-membership-infographic-products-main .products-image-div img{
        width: auto;
        height: 100%;
        object-fit: cover;
    }

    .membership-infographic-products-main-desktop .product-info-div .product-title {
        padding: 0 40px;
    }

    .earn-points-section .product-info-div{
        padding: 15px 60px;
    }

    .benefits-head h3{
        margin-bottom: 15px!important;
    }

    .benefits-head p{
        max-width: 190px;
    }

    .benefits-inner-img img{
        width: 100%;
        height: 100%;
    }

    .membership-benefits-desktop .product-info-div{
        padding: 40px 20px 25px 20px;
    }

.membership-tc p{
    text-align: center;
    font-size: 20px;
    font-family: 'AileronItalic'!important;
    color: #1f2321;
    margin-bottom: 50px;
}

/***************************** 05-10-21 ***********************************/

.mobile-benefits .carousel-control,
.mobile-barn-family .carousel-control{
    top: 50%;
    transform: translateY(-50%);
    color: #000;
}

.our-story-slider .carousel-control{
    top: 50%;
    transform: translateY(-50%);
    color: #000;
}


.mobile-benefits .carousel-control.right,
.mobile-barn-family .carousel-control.right{
    background: url(../images/back.png) no-repeat;  
    -ms-transform: rotate(180deg); /* IE 9 */
    transform: rotate(180deg);
    margin-top: -17px;
}

.our-story-slider .carousel-control.right{
    background: url(../images/back.png) no-repeat;  
    -ms-transform: rotate(180deg); /* IE 9 */
    transform: rotate(180deg);
    margin-top: -17px;
}

.carousel-arrow-parent .left, .carousel-arrow-parent .right{
    display: inline-block;
    -webkit-appearance: none!important;
    appearance: none;
    cursor: pointer;
    text-align: center;
    overflow: hidden;
    position: absolute;
    z-index: 2;
    border: 0;
    padding: 0;
    margin: 0;
    width: 32px;
    height: 32px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: all .3s linear;
    background-color: transparent;
}

.mobile-benefits .carousel-arrow-parent .left,
.mobile-barn-family .carousel-arrow-parent .left{
    left: 0;
}

.our-story-slider .carousel-arrow-parent .left{
    left: 0;
}

.mobile-benefits .carousel-arrow-parent .right,
.mobile-barn-family .carousel-arrow-parent .right{
    right: 0;
    opacity: 5;
}

.our-story-slider .carousel-arrow-parent .right{
    opacity: 5;
}

.mobile-benefits .carousel-control:focus, .mobile-benefits .carousel-control:hover,
 .mobile-barn-family .carousel-control:focus, .mobile-barn-family .carousel-control:hover{
    color: #000;
    text-decoration: none;
    filter: alpha(opacity=90);
    outline: 0;
    opacity: .9;
}

/*************** reward start ***********************/

.reward-maindiv{
    padding: 35px 0 60px;
}

.refferal-maindiv{
    padding: 15px 0 60px;
}

.reward-innerdiv h3{
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 36px;
    text-transform: inherit;
    margin-bottom: 30px;
}

.reward-innerdiv-lhs ul{
    list-style: none;
    padding-left: 0;
    padding-bottom: 30px;
}

.reward-innerdiv {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.reward-innerdiv-lhs ul li{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
}

.reward-innerdiv-lhs ul li .reward-inner-number{
    min-width: 44px;
    height: 44px;
    border-radius: 50%;
    background: #d8a041;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
}

.reward-innerdiv-lhs ul li .reward-inner-number span{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    font-weight: 600;
    font-size: 20px;
}

.reward-innerdiv-lhs ul li .reward-inner-content p{ margin-bottom: 0; font-size: 21px;}
.reward-innerdiv-lhs ul li .reward-inner-content{ display: flex; align-items: center;}
.reward-innerdiv-lhs{ width: 60%; max-width: 100%;}
.reward-innerdiv-rsh{ width: 40%; max-width: 100%; overflow: hidden;}
.reward-rsh-imgdiv{ height: 250px; max-height: 100%;}
.reward-innerdiv-rsh .reward-rsh-imgdiv img{ object-fit: cover; height: 100%;}
.reward-innerdiv-lhs .reward-bottom-content p{ font-size: 21px; font-style: italic; padding-right: 30px;}
.reward-overall-bottom{ padding-top: 70px;}
.reward-bottom-buttondiv ul li, .reward-bottom-buttondiv ul li{
    font-size: 21px;
    color: #000;
    justify-content: center;
    margin-bottom: 20px;
}
.reward-bottom-buttondiv ul li{ margin-bottom: 18px;}

 .reward-bottom-buttondiv ul .reward-bottom-span{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 400px;
    background: #d8a041;
    padding: 8px 28px;
    border-radius: 50px;
    margin: 0 auto;
}

.reward-bottom-buttondiv ul .reward-bottom-span .reward-bottom-right{
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 1;
}

.reward-bottom-left{ font-weight: 600; font-size: 26px;}

.icon-link{
    height: 63px;
    width: 63px;
    background: #d8a041;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.reward-bottom-icon{ display: flex; flex-wrap: wrap; justify-content: space-between;}
.icon-link a img{ height: 38px;}

.reward-bottom-buttondiv ul{
    padding-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    list-style: none;
    padding-left: 0;
}

.reward-bottom-rightdiv ul{
    padding-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    list-style: none;
    padding-left: 0;
    flex-direction: row;
}
.reward-bottom-buttondiv ul .reward-bottom-heading{ width: 100%;}
.reward-bottom-buttondiv ul .reward-bottom-icon{ margin: 0 20px;}
.reward-bottom-leftdiv{ width: 60%; max-width: 100%;}
.reward-bottom-rightdiv{ width: 40%; max-width: 100%; overflow: hidden;}
.reward-overall-bottom { display: flex; flex-wrap: wrap; justify-content: space-between; width: 100%;}

.ourstory-rhs {
    width: 47%;
    position: relative;
}

.ourstory-lhs {
    width: 47%;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
}

.our-philosopy-section {
    background: url("../images/our-philosophy.jpg");
    background-size: cover;
    margin-bottom: 0;
    position: relative;
    display: flex;
    z-index: 1;
    align-items: center;
}
.our-philosopy-inner{
    height: auto;
    align-items: center;
    padding: 60px 0;
    z-index: 1;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
 }   

 .our-philosopy-section img{
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
 }

 .our-story-section {
    background: #faf4ec;
    padding: 20px 0;
 }

.our-journey{
    background: #faf4ec;
    padding: 0px 20px 0;
}

.visit-us {
    background: #faf4ec;
    padding: 30px 0;
}

.visit-us p{
    max-width: 800px;
    text-align: center;
    margin: 0 auto 30px;
    font-size: 19px;
    color: #000!important;
    margin-bottom: 30px!important;
}

 .text-center {
    text-align: center;
 }

 .our-story-section .our-story-rhs{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    margin-top: 35px;
 }

 .our-story-inner {
    background: #fff;
 }

 .our-story-rhs p{
    max-width: 400px;
    margin: 0 auto 30px;
    font-family: 'Montserrat';
    font-size: 21px;
 }

.our-story-rhs h2{
    font-style:italic ;
    text-transform: inherit;
    font-size: 30px;
    font-weight: bold;
}

.our-story-second-lhs h2{
    font-style:italic ;
    text-transform: inherit;
    font-size: 30px;
    font-weight: bold!important;
    letter-spacing: 0;
}

 .our-philosopy-body h4{
    color: #fff!important;
    text-align: center;
    font-family: 'Merriweather'!important;
    font-weight: bold;
    font-style: italic;
    font-size: 24px;
 }

  .our-philosopy-body p{
    color: #fff!important;
    text-align: center;
    max-width: 700px;
    margin: 0 auto 30px;
    font-size: 19px;
 }
 .our-journey{padding: 20px 0;}

.our-journey-inner .products-image-div img, .det_inner_image img {
    height: 90px;
    width: 100%;
    object-fit: contain;
}

.our-journey-inner {
    padding: 20px 0 0 0;
    background: #fff;
}
.our-journey-inner .products-list-ulmain {
    align-items: center;
}

.our-journey-inner .products-list-ulmain {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    justify-content: center;
}
.our-journey-inner .products-list-ulmain li {
    display: contents;
}

.our-journey-inner .products-list-ulmain li {
    text-align: center;
    width: 46%;
    margin: 0 1.5625% 20px 0;
    background: #fff;
    transition: all .4s linear;
    display: flex;
}
.our-journey-inner .products-single-li {
    position: relative;
    z-index: 2;
}
.our-journey-inner .products-image-div {
    padding: 0;
}
.our-journey-inner .products-image-div, .det_inner_image {
    height: 150px;
    --webkit-box-pack: center;
    justify-content: center;
    align-items: flex-end;
    padding: 10px 10px 10px;
}
.our-journey-inner .product-info-div, .det_inner_content {
    padding: 30px 0px 25px 5px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    height: calc(100% - 250px);
    background: #fff;
    transition: 0.3s linear all;
}
.our-journey-inner .product-title-maindiv {
    position: relative;
    margin: 0;
    padding: 0;
}

.our-journey-inner .product-title-maindiv {
    text-align: left;
}
.our-journey-inner .product-title h3 {
    font-size: 24px;
    margin-bottom: 0;
    font-family: 'Montserrat'!important;
    color: #000000;
    text-transform: capitalize;
    line-height: 1;
}
.our-journey-inner .product-title p {
    font-size: 21px;
    margin-bottom: 0;
    font-family: 'Montserrat'!important;
    color: #000000!important;
    font-weight: 500;
    margin-top: 10px;
}

.our-journey-inner .products-list-ulmain {
    padding-bottom: 0px;
}

.trust-li img{
    width: 128px!important;
}

.visit-us-inner img{
    width: 100%;
}

.visit-us ul li
{
    width: 100%;
}
.visit-us .home-title{
    padding-bottom: 0px;
    font-family: 'Merriweather';
    font-weight: bold;
    font-style: italic;
    text-transform: inherit;
    letter-spacing: 0;
}

.visit-us-inner{
    padding: 0px 20px;
}

.our-story-second-lhs p{
    max-width: 400px;
    margin: 0 auto 15px;
    font-size: 21px;
    color: #000!important;
    line-height: 1.4;
}

.our-story-rhs p{
    color: #000!important;
    line-height: 1.4;
}

.our-story-second-lhs {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    margin-top: 50px;
}

.faq-page .filter_tabsec .nav-tabs>li{
    width: auto;
    float: none;
    display: inline-block;
    border-bottom: 0;
    position: relative;
}

.faq-page .container.cms-content{
    border-top: 0px;
}

.faq-page .myacc_filter .nav-tabs > li > a{
    font-size: 21px;
}

.our-story-slider .carousel-inner > .item > a > img, .carousel-inner > .item > img{
    max-width: 100%;
    margin: 0 auto;
}

.our-story-inner .hoj-lhs{
    width: 49%;
}

.our-story-slider .owl-nav {
    position: initial;
    bottom: 0;
    left: 0;
    display: flex;
}
.our-story-slider .owl-nav a{ 
    display: inline-block;
    -webkit-appearance: none!important;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    text-align: center;
    overflow: hidden;
    position: absolute;
    z-index: 2;
    border: 0;
    padding: 0;
    margin: 0;
    width: 99px;
    height: 77px;
    top: 50%;
    transform: translateY(-50%);
    transition: all .3s linear;
    background-color: transparent;

}
.our-story-slider .owl-nav a:before{position: absolute; content: ""; width: 31px; height: 31px; left: 0; right: 0; margin: auto; top: 50%;transform: translateY(-50%); padding: 0px 5px 0px 5px;}
.our-story-slider .owl-nav .owl-next:before{ background: url(../images/back.png) no-repeat;    -ms-transform: rotate(180deg); /* IE 9 */
    transform: rotate(180deg);
    margin-top: -38px; }
.our-story-slider .owl-nav .owl-prev:before{ background: url(../images/back.png) no-repeat }
.our-story-slider .owl-nav .owl-prev{ 
    left: 0; 
    opacity: 5;
    border: 0px;
}

.our-story-slider .owl-nav .owl-next{
    right: 0;
    opacity: 5;
    transform: rotate(360deg);
    margin-top: -17px;
}

.our-story-slider .carousel-indicators{
    display: none;
}

.our-story-slider .owl-nav a:hover{
    background: none;
}

.onl-phantry img{
    height: 110px!important;
}

.qlt-prod img{
    height: 80px!important;
} 

.pagesList-main-div .cms-page .container{
    padding: 0px 10px 0px!important;
}

.pagesList-main-div .our-story-page .container {
    padding: 0px 0px 0px!important;
}

.pagesList-main-div .container.cms-content{
    border-top:0px ;
}

.mainacc_menucontent .ord-body .order_no_deatails button{
    padding: 12px 15px 12px;
    width:100%
}

.our-story-first .hoj-lhs{
    width: 55%;
}

.our-story-first .hoj-rhs{
    width: 45%;
}

.our-story-second .hoj-lhs{
    width: 45%;
}

.our-story-second .hoj-rhs{
    width: 55%;
}

.our-story-slider-first .owl-nav .owl-prev{
    left: -30px;
}

.our-story-slider-second .owl-nav .owl-prev{
    left: -30px;
}

.our-story-slider-first .owl-nav .owl-next{
    right: -30px;
}

.our-story-slider-second .owl-nav .owl-next{
    right: -30px;
}

/*Catering */

.catering_banner {
    position: relative;
    height: 305px;
}

.catering_banner img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.banner_content{
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    color: #fff;
    width: 100%;
} 

.banner_content h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 58px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
    line-height: 1.1;
    text-align: center;
    color: #fff;
}

.catering_products_main {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 23px;
}

.catering_products_main .products_sidebar {
    width: 280px;
    margin-top: 30px;
     font-family: 'Poppins', sans-serif;
}

.catering_products_main .products_sidebar .product_sidebar_top {
    position: relative;
    display: none;
}

.catering_products_main .products_sidebar .product_sidebar_top h4 {
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 700;
    margin: 0 0 2px;
}

.catering_products_main .products_sidebar .product_sidebar_top p {
    font-size: 14px;
    margin: 0;
}

.catering_products_main .products_sidebar .product_sidebar_top .package_filter{
        position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 15px;
}

.catering_products_main .products_sidebar .product_sidebar_top .package_filter img {
    -webkit-transition: all .5s;
    transition: all .5s;
}

.catering_products_main .products_sidebar .product_sidebar_top .package_filter img.closed {
    width: 30px;
    opacity: 0;
    position: absolute;
    left: 0;
}

.catering_products_main .products_sidebar .products_sidebar_inner {
    padding: 0 15px;

}

.catering_products_main .products_sidebar .products_sidebar_inner .products_sidebar_content:first-child {
    padding-top: 10px;
}

.catering_products_main .products_sidebar .products_sidebar_inner .products_sidebar_content {
    padding: 15px 0;
    border-bottom: 2px solid #f2f2f2;
    position: relative;
}

.catering_products_main .products_sidebar .products_sidebar_inner .products_sidebar_content.active:before {
    opacity: 1;
}

.catering_products_main .products_sidebar .products_sidebar_inner .products_sidebar_content:first-child:before {
    top: 0;
}

.catering_products_main .products_sidebar .products_sidebar_inner .products_sidebar_content:before {
    content: "";
    display: block;
    width: 7px;
    height: 42px;
    background-color: #302d2a;
    position: absolute;
    left: -17px;
    top: 6px;
    opacity: 0;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

.catering_products_main .products_sidebar .products_sidebar_inner .products_sidebar_content h4 {
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    font-size: 14px;
    margin-top: 4px;
}

.catering_products_main .clear_ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: block;
    border: 2px solid #f3f3f3;
    box-shadow: 0 5px 16px 0 rgb(0 0 0 / 7%);
}

.catering_products_main .products_sidebar .products_sidebar_inner .products_sidebar_content li {
    line-height: 1.2;
    margin: 0px 0 0px;
    min-height: auto;
    width: auto;
    border: none;
    text-align: left;
}

.catering_products_main .products_sidebar .products_sidebar_inner .products_sidebar_content li a{
    border: none;
    text-align: left;
    justify-content: flex-start;
    color: #000;
    line-height: 1.3;
}

.catering_products_main .products_sidebar .products_sidebar_inner .products_sidebar_content li a:after{    content: "\f105";
    font-family: FontAwesome;
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 22px;
    line-height: 1;
}

.catering_products_main .products_sidebar .products_sidebar_inner .products_sidebar_content li a{
        font-size: 16px;
    font-weight: 600;
    display: block;
    padding: 15px 30px 15px 20px;
    text-transform: capitalize;
    position: relative;
    border-radius: 0px;
}


.catering_products_main .products_sidebar .products_sidebar_inner .products_sidebar_content li a.active {
    background: #DB203B;
    color: #000;
    border: none;
    text-align: left;
}

.catering_products_main .products_sidebar .products_sidebar_inner .products_sidebar_content li:last-child {
    margin-bottom: 0;
}

.catering_products_main .products_sidebar .products_sidebar_inner .products_sidebar_content {
    padding: 15px 0;
    border-bottom: 0px;
    position: relative;
}

.catering_products_main .products_sidebar .products_sidebar_inner .products_sidebar_content:before {
    content: "";
    display: block;
    width: 7px;
    height: 42px;
    background-color: #302d2a;
    position: absolute;
    left: -17px;
    top: 6px;
    opacity: 0;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

.catering_products_main .products_sidebar .products_sidebar_inner .products_sidebar_content:last-child {
    border-bottom: none;
}

.catering_products_main .products_list {
    width: calc(100% - 660px);
    margin-top: 30px;
    transition: all .4s linear;
}

.no-recrds-found {
    margin: 0;
    min-height: 270px;
    width: 100%;
    font-size: 20px;
    text-transform: uppercase;
    
    display: flex;
    justify-content: center;
    align-items: center;
}

.catering_products_main .products_list .products_list_content {
    margin-bottom: 6px;
    margin-bottom: 6px;
    display: block;
    flex-wrap: inherit;
    justify-content: flex-start;
    border: 2px solid #f3f3f3;
    padding: 10px;

}

.catering_products_main .products_list_inner{
    width: 100%;
}

.catering_products_main .products_list_desc{
     width: 100%;   
}

.products_list_inner {
    position: relative;
    overflow: hidden;
    height: 100%;
}

.products_list_inner .products_list_image {
    height: 100%;
}

.catering_products_main .products_list_inner .products_list_image img {
    max-height: 335px;
}

.products_list_inner .products_list_image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.catering_products_main .products_list_content .products_list_desc {
    padding: 15px 130px 0 0;
    min-height: 100px;
}

.catering_products_main .products_list_desc {
   
    position: relative;
    padding: 15px 0 37px;
    flex-grow: 1;
}

.catering_products_main .products_list_desc h4 {
    font-size: 19px;
    font-family: "Merriweather",serif;
    font-weight: 700;
    margin-bottom: 20px;
    line-height: 1.4;
        text-transform: inherit;
    margin: 0 0 5px;
    max-width: 440px;
}

.catering_products_main .products_list_desc h4 span {
    font-style: italic;
    
    text-transform: none;
    display: block;
    font-size: 16px;
    font-weight: 600;
    color: #000;
}

.catering_products_main .products_list_desc p {
    line-height: 1.4;
    margin: 0 0 10px;
    
}

.catering_products_main .products_list_content .products_list_desc .plistbtn_sec {
    left: auto;
    right: 0;
    top: 15px;
    bottom: auto;
}

.products_list_desc .plistbtn_sec {
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.products_list_desc .btn {
    text-transform: uppercase;
}

.catering_products_main .btn_green {
    margin-bottom: 0;
    margin-top: 0;
    min-width: 202px;
}
.catering_products_main .btn_green{
    color: #fff;
    display: inline-block;
    border-radius: 8px;
    margin: 0px 0 10px;
    padding: 19px 26px;
    -moz-appearance: none!important;
    -webkit-appearance: none!important;
    appearance: none!important;
    cursor: pointer;
    background: #000;
    border: 0;
    font-size: 14px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    text-transform: uppercase;
    line-height: 1;
    text-align: center;
    overflow: hidden;
    position: relative;
    z-index: 0;
    
    letter-spacing: 3px;
    font-weight: 600;
}

.catering_products_main .btn_green:hover {
    color: #000;
    background: #DB203B;
}

.fixed_checkout .btn:hover {
    color: #000;
    background: #DB203B;
}

.fixed_checkout .btn{
    color: #fff;
    display: inline-block;
    border-radius: 8px;
    margin: 0px 0 10px;
    padding: 19px 26px;
    -moz-appearance: none!important;
    -webkit-appearance: none!important;
    appearance: none!important;
    cursor: pointer;
    background: #000;
    border: 0;
    font-size: 14px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    text-transform: uppercase;
    line-height: 1;
    text-align: center;
    overflow: hidden;
    position: relative;
    z-index: 0;
    
    letter-spacing: 3px;
    font-weight: 600;
}

.btn_sm {
    min-width: 115px;
}

.catering_products_main .product_orders {
    margin-top: 30px;
}

.fixed_checkout_order, .fixed_checkout_order .btn {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;
}

.fixed_checkout_order {
    display: none;
}

.fixed_checkout_order .btn {
    background-color: #000;
    font-size: 17px;
    color: fff;
    height: 50px;
    padding: 11px 10px;
}
.fixed_checkout_order .btn:hover{
    background-color: #DB203B;
    color: #fff;    
}

.fixed_checkout_order, .fixed_checkout_order .btn {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;
}

.btn_upp {
    text-transform: uppercase;
}

.product_orders .product_orders_top {
    background: #000;
    color: #fff;
    display: none;
    position: relative;
    padding: 17px 25px;
}

.product_orders .product_orders_top h4 {
    font-size: 22px;
    
    font-weight: 400;
    margin: 0;
}

.product_orders .product_orders_top .close_product_orders {
    position: absolute;
    width: 25px;
    right: 15px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.product_orders .order_det {
    padding: 10px 13px 16px;
}

.product_orders .order_det .order_card {
    border: 1px solid #302d2a;
    border-radius: 5px;
    margin-bottom: 10px;
}

.product_orders .order_det .order_card .order_card_head {
    background-color: #302d2a;
    color: #fff;
    font-weight: 600;
    padding: 15px 70px 15px 13px;
    position: relative;
}

.product_orders .order_det .order_card .order_card_head a {
    position: absolute;
    right: 13px;
    top: 10px;
}

.product_orders .order_det .order_card .event_timing .input_field {
    display: flex;
    /*flex-wrap: wrap;*/
    align-items: center;
}

.product_orders .order_det .order_card .event_timing .form-group .tooltip_link {
    position: absolute;
    right: 0;
    top: 14px;
    display: block;
}

.product_orders .del_address .form-group .row {
    margin-left: -3px;
    margin-right: -3px;
}

.product_orders .del_address .form-group .row .col-lg-6 {
    padding-left: 3px!important;
    padding-right: 3px!important;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
}

.product_orders .react-datepicker-wrapper{
    width: 100%;
}

.catering_list_products h2{
    margin: 0;
    font-family: "Merriweather",serif;
    font-size: 15px;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #000;
    letter-spacing: .3px;
    width: 100%;
}

.catering_event h3{
    margin: 0;
    font-family: "Merriweather",serif;
    font-size: 15px;
    letter-spacing: .3px;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #000;
    width: 100%;   
}

.product_orders .react-datepicker__input-container:after {
  content: "";
  background: url(../images/arrow-down.png) no-repeat center;
  width: 45px;
  top: 1px;
  right: 1px;
  bottom: 1px;
  position: absolute;
  pointer-events: none;
  height: 46px;
}

.catering_products_main .products_sidebar_mobile .product_sidebar_top .package_filter {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 15px;
}

.catering_products_main .products_sidebar_mobile .product_sidebar_top .package_filter img {
    -webkit-transition: all .5s;
    transition: all .5s;
}

.catering_products_main .products_sidebar_mobile .product_sidebar_top .package_filter img.closed{
    width: 30px;
    opacity: 0;
    position: absolute;
    left: 0;
}

.catering_products_main .products_sidebar_mobile .product_sidebar_top .package_filter.close_filter img.closed{
    opacity: 0;
}

.catering_products_main .products_sidebar_mobile .product_sidebar_top .package_filter.close_filter img {
    opacity: 1;
}

.catering_products_main .products_sidebar_mobile .product_sidebar_top .package_filter img.closed{
    opacity: 1;
}

.catering_products_main .products_sidebar_mobile .product_sidebar_top .package_filter img{
    opacity: 0;
}

.catering_products_main .products_sidebar_mobile .products_sidebar_inner .products_sidebar_content:first-child {
    padding-top: 10px;
}

.catering_products_main .products_sidebar_mobile .products_sidebar_inner .products_sidebar_content {
    padding: 15px 0;
    border-bottom: 2px solid #f2f2f2;
    position: relative;
}

.catering_products_main .products_sidebar_mobile .products_sidebar_inner .products_sidebar_content h4 {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
    margin-top: 4px;
}

.catering_products_main .products_sidebar_inner ul li a{
    border: 0px;
}

.package_details .package_details_left .slick-arrow.slick-prev{
        left: 0;
    background-image: url(../images/next-arrow.png);
    background-position: 35%;
}

.package_details .package_details_left .slick-arrow {
    height: 46px;
    width: 26px;
    background-color: rgba(0,0,0,.7);
    background-repeat: no-repeat;
    background-size: 12px;
    font-size: 0px;
    border-radius: 0px;
}

.package_details .package_details_left .slick-arrow.slick-next {
    right: 0;
    background-image: url(../images/next-arrow.png);
    background-position: 60%;
    transform: rotate(180deg);
    top: 40%;
}

.slick-next, .slick-prev{
        font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: pointer;
    border: none;
}

.cat-modal{
    padding: 0px 15px;
    border: 0px;
}

.product_acc_grp .accordion .accordion_body{
    padding-top: 15px;
}

.product_acc_grp .product_card_desc .desc_left {
    width: 100%;
}

.product_acc_grp .product_card_desc .desc_right {
    width: 610px;
}

.product_acc_grp .product_det_cards {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
}

.product_acc_grp .product_det_cards .det_card {
    width: 25%;
    padding: 0 5px;
    margin: 0 0% 20px 0;
    position: relative;
}

.product_acc_grp .product_det_cards .det_card .prd_close {
    position: absolute;
    top: 5px;
    right: 15px;
    height: 30px;
    width: 30px;
    text-align: center;
    background: #000;
    border-radius: 50%;
    display: block;
    color: #fff;
}

.product_acc_grp .product_det_cards .det_card .prd_close img {
    width: 20px;
    top: 6px;
    position: relative;
    opacity: 1;
}

.product_acc_grp .product_det_cards .det_inner {
    border: 1px solid #dad9d9;
    height: 100%;
}

.product_acc_grp .product_det_cards .det_inner_image img {
    width: 100%;
    height: 225px;
    object-fit: cover;
    object-position: center;
}

.product_acc_grp .product_det_cards .det_inner_content {
    padding: 14px 15px 17px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    height: calc(100% - 225px);
}

.product_acc_grp .product_det_cards .det_inner_image {
width: 100%;
    height: 225px;
    display: flex;
    --webkit-box-pack: center;
    justify-content: center;
    align-items: flex-end;
    padding: 0px;
}

.product_acc_grp .product_det_cards .det_inner_content h5 {
    font-size: 17px;
    font-weight: 700;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    overflow: hidden;
    margin: 0 0 4px;
}
.product_acc_grp .product_det_cards .det_inner_content .product_desc {
    color: #777;
    font-weight: 300;
}

.product_acc_grp .product_det_cards .det_inner_content .form-group {
    margin-bottom: 10px;
}

.product_acc_grp .product_det_cards .det_inner_content p {
    margin: 0 0 8px;
    text-align: center;
}

.product_acc_grp .product_det_cards .det_inner_content i {
    font-weight: 700;
}

.product_acc_grp .product_det_cards .det_inner_content .custom_checkbox {
    width: 100%;
    line-height: 1.4;
    position: relative;
    display: inline-block;
}

.product_acc_grp .product_det_cards .det_inner_content .btn.btn_dark, .product_det_cards .det_inner_content .btn.btn_green {
    color: #fff;
    display: inline-block;
    border-radius: 4px;
    margin: 0px 0 10px;
    padding: 15px 15px;
    -moz-appearance: none!important;
    -webkit-appearance: none!important;
    appearance: none!important;
    cursor: pointer;
    background: #282828;
    border: 0;
    font-size: 14px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    text-transform: uppercase;
    line-height: 1;
    text-align: center;
    overflow: hidden;
    position: relative;
    z-index: 0;
    letter-spacing: 3px;
    font-weight: 600;
}

.product_acc_grp .product_det_cards .det_inner_content .btn.btn_dark, .product_det_cards .det_inner_content .btn.btn_green:hover {
    color: #000;
    background: #DB203B;
}

.text_center{
    text-align: center;
}

.product_acc_grp .product_det_cards .det_inner_content .custom_checkbox{
    position: relative;
    display: inline-block;
}   

.additionals{
    padding: 20px;
}

.btn_fill{
    width: 100%;
    color: #fff;
    background: #000;
}

.btn_fill:hover {
    color: #000;
    background: #DB203B;
}

.product_details_popup .btn{
    width: 100%;
    margin: 0 auto;
    text-align: center;
    justify-content: center;
    float: right;
    padding: 15px;
    border-radius: 4px;
}

.product_details_popup textarea{
    width: 100%;
}

.popup_product_details .counter button {
    border: none;
    background: transparent;
    width: 30%;
    font-size: 20px;
    outline: none;
    height: 100%;
    margin: 0px;
    color: #b9b9b9;
    padding: 0;
}

.popup_product_details .counter {
    width: 150px;
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    height: 40px;
    align-items: center;
    border: 2px solid #d5d5d5;
    border-radius: 6px;
    text-align: center;
}

.popup_product_details .counter span {
    width: 40%;
    font-size: 20px;
    height: 100%;
    border-left: 2px solid #d5d5d5;
    border-right: 2px solid #d5d5d5;
    line-height: 37px;
}

.product_acc_grp .package_checkitem .custom_checkbox span img{
    display: none;
}

.btn_brown {
    background-color: #fff;
    border:1px solid #000;
    padding: 10px 12px;
}

.product_details_popup .btn_brown{
    padding: 10px 12px;
    margin-bottom: 15px;
}

.popup_product_details .close {
    right: 4px;
    background: #000;
    opacity: 1;
    width: 30px;
    height: 30px;
    line-height: 30px;
    top: 5px;
}

.popup_product_details .close:after {
    content: "";
    background: url(../images/crosslg.png) no-repeat;
    width: 14px;
    height: 14px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
}

.popup_product_details .close:hover{
    background: #282828;
}

.popup_product_details .modal-header{
    padding: 0;
    border: 0;
}

.catering_products_main  .products_sidebar .product_sidebar_top .package_filter.close_filter img{
    opacity: 1;
}

.catering_products_main .products_sidebar .product_sidebar_top .package_filter img{
    opacity: 0;
}

.catering_products_main .products_sidebar .product_sidebar_top .package_filter.close_filter img.closed{
    opacity: 0;
}

.catering_products_main .products_sidebar .product_sidebar_top .package_filter img.closed{
    opacity: 1;
}

.product_orders .order_det .order_card .products_info {
    padding: 17px 10px 20px 18px;
}

.product_orders .order_det .order_card .products_info .products_info_item {
    margin-bottom: 10px;
    padding: 0px;
}

.product_orders .order_det .order_card .products_info .products_info_item label {
    margin-bottom: 8px;
    width: 100%;
    line-height: 1.3;
    padding-right: 90px;
    position: relative;
    font-weight: 600;
    color: #000;
    font-size: 14px;
}

.product_orders .order_det .order_card .products_info .products_info_item label span {
    float: right;
    position: absolute;
    right: 0;
    top: 0;
    color: #000;
}

.product_orders .order_det .order_card .products_info .products_info_item .products_info_options {
    padding-left: 0px;
    display: flex;
    flex-wrap: wrap;
    color: #959595;
    line-height: 1.4;
    margin: 0 0 10px;
}

.product_orders .order_det .order_card .products_info .products_info_item .products_info_options .products_info_options_wrap {
    width: calc(100% - 110px);
}

.product_orders .order_det .order_card .products_info .products_info_item .products_info_options p {
    padding-right: 20px;
    padding-left: 0;
    margin: 0 0 1px;
    font-style: italic;
    color: #000;
}

.modifier_values_list_wrap {
    font-weight: 600;
    padding-left: 15px;
    margin: 5px 0;
    font-size: 13.5px;
}

.modifier_values_list {
    font-weight: 400;
}

.modifier_values_list_wrap {
    font-weight: 600;
    padding-left: 15px;
    margin: 5px 0;
    font-size: 13.5px;
}

.product_orders .order_det .order_card .products_info .products_info_item .products_info_options .counter {
    width: 110px;
    border: 2px solid #e5e5e5;
    border-radius: 4px;
    display: flex;
    flex-wrap: wrap;
    height: 40px;
    text-align: center;
}

.product_orders .order_det .order_card .products_info .products_info_item .products_info_options .counter button {
    background: transparent;
    border: none;
    padding: 0;
    height: 100%;
    width: 28.33333%;
    margin: 0px;
    color: #000;
    outline: none;
    font-size: 20px;
    position: relative;
    top: 0px;
}

.product_orders .order_det .order_card .products_info .products_info_item .products_info_options .counter span {
    height: 100%;
    border-left: 2px solid #e5e5e5;
    border-right: 2px solid #e5e5e5;
    width: 43.33333%;
    color: #000;
    line-height: 35px;
    font-weight: 700;
}

.product_orders .order_det .order_card {
    border: 1px solid #302d2a;
    border-radius: 5px;
    margin-bottom: 10px;
}

.product_orders .order_det .order_card .order_card_head a {
    position: absolute;
    right: 13px;
    top: 10px;
}

.product_orders .order_det .order_card .event_timing {
    padding: 12px 11px 15px 17px;
    border-bottom: 1px solid #ebebeb;
}

.product_orders .order_det .order_card .event_timing .form-group {
    padding-right: 0px;
    position: relative;
    margin: 0 0 10px;
}

.product_orders .order_det .order_card .event_timing .input_field label {
    margin: 0;
    font-weight: 400;
}

.product_orders .order_det .order_card .event_timing .input_field .custom_select {
    width: calc(100% - 125px);
}

.checkout_section .product_orders .order_det .order_card .event_timing .input_field .custom_select {
    width: calc(100% - 390px);
}

select.form-control {
    background-color: #fff;
    position: relative;
    padding-right: 30px;
    -webkit-appearance: inherit;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAICAYAAADwdn+XAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MURCNUI2MUMwN0E5MTFFQTgyQ0RDQTBGMDM4MzdFMDQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MURCNUI2MUQwN0E5MTFFQTgyQ0RDQTBGMDM4MzdFMDQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoxREI1QjYxQTA3QTkxMUVBODJDRENBMEYwMzgzN0UwNCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoxREI1QjYxQjA3QTkxMUVBODJDRENBMEYwMzgzN0UwNCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PkL9PTAAAACkSURBVHjaYty9e/d7BgaGdiDuYiANWALxTiYg0Q/EnUCcQYJmAyA+BsRHWYBEExC/AuLpUMkZRGg+D8QrgTiCBU0TyJB/QDwLh2Y9qObVIM0gARYkyRlQzTOh4tPQNBsC8TkgXgHEkTBBFjRFIJuZoZp/A/FsqLguVPMaZM3YDIB54y/UMJAhF6GalwJxDLpiFhx+hYUByAWgaF4ExPHYFAIEGABEmyRca4IhoQAAAABJRU5ErkJggg==);
    background-position: calc(100% - 11px) 50%;
    background-size: 15px;
    background-repeat: no-repeat;
}

.form-group .error {
    display: inline-block;
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    margin-top: 7px;
    padding: 3px 15px;
    border-radius: 4px;
    position: relative;
    font-family: Helvetica;
}

.product_orders .order_det .order_card .event_timing .form-group .tooltip_link span p:last-child, .product_orders .order_det .order_card .event_timing .form-group:last-child {
    margin-bottom: 0;
}

.product_orders .order_det .order_card .products_cart {
    padding: 30px 10px;
}

.product_orders .products_total {
    padding: 20px 18px;
}

.product_orders .product-card {
    box-shadow: 0 0 10px rgb(0 0 0 / 20%);
    margin: 0 0 10px;
}

.product_orders .products_total .products_total_item {
    display: flex;
    flex-wrap: wrap;
    line-height: 1.3;
    margin-bottom: 12px;
}

.product_orders .products_total .products_total_item p {
    width: calc(100% - 90px);
    margin: 0;
    text-transform: uppercase;
    font-weight: 600;
}

.product_orders .products_total .products_total_item span {
    width: 90px;
    text-align: right;
    font-weight: 600;
}

.product_orders .products_total .products_total_item {
    display: flex;
    flex-wrap: wrap;
    line-height: 1.3;
    margin-bottom: 12px;
}

.product_orders .products_total .products_total_item.products_total_amount {
    border-top: 1px solid #f4f4f5;
    font-size: 18px;
    font-weight: 700;
    margin: 0;
    padding: 10px 0 19px;
}

.product_orders .products_total .products_total_item.products_total_amount p {
    width: calc(100% - 130px);
    font-weight: 500;
}

.product_orders .products_total .products_total_item.products_total_amount span {
    width: 130px;
    font-weight: 500;
}

.product_orders .products_total .products_total_item span {
    width: 90px;
    text-align: right;
    /*font-weight: 700;*/
}

.product_orders .link {
    text-transform: uppercase;
    
    color: #464646;
    font-weight: 700;
    text-decoration: underline;
    font-size: 16px;
}

.fixed_checkout .btn {
    font-size: 17px;
    height: 50px;
    padding: 15px 10px;
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    z-index: 1;
}

.product_orders .link:hover {
    color: #DB203B;
}

.fixed_checkout {
    display: none;
    margin-top: 15px;
    margin-bottom: 10px;
    position: relative;
    z-index: 1;
}

.fixed_checkout .btn.remove_sticky{
    position: static;
    border-radius: 5px;
}

.modal-open-html header{
        z-index: 9;
}

.modal-open-html #scrollbutton.show{
    opacity: 0;
}

.mobileSticky .package_details_right_inner {
    position: fixed;
    top: 0;
    background: #fff;
    z-index: 1;
    right: 0;
    box-shadow: 0 0 10px rgb(0 0 0 / 50%);
    padding-bottom: 10px;
    max-width: 480px;
    width: 100%;
    left: auto!important;
}

.package_details .mobileSticky .pdr_left {
    width: auto;
}

.package_details .package_details_right .pdr_left {
    width: calc(100% - 150px);
}

.package_details .mobileSticky .pdr_right {
    width: 100%;
    text-align: left;
}

.package_details .package_details_right .pdr_right {
    width: 150px;
    text-align: right;
}

.package_details .mobileSticky .pdr_right h4 {
    float: right;
    margin: 0;
}

.package_details_right_inner .pdr_left h4{
    margin-bottom: 5px;
}

.product_det_cards .det_inner_content .custom_checkbox span:before {
    left: auto;
    right: 0;
    top: -3px;
}

.product_det_cards .custom_checkbox input {
    opacity: 0;
    filter: alpha(opacity=0);
    height: 100%;
    left: 0;
    top: -1px;
    margin: 0;
    width: 100%;
    position: absolute;
    cursor: pointer;
}

.product_det_cards .det_inner_content .custom_checkbox {
    width: 100%;
}

.product_det_cards .custom_checkbox span:before {
    content: "";
    background: #fff;
    position: absolute;
    width: 27px;
    height: 27px;
    border: 1px solid #939393;
    left: 0;
    top: 3px;
    pointer-events: none;
    line-height: 25px;
    border-radius: 5px;
    -webkit-transition: all .3s;
    transition: all .3s;
    color: #fff;
    font-size: 21px;
}

.product_det_cards .custom_checkbox span {
    position: relative;
    padding: 0 0 0 32px;
    pointer-events: none;
    display: block;
}

.product_det_cards .det_inner_content .custom_checkbox span {
    padding: 0 32px 0 0;
}

.product_det_cards .custom_checkbox input[type=checkbox]:checked+span:before{
    content: "\f00c";
    font-family: FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    background-color: #DB203B;
    border-color: #DB203B;
    color: #000;
}

.package_details .package_details_right{
        width: 62%;
}

.package_details .mobileSticky .package_details_right_inner {
    padding: 11px 20px 17px;
}

.package_details .package_details_right .package_details_right_inner {
    padding: 20px 40px 0 30px;
    display: flex;
    flex-wrap: wrap;
}

.package_details .mobileSticky .pdr_left {
    width: auto;
}

.package_details .mobileSticky .pdr_right {
    width: 100%;
    text-align: left;
}

.package_details .package_details_right h4 {
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 10px;
}

.package_details .mobileSticky .pdr_right h4 {
    float: right;
    margin: 0;
}

.package_details .package_details_right .pdr_right h5, .popup_product_details .counter span {
    font-size: 18px;
}

.mobileSticky .counter, .package_details .mobileSticky .pdr_right .flavours, .package_details .mobileSticky .pdr_right i {
    margin-left: 0;
}

.modal-body {
    position: relative;
    padding: 0px 15px;
}

.product_details_popup .mdf-done, .product_details_popup .mdf-cancel{
    width: 49%;
    padding: 10px 5px;
    font-size: 14px;
    min-width: 49%;
    margin: 0 0 5px!important;
    letter-spacing: 0px!important;
    font-weight: 600!important;
}

.product_details_popup .mdf-cancel:hover{
    border: 1px solid #282828;
    color: #fff;
    background: #282828;
}

.package_checkitem .custom_checkbox span p{
    text-align: left!important;
}

.input-file-sec .upload-btn{
    pointer-events: none;
    padding: 11px 5px!important;
    font-size: 14px;
    width: 140px;
    letter-spacing: 0px!important;
}

.input-file-sec{
    display: flex;
    align-items: baseline;
}

.input-file-sec .file-name{
    font-size: 12px;
}

/********************************* 21-11-2021 ******************************/

.checkout_section {
    padding: 40px 0 75px;
}

.checkout_section .checkout_section_inner {
    display: flex;
    flex-wrap: wrap;
    margin-left: -7px;
    margin-right: -7px;
    margin-bottom: 17px;
}

.checkout_section .product_orders {
    width: 50%;
    padding: 0 7px;
}

.checkout_section .product_orders .product-card {
    height: 100%;
}

.product_orders .product-card {
    box-shadow: 0 0 10px rgb(0 0 0 / 20%);
    margin: 0 0 10px;
}

.checkout_section .checkout_title {
    font-size: 16px;
    font-weight: 600;
    /*font-family: Lora;*/
    text-transform: uppercase;
    margin: 0;
    padding: 25px 10px 15px;
}

.checkout_section .checkout_section_inner .product_orders:first-child .checkout_title {
    margin-bottom: 20px;
}

.product_orders .del_address {
    padding: 25px 20px 17px;
    border-bottom: 1px solid #f4f4f4;
    
}

.checkout_section .product_orders .del_address {
    padding: 25px 30px 27px;
}

.product_orders .del_address .form-group {
    margin-bottom: 8px;
}

.checkout_section .input_field {
    position: relative;
}

.checkout_section .del_address label {
    font-weight: 600;
    margin-bottom: 8px;
    color: #000;
}

.checkout_section .form-control {
    display: block;
    width: 100%;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-color: #dcdcdc;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}


.checkout_section .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
}

/*.product_orders .del_address .form-control {
    font-family: Lora;
}*/

.checkout_section .form-group .error {
    display: inline-block;
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    margin-top: 7px;
    padding: 3px 15px;
    border-radius: 4px;
    position: relative;
    /*font-family: Helvetica;*/
}

.product_orders .del_address .form-group .row .col {
    padding-left: 3px;
    padding-right: 3px;
    flex-basis: 0;
    flex-grow: 1;
}

.product_orders .del_address .form-group .row{
    display: flex;
    flex-wrap: wrap;
}

.checkout_section .product_orders .label_check_grp .form-group {
    font-weight: 600;
    font-size: 12px;
    float: right;
    margin-top: -4px;
    margin-left: 10px;
}

.product_orders .del_address .form-group {
    margin-bottom: 8px;
}

.product_orders .del_address .form-group:last-child {
    margin-bottom: 0;
}

.custom_checkbox {
    position: relative;
    display: inline-block;
    line-height: 32px;
}

.custom_checkbox span {
    position: relative;
    padding: 0 0 0 32px;
    pointer-events: none;
    display: block;
}

.checkout_section .product_orders .label_check_grp .form-group .custom_checkbox span {
    padding: 0 27px 0 0;
}

.checkout_section .custom_checkbox input {
    margin: 0;
    position: absolute;
    top: 7px;
    right: 0;
    cursor: pointer;
    border-radius: 50% !important;
    width: 20px;
    height: 20px;
}

.checkout_section .product_orders .del_address:last-child {
    border-bottom: none;
}

.checkout_section .product_orders .del_address textarea.form-control {
    height: 180px;
    padding: 15px;
}

.checkout_section .product_orders .del_address .smcustom_scroll {
    border: 1px solid #d0cfcb;
    height: 137px;
    padding: 15px 6px 15px 15px;
    color: #bfbdbd;
}

.checkout_section .product_orders .terms_check {
    margin-top: 15px;
    font-weight: 700;
    font-size: 12px;
}

.checkout_section .custom_checkbox {
    position: relative;
    display: inline-block;
    line-height: 32px;
}

.checkout_section .custom_checkbox.terms_check input{
    left: 0;
    top: 0;
}

.checkout_section .custom_checkbox.terms_check span{
    line-height: 1.6;
}

.event_date {
    padding: 0 25px 0 30px;
    margin-top: 25px;
}

.event_date .form-group {
    padding-right: 30px;
    position: relative;
    margin: 0 0 10px;
}

.event_date .input_field {
    display: flex;
    /*flex-wrap: wrap;*/
    align-items: center;
}

.event_date .input_field label {
    width: 160px;
    margin: 0;
    font-weight: 600;
    text-transform: uppercase;
    color: #000;
}

.event_date .input_field .custom_select {
    width: calc(100% - 160px);
}

.checkout_section .product_orders .custom_select_inner {
    max-width: 160px;
    margin-left: auto;
}

.event_date .form-group .tooltip_link {
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    display: block;
}

.checkout_section .product_orders .order_det {
    padding: 0 13px;
}

.checkout_section .modifier_values_list_wrap{
    margin-bottom: 0;
}

.checkout_section .pkgsli_row{
    padding: 15px 0 10px;
    margin: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.checkout_section .pkgsli_row .col-sm-8.col-7{
    width: calc(100% - 110px);
    padding-left: 0px!important;
}

.checkout_section .pkgsli_row .col-sm-4.col-5.pkgsli_right{
    width: 110px;
    padding-right: 15px!important;
}

.checkout_section .product_orders .products_total {
    padding: 20px 35px 5px 25px;
}

.checkout_section .btn_big{
    border-radius: 5px;
    text-align: center;
}

.banner {
    position: relative;
    height: 305px;
}

.banner img{
    height: 100%;
    object-fit: cover;
    /* width: 100%; */
}

.product_orders {
    width: 375px;
    padding-left: 12px;
}

/*.pkgsli_right a.remove{
    background: #000;
    color: #fff;
    font-size: 10px;
    padding: 10px;
}*/

/***********************    package popup ***********************************/

.product_acc_grp .accordion .accordion_title span{
    font-family: 'Poppins', sans-serif;
}

.additionals_setuplist > li{
    list-style: none;
}

.custom-addon-checkbox input{
    top: 6px;
}

.addon_list_main .addon_linfo .custom_checkbox{
        width: 100%;
    line-height: 1.4;
}

.addon_list_main .addon_linfo .custom_checkbox input {
    opacity: 0;
    filter: alpha(opacity=0);
    height: 100%;
    left: 0;
    top: -1px;
    margin: 0;
    width: 100%;
    position: absolute;
    cursor: pointer;
}

.addon_list_main .addon_linfo .custom_checkbox span:before{
        content: "";
    background: #fff;
    position: absolute;
    width: 27px;
    height: 27px;
    border: 1px solid #939393;
    left: 0;
    top: -4px;
    pointer-events: none;
    line-height: 25px;
    border-radius: 5px;
    -webkit-transition: all .3s;
    transition: all .3s;
    color: #fff;
    font-size: 21px;
}

.addon_list_main .addon_linfo .custom_checkbox input[type=checkbox]:checked+span:before{
        content: "\f00c";
    font-family: FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    background-color: #DB203B;
    border-color: #DB203B;
    color: #000;
}

.addon_list_main .addon_linfo .custom_checkbox span h4{
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    margin: 0px 0px 0px 0px;
}

.addon_list_main .addon_linfo .custom_checkbox span p{
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    font-weight: 100;
    margin: 0px 0px 20px 0px;
}

.addon_list_main .addon_linfo .addon_price{
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
}

.addon_popup .mfp-close {
        right: 4px;
    background: #000;
    opacity: 1;
    width: 30px;
    height: 30px;
    line-height: 30px;
        top: 5px;
}

.addon_popup .mfp-close:after {
    content: "";
    background: url(../images/crosslg.png) no-repeat;
    width: 14px;
    height: 14px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
}

.addon_popup .mfp-close:hover{
    background: #282828;
}

.addon_popup .addon_popbot{
    text-align: center;
} 

.addon-btn-grp .btn_green {
    color: #fff;
    display: inline-block;
    border-radius: 4px;
    margin: 0px 0 10px;
    padding: 15px 15px;
    -moz-appearance: none!important;
    -webkit-appearance: none!important;
    appearance: none!important;
    cursor: pointer;
    background: #000;
    border: 0;
    font-size: 14px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    text-transform: uppercase;
    line-height: 1;
    text-align: center;
    overflow: hidden;
    position: relative;
    z-index: 0;
    
    letter-spacing: 3px;
    font-weight: 600;
}

.addon-btn-grp .btn_green:hover {
        color: #000;
    background: #DB203B;
}

.addon-btn-grp .mdf-cancel{
    width: 49%;
    padding: 10px 5px;
    font-size: 14px;
    min-width: 49%;
    margin: 0 0 5px!important;
    letter-spacing: 0px!important;
    font-weight: 600!important;
}

.addon-btn-grp .mdf-cancel:hover{
    border: 1px solid #fff;
    color: #fff;
}


.addon-btn-grp .btn{
    width: 100%;
    margin: 0 auto;
    text-align: center;
    justify-content: center;
    float: right;
    padding: 15px;
    border-radius: 4px;
}

.addon-btn-grp{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto;
}

.addon_checklist li .custom_checkbox {
    width: 100%;
    line-height: 1.4;
}

.addon_checklist li .custom_checkbox input {
    opacity: 0;
    filter: alpha(opacity=0);
    height: 100%;
    left: 0;
    top: -1px;
    margin: 0;
    width: 100%;
    position: absolute;
    cursor: pointer;
}

.addon_checklist li .custom_checkbox input[type=checkbox]:checked+span:before{
    content: "\f00c";
    font-family: FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    background-color: #DB203B;
    border-color: #DB203B;
    color: #000;
}

.addon_checklist li .custom_checkbox span:before {
    content: "";
    background: #fff;
    position: absolute;
    width: 27px;
    height: 27px;
    border: 1px solid #939393;
    left: 0;
    top: -4px;
    pointer-events: none;
    line-height: 25px;
    border-radius: 5px;
    transition: all .3s;
    color: #fff;
    font-size: 21px;
}

.pkgslist label {
    width: 100%;
    line-height: 1.3;
    padding-right: 90px;
    position: relative;
    font-weight: 600;
    margin-bottom: 8px;
    color: #000;
    font-size: 14px;
}

.pkgslist_additionrow{
    padding-left: 0px;
    display: flex;
    flex-wrap: wrap;
    color: #959595;
    line-height: 1.4;
    margin: 0 0 10px;
}

.product_orders .pkgslist_subaddition p{
    color: #000;
}

.product_orders .pkgsli_right span{
    color: #000;
}

.product_orders .pkgsli_right .remove {
    top: 18px;
    right: 18px;
}

.product_orders .pkgsli_right .remove {
    background: #b0b0b0;
    width: 14px;
    height: 14px;
    position: absolute;
    padding: 5px;
    right: 0px;
    top: 2px;
    border-radius: 0;
}

.product_orders .pkgsli_right .remove:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.product_orders .pkgsli_right .remove:after, .product_orders .pkgsli_right .remove:before {
    position: absolute;
    top: 3px;
    right: 7px;
    content: "";
    height: 8px;
    width: 1.2px;
    background-color: #fff;
}

.product_orders .pkgsli_right .remove img {
    width: 8px;
}

.product_orders .pkgsli_right .remove:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.product_orders .pkgsli_right .remove:hover{
    background: #000;
}

.checkout_section .product_orders .pkgsli_row{
        /*display: block;*/
    flex-wrap: wrap;

}

.catering_receipt_popup .pkgslist_additionrow{
        display: block;
}

/*.our-story-second h2 strong{
    font-weight: 900;
    font-family: 'Poppins', sans-serif;
}*/

.mk-catering-payment .chk-payment-col-radio li label{
    padding-left: 20px !important;
} 


.thank-order-detaildiv .tnk-chk-order .button:hover{ color: #fff;}


.thank-order-detaildiv .order-popup-inner {
    padding: 15px;
}

.signup-popup .custom_checkbox {
    display: flex;
}

.popup_product_details .product_acc_grp {
    margin-bottom: 0px;
}

.popup_product_details .additionals{
    padding: 0px 0px 0px;
}

.popup_product_details .additionals .instruction{
    padding: 20px 15px 20px;
}

.popup_product_details .additionals_setuplist > li{
    padding-bottom: 5px;
}

.catering_delivery_total_left h3{
    font-size: 15px;
}

.catering_delivery_total_left .delivery_total_right{
    font-size: 15px;
}

.catering_delivery_total_left img{
    height: 22px;
}

.event-container .custom_checkbox span{
    display: inline-block;
    line-height: 0;

}

.event-container .custom_checkbox{
    display: inline-block;
    line-height: 22px;   
}

.contactus_wrapper form.form_sec {
    display: flex;
    flex-flow: row wrap;
}

.contact_col {
    float: left;
    width: 50%;
    padding: 0 15px;
}

.contact_form textarea.form-control {
    margin: 0;
    height: 105px;
}

.contact_form .btn_sec .button {
    width: 100%;
    margin: 0px 0 0;
}

.catering_products_main .product_orders .pkgsli_row {
    /* display: block; */
    flex-wrap: wrap;
}

.catering_products_main .pkgsli_row {
    padding: 15px 0 10px;
    margin: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.catering_products_main .pkgsli_row .col-sm-8.col-7 {
    width: calc(100% - 110px);
    padding-left: 0px!important;
}

.catering_products_main .pkgsli_row .col-sm-4.col-5.pkgsli_right {
    width: 110px;
    padding-right: 18px!important;
}

.products_main .product_orders{
    display: block;
}

.checkout_section .product_orders .product-card .pkgslist_addition{
    width: 100%;
}

.thankyou-main-div .tnk-you .thank-order-detaildiv .pkgslist_addition .pkgslist_additionrow{
    display: block;
} 

.thankyou-main-div .tnk-you .thank-order-detaildiv .pkgslist_addition .pkgslist_additionrow .pkg-list-lhs > div{
    padding-right: 0px;
}

.catering_receipt_popup .mCSB_scrollTools{
    display: none!important;
}

.catering_receipt_popup .mCSB_inside > .mCSB_container{
    margin-right: 0px;
}

.catering_receipt_popup .panel-collapse {
        height: 100%;
    max-width: 100%;
}

.product_det_cards .det_inner_content .btn.btn_green,
.product_det_cards .det_inner_content .btn.mdf-cancel{
    text-transform: uppercase;
}
/*.home-insta-inner {
    display: flex;
}*/
.home-insta-inner img{ border: 3px solid rgba(255,255,255,0); object-fit: cover; width: 100%; height: 100%;}
/*.home-insta-inner {
    display: flex;
}*/

/*.home-insta-lhs {
    width: 30%;
}*/

.home-insta-rhs {
    width: 22%;
}

.home-insta-middle {
    width: 48%;
    display: flex;
    flex-flow: column;
}
.home-insta-middle > div{ display: flex;}
.home-insta-mtop img:first-child, .home-insta-mbtm img:last-child {
    width: 65%;
}

.home-insta-mtop img:last-child, .home-insta-mbtm img:first-child {
    width: 35%;
}
.home-insta-btmlink{  display: flex; justify-content: center; padding: 10px 0 0 0}
.home-insta-btmlink a {
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 3.9px;
    position: relative;
    color: #db203b;
}
.home-insta-btmlink a:after {
    display: block;
    position: absolute;
    width: 100%;
    top: 0%;
    left: 0%;
    content: attr(data-hover);
    opacity: 1;
    color: #000;
    -webkit-transition: text-shadow 1s, opacity 0.8s 0.2s;
    -moz-transition: text-shadow 1s, opacity 0.8s 0.2s;
    transition: text-shadow 1s, opacity 0.8s 0.2s;
}
.home-insta-btmlink a:hover:after {
    opacity: 0;
    text-shadow: -1px 30px 10px #db203b, 1px -30px 10px #db203b;
}
.home-order-now-li-item input {
    width: 100% !important;
}

.home-blog-slider .owl-stage {
    display: flex;
}

.home-blog-slider .owl-item > div {
    height: 100%;
}
.takeaway-bag, .dinein-plate {
    width: 90px;
}

.product-details-lhs > .slick-slider > .slick-list {
    border: 2px solid #da203a;
    line-height: 0;
}

.product-details-lhs > .slick-slider .slick-dots{
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    padding: 0;
    text-align: center;
}
.product-details-lhs > .slick-slider .slick-dots li {
    display: inline-block;
    margin: 0 5px;
}

.product-details-lhs > .slick-slider .slick-dots li button {
    padding: 0;
    width: 11px;
    height: 11px;
    font-size: 0;
    overflow: hidden;
    margin: 0;
    background: none;
    border: 1px solid #fff;
}

.product-details-lhs > .slick-slider .slick-dots li.slick-active button {
    background: #da203a; border: 1px solid #da203a;
}

#responsive_view {
    display: none;
}

.reservation-main-div .res-pm { padding: 0;}

.inn_product_row .addcart_row .qty_bx {
    width: 100%;
    height: 42px;
    padding: 0 38px;
    border-radius: 8px;
}

.inn_product_row .addcart_row .qty_bx span.qty_minus {
    left: 0px;
}

.inn_product_row .addcart_row .qty_bx span {
    width: 38px;
    height: 42px;
}

.product-addtional-info .accordion-item.active .title{
    background: #fff;
}

.favourite-main-list .product-lhs{
    width: 100%;
}

.products-list-ulmain .products-single-li .like{ position: absolute; top: 8px; right: 8px;}
.products-single-li .like:hover .no_mou,
.products-single-li .like.active .no_mou {display: none;}
.products-single-li .like:hover .mou_active,
.products-single-li .like.active .mou_active {display: flex;}
.products-single-li .like .mou_active {display: none;}
.like img{width: 23px;height: 19px}
.product-details-lhs .like{ position: absolute; top: 15px; right: 30px; z-index: 1;}
li.hcart_like.active a img:first-child, li.hcart_like a img:last-child {  display: none;}
li.hcart_like.active a img:last-child{ display: block;}

.products-list-ulmain li:hover, .category-products-main .products-single-li:hover{ border: 2px solid #db203b;}
.product-menu-listing .products-single-li .like.active img.active-hart{  display: block;}
.product-details-lhs .like img:first-child{ display: block;}
.product-details-lhs .like img:last-child{ display: none;}
.product-details-lhs .like.active img.active-hart{ display: block;}
.product-details-lhs .like.active img:first-child{ display: none;}

.common-inner-product { text-align: center; max-width: 1020px; margin: 0 auto; padding: 30px 0 0; font-weight: 500;}
.category-products-main{ margin-bottom: 75px;}
.category-products-main .owl-stage { display: flex;}
.container-noneed .category-products-main .products-single-li { height: 100%;}

.vouchar-popup .btn-block{
    display: block;
    padding: 14px 40px 14px 22px;
    color: #fff;
    background: #DB203B;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    position: relative;
    letter-spacing: 0.5px;
    margin: 15px 15px 0 25px;
    line-height: 1;
    font-weight: 600;
    border-radius: 6px;
    white-space: nowrap;
}

.vouchar-popup .promo-earned-content p { margin: 7px 0 5px;}
.voacher-main-div .voacher_btn-one{ width: 100%; margin: 0 15px; padding: 14px 10px 14px;}
.voacher-main-div .promo-earned-col { width: 32%; margin: 0 2% 20px 0;}
.voacher-main-div .promo-earned-col:nth-child(3n){ margin-right: 0;}
.voacher-main-div .myacc_order_details{ justify-content: flex-start;}
/************* PRODUCT VOACHER *********************/
.vouchergift-popup{ max-width: 900px; }
.vouchergift-popup .product_voucher{ margin-bottom: 30px;}
.vouchergift-popup .product_voucher li a, .vouchergift-popup .product_voucher li.active a{ 
    background-color: transparent;
}
.vouchergift-popup .product_voucher li a:hover{background: transparent;}
.vouchergift-popup .product_voucher li a span{ font-size: 24px; color: #000;}
.voucher-popup-inner h2{ font-size: 18px;}
.howtouse-gift-vouchar { padding-top: 30px;}
.vouchergift-popup .button { width: 100%;  margin: 15px 0 0;}
.howtouse-gift-vouchar h3{ font-size: 20px; margin: 10px 0 15px;}

.container-noneed .owl-dots, .featured-products-section .owl-dots{ text-align: center; display: flex; justify-content: center; margin: 20px 0 0;}
.container-noneed .owl-dots .owl-dot, .featured-products-section .owl-dot { margin: 0 3px;}
.container-noneed .owl-dots .owl-dot span, .featured-products-section .owl-dot span { display: block; width: 12px; height: 12px; border: 1px solid #000;
    background: transparent;  border-radius: 50%;
}
.container-noneed .owl-dots .owl-dot.active span, .featured-products-section .owl-dot.active span { background: #000;}

.product-tag-list ul li:hover{ border: none; }

.hcart_add_innr_loca {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 10px 0 5px;
}
.hcart_add_innr_loca .input_field {
    width: 100px;
    margin-left: 8px;
}
.hcart_add_innr_loca input.form-control {
    margin: 0;
    height: 38px;
    padding: 5px;
    border-radius: 6px;
}
a.cart-loc-change {
    font-size: 14px;
    border-bottom: 1px solid #c5c5c5;
    line-height: 1;
    display: inline-flex;
    font-weight: 500
}
.hmenu_list.desktop_hmenu_list > li:hover > ul, .hmenu_list.desktop_hmenu_list > li ul li:hover>ul {
    display: block;
}

.css-1pcexqc-container{ z-index: 4; }

.pay-xendit-popup .alert_height iframe{
    width: 500px;
    height: 360px;
}

.home-testimonial .owl-carousel .owl-nav.disabled{
    display: block;
}

.css-1pcexqc-container div{border-radius: 0px}

.product-item-img{display: flex; flex-wrap: wrap; justify-content: center;margin-bottom: 10px}
.product-item-img img{margin-right: 10px;height: 21px}
.product-item-img img:last-child{margin-right: 0px;}

.product-details-top-info .product-item-img{justify-content: flex-start;}

.featured-products-section .owl-item .product-item-img img{width: 21px}

.prodet_baninfo .product-tags-list ul li img{ height: 21px; margin-right: 5px;}

.contact_form h3{text-align: center;    font-size: 20px;}

.small_sticky .product-cconmid-rhs .menu-list-intvl.active .menu-title-link{
    color: #fff;
}

.small_sticky .product-cconmid-rhs a.menu-title-link{
    color: #717171;
}
.small_sticky .product-cconmid-rhs a.menu-title-link:hover{
    color: #fff;
}

.small_sticky .product-cms-content-middle{
    margin: 10px;
    padding: 10px;
}


/*Darker Version Css*/

body.dark-theme{    background: url(../images/home-bg.jpg);color: #fff }

.dark-theme h1,
.dark-theme h2,
.dark-theme h3,
.dark-theme h4,
.dark-theme h5,
.dark-theme h6 {
    color: #fff;
}
.dark-theme input[type="reset"],
.dark-theme input[type="button"],
.dark-theme input[type="submit"],
.dark-theme .button,
.dark-theme button {
    color: #000;
    background: #fff;
}
.dark-theme input[type="reset"]:hover,
.dark-theme input[type="button"]:hover,
.dark-theme input[type="submit"]:hover,
.dark-theme .btn:hover,
.dark-theme .button:hover,
.dark-theme button:hover {
    color: #fff;
    background: #DB203B;
}
.dark-theme a{
    color: #fff;
 }   
.dark-theme a:hover {color: #DB203B;}
.dark-theme .homebanner-content-inner h2{color: #000}
.dark-theme .homebanner-content-inner h4{color: #000}
.dark-theme .homebanner-content-inner p{color: #000}
.dark-theme .homebanner-content-inner a{color: #000}
.dark-theme .homebanner-content-inner a:hover {color: #DB203B;}
.dark-theme .home-content{background: transparent;}
.dark-theme .home-content-inner ul li a{background: #000;color: #fff;border: 1px solid #000;box-shadow:none;}
.dark-theme .home-content-inner ul li a:hover, .home-content-inner ul li.active a{ background: #e2193f; color: #fff }
.dark-theme .featured-products-section .products-list-ulmain li{background: #000;border: 2px solid #000;}
.dark-theme .products-list-ulmain li:hover, .dark-theme .category-products-main .products-single-li:hover{ border: 2px solid #db203b;}
.dark-theme .product-info-div, .det_inner_content{background: #000} 
.dark-theme .product-price h3{color: #fff}
.dark-theme .home-our-journey{background: #f5f5f5;color: #535353}
.dark-theme .hoj-lhs h2{color: #000}
.dark-theme .hoj-lhs h5{color: #000}
.dark-theme .hoj-lhs .button{background: #fff; border: 1px solid #535353;color: #535353}
.dark-theme .hoj-lhs .button:hover{background: #e2193f; border: 1px solid #e2193f;color: #fff}
.dark-theme .f-newletter-form .button{background: none}
/*.dark-theme .footer-newletter span{color: #e2193f}*/
.dark-theme .mobile-footer-nav .accordion button.title{ color: #fff; }
.dark-theme .f-newletter-form input[type="text"]::placeholder{color: #e2193f}
.dark-theme .f-newletter-bottom label{color: #f5f5f5}
.dark-theme .home-bottom-link a:after{filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);}
.dark-theme .home-testimonial .owl-prev:before{ filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);}
.dark-theme .home-testimonial .owl-next:before{ filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);}
.dark-theme .product-short-description{color: #cac9c9}
.dark-theme .qty_bx span.qty_minus{/*background: #2c2c2c;*/background: transparent; color: #fff;border: 2px solid #2c2c2c;/*border-right: 2px solid #ffffff08;*/}

.dark-theme .hcart_scrollarea .qty_bx span.qty_minus {
    background: transparent;
    border: 2px solid #000000;
}

.dark-theme .hcart_scrollarea .qty_bx input {
    background: #18121200;
    color: #fff;
    border: 2px solid #0000;
}

.dark-theme .hcart_scrollarea .qty_bx span.qty_plus {
    border: 2px solid #000000;
    background: #000000;
}

.dark-theme .qty_bx span.qty_plus:before, .dark-theme .qty_bx span.qty_plus:after{background: #fff;}
.dark-theme .qty_bx span.qty_minus:before{background: #fff;}
.dark-theme .qty_bx span{background: #2c2c2c;}
.dark-theme .qty_bx input{/*background: #2c2c2c;*/background: #000; color: #fff; border: 2px solid #000;}
.dark-theme .compo_update_div .qty_bx input { background: #0000; border: 2px solid #0000;}
.dark-theme .compo_update_div .qty_bx{ border: none; }
.dark-theme .product-cconmid-rhs .menu-list-intvl div{border:2px solid #000;}
.dark-theme .menu-title-link div:hover, .dark-theme .owl-carousel .owl-item .menu-list-intvl.active div{border-color: #DB203B;}
.dark-theme .productlist-main-div .products-list-ulmain > li,  .dark-theme .product_det_cards > ul li.det_card{background: #000; border: 2px solid #000;}
.dark-theme .products-list-ulmain li:hover, .dark-theme .category-products-main .products-single-li:hover{ border: 2px solid #db203b;}
.dark-theme .product-short-description p span{background-color: transparent;}
.dark-theme .product-cconmid-rhs .menu-list-intvl.active .menu-title-link{color: #f2f2f0}
.dark-theme .product-cconmid-rhs a.menu-title-link:hover{ color: #f2f2f0; }
.dark-theme .filters-lhs ul, .dark-theme .filters-rhs ul, .dark-theme .cafe-lhs-filter ul{background: #000}
.dark-theme .addcart_row .btn{color: #000; height: 40px;}
.dark-theme .addcart_row .btn:hover{color: #fff}
.dark-theme .product-filters{border-bottom:2px solid #ffffff08;}
.dark-theme .delevery_popup, .dark-theme .error_postal_popup, .dark-theme .offline_popup{background: #3a3a3a;border: 1px solid #db203b}
.dark-theme .datetime_selt_lbl{color: #fff}
.dark-theme .cafe-popup .full-login-new-body{background: #3a3a3a}
.dark-theme .order_delivery_item li a{background: #000; box-shadow: 0px 5px 16px 0px rgb(255 255 255 / 2%);}
.dark-theme .order_delivery_item h3{color: #fff}
.dark-theme .order_delivery_item li a.active, .dark-theme .order_delivery_item li a:hover{background: #fff}
.dark-theme .order_delivery_item li a.active h3, .dark-theme .order_delivery_item li a:hover h3{color: #000}
.dark-theme #ordernowpopup, .dark-theme #order-popup{background: #3a3a3a; border: none}
.dark-theme .self_popup, .dark-theme .delevery_popup_datetime{background: #3a3a3a; border: 1px solid #db203b}
.dark-theme .white-popup{background: #3a3a3a; border: none}
.dark-theme .warning_popup .alt_btns a.button-left{background: #db203b; color: #fff}
.dark-theme .warning_popup .alt_btns a.button-left:hover{background: #fff; color: #000}
.dark-theme .outlet-list-name{color: #000}
.dark-theme .otulet-direction span a{color: #000}
.dark-theme .self_outlet .self_outlet_inner > li:hover > span, .dark-theme .self_outlet .self_outlet_inner > li.active > span{border: 1px solid #db203b;background: #000}
.dark-theme .self_outlet .self_outlet_inner > li > span{border: 1px solid #000}
.dark-theme .scan-qr{border: 2px solid #000}
.dark-theme .scan-qr:hover {border: 2px solid #000;background: #fff;}
.dark-theme .dinein_popup_item .takelist-btn-part .button{background: #fff;color: #000;}
.dark-theme .dinein_popup_item .takelist-btn-part .button:hover{background: #db203b;color: #fff;}
.dark-theme .dinein_popup_item .takelist-btn-part .takeaway-btn-act{background: #db203b;color: #fff;}
.dark-theme .dinein_popup_item .takelist-btn-part .takeaway-btn-act:hover{background: #000;color: #fff;}
.dark-theme .delevery_popup_datetime .btn_sec input.button{background: #db203b;color: #fff;}
.dark-theme .delevery_popup_datetime .btn_sec input.button:hover{background: #000;color: #fff;}
.dark-theme .two-button-row .button-right{background: #db203b;color: #fff;}
.dark-theme .two-button-row .button-right:hover{background: #000;color: #fff;}
.dark-theme .full-login-new{background: #3a3a3a}
.dark-theme .login-popup, .dark-theme .signup-popup, .dark-theme .forgot-password-popup{background: #3a3a3a;border: 1px solid #db203b}
.dark-theme .or-seperator span{background: #3a3a3a;color:#fff}
.dark-theme .pop-whole .kep-login-facebook.metro, .dark-theme #login-content .kep-login-facebook.metro{border: 1px solid #000;color: #fff}
.dark-theme .pop-whole .kep-login-facebook.metro:hover, .dark-theme #login-content .kep-login-facebook.metro:hover{border: 1px solid #3d5a98;}
.dark-theme .login_pop_sub button:hover{border: 1px solid #db203b;}
.dark-theme .controls.two-links a{color: #fff}
.dark-theme .controls.two-links a:hover{color: #db203b}
.dark-theme .login_pop_sub button{color:#000; background: #fff;}
.dark-theme .controls.single-link a{color: #fff}
.dark-theme .controls.single-link a:hover{color: #db203b}
.dark-theme .popup-body .sign_reg p{color: #fff}
.dark-theme .popup-body .sign_reg a{color: #fff}
.dark-theme .popup-body .sign_reg a:hover{color: #db203b}
.dark-theme .common-inner-banner{color: #fff}
.dark-theme .common-inner-banner p{color: #fff}
.dark-theme .cms-page .container{background: #000}
.dark-theme .box_in.accsec_right{background: #3a3a3a; border: none;box-shadow: none;}
/*.dark-theme .accsec_right .welcome-title h1>div span{color: #fff}*/
.dark-theme .other-add-row li{color: #000}
.dark-theme .filter_tabsec .main_tabin .account_sec .accmenu_sec .setting_menu_list > ul li.active a{background: #db203b;color: #ffffff;}
.dark-theme .filter_tabsec .main_tabin .account_sec .accmenu_sec .setting_menu_list > ul > li > a{    color: #000;    background: #fff;}
.dark-theme .filter_tabsec .main_tabin .account_sec .accmenu_sec .setting_menu_list > ul li a:hover{    background: #db203b;color: #ffffff;}
.dark-theme .css-1pcexqc-container div{background: #fff; color: #000}
.dark-theme .myacc_filter .nav-tabs > li.active > a, .dark-theme .nav-tabs > li.active > a:focus, .dark-theme .nav-tabs > li.active > a:hover{background-color: #3a3a3a;color: #fff}
.dark-theme .myacc_filter .product_voucher > li.active > a, .dark-theme .nav-tabs > li.active > a:focus, .dark-theme .nav-tabs > li.active > a:hover{background-color: transparent;color: #fff}
.dark-theme .myacc_filter .nav-tabs > li > a{background-color: #3a3a3a;color: #fff}
.dark-theme .myacc_filter .product_voucher > li > a{background-color: transparent;color: #fff}
.dark-theme .myacc_filter .nav.nav-tabs.text-center > li:before{background: #db203b;    height: 4px;}
.dark-theme .myacc_filter .nav.nav-tabs.text-center{border-bottom: 4px solid #e3e3e3;}
.dark-theme .setting_menu_list > ul > li > a{background: #fff;color: #000;}
.dark-theme .setting_menu_list > ul > li > a:hover{background: #db203b;color: #ffffff;}
.dark-theme .accmenu_sec .setting_menu_list ul li.active a{background: #db203b;color: #ffffff;}
.dark-theme .promo-form .applyBtn{background: #db203b;    color: #fff;}
.dark-theme .promo-earned-code{color: #fff}
.dark-theme .promation_btn .button{color: #fff;background: #db203b;}
.dark-theme .promo-bot-left p{color: #fff}
.dark-theme .promation_btn .promation_btn-one{ background: #f7f7f7; color: #000; border: 1px solid #d4d4d1; }
.dark-theme .promation_btn .promation_btn-one:hover{ background: #000; color: #fff; border: 1px solid #000; }
.dark-theme .inn-product-popup{background: none}
.dark-theme .prd_chosen_sub_item_right button{background: #ce0e2d; color: #fff;}
.dark-theme .prd_chosen_sub_item_right button:hover{background: #fff; color: #000;}
.dark-theme .text-box em{color: #fff}
.dark-theme p.total_price.product_details_price{color: #fff}
.dark-theme .product-description-tab ul{border-bottom: 3px solid #eaeaea;}
.dark-theme .product-description-tab ul li:after{background: #db203b;}
.dark-theme .product-description-tab ul li a:hover{color:#fff}
.dark-theme .product-go-back a:before{filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);}
.dark-theme .products-single-li{border: 2px solid #000;}
.dark-theme .cartaction_checkout{background: #c81d35;color: #fff}
.dark-theme .cartaction_checkout:hover{background: #000;color: #fff}
.dark-theme .hcart_tt{background: #db203b;}
.dark-theme .hcart_tt h3{color: #fff}
.dark-theme .hcart_dropdown{background: #2c2c2c}
.dark-theme .product-details{border-bottom: 2px solid #ffffff08;}
.dark-theme .delivery-cart-div p{color: #fff}
.dark-theme .product_orders_top{color: #000;background: #fff}
.dark-theme .product_modify_cart .hclear_cart{color: #000;border-bottom: 0;}
.dark-theme .product_modify_cart .hclear_cart.modify_item {border-bottom: 1px solid #000;}
.dark-theme .product_modify_cart .hclear_cart:hover{color: #DB203B}
.dark-theme header .hcart_dropdown .cart_footer{background: #3a3a3a}
.dark-theme .indication_progress{background: #fff}
.dark-theme .progress_bar_div .help-block{color: #fff}
.dark-theme .product_orders_top h4{color: #000}
.dark-theme .checkout-main-div .birthday-info-div .birthday-info-top h3>div span{color: #fff}
.dark-theme .checkout-heading{background: #585858;color: #fff}
.dark-theme .cdd-details-lhs, .dark-theme .cdd-details-rhs{background: #000; border: 2px solid #ffffff08;}
.dark-theme .chk_hea{color: #fff}
.dark-theme .order-details-with-clear h5, .dark-theme .order_card_head h5{color: #000}
.dark-theme .checkout-billing-address .chk_hea{color: #000}
.dark-theme .redeem-col{background: #000;border: 2px solid #ffffff08;}
.dark-theme .redeem-item-hea .points{background: #fff;color: #000}
.dark-theme .redeem_apply .button{background: #db203b;color: #fff}
.dark-theme .redeem_apply .button:hover{background: #000;color: #fff}
.dark-theme .birthday-info-middle{color: #fff}
.dark-theme .chk-payment{border: 2px solid #ffffff08;}
.dark-theme .chk-payment-btn-row .button {background: #c81d35;color: #fff;}
.dark-theme .chk-payment-btn-row .button:hover{ background: #000; color: #fff; }
.dark-theme .chekout_cart_bar .cart_footer{background: #3a3a3a}
.dark-theme .mainacc_toptext p{color: #fff}
.dark-theme .tnk-detail h2{background: #585858;color: #fff}
.dark-theme .tnk-delivery{background: #2a2a2a}
.dark-theme .thankyou-main-div .innersection_wrap.tnk-you .thank-order-detaildiv .tnk-detail .tnk-order{background: #fff}
.dark-theme .thankyou-main-div .innersection_wrap.tnk-you .thank-order-detaildiv .tnk-detail .tnk-order h3{color: #000}
.dark-theme .thankyou-main-div .innersection_wrap.tnk-you .thank-order-detaildiv .tnk-detail .tnk-order p{color: #000}
.dark-theme .delivery_total_number .delivery_total_left h4{color: #fff}
.dark-theme .order-details-with-clear, .order_card_head{background: #fff}
.dark-theme .thankyou-main-div .tnk-you .thank-order-detaildiv .tnk-delivery .cart_footer.tnk_cart_footer{background: #3a3a3a}
.dark-theme .tnk-delivery .tnk_cart_footer .cart_row.grant-total-cls{border-bottom: 2px solid #ffffff08;}
.dark-theme .thank-order-detaildiv .tnk-chk-order .button{background: #db203b; color: #fff}
.dark-theme .thankyou-main-div .tnk-you .thank-order-detaildiv .tnk-delivery .tnk-chk-order .button:hover{background: #fff;color: #db203b}
.dark-theme .thank-order-detaildiv{box-shadow: none;border: none}
.dark-theme .tnk-detail{margin-top: 0px}
.dark-theme .myacc_head_sec{background: #000;color: #fff}
.dark-theme .myacc_order_details .current_order{background: #000;border: none}
.dark-theme .delivery_total_left span{color: #fff}
.dark-theme .order_amt h3{color: #000 }
.dark-theme .count_num_t{background: #db203b;color: #fff}
.dark-theme .setting_menu_list li:hover .count_num_t, .dark-theme .setting_menu_list li.active .count_num_t{background: #fff;color:#000}
.dark-theme .web_order_typecls li:hover .count_num_t, .dark-theme .web_order_typecls li.active .count_num_t{color: #000}
.dark-theme .Viewreceipt .tnk-order{background: #000}
.dark-theme .Viewreceipt .tnk-order h3{color: #fff}
.dark-theme .Viewreceipt .tnk-order p{color: #fff}
.dark-theme .Viewreceipt .order-items-maindiv{background: #2a2a2a;}
.dark-theme .congrats-inner h2{color: #db203b}
.dark-theme .normal-popup{background: #3a3a3a; border: 1px solid #db203b}
.dark-theme .promo-check-popup{border: none;}
.dark-theme .normal_popup_cont p{color: #fff}
.dark-theme .normal-popup button.mfp-close:hover{background: #000}
.dark-theme .promopopup-lhs .frm-inputbtn-div .button{color: #fff}
.dark-theme .innerproduct p{max-width: 920px;margin: 0 auto;text-align: center;margin-bottom: 15px}
.dark-theme .omise-pay-title{color: #fff}
.dark-theme .pay-xendit-popup .omise-pay-title{color: #000}
.dark-theme .pay-gcash-popup .omise-pay-title{color: #000}
.dark-theme .pay-paymaya-popup .omise-pay-title{color: #000}

.dark-theme .filters-lhs span, .dark-theme .filters-rhs span, .dark-theme .cafe-lhs-filter span{color: #fff}
.dark-theme .prd_chosen_row.compo_top_div{background: none}
.dark-theme .compo_pro_acc{background: #3a3a3a}
.dark-theme .compopro_acc_head::before, .dark-theme .compopro_acc_head::after{background: #fff}
.dark-theme .chosen_adn .combo-input{background: none;color: #fff}
.dark-theme .compo_update_div button{ background: #db203b;color: #fff }
.dark-theme .head_right .head-group h4{color: #000}
.dark-theme .head_right::before{background : #fff}
.dark-theme .web_order_typecls li.active a, .dark-theme .web_order_typecls li a:hover {background: #db203b;}
.dark-theme .delivery_total_left img{filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);}
.dark-theme .mobile-order-lhs{background: #fff;}
.dark-theme .mobile-order-lhs a{color: #000}
.dark-theme .congrats-inner, .dark-theme .promo-form{background: none}
.dark-theme .refer-btn a:hover{background: #fff;color: #DB203B}
.dark-theme .acc-inform .form-group .row .button:hover {background: #fff;color: #db203b}
.dark-theme .cart_extrainfo p, .dark-theme .notes-block{color: #ededed}
.dark-theme .cart_extrainfo p b{color: #ededed}
.dark-theme .cart-direction img{filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);max-height: 33px;transform: scaleX(-1);}
.dark-theme .mainacc_toptext img{filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);} 
.dark-theme .frm-action-div{   background: #3a3a3a; color: #fff;}
.dark-theme .birthday-info-div{border-top: 2px solid #ffffff08;;}
.dark-theme .chk-payment-col-radio li{border: 2px solid #ffffff08;background: #fff;color: #000;}
.dark-theme .vouchergift-popup .product_voucher_body .product_voucher li a span{color: #fff}
.dark-theme .omise_pay_header{border-bottom: 2px solid #ffffff08;}
.dark-theme .pay-xendit-popup .omise_pay_header{border-bottom: 1px solid #d2d2d3;}
.dark-theme .omise_pay_body{border-top:2px solid #ffffff08;}
.dark-theme .myacc-main-div .order_btns .view_recipt{ background: #db203b; color: #fff }
.dark-theme .myacc-main-div .order_btns .view_recipt:hover{ background: #000; color: #fff }
.dark-theme .product-details-lhs > .slick-slider > .slick-list{border: none;background: #2c2c2c}
/*.dark-theme .inn_product_row .addcart_row .qty_bx{border: 2px solid #ffffff08;}*/
.dark-theme .prd_chosen_sub_row{border-top: 2px solid #ffffff08;}
.dark-theme img.pop-scooter-img{filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);}

.dark-theme .self_popup .full-login-new-body .takeaway-bag{filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);} 

.dark-theme .self_popup .full-login-new-body .dinein-plate{filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);}

.dark-theme .birthday-info-top h3 img{filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);}
.dark-theme .pay-xendit-popup .omisepop_in .alt_btns .button{background: #db203b;color: #fff}
.dark-theme .pay-xendit-popup .omisepop_in .alt_btns .button:hover{background: #000;color: #fff}
.dark-theme .pay-gcash-popup .omisepop_in .alt_btns .button{background: #db203b;color: #fff}
.dark-theme .pay-gcash-popup .omisepop_in .alt_btns .button:hover{background: #000;color: #fff}
.dark-theme .pay-paymaya-popup .omisepop_in .alt_btns .button{background: #db203b;color: #fff}
.dark-theme .pay-paymaya-popup .omisepop_in .alt_btns .button:hover{background: #000;color: #fff}

.dark-theme .delivery_total_text::before{border: 2px dashed #000000;}
.dark-theme .Viewreceipt .tnk-delivery .delivery_total_number{border-bottom: 2px dashed #000000;}
.dark-theme .add-extra-paired-products{background: #2c2c2c}
.dark-theme .add-extra-paired-products .slick-slide > div button{background: #db203b;color: #fff}
.dark-theme .add-extra-paired-products .slick-slide > div button:hover{background: #000;color: #fff}
.dark-theme .cart-direction::before{border: 1px dashed #000000;}
.dark-theme .qty_bx {/*border: 2px solid #ffffff08;*/ border: 0;}

.dark-theme .qty_bx span.qty_plus {/*border-left: 2px solid #ffffff08;*/ border: 2px solid #2c2c2c;}
.dark-theme .mobile-cart h4{color: #000}
.dark-theme .checkout-billing-address{border-bottom:2px solid #ffffff08;}
.dark-theme .Viewreceipt .delivery_total::before{border: 2px dashed #000;}
.dark-theme .tnk-delivery .cart_info{ color: #fff3f3 }
.dark-theme .promo-earned-col-item{background: #2a2a2a;}
.dark-theme .promo-earned-info:before{background: #2a2a2a;}
.dark-theme .refer-friend-reward-section p{color: #a9a9a9}
/*.dark-theme .accsec_right .welcome-title h1> img{filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);}*/
.dark-theme .react-datepicker button{background: none}
.dark-theme .pay-omiseconf-popup{background: #f5f5f7}
.dark-theme .container-noneed .owl-dots .owl-dot.active span{background: #fff;}
.dark-theme .container-noneed .owl-dots .owl-dot span{border: 1px solid #fff;}
.dark-theme .faq-page .accordion .accordion-item .title{background: #000;color: #fff; border-bottom: 1px solid #ffffff08; padding: 18px 15px 8px;}
.dark-theme .accordion .accordion-item.active .panel{ padding: 0 15px; }
.dark-theme .pagesList-main-div .myacc_filter .nav-tabs > li.active > a, .dark-theme .pagesList-main-div .nav-tabs > li.active > a:focus, .dark-theme .pagesList-main-div .nav-tabs > li.active > a:hover{background: transparent;}
.dark-theme .pagesList-main-div .myacc_filter .nav.nav-tabs.text-center > li:before{height: 5px}
.dark-theme .faq-page .cms-content .accordion .accordion-item .title::before, .dark-theme .faq-page .cms-content .accordion .accordion-item .title::after{background: #fff}
.dark-theme .faq-page .accordion .accordion-item .panel p{background: transparent;color: #fff}
.dark-theme .panel{background: #3a3a3a;}
.dark-theme .pagesList-main-div .cms-page .cms-content p{color: #fff}
.dark-theme .ourrest_info{background: #3a3a3a}
.dark-theme .ourrest_info p{color: #fff}
.dark-theme .ourrest_info p strong{color: #fff}
.dark-theme .li-full-width a:hover{background: #000;color: #fff}
.dark-theme .page404-main-div .gohome{background: #DB203B;color: #fff}
.dark-theme .page404-main-div .gohome:hover{background: #000;color: #fff}
.dark-theme .contact_form .btn_sec .button{background: #DB203B;color: #fff}
.dark-theme .contact_form .btn_sec .button:hover{background: #000;color: #fff}
.dark-theme .pagesList-main-div .myacc_filter .nav.nav-tabs.text-center{border-bottom: transparent;}
.dark-theme .page404-main-div img {filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);}
.dark-theme .offline-head p{color: #fff}
.dark-theme .offline-head h2{color: #fff}

.dark-theme .addcart_done_maindiv .qty_bx input{ background: transparent; border: none;}


/************************* 30-03-22 suresh css *****************************************************************/

.product-cms-content-middle.sticky-menu-nav{
    display: none;
}

.product-cms-content-middle.sticky-menu-nav .menu-title-link{
    flex-flow: column;
}

.product-cms-content-middle.sticky-menu-nav .menu-list-intvl.active a{
    background: #fff;
}

.product-cms-content-middle.sticky-menu-nav .menu-list-normal{
    display: none;
}

.product-cms-content-middle.sticky-menu-nav .menu-title-link div:hover, 
.product-cms-content-middle.sticky-menu-nav .owl-carousel .owl-item .menu-list-intvl.active div,
.product-cms-content-middle.sticky-menu-nav .product-cconmid-rhs .menu-list-intvl div{
    border: none;
}


.menu-nav-section.sticky-menu-nav{
    background: #f6f6f6;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px;
}

.menu-nav-section.sticky-menu-nav .menu-list-intvl.active .menu-title-link,
.menu-nav-section.sticky-menu-nav .menu-list-intvl:hover .menu-title-link {
    color: #000;
    background: #f6f6f6;
}

.menu-nav-section.sticky-menu-nav .menu-list-intvl a:before, .menu-nav-section.sticky-menu-nav .more-menu-parent:before{
    background: #db2139;
}

.menu-nav-section.sticky-menu-nav .menu-list-intvl a{
    color: #606060;
    font-weight: 600;
    text-transform: capitalize;
    padding: 14px 20px;
}
.inn_product_hea .product-item-img{
    display: contents;
}
.check_pg .products-ordernow-action .button{
    margin: 5px 0px;
    font-size: 14px;
    padding: 15px 8px;
    min-width: 102px;
    height: auto;
    display: inline-block;
}

.check_pg .owl-carousel .owl-stage{
    display: flex;
}

.check_pg .products-single-div{
    text-align: center;
    height: 100%;
    border: 1px solid #e5e5e5;
}

.check_pg .products-image-div{
    height: 150px;
}

.check_pg .product-info-div{
    height: calc(100% - 150px);
    padding: 10px 10px 100px 10px;
}

.check_pg .products-image-div img{
    height: 100%;
}

.check_pg .product-title h3{
    font-size: 14px;
    color: #000;
}

.check_pg  .product-short-description p{
    font-size: 12px;
}

.check_pg .addcart_done_maindiv .qty_bx {
    width: 102px;
    height: 40px;
    padding: 0 35px;
}

.check_pg .addcart_done_maindiv .qty_bx span {
    width: 35px;
}

.check_pg .qty_bx span.qty_minus:before, .check_pg .qty_bx span.qty_plus:before{
    width: 12px;
}

.check_pg .qty_bx span.qty_plus:after{ height: 12px; }

.check_pg .addcart_done_maindiv .button{
    margin: 10px 0;
}

.addcart_done_innerdiv {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
    max-width: 190px;
    margin: 0 auto;
}

.check_pg .addcart_done_maindiv{
    margin: 10px 10px 5px;
}

.check_pg .owl-dots {
    display: flex !important;
    margin: 15px 0 0 0;
    padding: 0;
    background: #cfcecb;
    height: 6px;
    width: 100%;
    border-radius: 10px;
    list-style: none;
    position: relative;
    justify-content: stretch;
}
.check_pg .owl-dot{
    width: 25%;
}
.check_pg .owl-dot span{
    padding: 0;
    font-size: 0;
    margin: 0;
    background: #cfcecb;
    height: 6px;
    border-radius: 10px;
    width: 100%;
    display: block;
}
.check_pg .owl-dot.active span{ background: #DB203B; }

.checkout_bar {
    padding: 5px 0 10px;
}

.checkout_bar .progress_bar {
    background: #29d492;
}

.checkout_bar .progress_bar_div {
    padding: 5px 25px 10px;
}

.dark-theme .pickup-cart-div h4{ color: #fff; }

.dark-theme .check_pg_inner{ background: #2c2c2c; }

.dark-theme .check_pg .products-single-div{ border: 1px solid #2c2c2c; }

.dark-theme .check_pg .product-title h3{ color: #fff; }

.dark-theme .check_pg .qty_bx input{ border-radius: 5px; }

.maccont_membershipmaindiv{
    background: url(../images/myaccount-bg.png) no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 40px 20px;
    box-shadow: rgb(0 0 0 / 6%) 0px 2px 4px;
}

.maccont_sec{
    flex-flow: column;
}

.maccont_memberimagediv ul{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0;
}

.maccont_memberimagediv ul li{
    list-style: none;
}

.maccont_memberimagediv ul li div{
    opacity: 0.5;
}

.maccont_memberimagediv ul li.active div{
    opacity: 1;
}

.maccont_memberimagediv {
    width: 380px;
}

.maccont_membershipmaindiv .welcome-title{
    width: calc(100% - 380px);
}

.maccont_sec .accsec_right{
    padding-top: 20px;
}

.maccont_membershipmaindiv .rewards-title{
    width: calc(100% - 380px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
}

.maccont_membershipmaindiv .rewards-title h4{
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 4px;
    margin-bottom: 10px;
    font-size: 18px;
}

.maccont_membershipmaindiv .rewards-title h3{
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 10px;
    font-size: 28px;
    color: #fbd008;
}

.welcome-title a {
    font-weight: 500;
    border-bottom: 1px solid #000;
    font-size: 14px;
}


.dark-theme .f-newletter-form input[type="text"]::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    #fff;
}
.dark-theme .f-newletter-form input[type="text"]:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color:    #fff;
   opacity:  1;
}
.dark-theme .f-newletter-form input[type="text"]::-moz-placeholder { /* Mozilla Firefox 19+ */
   color:    #fff;
   opacity:  1;
}
.dark-theme .f-newletter-form input[type="text"]:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color:    #fff;
}
.dark-theme .f-newletter-form input[type="text"]::-ms-input-placeholder { /* Microsoft Edge */
   color:    #fff;
}

.dark-theme .f-newletter-form input[type="text"]::placeholder { /* Most modern browsers support this now. */
   color:    #fff;
}

.dark-theme .mobile-footer-nav .accordion .accordion-item.active button.title{ color: #000; }

.dark-theme .accsec_right .welcome-title h1{ color: #000; }
.dark-theme .welcome-title a, .dark-theme .rewards-title a{color: #000;}
.dark-theme .maccont_membershipmaindiv .rewards-title h4{ color: #000; }

.dark-theme .featured-products-section .owl-dot.active span { background: #fff;}
.dark-theme .featured-products-section .owl-dot span { border: 1px solid #fff;}

.dark-theme .detail-pg-slider .owl-dots .owl-dot span{ border: 1px solid #fff; }
.dark-theme .detail-pg-slider .owl-dot.active span { background: #fff; }

.logo{
    max-width: 150px;
    height: 86px;
}

.common_compo_div .custom_radio {
    display: flex;
    justify-content: space-between;
    position: relative;
    flex-direction: row-reverse;
    padding: 10px 0 10px 0px;
}

.compo_inner_main .components_selctbox_cls:after,
.compo_inner_main .custom-select-bxcls:after{
    display: none;
}

.components_selctbox_cls .subscribe_accept{
    width: 28px;
    height: 28px;
    border-radius: 50%;
}

@supports (-webkit-appearance: none) or (-moz-appearance: none){

    .dark-theme .components_selctbox_cls input[type=checkbox]:checked{
        background: #000;
        border: 1px solid #2c2c2c;
    }

    .dark-theme .components_selctbox_cls input[type=checkbox]:not(.switch) {
        border-radius: 50%;
        background: #0000;
        border: 1px solid #5b5b5b;
    }

    .components_selctbox_cls input[type=checkbox]:not(.switch) {
        border-radius: 50%;
    }

    .components_selctbox_cls input[type=checkbox]:not(.switch):after {
        width: 6px;
        height: 14px;
        left: 11px;
        top: 5px;
    }
}

.common_compo_div .custom_radio input{
    width: 28px;
}

.common_compo_div .custom_radio span{
    width: calc(100% - 30px);
}

.welcome-title{
    text-align: center;
}

.welcome-title a ,.rewards-title a{
    font-weight: 500;
    border-bottom: 1px solid #000;
    font-size: 14px;
}

.welcome-title a:hover, .rewards-title a:hover{
    color: #DB203B;
    border-bottom: 1px solid #DB203B;
}

#refer-popup .addcart_row{
    padding: 50px;
}

.innerproduct .product-short-description p{
    margin: 0;
}
.compo_mdfselect_maindiv .error_combo_div {
    color: red;
    display: none;
}

/* ===============White Theme================*/
.whitetheme input[type="reset"]:hover, .whitetheme input[type="button"]:hover, .whitetheme input[type="submit"]:hover, .whitetheme .btn:hover, .whitetheme .button:hover, .whitetheme button:hover, .whitetheme .takelist-btn-part .button:first-child:hover, .whitetheme .delevery_popup_datetime .button:first-child:hover, .whitetheme .cartaction_checkout {
    color: #fff;
    background: rgb(162,84,40);
background: -moz-linear-gradient(left,  rgba(162,84,40,1) 0%, rgba(208,138,62,1) 100%);
background: -webkit-linear-gradient(left,  rgba(162,84,40,1) 0%,rgba(208,138,62,1) 100%);
background: linear-gradient(to right,  rgba(162,84,40,1) 0%,rgba(208,138,62,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a25428', endColorstr='#d08a3e',GradientType=1 );
}
.whitetheme a:hover {
    color: #ce883d;
}
.whitetheme header { background: #fff; }
.whitetheme ul.hmenu_list > li > a, .whitetheme .hsign_sec > a{ color:#0d0b06; font-weight: 500; font-size: 16px;} 
.whitetheme ul.hmenu_list > li > a:hover, .whitetheme .hsign_sec > a:hover{ color:#ce883d; }
.whitetheme .products-single-li:hover .products-ordernow-action .button {
background: rgb(162,84,40);
background: -moz-linear-gradient(left,  rgba(162,84,40,1) 0%, rgba(208,138,62,1) 100%);
background: -webkit-linear-gradient(left,  rgba(162,84,40,1) 0%,rgba(208,138,62,1) 100%);
background: linear-gradient(to right,  rgba(162,84,40,1) 0%,rgba(208,138,62,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a25428', endColorstr='#d08a3e',GradientType=1 ); color: #fff;
}
.whitetheme .chk-payment-btn-row .button{ background: #000; }
.whitetheme .products-list-ulmain li:hover, .whitetheme .category-products-main .products-single-li:hover {
    border: 2px solid #ce883d;
}
.whitetheme footer {
    background: #f1efef;
    color: #000;
}
.whitetheme .copyright-section{ background: #fff; }
.whitetheme .footer-newletter span, .whitetheme .footer-newletter h4, .whitetheme .f-newletter-bottom label, .whitetheme .footer-nav h5, .whitetheme .footer-nav ul li a, .whitetheme .f-newletter-form input[type="text"] { color: #000; } 
.whitetheme .f-newletter-bottom input[type="checkbox"]{ background: #f1efef; border-color: #ce883d; }
.whitetheme .f-newletter-form input[type="text"]::placeholder {color: #000;}
.whitetheme .home-our-journey{ background: url(../images/texture-light.jpg) no-repeat; color: #000;     background-size: cover; }
.whitetheme .home-our-journey .button:hover, .whitetheme .hordertype_sec a, .whitetheme .acc-inform .form-group .row .button, .whitetheme .refer-btn a, 
.whitetheme .prd_chosen_sub_item_right button {background: rgb(162,84,40);
background: -moz-linear-gradient(left,  rgba(162,84,40,1) 0%, rgba(208,138,62,1) 100%);
background: -webkit-linear-gradient(left,  rgba(162,84,40,1) 0%,rgba(208,138,62,1) 100%);
background: linear-gradient(to right,  rgba(162,84,40,1) 0%,rgba(208,138,62,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a25428', endColorstr='#d08a3e',GradientType=1 ); color: #fff;
}
.whitetheme .home-our-journey .button{border: 0;}
.whitetheme .hsign_sec > span{     color: #000; }
.whitetheme .hmenu_sec .desktop_hmenu_list ul.submenu_list li a:hover {  color: #d08a3e;}
.whitetheme .f-newletter-form .button:after{ background: url(../images/brown-arrow.svg) no-repeat;  }


.whitetheme .takelist-btn-part .button:first-child:hover, .whitetheme .delevery_popup_datetime .button:first-child:hover {
    border: 1px solid #b66b31;
}

.whitetheme ul.hmenu_list > li.active ul li a , .whitetheme ul.hmenu_list > li.active > a, .whitetheme .controls.two-links a:hover, .whitetheme .signup-popup .full-login-new .popup-body .form-group-11 .single-link a:hover, .whitetheme .controls.single-link a:hover, .whitetheme .forgot-password-popup .full-login-new .popup-body .form-group .single-link a:hover, .whitetheme .popup-body .sign_reg a.sign_reg_uline:hover, .whitetheme .popup-body .sign_reg a:hover, .whitetheme .cart_body .hclear_cart:hover {
     color: #b66b31;
}
.whitetheme .menu-title-link div:hover, .whitetheme .owl-carousel .owl-item .menu-list-intvl.active div, .whitetheme .product-details-lhs > .slick-slider > .slick-list {
    border-color: #d08a3e;
}
.whitetheme .alert_header, .whitetheme .full-login-new-header,  .whitetheme .menu_icon .icon-bar, .whitetheme .mobile-menu, .whitetheme .mobile-menu .mobile-menu-header, .whitetheme .hcart_round, .whitetheme .promo-form .applyBtn:hover, .whitetheme .tnk-chk-order .button:hover  { background: #d08a3e !important;}
.whitetheme .cartaction_checkout:hover{ background: #000; }
.whitetheme .mobile-order-rhs a:hover, .whitetheme .mobile-menu .menu a:hover{ color: #000 }
.whitetheme .mobile-order-rhs a{ background: #a3562b; }
/* .whitetheme .sticky-menu-nav{ top: 106px;  } */
.whitetheme .menu-nav-section.sticky-menu-nav .menu-list-intvl a:before, .whitetheme .menu-nav-section.sticky-menu-nav .more-menu-parent:before {
    background: #c8813a;
}
.whitetheme .home-title, .whitetheme .home-title strong, .whitetheme .product-cms-content-top h3, .whitetheme .hoj-lhs h1 { font-family: 'Bessita Handwriting Free'; text-transform: capitalize; font-weight: normal;font-size: 38px;}
.whitetheme .product-cms-content-top h3{letter-spacing: 0; font-weight: bold}
.whitetheme #scrollbutton a{     color: #d08a3e; }
.whitetheme .hoj-lhs h5{ color: #000000}
.whitetheme .aboutus-page .container.cms-content {
    max-width: 100%;
    padding: 0;
    border: 0;
}
.whitetheme .aboutus-page .home-testimonial {
    padding: 70px 0 80px;
}
.whitetheme .aboutus-page .hoj-rhs .owl-nav{ display: none}
.whitetheme .compo_minmax_maindiv .combo_pro_price { color: #cf7a21;}
.whitetheme .homebanner-content-inner h4{ color: #ce883d; font-weight: 500;margin-bottom: 20px}
.whitetheme .homebanner-content-inner h2{font-weight: normal; font-family: 'Bessita Handwriting Free';}

.whitetheme .center-follow.text-center {
    padding: 40px 0 0 0;
    font-weight: bold;
    font-size: 20px;
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  .whitetheme input[type=checkbox],
.whitetheme input[type=radio] {
    --active: #bb7134;
  }
}

.outlet_unit_phone,.outlet_unit_mail{
    display: flex;
}
.pac-container { z-index: 99999 !important; }   
.check-billing .controls-two .focus-out {width: 47%;}   
.check-billing .controls-two span {margin-top: 10px;}

.footer-qrcode ul{
    padding: 0;
}

.footer-qrcode ul li{
    list-style: none;
    width: 120px;
    margin: 0 auto;
}

.footer-qrcode {
    margin: 30px 0 10px;
}

.footer-qrcode a {
    display: block;
    padding: 15px 20px;
    color: #fff;
    background: #DB203B;
}

.footer-qrcode a:hover {
    color: #000;
    background: #fff;
}

.qrheader img{
    /*width: 85px;*/
    height: 85px;
}

.qr_pop_col{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.qu_pop_item{
    width: 305px;
    height: 100%;
}

#qr-popup .full-login-new-body {
    max-width: 700px;
}

#qr-popup{
    background: #f6f6f6;
}


#qr-popup .scan-qr {
    padding: 40px 40px 30px;
}

#qr-popup .takelist-btn-part .button:first-child{
    min-width: 205px;
}

#qr-popup .takelist-btn-part{
    margin: 40px auto 0;
}

#qr-popup .self_pop_row{
    padding: 20px;
}

.failed-page .common-inner-banner {
    color: #fff;
    margin-bottom: 170px;
}
#otherpayment-popup .inside-dine-popup-rhs{
    background: #ffffff;
    padding: 45px;
    position: relative;
    z-index: 9;
    border: 1px solid rgb(0 0 0 / 90%);
    margin: 0 auto;
    transition: 0.4s linear all;
}

#otherpayment-popup .pop-whole-lhs-inner{
    margin: 0 auto;
}
.dark-theme #otherpayment-popup .inside-dine-popup-rhs{
    background: #0c0c0c;
}
.menu-section-left-inner.more-menu-avai {
    width: 100%;
}
.maccont_membershipmaindiv.membership_rewards {
    justify-content: center;
}
.dark-theme .tnk-detail h3 {
    color: #585858;
}
.StripeElement{
    height: auto!important;
}
.address_linfo span{
    position: inherit!important;
}

.product-step-inner-white{
    max-width: 680px;
    background: #fff;
    border-radius: 20px;
    margin: 0 auto;
    padding: 40px 50px;
}
.big-plane {
    max-width: 330px;
}
.payment-de {
    max-width: 330px;
}
.product-step-top{
    color: #000;
}
.product-step-top img{
    margin-bottom: 30px
}
.product-step-top h2{
  text-transform: inherit;
  font-size: 25px;    
  margin: 0 0 5px 0;   
    color: #000;
}
.product-step-top p{
  font-size: 17px;    
  margin: 0;    
    font-weight: 600;
}
.product-step-inner{
    max-width: 580px;
    margin: 0 auto;
}
.product-step-top {
    margin: 0 0 20px 0;
}
.product-step {
    padding: 0px 0 20px;
    margin: 0 0 30px 0;
}
.progressbar {
    padding: 0;
    display: flex;
}
.progressbar li {
    list-style-type: none;
    flex: 1 1 0;
    position: relative;
    text-align: center;
}
.progressbar li figure {
    width: 20px;
    height: 20px;
    display: block;
    text-align: center;
    margin: 0 auto 0;
    border-radius: 50%;
    position: absolute;
    z-index: 2;
    background: #dad8d8;
    top: 0;
    left: 0;
    right: 0;
    cursor: pointer;
    font-size: 0
}
.progressbar li:first-child figure{ left: 0px; right: inherit}
.progressbar li:last-child figure{ left: inherit; right: 0px}

.progress-content {
    padding: 50px 0 0;
}
.progressbar li:after {
    width: 100%;
    height: 6px;
    content: "";
    position: absolute;
    background-color: #e8e7e7;
    top: 8px;
    left: 0;
    z-index: 0;
    
}
.progressbar li:first-child:after {
    border-radius: 10px 0 0 10px
}
.progressbar li:last-child:after {
    border-radius: 0 10px 10px 0px
}

.progressbar li.active figure {
    background: #da203a;
}
.progressbar li.active:after{ background: #da203a;}

.pro-avbl-errormsg {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
    padding: 10px 15px;
    border-radius: 0;
    -webkit-border-radius: 0;
    text-align: left;
    transition: all .4s;
    margin-top: 5px;
    width: 100%;
}
.retry .button {
    padding: 15px 20px;
    margin: 10px 5px;
    font-size: 12px;
    border-radius: 5px;
}

.retry .button:first-child {
    background: #da203a;
}

.buttonscart{
    margin: 0 10px;
}
.hordertype_sec.home a:after{display: none;}

.hcart_scrollarea .timeslot_info_main .syd_time.delivery_submit_cls {
    display: flex;
    justify-content: flex-end;
}
.hcart_scrollarea .timeslot_info_main {
    width: 100%;
}
.hcart_scrollarea .cart-header-second .col-sm-cls {
    width: 100%;
}

/************************************ 16-08-2022 - start *************************************/

.hcart_maiin_scrollarea .hcart_scrollarea .overall-parent .product-details, 
.checkout-right-body-section .overall-parent .product-details-parent .product-details{
    display: block;
}

.hcart_maiin_scrollarea .hcart_scrollarea .overall-parent .product-details .product-details-container,
.checkout-right-body-section .overall-parent .product-details-parent .product-details .product-details-container{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

header .cart_action {
    padding: 0;
    z-index: 99;
    background: #fff;
}

.pro-avbl-errormsg{
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
    padding: 10px 15px;
    border-radius: 0;
    -webkit-border-radius: 0;
    text-align: left;
    /*display: none;*/
    transition: all .4s;
    margin-top: 5px;
    width: 100%;
}

.foodbarn-dflex .menu-title-link{
    padding: 12px 5px;
}

.hcart_scrollarea .syd_time .custom_select{
    display: inline-block;
    width: 65%;
}

.hcart_scrollarea .syd_time{
    text-align: right;
}

.category_open{
    overflow: hidden !important;
}

.chk-payment .pay_by_cash_hans{
    height: 70px;
    align-items: center;
}

.ohrs-main {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.ohrs-lhs {
    width: 55px;
}

.ohrs-rhs {
    width: calc(100% - 55px);
}

/* .home-banner .slick-slide .home-banner-info.home-add-text img{
    position: unset;
    left: 0;
    top: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
} */

.product_tag_sec_main .tab-content .fade.in{
    opacity: 1;
}

.product_tag_sec_main .tab-content .fade{
    opacity: 0;
}

.maccont_membership .no-membership .welcome-title {
    width: 100%;
}
.dark-theme .maccont_membership .welcome-title h1, .dark-theme .maccont_membership .welcome-title h1 span{
color: #fff;
}

.dark-theme .welcome-title .dark-thmimg{
    display: block;
}

.dark-theme .welcome-title .white-thmimg{
    display: none;
}

.welcome-title .dark-thmimg{
    display: none;
}
strong {
    font-weight: 700;
}

.ourstory-main{
    margin: 0 0 50px;
}

.ourstory-main ul{
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    list-style: none;
}

.ourstory-main li{
    text-align: center;
    width: 19.140625%;
    margin: 0 1.07421875% 20px 0;
}

.ourstory-main li:nth-child(5n) {
    margin-right: 0;
}

.ourstory-content{
    margin: 20px 0 30px;
    text-align: center;
}
.ourstory-mrgtop-40{
    margin-top: 40px;
}

.ourstory-mission{
    margin: 50px 0;
    text-align: center;
}

.ourstory-mission h5, .ourstory-vision h5{
    text-transform: capitalize;
    text-decoration: underline;
}

.ourstory-vision{
    text-align: center;
}
